/*    
<summary>
  This functional component "ShowPopupHOC" provides props to the child component.
  1. State "showShowPopup" that manages the show/hide of pop up
  2. Function "showPopupToggleHandler" to toggle the show/hide popup state "showShowPopup"
  Developer:Aashish Singh, Created Date:14-June-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, {useState } from 'react';

const ShowPopupHOC = (Component:any) => 
function Comp(props:any){
    const [showShowPopup,setShowShowPopup] = useState<boolean>(false);

    const showToggleHandler = () => {
        setShowShowPopup(!showShowPopup);
    }
	
	return <Component showShowPopup={showShowPopup} showPopupToggleHandler={showToggleHandler} {...props}/>;
};

export default ShowPopupHOC;   