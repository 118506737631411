/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   Developer: Aashish Singh, Created Date: 18-JUly-2024 
</summary>
*/
enum PaymentOptionEnum{
    Invoice = 0
}

export default PaymentOptionEnum;