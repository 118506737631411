/*    
<summary>
   This class component is all about Managing Authentication functionality.
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import jwt from 'jwt-decode';
import ILogin from '../../models/ILogin';
import { IAuthState } from '../../models/state/IAuthState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import IAuthResponse from '../../models/response/IAuthResponse';
import { ICommonState } from '../../models/state/ICommonState';
import base64 from 'base-64';
import roleTypeEnum from '../../constants/role-type-enum';
import { IPermissions } from '../../models/response/IRoleResponse';
import fotaStore from './fota-store';

export class AuthStore implements IAuthState, ICommonState {
    inProgress = false;
    error = '';
    isAuthenticated = false;
    token = '';
    userId: number = 0;
    tenantId: number = 0;
    userType = '';
    email = '';
    loginFormData:ILogin = {
        email: "",
        password: "",
        rememberMe:false,
    }
    logoutSuccess = false;
    logoutError = "";
    logoutInprogress= false;
    permissions: IPermissions[] = [];

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            tenantId: observable,
            userId: observable,
            isAuthenticated: observable,
            loginFormData: observable,
            logoutSuccess: observable,
            logoutError: observable,
            logoutInprogress: observable,
            permissions: observable,
            reset: action,
            login: action,
            logout: action,
            getToken: computed,
            getUserId: computed,
            getTenantId: computed,
            getOrganizationId: computed,
            getIsTrialTenant: computed,
            getAllPermission: computed
        });
    }

    get getAllPermission() {
        if(this.permissions) {
            return [...this.permissions];
        }
        return [];
    }

    get getRoleType() {
        return localStorage.getItem('mzl_role_type');
    }

    get getToken() {
        return localStorage.getItem('mzl_token');
    }

    get getRefreshToken() {
        return localStorage.getItem('mzl_refresh_token');
    }

    get getEmail() {
        return localStorage.getItem('mzl_email');
    }

    get getUserId() {
        let userId =  localStorage.getItem('mzl_userId') ? localStorage.getItem('mzl_userId') : "0" ;
        return parseInt(userId!);
    }
    get getTenantId() {
        let tenantId =  localStorage.getItem('mzl_tenantId') ? localStorage.getItem('mzl_tenantId') : "0" ;
        return parseInt(tenantId!);
    }
    
    get getOrganizationId() {
        let orgId =  localStorage.getItem('mzl_organizationId') ? localStorage.getItem('mzl_organizationId') : "" ;
        return orgId!;
    }
    
    get getIsTrialTenant() {
        return localStorage.getItem('mzl_IsTrialTenant');
    }

    /*
    This function is used to reset all observables to their initial values.  
    */

    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.isAuthenticated = false;
        this.logoutError = "";
        this.logoutInprogress = false;
        this.logoutSuccess = false;
        this.loginFormData = {
            email: "",
            password: "",
            rememberMe:false,
        }
    }
    /*
    This function is used to reset Logout observables to their initial values.  
    */

    resetLogout = () => {
        this.logoutError = "";
        this.logoutInprogress = false;
        this.logoutSuccess = false;
    }

    /*
    This function is used to Authenticate User and get token (if authentic) from API.  
     (Updated By:Deepak Paliwal, Date:10-March-2023)
    */

    login = (data: ILogin) => {
        this.inProgress = true;
        this.error = '';
        this.loginFormData = JSON.parse(JSON.stringify(data));
        const authData = { Email: data.email, Password: base64.encode(data.password) };
        console.log(URLConstants.Authenticate);
        return baseService.postRequest(URLConstants.Authenticate, authData)
            .then((response: IApiResponse<IApiSuccessResponse<IAuthResponse>>) => {
                if(response.data.Error)
                {
                    this.error = response.data.Message;
                }
                else{
                    let data = response.data.Data;
                    let role = data.Role ? data.Role.RoleType : "";
                    let userType = data.Role ? data.Role.UserType : "";
                    let roleName = data.Role ? data.Role.Name : "";
                    let permissions:IPermissions[] = data.Role ? data.Role.Permissions : [];
                    const userToken : any = jwt(data.AccessToken); 
                    this.permissions = permissions;
                    localStorage.setItem('mzl_email', userToken.userEmail);
                    localStorage.setItem('mzl_userId', data.UserId);
                    localStorage.setItem('mzl_role_type', role.toString());
                    localStorage.setItem('mzl_user_type', userType.toString());
                    localStorage.setItem('mzl_role_name', roleName);
                    localStorage.setItem('mzl_permissions', JSON.stringify(permissions));
                    localStorage.setItem('mzl_organizationId', userToken.organizationId);
                    localStorage.setItem('mzl_tenantId', userToken.tenantId);
                    localStorage.setItem('mzl_login_tenantId', userToken.tenantId);
                    localStorage.setItem('mzl_token', data.AccessToken);
                    localStorage.setItem('mzl_darkTheme', 'false');
                    localStorage.setItem('mzl_IsTrialTenant', 'false');
                    this.isAuthenticated = true;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => {
                this.inProgress = false;
            }));
    }

    /*
    This function is used to reset all observables to their initial values and clear local storage.  
    */

    logout = () => {
        this.logoutInprogress = true;
        return baseService.postRequest(URLConstants.Logout, {})
            .then((response: IApiResponse<any>) => {
                if(response.data.Error)
                {
                    this.logoutError = response.data.Message;
                }
                else{
                    this.reset();
                    const lang:any = localStorage.getItem('mzl_language')
                    const theme:any = localStorage.getItem('mzl_darkTheme')
                    localStorage.clear();
                    fotaStore.reset();
                    localStorage.setItem('mzl_language', lang === "jp" ? "jp" : "en");
                    localStorage.setItem('mzl_darkTheme', theme === "false" ? "false" : "true");
                    this.logoutSuccess = true;
                }
            })
            .catch((err: string) => {
                this.logoutError = err;
            })
            .finally(action(() => {
                this.logoutInprogress = false;
            }));
    }

}

export default new AuthStore();
