/*    
<summary>
  This functional component "RegisterDeviceByCSVForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date: 11-June-2024,
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/StoreProvider";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormFileInput from "../../../shared-components/FormikFormHandlers/FormikFormFileInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { registerDeviceCsvValidateSchema, selectGroupValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import Label from "../../../shared-components/Label/Label";
import { formatMessage } from "../../../translations/format-message";
import { IOption } from "../../../models/ICommon";
import IAddDeviceIccidBulk, { IDeviceIccidBulkDetails } from "../../../models/Form/IAddDeviceRegisterInBulk";
import moment from "moment";
import Papa from "papaparse";
import { DATE_TIME_FORMAT_FILENAME } from "../../../constants/common-constants";
import { getTenantIdFromLocalStorage } from "../../../helpers/local-stotrage-helper";
import AddUpdateDeviceRegisterDetail from "./add-edit-device-register-detail";

interface IProps {
  initialValues: {CSVFile: string, OrderNo:string};
  tenantId:number;
  submitHandler: (data: any) => void;
  isLoading: boolean;
  deliveredOrderList: IOption[];
  modalClosed: () => void;
}

const RegisterDeviceByCSVForm = (props: IProps) => {
  let totalDeviceToBeAdded: number = 0;
  const { deviceRegisterStore, preferencesStore } = useStore();
  const { getAddBulkCount } = preferencesStore;
  const { resetAddDeviceRegisterCSV} = deviceRegisterStore;
  const [targetData, setTargetData] = useState("");
  const { initialValues, submitHandler, isLoading, modalClosed } =
    props;

    const getHeaderArray = (csvRecordsArr: any): string[] => {
      let headerArray = [];
      let headers = csvRecordsArr[0].split(",");
      for (let i = 0; i < headers.length; i++) {
        headerArray.push(headers[i]);
      }
      return headerArray;
    };
  
    const headerInvalidError = (headersRow: any) => {
      var isInvalid: boolean = false;
      if (headersRow.length > 2) {
        isInvalid = true;
        return isInvalid;
      } else if (
        headersRow[0].trim() != "Name" ||
        headersRow[1].trim() != "ICCID"
      ) {
        isInvalid = true;
        return isInvalid;
      }
      return isInvalid;
    };
  
    const handleFileUpload = (event: any) => {
      var files = event?.target?.result;
      setTargetData(files.toString());
    };
  
    const startFileDecode = () => {
      if (targetData) {
        let csvData: string = targetData;
        let csvRecordsArray = csvData?.split(/\r\n|\n/);
        let headersRow = getHeaderArray(csvRecordsArray);
        if (csvRecordsArray.length > 0) {
          totalDeviceToBeAdded = csvRecordsArray.length - 2;
        }
        let invalid = headerInvalidError(headersRow);
        if (invalid) {
          toast.error(formatMessage("invalid_headers"));
        } else {
          if (csvRecordsArray[1] != "") {
            let csv = CSVDataConditions(headersRow, csvRecordsArray);
            if (csv && csv?.length > 0) {
              return csv;
            }
          } else {
            toast.error(formatMessage("empty_csv"));
            return [];
          }
        }
      } else {
        toast.error(formatMessage("invalid_csv_format"));
      }
    };
  
    const CSVDataConditions = (headersRow: any, csvRecordsArray: any) => {
      let dataManipulated = getDataRecordsArrayFromCSVFile(
        csvRecordsArray,
        headersRow.length
      );
      return dataManipulated;
    };
  
    const getDataRecordsArrayFromCSVFile = (
      csvRecordsArray: any,
      headerLength: number
      ) => {
      var ImportDataArr = [];
      var totalColumns = 2;
      csvRecordsArray.splice(csvRecordsArray.length - 1, 1);
      for (let i = 1; i < csvRecordsArray?.length; i++) {
        let data = csvRecordsArray[i]?.split(",");
        let splits = data[2]?.split(".");
        if (data[2] != "") {
          splits = splits?.map((str:any) => str.replace(/\\/g, "").replace(/"/g, "")).filter(Boolean);
        }
        if (data.length > totalColumns) {
          data.splice(totalColumns);
        }
        if (data.length == totalColumns) {
          var CSVdata = {
            Name: data[0]?.trim(),
            ICCID: data[1]?.trim(),
          };
          ImportDataArr.push(CSVdata);
        }
      }
      return ImportDataArr;
    };
  
    function stringToBool(str: string): boolean | null {
      const lowerStr = str.toLowerCase();
      if (lowerStr === "true") {
        return true;
      } else if (lowerStr === "false") {
        return false;
      } else {
        return null; // Return null for invalid input
      }
    }

    const downloadDummyCSVHandler = () => {
      const currentDate = new Date();
      const csv = Papa.unparse([{Name:"ABCDE",ICCID:"123456"},{}]);
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = "RegisterDeviceSampleCSV" + moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) + ".csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };
  
    useEffect(() => {
      if (getAddBulkCount > 0) {
        modalClosed();
        resetAddDeviceRegisterCSV();
      }
    }, [getAddBulkCount]);
  return (
    <>
      <div className="mx-auto">
        {isLoading && (
          <FormLoader loading={getAddBulkCount > 0 ? false : isLoading} />
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={registerDeviceCsvValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            let data:Array<IDeviceIccidBulkDetails> | undefined = startFileDecode();
            let tempValues: IAddDeviceIccidBulk = {
              OrderNo: values.OrderNo,
              Devices: data ? data : [],
              TenantId: props.tenantId,
            };
            if (data && data.length > 0) {
              submitHandler({...tempValues});
            }
          }}
          render={({ values }) => {
            return (
              <Form id="formBody">
                <div className="row px-4">
                <div className="col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style mb-0"
                      required={true}
                      label="order_no"
                    />
                    <Field
                      data-testid="orderNo"
                      className="form-control"
                      name="OrderNo"
                      getData={true}
                      options={props.deliveredOrderList}
                      as={FormikFormSelect}
                    />
                    <p className="small mt-1 mb-2">
                        <AddUpdateDeviceRegisterDetail orderNo={values.OrderNo} />
                      </p>
                  </div>
                </div>
                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="import_csv_file"
                      />
                      <a href="javascript:;"
                              className="download_icon"
                              onClick={downloadDummyCSVHandler}
                              title={formatMessage("download_sample_csv")}>
                              <svg
                                version="1.1"
                                x="0px"
                                y="0px"
                                fill="currentColor"
                                height="13"
                                width="13"
                                viewBox="0 0 512 512"
                              >
                                <g>
                                  <g>
                                    <path
                                      d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
        c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
        c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"
                                    />
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z" />
                                  </g>
                                </g>
                              </svg>
                            </a>
                      <Field
                        className="form-control"
                        id="ckf_file"
                        name="CSVFile"
                        handleFileUpload={handleFileUpload}
                        as={FormikFormFileInput}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer border-0 pt-0">
                  <LoaderButton
                    type="submit"
                    id="SaveDevice"
                    isLoading={isLoading}
                    text="submit"
                  />
                  <Button
                    data-testid="Cancel"
                    variant="secondary"
                    onClick={modalClosed}
                    disabled={isLoading}
                    className=""
                  >
                    <FormattedMessage id="close" />
                  </Button>
                </div>
              </Form>
            );
          }}
        ></Formik>
      </div>
    </>
  );
};

export default observer(RegisterDeviceByCSVForm);
