/*    
<summary>
  This functional component "AddUpdateDeviceForm" provides form to add new user or update existing user.
  Developer:Mohammad Saquib Khan, Created Date: 29-March-2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import LoaderButton from "../../../../../shared-components/Button/LoaderButton";
import FormLoader from "../../../../../shared-components/FormLoader/FormLoader";
import FormikFormSelect from "../../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { selectGroupValidateSchema } from "../../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../../../shared-components/Label/Label";
import { formatMessage } from "../../../../../translations/format-message";
import toast from "react-hot-toast";
import { useStore } from "../../../../../contexts/StoreProvider";
import FormikFormFileInput from "../../../../../shared-components/FormikFormHandlers/FormikFormFileInput";

interface IProps {
  initialValues: any;
  groupList: any;
  submitHandler: (data: any) => void;
  isLoading: boolean;
  modalClosed: () => void;
}

const ChooseGroupForm = (props: IProps) => {
  let totalDeviceToBeAdded: number = 0;
  const { deviceStore, preferencesStore } = useStore();
  const { getAddBulkCount } = preferencesStore;
  const { resetAddBulkDeviceState , resetAddDeviceCSV} = deviceStore;
  const [targetData, setTargetData] = useState("");
  const { initialValues, submitHandler, groupList, isLoading, modalClosed } =
    props;

  const getHeaderArray = (csvRecordsArr: any): string[] => {
    let headerArray = [];
    let headers = csvRecordsArr[0].split(",");
    for (let i = 0; i < headers.length; i++) {
      headerArray.push(headers[i]);
    }
    return headerArray;
  };

  const headerInvalidError = (headersRow: any) => {
    var isInvalid: boolean = false;
    if (headersRow.length > 3) {
      isInvalid = true;
      return isInvalid;
    } else if (
      headersRow[0].trim() != "Name" ||
      headersRow[1].trim() != "ICCID" ||
      headersRow[2].trim() != "Tags"
    ) {
      isInvalid = true;
      return isInvalid;
    }
    return isInvalid;
  };

  const handleFileUpload = (event: any) => {
    var files = event?.target?.result;
    setTargetData(files.toString());
  };

  const startFileDecode = () => {
    if (targetData) {
      let csvData: string = targetData;
      let csvRecordsArray = csvData?.split(/\r\n|\n/);
      let headersRow = getHeaderArray(csvRecordsArray);
      if (csvRecordsArray.length > 0) {
        totalDeviceToBeAdded = csvRecordsArray.length - 2;
      }
      let invalid = headerInvalidError(headersRow);
      if (invalid) {
        toast.error(formatMessage("invalid_headers"));
      } else {
        if (csvRecordsArray[1] != "") {
          let csv = CSVDataConditions(headersRow, csvRecordsArray);
          if (csv && csv?.length > 0) {
            return csv;
          }
        } else {
          toast.error(formatMessage("empty_csv"));
          return [];
        }
      }
    } else {
      toast.error(formatMessage("invalid_csv_format"));
    }
  };

  const CSVDataConditions = (headersRow: any, csvRecordsArray: any) => {
    let dataManipulated = getDataRecordsArrayFromCSVFile(
      csvRecordsArray,
      headersRow.length
    );
    return dataManipulated;
  };

  const getDataRecordsArrayFromCSVFile = (
    csvRecordsArray: any,
    headerLength: number
    ) => {
    var ImportDataArr = [];
    var totalColumns = 3;
    csvRecordsArray.splice(csvRecordsArray.length - 1, 1);
    for (let i = 1; i < csvRecordsArray?.length; i++) {
      let data = csvRecordsArray[i]?.split(",");
      let splits = data[2]?.split(".");
      if (data[2] != "") {
        splits = splits?.map((str:any) => str.replace(/\\/g, "").replace(/"/g, "")).filter(Boolean);
      }
      if (data.length > totalColumns) {
        data.splice(totalColumns);
      }
      if (data.length == totalColumns) {
        var CSVdata = {
          Name: data[0]?.trim(),
          ICCID: data[1]?.trim(),
          Tags: data[2] == "" ? [] : splits.slice(0, 5)
        };
        ImportDataArr.push(CSVdata);
      }
    }
    return ImportDataArr;
  };

  function stringToBool(str: string): boolean | null {
    const lowerStr = str.toLowerCase();
    if (lowerStr === "true") {
      return true;
    } else if (lowerStr === "false") {
      return false;
    } else {
      return null; // Return null for invalid input
    }
  }

  useEffect(() => {
    if (getAddBulkCount > 0) {
      modalClosed();
      resetAddDeviceCSV();
    }
  }, [getAddBulkCount]);

  return (
    <>
      <div className="mx-auto">
        {isLoading && (
          <FormLoader loading={getAddBulkCount > 0 ? false : isLoading} />
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={selectGroupValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            let data = startFileDecode();
            values.AddDevices = data;
            if (data && data.length > 0) {
              submitHandler(values);
            }
          }}
          render={({ values }) => {
            return (
              <Form id="formBody">
                <div className="row px-4">
                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="available_group"
                      />
                      <Field
                        id="Groups"
                        className="form-control"
                        name="GroupId"
                        options={groupList}
                        as={FormikFormSelect}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-4">
                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="import_csv_file"
                      />
                      <Field
                        className="form-control"
                        id="ckf_file"
                        name="CSVFile"
                        handleFileUpload={handleFileUpload}
                        as={FormikFormFileInput}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer border-0 pt-0">
                  <LoaderButton
                    type="submit"
                    id="SaveDevice"
                    isLoading={isLoading}
                    text="submit"
                  />
                  <Button
                    data-testid="Cancel"
                    variant="secondary"
                    onClick={modalClosed}
                    disabled={isLoading}
                    className=""
                  >
                    <FormattedMessage id="close" />
                  </Button>
                </div>
              </Form>
            );
          }}
        ></Formik>
      </div>
    </>
  );
};

export default observer(ChooseGroupForm);
