/*    
<summary>
   This component is all about Managing Device & performing CRUD operations on the users.
   CRUD (Create, Read, Update, Delete)
   Developer: Aashish Singh, Created Date:10-April-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import "./Device.css";
import { observer } from "mobx-react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Grid from "../../shared-components/Grid/Grid";
import AddDevicePopupHOC from "./hoc/AddDevicePopupHOC";
import { useStore } from "../../contexts/StoreProvider";
import protocolEnum from "../../constants/protocol-enum";
import AddDevice from "./add-edit-device/add-edit-device";
import Pagination from "../../shared-components/Grid/Pagination";
import IPageInfo, { IActionFormatter, IOption, IPageLimitInfo } from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { faTrashAlt, faPencilAlt, faPaperPlane, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import ConfirmPopup from "../../shared-components/confirmationPopup/confirm-popup";
import DeletePopupHOC from "../../shared-components/confirmationPopup/hoc/confirmation-popup-hoc";
import {
  ActionFormatter,
  DeviceActionFormatter,
  IsActiveFirstPacketTimeFormatter,
  TableTagsFormatter,
} from "../../shared-components/Grid/GridFormatter";
import ActiveDeviceConfirmPopupHOC from "./hoc/device-active-popup-hoc";
import AllDeviceDeletePopupHOC from "./hoc/all-device-delete-popup-hoc";
import SelectedDeviceDeletePopupHOC from "./hoc/selected-device-delete-popup-hoc";
import userTypeEnum from "../../constants/user-type-enum";
import SlidingPanel from "react-sliding-side-panel";
import DeviceDetials from "./device-details";
import AllGroupsPopup from "./add-edit-device/all-groups-popup";
import SelectGroupPopupHOC from "./hoc/SelectGroupPopupHOC";
import SelectGroupsPopup from "./add-edit-device/select-groups-popup";
import ChooseGroupPopupHOC from "./hoc/ChooseGroupPopupHOC";
import moment from "moment";
import config from "../../helpers/config-helper";
import no_device from "../../assets/Images/svg/no-device.svg";
import {
  DeviceFilter,
  IDeviceFilter,
  IDeviceList,
  ISelectedDevice,
} from "../../models/response/IDeviceResponse";
import DeviceFilterState from "../device/add-edit-device/device-filter";
import _ from "lodash";
import DeviceFilterPopupHOC from "./hoc/DeviceFilterPopupHOC";
import userSettingEnum from "../../constants/user-setting-enum";
import LoaderIconButton from "../../shared-components/Button/LoaderIconButton";
import Papa from "papaparse";
import PublishMessagePopupHOC from "./hoc/PublishMessagePopupHOC";
import Downlink from "./add-edit-device/downlink";
import systemModuleEnum from "../../constants/system-module-enum";
import { isOperationPermitted, showIcons } from "../../helpers/action-permission-helper";
import { getLoggedInTenantIdFromLocalStorage, getRoleTypeFromLocalStorage, getTenantIdFromLocalStorage, getThemeFromLocalStorage, getUserTypeFromLocalStorage } from "../../helpers/local-stotrage-helper";
import { getProtocol } from "../../constants/common-constants";
import roleTypeEnum from "../../constants/role-type-enum";
import { useHistory } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import FilteredDeviceDeletePopupHOC from "./hoc/filtered-device-delete-popup-hoc";

const appConfig = config();
const fileNameDateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_FILENAME;

const Device = (props: any) => {
  const { preferencesStore, deviceStore, groupStore, tenantStore, oneNceStore, deviceRegisterStore } =
    useStore();
  const {
    userSetting,
    language,
    activeGroupId,
    setUpdateFilterActive,
    deviceNameFilter,
    setUpdateDeviceName,
    filterActive,
    tags,
    setUpdateTags,
    setUpdateActiveGroup,
    getAddBulkCount,
    setUpdateSearchByICCID,
    getSearchByICCID,
    setUserSetting,
    islanguageChanged,
    updateLanguageChangedFlag,
  } = preferencesStore;
  const {
    getAllDevices,
    deleteAllDevice,
    deleteSelectedDeviceState,
    deleteSelectedDevice,
    deviceDataList,
    inProgress,
    allDeviceslist,
    setSelectedTagDeviceId,
    addUpdateDevicestate,
    deleteDeviceState,
    deleteAllDeviceState,
    reset,
    updateDeviceActiveSwitch,
    deleteDevice,
    deviceDataListByFilter,
    moveDevicesState,
    resetGetDeviceDetail,
    resetMoveDevicesToGroups,
    addBulkDeviceState,
    resetAddBulkDeviceState,
    exportDevices,
    exportDevicesState,
    resetExportCSVState,
    resetDeleteFilteredDevices,
    deleteFilteredDevice,
    deleteFilteredDevicesState,
    allTagslist,
    deviceDetailState,
    totalPacketsCountState,
    allDevicesNameList,
    allDevicesIccidList,
    addDeviceCSVState,
    resetDeviceInfoState
  } = deviceStore;

  const {
    getOneNceTokenService,
    resetOneNceErrorState,
    isErrorPresent,
    simDataLoading,
    resetOneNceDeviceSimUsageState
  } = oneNceStore;
  const { allAvailableGroups, allAvailableGroupsFilter } = groupStore;
  const { registerDeviceCountState, registerDeviceCount, resetRegisterDeviceCountState, GetRegisteredDeviceCountByTenantIdService } = deviceRegisterStore;
  let localStorageUserType: number = getUserTypeFromLocalStorage();
  const { GetAllTenantsService, resetAllTenantList, allTenants, allTenantOptions, selectedTenantId, setSelectedTenantId } = tenantStore;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [openPanel, setOpenPanel] = useState(false);
  const [updateDeviceObj, setupdateDeviceObj] = useState<any>({});
  const [groupId, setGroupId] = useState<number>(activeGroupId);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  // const allDeviceslist: Array<any> = allDeviceslist;
  // console.log(">>>>>Sss", allDeviceslist);
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.deviceLimitConfig,
    isPageLimitChange: false,
  });

  const [singleSelectedDevices, setSingleSelectedDevices] = useState<
    Array<number>
  >([]);
  
  const [selectedDevicesGroup, setSelectedDevicesGroup] = useState<
    Array<string>
  >([]);

  const [selectedDestinationProtocol, setSelectedDestinationProtocol] = useState<
  Array<any>
  >([]);

  const [selectedDevices, setSelectedDevices] = useState<Array<any>>([]);

  const [IsFilterActive, setIsFilterActive] = useState<boolean>(filterActive);
  const [filter, setFilter] = useState<IDeviceFilter>({
    GroupId: activeGroupId,
    Tags: tags,
    DeviceName: deviceNameFilter,
    SearchByICCID: getSearchByICCID.toString(),
    isFilterActive: IsFilterActive,
  });

  const GroupId = props.location.state ? props.location.state.groupId : -1;

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const history = useHistory();

  const filterSubmitHandler = (values: DeviceFilter) => {
    closeHandler();
    setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage: true });
    setFilter({ ...values, isFilterActive: true });
    setFilterState(values);
    closePanelHandler();
    console.log(activeGroupId, tags, deviceNameFilter, getSearchByICCID);
  };

  /**
   * This function calls the "getAllDevices" store funtion that get device list from API
   */
  const callGetAllDevicesService = () => {
    if(localStorageUserType === userTypeEnum.SystemUser)
      {if(selectedTenantId > 0 ) callGetAllDevicesBySystemUser();}
    else
      callGetAllDevicesByUser();
  };
  
  const callGetAllDevicesBySystemUser = () => {
    if(selectedTenantId > 0 && allTenants.length > 0){
      if (filter.isFilterActive) {
        let obj = {
          ..._.omit(filter, ["isFilterActive"]),
          SearchByICCID: JSON.parse(filter.SearchByICCID),
          PagingDetails: {
            PageNo: pageInfo.currentPage,
            PageSize: pageLimitInfo.pageLimit,
          },
        };
        getAllDevices(obj, selectedTenantId);
      } else {
        let default_obj = {
          GroupId: 0,
          Tags: [],
          DeviceName: "",
          SearchByICCID: false,
          PagingDetails: {
            PageNo: pageInfo.currentPage,
            PageSize: pageLimitInfo.pageLimit,
          },
        };
      getAllDevices(default_obj, selectedTenantId);
      setPageInfo({ ...pageInfo, isRefreshPage: false });
    }
    GetRegisteredDeviceCountByTenantIdService(selectedTenantId);
    }
    
  };

  const callGetAllDevicesByUser = () => {
    if(selectedTenantId == -1)
      if (filter.isFilterActive) {
        let obj = {
          ..._.omit(filter, ["isFilterActive"]),
          SearchByICCID: JSON.parse(filter.SearchByICCID),
          PagingDetails: {
            PageNo: pageInfo.currentPage,
            PageSize: pageLimitInfo.pageLimit,
          },
        };
        getAllDevices(obj, getTenantIdFromLocalStorage());
      } else {
        let default_obj = {
          GroupId: 0,
          Tags: [],
          DeviceName: "",
          SearchByICCID: false,
          PagingDetails: {
            PageNo: pageInfo.currentPage,
            PageSize: pageLimitInfo.pageLimit,
          },
        };
      getAllDevices(default_obj, getTenantIdFromLocalStorage());
      setPageInfo({ ...pageInfo, isRefreshPage: false });
    }
    GetRegisteredDeviceCountByTenantIdService(getTenantIdFromLocalStorage());
  };
  
  const deleteAllFiltered = () => {
    let obj = {
      ..._.omit(filter, ["isFilterActive"]),
      SearchByICCID: JSON.parse(filter.SearchByICCID),
    };
    deleteFilteredDevice(obj, selectedTenantId);
  };

  const callExportDevices = () => {
    let obj = {
      ..._.omit(filter, ["isFilterActive"]),
      SearchByICCID: JSON.parse(filter.SearchByICCID),
      TenantId: localStorageUserType === userTypeEnum.SystemUser ? selectedTenantId : getTenantIdFromLocalStorage()
    };
    exportDevices(obj);
  };

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.DeviceLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function shows the add device pop up from where we can add new device and update existing device. we use id to identify
   * that we are adding or updating device.
   * "Id == -1" : we are adding new device
   * "Id != -1" : means we are updating existing device
   */
  const openAddDeviceHandler = (row: { Id: number }, event?: any) => {
    event.stopPropagation();
    closePanelHandler();
    setSelectedId(row.Id);
    resetGetDeviceDetail();
    props.addDeviceToggleHandler();
  };

  /**
   * This function deletes the device by providing Id to the Api
   */
  const deleteHandler = () => {
    closePanelHandler();
    deleteDevice(selectedId, selectedTenantId);
  };

  /**
   * This function shows the device data popup component and sets the seleted device Id
   */
  const dataHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    // props.deletePopupToggleHandler();
  };

  /**
   * This function shows the delete device confirmation popup component and sets the seleted device Id
   */
  const deleteClickHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    closePanelHandler();
    setSelectedId(row.Id);
    props.confirmToggleHandler();
  };

  const publishClickHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation();
      closePanelHandler();
      setSelectedRow(row);
    props.publishMessageToggleHandler();
  };

  /**
   * This function shows the Group Details component
   */
  const openPanelHandler = () => {
    setOpenPanel(true);
  };

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetAllDevicesService();
  };

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if(localStorageUserType === userTypeEnum.SystemUser)
      GetAllTenantsService();
    getOneNceTokenService();
    return(()=>{
      resetAllTenantList();
      setSelectedTagDeviceId(0);
      resetFilter();
      resetRegisterDeviceCountState();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(selectedTenantId > 0)
      callGetAllDevicesService();
    else
      callGetAllDevicesService();
  },[selectedTenantId, filter, allTenants]);

  // useEffect(() => {
  //   if (filter.isFilterActive) {
  //     callGetAllDevicesService();
  //   } else {
  //     callGetAllDevicesService();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filter]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetAllDevicesService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deviceDataList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: deviceDataList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDataList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deviceDataList" of the useEffect changed.
   */
  // useEffect(() => {
  //   setPageInfo({
  //     ...pageInfo,
  //     totalPages: deviceDataListByFilter?.PagingDetails?.TotalPages,
  //     isPagerChange: false,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deviceDataListByFilter]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetAllDevicesService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateDevicestate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateDevicestate.success) {
      callGetAllDevicesService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateDevicestate.success]);

  useEffect(() => {
    if (addDeviceCSVState.success) {
      callGetAllDevicesService();
    }
  }, [addDeviceCSVState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteDeviceState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteDeviceState.success) {
      toast.success(formatMessage("deleted_success"));
      reset();
      props.confirmToggleHandler();
      callGetAllDevicesService();
      setSelectedDevices([]);
      setSingleSelectedDevices([]);
      setSelectedDevicesGroup([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDeviceState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addBulkDeviceState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addBulkDeviceState.success) {
      resetAddBulkDeviceState();
      callGetAllDevicesService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBulkDeviceState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "exportDevicesState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (exportDevicesState.success) {
      resetExportCSVState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportDevicesState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteFilteredDevicesState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteFilteredDevicesState.success) {
      toast.success(formatMessage("deleted_success"));
      props.filteredDeviceDeleteToggleHandler();
      resetDeleteFilteredDevices();
      callGetAllDevicesService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFilteredDevicesState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteAllDeviceState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteAllDeviceState.success) {
      toast.success(formatMessage("deleted_success"));
      reset();
      props.allDeviceDeleteToggleHandler();
      callGetAllDevicesService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAllDeviceState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteSelectedDeviceState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteSelectedDeviceState.success) {
      toast.success(formatMessage("deleted_success"));
      reset();
      props.selectedDeviceDeleteToggleHandler();
      if ((selectedDevices.length >= pageLimitInfo.pageLimit) || (pageInfo.currentPage == pageInfo.totalPages && selectedDevices.length > allDeviceslist.length)) {
        setPageInfo({
          ...pageInfo,
          currentPage: 1,
          isPagerChange: true,
          isRefreshPage: true,
        });
      } else {
        callGetAllDevicesService();
      }
      setSelectedDevices([]);
      setSingleSelectedDevices([]);
      setSelectedDevicesGroup([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSelectedDeviceState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteDeviceState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteDeviceState.error) {
      toast.error(formatMessage(deleteDeviceState.error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDeviceState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteAllDeviceState.error" of the useEffect changed.
   */
  // useEffect(() => {
  //   if (deleteAllDeviceState.error) {
  //     toast.error(formatMessage(deleteAllDeviceState.error));
  //     reset();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deleteAllDeviceState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteSelectedDeviceState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteSelectedDeviceState.error) {
      toast.error(formatMessage(deleteSelectedDeviceState.error));
      setSelectedDevicesGroup([]);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSelectedDeviceState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteSelectedDeviceState.success" of the useEffect changed.
   */
  // useEffect(() => {
  //   if (deleteSelectedDeviceState.success) {
  //     callGetAllDevicesService();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deleteSelectedDeviceState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "islanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetAllDevicesService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "moveDevicesState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (moveDevicesState.success) {
      setSelectedDevices([]);
      setSingleSelectedDevices([]);
      setSelectedDevicesGroup([]);
      resetMoveDevicesToGroups();
      callGetAllDevicesService();
    }
  }, [moveDevicesState.success]);

  /**
   * This function will update the Activate / DeActivate the Stability of the Device
   * Parameters will be in Row & (e) will provide the Switch Value i.e, True / False.
   */
  const switchUpdate = (row: any, e: React.ChangeEvent<HTMLInputElement>) => {
    closePanelHandler();
    let obj = {
      Id: row.Id,
      GroupId: row.Group.Id,
      Name: row.Name,
      ICCID: row.ICCID,
      Tags: row.Tags,
    };
    setupdateDeviceObj(obj);
    props.deviceActiveConfirmToggleHandler();
  };

  const closeHandler = () => {
    props.deviceFilterToggleHandler();
  };

  const deviceFilterHandler = () => {
    props.deviceFilterToggleHandler();
  };

  const updateDeviceHandler = () => {
    closePanelHandler();
    //Updating the Device IsActive Field with the new Data Object
    updateDeviceActiveSwitch(updateDeviceObj.Id, updateDeviceObj);
  };

  const resetFilter = () => {
    closePanelHandler();
    setSingleSelectedDevices([]);
    setSelectedDevicesGroup([]);
    setUpdateTags([]);
    setUpdateDeviceName("");
    setUpdateFilterActive(false);
    setUpdateActiveGroup(0);
    setUpdateSearchByICCID("false");
    setFilter({
      GroupId: 0,
      Tags: [],
      DeviceName: "",
      SearchByICCID: "false",
      isFilterActive: false,
    });
    setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage: true });
  };

  const setFilterState = (values: DeviceFilter) => {
    setOpenPanel(false);
    setSingleSelectedDevices([]);
    setSelectedDevicesGroup([]);
    if (values.GroupId > 0 || (values.GroupId == 0 && values.Tags.length > 0)) {
      setUpdateActiveGroup(values.GroupId);
      setUpdateTags(values.Tags);
      setUpdateDeviceName(values.DeviceName);
      setIsFilterActive(true);
      setUpdateFilterActive(true);
      setUpdateSearchByICCID(values.SearchByICCID);
    } else {
      setUpdateActiveGroup(values.GroupId);
      setUpdateTags(values.Tags);
      setUpdateDeviceName(values.DeviceName);
      setIsFilterActive(getUserTypeFromLocalStorage() === userTypeEnum.SystemUser ? true : false);
      setUpdateFilterActive(getUserTypeFromLocalStorage() === userTypeEnum.SystemUser ? true : false);
      setUpdateSearchByICCID(values.SearchByICCID);
    }
  };

  const handleDropdownChange = (event: any) => {
    const selectedValue = event.target.value;
    setGroupId(selectedValue);
  };

  const handleDownload = () => {
    closePanelHandler();
    const currentDate = new Date();
    const deviceListToExport = selectedDevices.map((device:ISelectedDevice)=>{
      return ({
        ICCID: device.ICCID,
        Name: device.Name,
        GroupName: device?.GroupName,
        SourceProtocol: device.SourceProtocol,
        DestinationProtocol: device.DestinationProtocol,
        IsActive: device.IsActive,
        Tags: device.Tags,
        ActivationDate: device.ActivationDate,
      })
    });
    const csv = Papa.unparse(deviceListToExport);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download =  moment(currentDate).format(fileNameDateTimeFormat) + " Devices.csv";
    document.body.appendChild(a);
    a.click();
    toast.success(formatMessage("download_started_successfully"));
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  function handleRowSelect(row: any, isSelect: any, rowIndex: any, e: any) {
    setSelectedTagDeviceId(0);
    if (isSelect) {
      setSingleSelectedDevices((prevState) => {
        const array: any = [...prevState];
        array.push(row.Id);
        return array;
      });
      if(row.Group)
      {
        let isGroupPresent = selectedDevicesGroup.includes(row.Group.Name);
        if (!isGroupPresent)
        {
          setSelectedDevicesGroup((prevState) => {
            const array: string[] = [...prevState];
            array.push(row.Group.Name);
            return array;
          });
        }
      }

      setSelectedDestinationProtocol((prevState) => {
        const array: any[] = [...prevState];
        if(row.DestinationProtocol == 4)
          array.push({protocol: row.DestinationProtocol, Id: row.Id});
        return array;
      });

      setSelectedDevices((prevState) => {
        const array: any = [...prevState];
        var obj = {
          ICCID: row.ICCID,
          Name: row.Name,
          GroupName: row?.Group?.Name,
          SourceProtocol: protocolEnum[row.SourceProtocol],
          DestinationProtocol: protocolEnum[row.DestinationProtocol],
          IsActive: row.IsActive,
          Tags: row.Tags,
          FirstPacketTimeStamp: row.FirstPacketTimestamp,
          ActivationDate: row.ActivationDate,
        };
        array.push(obj);
        return array;
      });
    } else {
      setSingleSelectedDevices((prevState) => {
        const array: any = [...prevState];
        let index = array.findIndex((item: any) => item === row.Id);
        if (index > -1) array.splice(index, 1);
        return array;
      });

      if(row.Group){
        setSelectedDevicesGroup((prevState) => {
          const array: string[] = [...prevState];
          let index = array.findIndex((item: any) => item === row.Group.Name);
          let numberOfDevicesForGroup = selectedDevices.filter((device)=> device.GroupName == row.Group.Name);
          if (index > -1 && numberOfDevicesForGroup.length == 1) array.splice(index, 1);
          return array;
        });
      }

      setSelectedDestinationProtocol((prevState) => {
        const array: any[] = [...prevState];
        let index = array.findIndex((item: any) => item.Id === row.Id);
        if (index > -1) array.splice(index, 1);
        return array;
      });

      setSelectedDevices((prevState) => {
        const array: any = [...prevState];
        let index = array.findIndex((item: any) => item.ICCID === row.ICCID);
        if (index > -1) array.splice(index, 1);
        return array;
      });
    }
  }
  
  function handleSelectAll(isSelect: any, rows: any, e: any) {
    setSelectedTagDeviceId(0);
    if (isSelect) {
      setSingleSelectedDevices((prevState) => {
        const array: any = [...prevState];
        rows.forEach((row: any) => {
          array.push(row.Id);
        });
        return array;
      });

      setSelectedDevicesGroup((prevState:string[]) => {
        const array:string[] = [...prevState];
        rows.forEach((row: any) => {
          if(row.Group){
            let isGroupPresent = array.includes(row.Group.Name);
            if (!isGroupPresent)
              array.push(row.Group.Name);
          }
        });
        return array;
      });

      setSelectedDestinationProtocol((prevState:string[]) => {
        const array:any[] = [...prevState];
        rows.forEach((row: any) => {
          if(row.DestinationProtocol == 4){
              array.push({protocol: row.DestinationProtocol, Id: row.Id});
          }
        });
        return array;
      });

      setSelectedDevices((prevState) => {
        const array: any = [...prevState];
        rows.forEach((row: any) => {
          var obj = {
            ICCID: row.ICCID,
            Name: row.Name,
            GroupName: row?.Group?.Name,
            SourceProtocol: protocolEnum[row.SourceProtocol],
            DestinationProtocol: protocolEnum[row.DestinationProtocol],
            IsActive: row.IsActive,
            Tags: row.Tags,
            FirstPacketTimeStamp: row.FirstPacketTimestamp,
            ActivationDate: row.ActivationDate,
          };
          array.push(obj);
        });
        return array;
      });
    } else {
      setSingleSelectedDevices([]);
      setSelectedDevices([]);
      setSelectedDevicesGroup([]);
      setSelectedDestinationProtocol([]);
    }
  }

  const selectRow = {
    mode: "checkbox",
    selected: singleSelectedDevices,
    onSelect: handleRowSelect,
    onSelectAll: handleSelectAll,
  };

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    if (
      !(
        simDataLoading ||
        deviceDetailState.inProgress ||
        totalPacketsCountState.inProgress
      )
    ) {
      resetOneNceDeviceSimUsageState();
      if(isErrorPresent.flag && row.ICCID != selectedRow.ICCID)
      resetOneNceErrorState();
      setSelectedRow(row);
      openPanelHandler();
    }
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const actionIcons: IActionFormatter[] = showIcons([
    {
      dataField: "isEdit",
      handler: openAddDeviceHandler,
      icon: faPencilAlt,
      isDisable: true,
      title: "edit",
      action: "edit",
      varient: "primary",
    },
    {
      dataField: "isDelete",
      handler: deleteClickHandler,
      icon: faTrashAlt,
      isDisable: true,
      title: "delete",
      action: "delete",
      varient: "danger",
    },
    {
      dataField: "isPublishMessage",
      handler: publishClickHandler,
      icon: faLocationArrow,
      isDisable: true,
      title: "publish",
      action: "edit",
      varient: "primarys",
    },
  ], systemModuleEnum.Device)
  /**
   * The column constant defines the column description for the user grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("id"),
      hidden: true,
    },
    {
      dataField: "GroupId",
      text: formatMessage("label_group_id"),
      hidden: true,
    },
    {
      dataField: "ICCID",
      text: formatMessage("iccid"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
    },
    {
      dataField: "Name",
      text: formatMessage("device_name"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
    },
    {
      dataField: "Tags",
      text: formatMessage("tags"),
      formatter: TableTagsFormatter(setSelectedTagDeviceId),
      classes: "overflow-visible assignGroupWrap",
    },
    {
      dataField: "Group.Name",
      text: formatMessage("group_name"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
      hidden: localStorageUserType ==  userTypeEnum.SystemUser ? true : false
    },
    {
      dataField: "Protocol",
      text: formatMessage("device_label_protocol"),
      formatter: (cell: any, row: any) => {
        return (
          <>
            {
              row.DestinationProtocol !== protocolEnum.AWSIoT ? (
                <span>
                  {row.DestinationProtocol == protocolEnum.HTTPS ? <FormattedMessage id="webhook" /> : 
                    row.DestinationProtocol == protocolEnum.AWSIoT ? <FormattedMessage id="aws_connector" /> : 
                    row.DestinationProtocol == protocolEnum.AWSS3Bucket ? <FormattedMessage id="aws_s3_bucket" /> :
                    row.DestinationProtocol == protocolEnum.AWSKinesis ? <FormattedMessage id="aws_kinesis" /> :
                    getProtocol(row.DestinationProtocol)}
                </span>
              ) :
              <>
                <FormattedMessage id="aws_connector" />
                {
                  (row.ClientCertificate == "" && row.ClientKey == "") &&
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#cf2026"
                      className="bi bi-info-circle ms-2"
                      viewBox="0 0 16 16"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html={formatMessage("iot_core_certificate_info")}
                      data-tooltip-variant="dark"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <Tooltip id="my-tooltip" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                  </>
                }
              </>
            }
          </>
        );
      },
      hidden: localStorageUserType ==  userTypeEnum.SystemUser ? true : false
    },
    {
      dataField: "FirstPacketTimestamp",
      text: formatMessage("first_packet_timestamp"),
      formatter: IsActiveFirstPacketTimeFormatter("first_packet_timestamp"),
    },
    {
      dataField: "",
      formatter: DeviceActionFormatter(actionIcons),
      text: formatMessage("actions"),
      hidden: actionIcons.length === 0 ? true : false,
    },
  ];

  const deleteAll = () => {
    deleteAllDevice(selectedTenantId);
  };

  const deleteSelected = () => {
    let obj = { 
      ICCIDs: selectedDevices.map((device:any)=>{ return device.ICCID }) 
    };
    deleteSelectedDevice(obj, selectedTenantId);
  };

  const deleteSelectedConfirm = () => {
    closePanelHandler();
    props.selectedDeviceDeleteToggleHandler();
  };
  
  const deleteFilteredConfirm = () => {
    closePanelHandler();
    props.filteredDeviceDeleteToggleHandler();
  };

  const chooseGroupHandler = () => {
    closePanelHandler();
    props.chooseGroupToggleHandler();
  };

  const moveDevices = () => {
    closePanelHandler();
    props.selectGroupToggleHandler();
  };

  const deleteAllDeviceConfirm = () => {
    closePanelHandler();
    props.allDeviceDeleteToggleHandler();
  };

  const closePanelHandler = () => {
    resetDeviceInfoState();
    setSelectedRow({});
    setOpenPanel(false);
    resetGetDeviceDetail();
    oneNceStore.reset();
  };

  return (
    <React.Fragment>
      {(moveDevicesState.inProgress || tenantStore.inProgress || registerDeviceCountState.inProgress)  && (
        <FormLoader loading={moveDevicesState.inProgress || tenantStore.inProgress || registerDeviceCountState.inProgress} />
      )}
      {props.showAddDevicePopup && (
        <AddDevice id={selectedId} modalClosed={props.addDeviceToggleHandler} />
      )}

      {props.showSelectGroupPopup && (
        <AllGroupsPopup
          selectedDevices={selectedDevices}
          selectedDevicesGroup={selectedDevicesGroup}
          modalClosed={props.selectGroupToggleHandler}
        />
      )}

      {props.showChooseGroupPopup && (
        <SelectGroupsPopup
          allDevices={allDeviceslist}
          modalClosed={props.chooseGroupToggleHandler}
        />
      )}

      {props.showPublishMessagePopup && (
        <Downlink
          modalClosed={props.publishMessageToggleHandler} iccid={selectedRow.ICCID} />
      )}

      {props.showConfirmPopup && (
        <ConfirmPopup
          title="delete_device"
          modalSubmit={deleteHandler}
          modalClosed={props.confirmToggleHandler}
          message="delete_device_confirm"
          dialogClassName="delete_popup"
          category="delete"
          isLoading={deleteDeviceState.inProgress}
        />
      )}

      {props.showAllDeviceDeletePopup && (
        <ConfirmPopup
          title="delete_device"
          modalSubmit={deleteAll}
          modalClosed={props.allDeviceDeleteToggleHandler}
          message="delete_all_device_confirm"
          dialogClassName="delete_popup"
          category="delete"
          groupId={groupId}
          groups={allAvailableGroups}
          isLoading={deleteAllDeviceState.inProgress}
        />
      )}

      {props.showSelectedDeviceDeletePopup && (
        <ConfirmPopup
          title="delete_device"
          modalSubmit={deleteSelected}
          modalClosed={props.selectedDeviceDeleteToggleHandler}
          message="delete_selected_device_confirm"
          dialogClassName="delete_popup"
          category="delete"
          isLoading={deleteSelectedDeviceState.inProgress}
        />
      )}
      
      {props.showFilteredDeviceDeletePopup && (
        <ConfirmPopup
          title="delete_device"
          modalSubmit={deleteAllFiltered}
          modalClosed={props.filteredDeviceDeleteToggleHandler}
          message="delete_filtered_device_confirm"
          dialogClassName="delete_popup"
          category="delete"
          isLoading={deleteFilteredDevicesState.inProgress}
        />
      )}

      {props.showDeviceFilterPopup && (
        <DeviceFilterState
          initialValues={_.omit(filter, ["isFilterActive"])}
          isFilterActive={IsFilterActive}
          groupOptions={allAvailableGroupsFilter}
          submitHandler={filterSubmitHandler}
          modalClosed={closeHandler}
          allDevicesName={allDevicesNameList}
          allDevicesIccid={allDevicesIccidList}
          allDevicesTags={allTagslist}
        />
      )}

      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row g-0 align-items-center">
              <div className="col-sm-5 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 ps-5 mb-0">
                        <FormattedMessage id="device_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-7 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                      {localStorageUserType ==  userTypeEnum.SystemUser &&
                        <li>
                          <select
                            className="custom-select form-control me-2"
                            value={selectedTenantId}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              let value = parseInt(e.target.value);
                              if (value < 0) toast.error(formatMessage("please_select_any_tenant"));
                              else {
                                if(selectedTenantId !== value){
                                  closePanelHandler();
                                  setSelectedDevices([]);
                                  setSingleSelectedDevices([]);
                                  setSelectedDevicesGroup([]);
                                  setPageInfo((prevState)=>{ 
                                    return { ...prevState, currentPage:1, isRefreshPage: true }
                                  })
                                  setSelectedTenantId(value)
                                }
                              };
                            }}
                          >
                            {allTenantOptions.map((data: IOption, i: number) => (
                              <option key={i} value={data.id}>
                                {i == 0 ? formatMessage(data.value) : data.value}
                              </option>
                            ))}
                          </select>
                        </li>
                      }
                    <li className="" title={formatMessage("refresh")}>
                      <LoaderIconButton
                        id="RefreshDevices"
                        onClick={() => refreshClickHandler()}
                        svg={
                          <svg
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-arrow-repeat m-0"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                            <path
                              fillRule="evenodd"
                              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                            />
                          </svg>
                        }
                        className="btn-sm mx-1"
                        variant="light"
                      />
                    </li>
                    {filter.isFilterActive == true ? (
                      <li>
                        <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm me-2"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={resetFilter}
                          title={formatMessage("reset_filter")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="20px"
                            height="20px"
                            viewBox="0 0 122.88 110.668"
                            enable-background="new 0 0 122.88 110.668"
                          >
                            <g>
                              <path
                                fill="currentColor"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                              />
                            </g>
                          </svg>
                        </button>
                      </li>
                    ) : (
                      ""
                    )}

                    <li data-testid="Filter">
                      <div className="dropdown">
                        <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm me-1"
                          type="button"
                          onClick={deviceFilterHandler}
                          disabled={(localStorageUserType === userTypeEnum.SystemUser && selectedTenantId == -1) ? true : false}
                          title={formatMessage("filter")}
                        >
                          <svg
                            className="svg-icon"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                              fill="currentColor"
                            />
                            <path
                              d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </div>
                    </li>
                    {
                      localStorageUserType !== userTypeEnum.SystemUser &&
                      <li 
                        title={formatMessage("device_iccid_title")}
                        >
                          <LoaderButton
                            id="device_iccid_title"
                            text="device_iccid_title"
                            className="me-1"
                            variant="secondary"
                            disabled={registerDeviceCount <= 0}
                            onClick={(
                              event: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                              >
                            ) => history.push(RoutesConstants.DeviceRegister)}
                          />
                      </li>
                    }
                    {
                      isOperationPermitted(systemModuleEnum.Device, "Delete") &&
                      <li>
                        <div className="dropdown">
                          <button
                            title={formatMessage("delete_device")}
                            data-testid="BulkAction"
                            className="btn custom-select bulkActionButton"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 20 20"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                            </svg>
                            <FormattedMessage id={"delete_device"} />
                          </button>
                          <ul className="dropdown-menu bulkaction-menu">
                            <li>
                              {singleSelectedDevices.length > 0 ? (
                                <a
                                  data-testid="deleteAll"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={deleteSelectedConfirm}
                                  className="mx-2"
                                >
                                  <FormattedMessage id="delete_selected" />
                                </a>
                              ) : (
                                <span
                                  className="disabled_link"
                                  title={formatMessage("select_devices")}
                                >
                                  <FormattedMessage id="delete_selected" />
                                </span>
                              )}
                            </li>

                            <li>
                              {allDeviceslist.length > 0 && selectedTenantId > 0 ? (
                                <a
                                  data-testid="deleteAll"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={deleteAllDeviceConfirm}
                                  className="mx-2"
                                >
                                  <FormattedMessage id="delete_all_devices" />
                                </a>
                              ) : (
                                <span
                                  className="disabled_link"
                                  title={formatMessage("delete_all_option")}
                                >
                                  <FormattedMessage id="delete_all_devices" />
                                </span>
                              )}
                            </li>

                            <li>
                              {allDeviceslist.length > 0 &&  selectedTenantId > 0 && filter.isFilterActive == true ? (
                                <a
                                  data-testid="deleteAll"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={deleteFilteredConfirm}
                                  className="mx-2"
                                >
                                  <FormattedMessage id="delete_all_filtered" />
                                </a>
                              ) : (
                                <span
                                  className="disabled_link"
                                  title={formatMessage("delete_all_filtered")}
                                >
                                  <FormattedMessage id="delete_all_filtered" />
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </li>
                    }
                    {
                      isOperationPermitted(systemModuleEnum.Device, "View") &&
                      <li className="ms-1">
                        <div className="dropdown">
                          <button
                            data-testid="CSVOperations"
                            title={formatMessage("others")}
                            className="btn custom-select bulkActionButton"
                            type="button"
                            data-bs-toggle="dropdown"
                            disabled={getAddBulkCount > 0 ? true : false}
                            aria-expanded="false"
                          >
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-plus"
                              viewBox="0 0 20 20"
                            >
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg> */}
                            <FormattedMessage id="others" />
                          </button>
                          <ul className="dropdown-menu bulkaction-menu wide-menu">
                            <li>
                              {allDeviceslist.length > 0 ? (
                                <a
                                  data-toggle="modal"
                                  onClick={() => {
                                    callExportDevices();
                                  }}
                                  className="enabled_link"
                                  data-testid="exportAll"
                                  data-target="#exportAll"
                                >
                                  <span>
                                    <FormattedMessage id="export_all" />
                                  </span>
                                </a>
                              ) : (
                                <span
                                  className="disabled_link"
                                  title={formatMessage("export_all")}
                                >
                                  <FormattedMessage id="export_all" />
                                </span>
                              )}
                            </li>

                            <li>
                              {singleSelectedDevices.length > 0 ? (
                                <a
                                  data-toggle="modal"
                                  onClick={() => {
                                    handleDownload();
                                  }}
                                  className="enabled_link"
                                  data-testid="exportSelected"
                                  data-target="#exportSelected"
                                >
                                  <span>
                                    <FormattedMessage id="export_selected" />
                                  </span>
                                </a>
                              ) : (
                                <span
                                  className="disabled_link"
                                  title={formatMessage("export_selected")}
                                >
                                  <FormattedMessage id="export_selected" />
                                </span>
                              )}
                            </li>
                              {
                                localStorageUserType === userTypeEnum.TenantUser &&
                                <li>
                                    {(selectedDevicesGroup.length === 1  && selectedDestinationProtocol?.length == 0) ? (
                                      <a
                                        data-testid="moveDevices"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={moveDevices}
                                        className="enabled_link"
                                      >
                                        <FormattedMessage id="move_devices_group" />
                                      </a>
                                    ) : (<span
                                      className="disabled_link"
                                      title={formatMessage("move_devices_group")}
                                    >
                                      <FormattedMessage id="move_devices_group" />
                                    </span>
                                    )}
                                </li>
                              }
                          </ul>
                        </div>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_items_list">
        {(inProgress || oneNceStore.inProgress) && (
          <FormLoader loading={inProgress || oneNceStore.inProgress} />
        )}
        {allDeviceslist.length > 0 ? (
          <div
            className={`card mt-3 changingPanel ${
              openPanel ? "panelOpen" : ""
            }`}
          >
            <div
              className={`tableDesign row-eq-height tableDevices ${
                getRoleTypeFromLocalStorage() == roleTypeEnum.ReadOnly  ? "" : "normal_view"
              }`}
            >
              <div
                className="table-responsive checkbox_table"
                data-testid="table"
              >
                {allDeviceslist && (
                  <Grid
                    data={allDeviceslist}
                    columns={columns}
                    keyField="Id"
                    loading={inProgress}
                    rowClasses={rowClassFormat}
                    selectRow={
                      getRoleTypeFromLocalStorage() != roleTypeEnum.ReadOnly 
                        ? selectRow
                        : undefined
                    }
                    rowEvents={
                      singleSelectedDevices.length === 0
                        ? { onClick: rowEvents }
                        : undefined
                    }
                  />
                )}
              </div>
            </div>
            {allDeviceslist.length > 0 ? (
              <div className="row g-0 p-2 tableFooter">
                <div className="col-sm-6 d-flex pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                  <p className="ms-2">
                    {singleSelectedDevices.length > 0 ? (
                      <span>
                        {" "}
                        <FormattedMessage id={"selected_devices_count"} />:{" "}
                        {singleSelectedDevices.length}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={pageInfo.isRefreshPage}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            <img src={no_device} alt="" className="no_message" />
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_device" />
            </p>
            <p className="mb-3">
              {
                localStorageUserType === userTypeEnum.SystemUser && selectedTenantId ==  -1 ?
                <FormattedMessage id={filter.isFilterActive ? "no_record_after_filter" : "select_tenant_to_get_device_collection"} /> :
                <FormattedMessage id={filter.isFilterActive ? "no_record_after_filter" : "no_device_collection"} />
              }
            </p>

            {/* {isOperationPermitted(systemModuleEnum.Device, "Add")  && (
              <LoaderButton
                type="button"
                text="+_add_device"
                data-toggle="modal"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => openAddDeviceHandler({ Id: -1 }, event)}
                data-testid="modalbtn"
                data-target="#addUser"
                className="btn btn-primary me-2"
              />
            )} */}
          </div>
        )}
      </div>

      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <DeviceDetials selectedData={selectedRow} tenantId={localStorageUserType === userTypeEnum.SystemUser ? selectedTenantId : getLoggedInTenantIdFromLocalStorage()} closePanelHandler={closePanelHandler} />
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};
export default DeviceFilterPopupHOC(
  PublishMessagePopupHOC(
  ChooseGroupPopupHOC(
    SelectGroupPopupHOC(
      FilteredDeviceDeletePopupHOC(
        ActiveDeviceConfirmPopupHOC(
          AllDeviceDeletePopupHOC(
            SelectedDeviceDeletePopupHOC(
              DeletePopupHOC(AddDevicePopupHOC(observer(Device)))
            )
          )
        )
      )
    )
  )
  )
);
