/*    
<summary>
  This functional component shows the configuration details.
  Developer:Aashish Singh, Created Date: 09-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import { IConfigurationList } from "../../models/response/IConfigurationResponse";
import { ConfigurationGroupsFormatterDetailView } from "../../shared-components/Grid/GridFormatter";
import authenticationEnum from "../../constants/authentication-enum";
import { formatMessage } from "../../translations/format-message";
import protocolEnum from "../../constants/protocol-enum";
import { getProtocol } from "../../constants/common-constants";

interface IProps {
  selectedData: IConfigurationList;
}

const ConfigurationDetails = (props: IProps) => {
  const { configurationStore } = useStore();
  const { GetConfigurationByIdService, configDetail } = configurationStore;

  /**
   * This function calls the "GetConfigurationByIdService" store funtion that get group data from API
   */
  const callGetConfigurationById = () => {
    GetConfigurationByIdService(props?.selectedData.Id);
  };

  useEffect(() => {
    if (props?.selectedData.Id > 0) callGetConfigurationById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedData.Id]);

  return (
    <React.Fragment>
      <div className="p-3">
        {/* <div className="row pt-2">
          <div className="col-6">
            <label className="text-muted bold"><FormattedMessage id={"config_name"} />: </label>
          </div>
          <div className="col-6">
            <p className="mw-100">{configDetail.Name}</p>
          </div>          
        </div> */}
        {/* <div className="row">
          <div className="col-6 pt-2">
            <label className="text-muted bold"><FormattedMessage id={"source_protocol"} />: </label>
            <p className="mw-100">
              {protocolEnum[configDetail.SourceProtocol]}
            </p>
          </div>
          <div className="col-6 pt-2">
            <label className="text-muted bold"><FormattedMessage id={"destination_protocol"} />: </label>
            <p className="mw-100">
              {protocolEnum[configDetail.DestinationProtocol]}
            </p>
          </div>
        </div> */}
        <div className="row pt-2">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"configuration_type"} />:{" "}
            </label>
          </div>
          <div className="col-12">
            <p className="mw-100">
              <span>
                {configDetail?.DestinationProtocol == protocolEnum.HTTPS ? <FormattedMessage id="webhook" /> : 
                configDetail?.DestinationProtocol == protocolEnum.AWSIoT ? <FormattedMessage id="aws_connector" /> : 
                configDetail?.DestinationProtocol == protocolEnum.AWSS3Bucket ? <FormattedMessage id="aws_s3_bucket" /> :
                configDetail?.DestinationProtocol == protocolEnum.AWSKinesis ? <FormattedMessage id="aws_kinesis" /> : 
                getProtocol(configDetail?.DestinationProtocol)}
              </span>
            </p>
          </div>
        </div>
        {configDetail.Description && (
          <div className="pt-2">
            <label className="text-muted bold">
              <FormattedMessage id={"desc"} />:{" "}
            </label>
            <p className="me-3 text-break">{configDetail.Description}</p>
          </div>
        )}
        {(configDetail.HttpsParameters && configDetail.DestinationProtocol === protocolEnum.HTTPS) && (
          <div className="row pt-2">
            <div className="col-12">
              <label className="text-muted bold">
                <FormattedMessage id={"destination_url"} />:{" "}
              </label>
            </div>
            <div className="col-12">
              <p className="mw-100">
                {configDetail.HttpsParameters?.Host}
              </p>
            </div>
          </div>)}
        {(configDetail?.HttpsParameters && configDetail.DestinationProtocol === protocolEnum.HTTPS) && (
          <div className="row pt-2">
            <div className="col-12">
              <label className="text-muted bold">
                <FormattedMessage id={"authentication_type"} />:{" "}
              </label>
            </div>
            <div className="col-12">
              <p className="mw-100">
                {configDetail?.HttpsParameters &&
                  formatMessage(
                    authenticationEnum[
                    configDetail?.HttpsParameters.AuthorizationType
                    ]
                  )}
              </p>
            </div>
          </div>
        )}
        <div className="row pt-2">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"headers"} />:{" "}
            </label>
          </div>
          <div className="col-12">
            {configDetail?.HttpsParameters &&
              configDetail?.HttpsParameters?.CustomHeaders?.length > 0 ? (
              configDetail?.HttpsParameters?.CustomHeaders?.map(
                (headers: any) => {
                  return (
                    <>
                      <p className="header-text">
                        <span className="text-code">{headers.Key}</span> : <span className="text-code">{headers.Value}</span>
                      </p>
                    </>
                  );
                }
              )
            ) : (
              <p className="mw-100">
                <FormattedMessage id={"no_headers"} />
              </p>
            )}
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"body"} />:{" "}
            </label>
          </div>
          <div className="col-12">
            <p className="mw-100">
              {configDetail?.HttpsParameters?.IsSendICCID == true ? (
                <FormattedMessage id={"iccid"} />
              ) : (
                <FormattedMessage id={"topic"} />
              )}
            </p>
          </div>
        </div>
        {configDetail.Groups && (
          <div className="pt-2">
            <label className="text-muted bold">
              <FormattedMessage id={"used_in_groups"} />:{" "}
            </label>
            <p className="">
              {ConfigurationGroupsFormatterDetailView(configDetail)}
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default observer(ConfigurationDetails);
