/*    
<summary>
  This functional component shows the tenant details.
  Developer:Aashish Singh, Created Date: 10-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import { ITenantList } from "../../models/response/ITenantResponse";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import "./tenant-detail.css";

interface IProps {
  selectedData: ITenantList;
}

const TenantDetails = (props: IProps) => {
  const { tenantStore } = useStore();
  const { GetTenantService, tenantDetails, tenant, tenantState } = tenantStore;

  /**
   * This function calls the "GetTenantService" store funtion that get group data from API
   */
  const callGetTenantById = () => {
    GetTenantService(props?.selectedData.Id, props.selectedData.UserId);
  };

  useEffect(() => {
    if (props?.selectedData.Id > 0) callGetTenantById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedData.Id]);

  return (
    <React.Fragment>
        {tenantState.inProgress && <FormLoader loading={tenantState.inProgress} />}
        {
            tenant &&
            <div className="p-3">
                <div className="row pt-2">
                    <div className="col-12">
                        <label className="text-muted bold">
                        <FormattedMessage id={"tenant_email"} />:{" "}
                        </label>
                    </div>
                    <div className="col-12">
                        <p className="mw-100">
                            {tenantDetails.Email}
                        </p>
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col-6">
                        <label className="text-muted bold">
                        <FormattedMessage id={"user_limit"} />:{" "}
                        </label>
                        <p className="mw-100">
                            {tenantDetails.MaxUserAllow}
                        </p>
                    </div>
                    <div className="col-6">
                        <label className="text-muted bold">
                        <FormattedMessage id={"device_limit"} />:{" "}
                        </label>
                        <p className="mw-100">
                            {tenantDetails.MaxDeviceAllow}
                        </p>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-6">
                        <label className={` ${tenantDetails.IsActive == true ? 'alert-success' : 'alert-danger' } alert tenant-alert`}>
                        {tenantDetails?.IsActive == true ? (
                            <i className="fas fa-check"></i>
                        ) : (
                            <i className="fas fa-times"></i>
                        )}
                            <FormattedMessage id={"is_active"} />
                        </label>
                    </div>
                    <div className="col-6">
                        <label className={` ${tenantDetails.IsDataPakcetsStoreInDB == true ? 'alert-success' : 'alert-danger' } alert tenant-alert`}>
                        {tenantDetails?.IsDataPakcetsStoreInDB == true ? (
                            <i className="fas fa-check"></i>
                        ) : (
                            <i className="fas fa-times"></i>
                        )}
                        <FormattedMessage id={"store_in_DB"} />
                        </label>
                    </div>
                    <div className="col-12">
                        <label className={` ${tenantDetails.SendThresholdAlert == true ? 'alert-success' : 'alert-danger' } alert tenant-alert`}>
                        {tenantDetails?.SendThresholdAlert == true ? (
                            <i className="fas fa-check"></i>
                        ) : (
                            <i className="fas fa-times"></i>
                        )}
                        <FormattedMessage id={"send_alert"} />
                        </label>
                    </div>
                </div>
                
                <div className="card bg-light p-3">
                    <div className="row pt-2">
                        <div className="col-6">
                            <label className="text-muted bold">
                            <FormattedMessage id={"organization_name"} />:{" "}
                            </label>
                            <p className="mw-100">
                                {tenantDetails.OrganizationName}
                            </p>
                        </div>
                        <div className="col-6">
                            <label className="text-muted bold">
                            <FormattedMessage id={"organization_id"} />:{" "}
                            </label>
                            
                            <p className="mw-100">
                                {tenantDetails.OrganizationId}
                            </p>
                        </div>
                        <div className="col-6">
                            <label className="text-muted bold">
                            <FormattedMessage id={"vat_number"} />:{" "}
                            </label>
                            
                            <p className="mw-100">
                                {tenantDetails.VatNo}
                            </p>
                        </div>
                    </div> 
                    <hr/>
                    <div className="row pt-2">
                        <div className="col-2">
                            <label className="text-muted bold">
                            {/* <FormattedMessage id={"contact_person_name"} />:{" "} */}
                            <i className="fas fa-user tenant-contact-icon"></i>
                            </label>
                        </div>
                        <div className="col-10">
                            <h3 className="m-0">
                                {tenantDetails.ContactPersonName}
                            </h3>
                            <p className="mw-100 mb-0 text-muted">
                                {tenantDetails.ContactEmail}
                            </p>
                            <p className="mw-100 mb-0 text-muted">
                                {tenantDetails.ContactPhoneNumber}
                            </p>
                            <p className="mw-100">
                                {tenantDetails.ShippingAddress}
                            </p>
                        </div>
                    </div>
                    
                    
                </div>
                
            </div>
        }
    </React.Fragment>
  );
};

export default observer(TenantDetails);
