/*    
<summary>
   This file is used to provide initial values or state to the User Data observables.
   Developer:Ashish Singh, Created Date: 29-August-2023
</summary>
*/

import { IAddUser } from "../../models/Form/IAddUser";

export const initialState:IAddUser = {
    Role: {
        Id: 0,
        Name: "",
        RoleType: 0,
        UserType: 0,
        Description: "",
        Permissions: [
          {
            Id: 0,
            Module: 0,
            View: false,
            Add: false,
            Edit: false,
            Delete: false
          }
        ],
    CreatedDate: "",
    UpdatedDate: "",
    CreatedBy: 0,
    UpdatedBy: 0
    },
    Password: '', 
    Email: '',
    IsActive: true,
    UserName: ''
}