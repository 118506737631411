/*    
<summary>
   This class component is all about showing payment history data.
   Developer:Aashish Singh, Created Date:14-June-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { initialState as GetAllPaymentHistoryState } from '../initial-state/get-all-payment-history-state';
import { action, computed, makeObservable, observable } from 'mobx';
import { AuthStore } from './auth-store';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { IObservableInitialState, IOption } from '../../models/ICommon';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import { DATE_TIME_FORMAT } from '../../constants/common-constants';
import { IPaymentHistoryListVM, IPaymentHistoryVM, IPaymentHistoryList } from '../../models/response/IPaymentHistoryResponse';
import moment from 'moment';

export class PaymentHistoryStore implements ICommonState {
    inProgress = false;
    error = '';
    paymentHistoryList: IPaymentHistoryListVM = GetAllPaymentHistoryState;
    
    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    
    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            paymentHistoryList: observable,
            GetPaymentHistoryListService: action,
            reset: action,
            resetStore: action,
            allPaymentHistory: computed,
        });
    }

    /**
     * This function is used to Get All PaymentHistorys List data with pagination information by calling an API.
     * @param currentPage : Current Page Number
     * @param pagerSize : Page Size
     * @returns 
     */
    GetPaymentHistoryListService = (currentPage: number, pagerSize: number, orderId: number) => {
        this.inProgress = true;
        let url = URLConstants.Order + "/"+ orderId +"/Payment/History?PageNo=" + currentPage + "&PageSize=" + pagerSize;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IPaymentHistoryListVM>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.paymentHistoryList = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

   /**
    * This function is used to map paymentHistoryList to allPaymentHistory List suitable for Grid Component.
    * @returns allPaymentHistorysList
    */
    get allPaymentHistory(): IPaymentHistoryList[] {
        if (this.paymentHistoryList?.PaymentHistory && this.paymentHistoryList.PaymentHistory?.length > 0)
            return this.paymentHistoryList.PaymentHistory.map((history:IPaymentHistoryVM) => {
                return {
                    Id: history.Id,
                    Amount: history.Amount !== null ? Number(history?.Amount).toFixed(2) : "0",
                    PaymentReceviedDate: moment(history?.PaymentReceviedDate).format(DATE_TIME_FORMAT),
                    PaymentReceviedBy: history?.PaymentReceviedBy,
                    PaymentConfirmationDate: moment(history?.PaymentConfirmationDate).format(DATE_TIME_FORMAT),
                    Remarks: history?.Remarks 
                }
            })
        return [];
    }
    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.paymentHistoryList = GetAllPaymentHistoryState;
    }

}

export default new PaymentHistoryStore();
