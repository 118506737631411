/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used for the pagination & dashboard setting for corresponding modules.
   Developer: Aashish Singh, Created Date: 29-August-2023 
</summary>
*/
enum userSettingEnum{
    DeviceLimitConfig = 'deviceLimitConfig',
    ConfigLimitConfig = 'configLimitConfig',
    GroupLimitConfig = 'groupLimitConfig',
    UserLimitConfig = 'userLimitConfig',
    TenantLimitConfig = 'tenantLimitConfig',
    BillingLimitConfig = 'billingLimitConfig',
    AuditLogLimitConfig = 'auditLogLimitConfig',
    PricingPlanLimitConfig = 'pricingPlanLimitConfig',
    OrderLimitConfig = 'orderLimitConfig',
    DeviceRegisterLimitConfig = 'deviceRegisterLimitConfig',
    PaymentHistoryLimitConfig = 'paymentHistoryLimitConfig',
    FotaLimitConfig = 'fotaLimitConfig',
}

export default userSettingEnum;