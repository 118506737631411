/*    
<summary>
   This component is Used for create account resend email.
   Developer: Aashish Singh, Created Date:31-July-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "react-sliding-side-panel/lib/index.css";
import mailsentIcon from "../../../assets/Images/svg/arrow.svg";
import noMessageIcon from "../../../assets/Images/svg/interface-design.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "../../../contexts/StoreProvider";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import { formatMessage } from "../../../translations/format-message";
import toast from "react-hot-toast";
import { getSelectedResendFromLocalStorage } from "../../../helpers/local-stotrage-helper";
import { resendMailTimer } from "../../../constants/common-constants";

const ResendMail = (props: any) => {
  const { tenantStore } = useStore();
  const {
    ResendAccountActivateLinkService,
    resendActivationLinkState,
    resetAccountActivationLink,
    resetAddTrialTenantState,
  } = tenantStore;

  const history = useHistory();
  const email =
    props.location && props.location.state ? props.location.state?.email : "";
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);
  let t: any;
  const [timer, setTimer] = useState(resendMailTimer);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    let interval: any;
    if (timer > 0) {
      setIsDisabled(true);
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setIsDisabled(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("mzl_selected_resend_email");
    };
  }, []);

  const handleButtonClick = () => {
    if (email != "") {
      resetAccountActivationLink();
      ResendAccountActivateLinkService({ Email: email });
    }
  };

  useEffect(() => {
    if (resendActivationLinkState.success) {
      resetAddTrialTenantState();
      setSuccess(false);
      setTimer(resendMailTimer);
    }
  }, [resendActivationLinkState.success]);

  useEffect(() => {
    if (resendActivationLinkState.error) {
      resetAddTrialTenantState();
      setSuccess(false);
    }
  }, [resendActivationLinkState.error]);

  return (
    <div className="bg-img1 text-start background">
      {
        getSelectedResendFromLocalStorage() ?
          <div className="d-flex justify-content-center align-items-center vh-100">
            {resendActivationLinkState.inProgress && (
              <FormLoader loading={resendActivationLinkState.inProgress} />
            )}

            <div className="text-center activate_message_wrap">
              
              {success && (
                <>
                  <img src={mailsentIcon} alt="" className="no_message" />
                  <p className="noData h4 text-muted mb-0">
                    <FormattedMessage id="activating_account" />
                  </p>
                  <p className="noData h5 my-4">
                    <FormattedMessage id="account_created_successfully_otp_sent_to" /><br/>
                    <span className="ms-2 fw-bold">{email}</span>
                  </p>
                </>
              )}
              {resendActivationLinkState.success && (
                <>
                  <img src={mailsentIcon} alt="" className="no_message" />
                  <p className="noData h4 text-muted mb-0">
                    <FormattedMessage id="activating_account" />
                  </p>
                  <p className="noData h4 my-2  text-success">
                    <FormattedMessage id="AccountVerificationMailSent" />
                  </p>
                </>
              )}

              {resendActivationLinkState.error && (
                <>
                <img src={noMessageIcon} alt="" className="no_message" />
                <p className="noData h4 text-muted mb-0">
                  <FormattedMessage id="activating_account" />
                </p>
                <p className="noData h4 my-2  text-danger">
                  {formatMessage(resendActivationLinkState.error)}
                </p>
                </>
              )}
              <div className="mt-3">

                <button
                  onClick={handleButtonClick}
                  disabled={isDisabled}
                  className="btn btn-link"
                >
                  <FormattedMessage id="resend_link" />
                  {isDisabled && <span className="ms-2">({timer})</span>}
                </button>

                <button
                  className="btn btn-primary"
                  onClick={() => {
                    resetAddTrialTenantState();
                    resetAccountActivationLink();
                    localStorage.clear();
                    setTimeout(() => {
                      history.push(RoutesConstants.Login);
                    });
                  }}
                >
                  <FormattedMessage id={"back_to_login"} />
                </button>
              </div>
            </div>
          </div> :
          <div className="text-center activate_message_wrap">
            <img src={noMessageIcon} alt="" className="no_message" />
            <h1 className="mb-3"><FormattedMessage id="page_not_available" /></h1>
            <button
              className="btn btn-primary p-2"
              onClick={() => {
                resetAddTrialTenantState();
                resetAccountActivationLink();
                localStorage.clear();
                setTimeout(() => {
                  history.push(RoutesConstants.Login);
                });
              }}
            >
              <FormattedMessage id={"back_to_login"} />
            </button>
          </div>
      }
    </div>
  );
};

export default observer(ResendMail);
