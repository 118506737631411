/*    
<summary>
  This functional component "RegisterDeviceByCSV" defines the props like initial values & functions for the child form component.
  Developer:Aashish Singh, Created Date: 11-June-2024,
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import RegisterDeviceByCSVForm from "../forms/register-device-csv-form";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  tenantId: number;
  modalClosed: () => void;
}

const RegisterDeviceByCSV = (props: IProps) => {
  const { deviceRegisterStore, orderStore, tenantStore } = useStore();
  const {selectedTenantId} = tenantStore;
  const { addDeviceRegisterCSV, resetAddDeviceRegisterCSV, addDeviceRegisterCSVState } =
    deviceRegisterStore;
  const {GetAllDeliveredOrderByTenantIdService, allDeliveredOrderState, getAllDeliveredOrderOptions, resetAllDeliveredOrderState} = orderStore;

  const onSubmitForm = (values: any) => {
    addDeviceRegisterCSV(values);
  };

  const onModalClosed = () => {
    resetAllDeliveredOrderState();
    resetAddDeviceRegisterCSV();
    props.modalClosed();
  };

  
  /**
 * The useEffect Hook lets you perform side effects in function component.
 * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
 * while a dependency of the useEffect has changed.
 */
  useEffect(() => {
    GetAllDeliveredOrderByTenantIdService(props.tenantId);
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addBulkDeviceState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addDeviceRegisterCSVState.success) {
      // toast.success(formatMessage("added_success"));
      onModalClosed();
    }
  }, [addDeviceRegisterCSVState.success]);

    /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addBulkDeviceState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (addDeviceRegisterCSVState.error) {
      resetAddDeviceRegisterCSV();
    }
  }, [addDeviceRegisterCSVState.error]);

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="device_register_csv" />
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
            title={formatMessage("close")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <RegisterDeviceByCSVForm
            initialValues={{CSVFile: "", OrderNo:"please_select" }}
            tenantId={selectedTenantId}
            submitHandler={onSubmitForm}
            deliveredOrderList={getAllDeliveredOrderOptions}
            isLoading={addDeviceRegisterCSVState.inProgress || allDeliveredOrderState.inProgress}
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(RegisterDeviceByCSV);
