import { observer } from "mobx-react";
import React from "react";
import { useStore } from "../../../contexts/StoreProvider";
import IAddDeviceBulk from "../../../models/Form/IAddDeviceInBulk";
import Lottie from "react-lottie";
import monozlogo from "../../../assets/Images/monoZ_Black.png";
import ForgetPasswordAuthForm from "../form/forget-password-auth-form";
import { defaultLottieOptions } from "../../../constants/common-constants";
import Footer from "../../../shared-components/Footer/Footer";

const ForgetPassword = (props: any) => {
  /* Returning JSX */
  return (
    <React.Fragment>
      <div className="bg-img1 text-start background">
        <div className="login_wrapper">
          <img src={monozlogo} alt="mono_z_Logo" className="LightSmallLogo" />

          <Lottie options={defaultLottieOptions} height={230} width={790} />
        </div>
        <ForgetPasswordAuthForm />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default observer(ForgetPassword);
