/*    
<summary>
  This functional component "AddAWSKinesisConfigForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date: 06-August-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import "./aws-kinesis-form.css";
import FormikFormInput from "../../../../../shared-components/FormikFormHandlers/FormikFormInput";
import { Button } from "react-bootstrap";
import LoaderButton from "../../../../../shared-components/Button/LoaderButton";
import { addAWSKinesisValidateSchema, addAWSS3BucketValidateSchema } from "../../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormLoader from "../../../../../shared-components/FormLoader/FormLoader";
import { Toaster } from "react-hot-toast";
import FormikFormSelect from "../../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { formatMessage } from "../../../../../translations/format-message";
import Label from "../../../../../shared-components/Label/Label";
import { FormattedMessage } from "react-intl";
import FormikFormTextArea from "../../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { SourceProtocol } from "../../../../../constants/options.constant";
import { IOption } from "../../../../../models/ICommon";
import { useStore } from "../../../../../contexts/StoreProvider";
import { observer } from "mobx-react";
import { ShowHidePasswordButton } from "../../../../../shared-components/Grid/GridFormatter";
import { getThemeFromLocalStorage } from "../../../../../helpers/local-stotrage-helper";

interface IProps {
  Id: number;
  actionLoading: boolean;
  dataLoading: boolean;
  IsDataPakcetsStoreInDB: boolean;
  initialValues: any;
  regionOptions: IOption[];
  submitHandler: (data: any) => void;
  modalClosed: () => void;
}

const AddAWSKinesisConfigForm = (props: IProps) => {
  const { initialValues, submitHandler, modalClosed, actionLoading, dataLoading } = props;
  const { tenantStore } = useStore();
  const { tenantDetails } = tenantStore;
  const topicInfo = formatMessage("topic_info").replace(
    "{0}",
    tenantDetails.OrganizationId
  );
  const eg = "e.g-Level1/{orgid}/{iccid}/Level4";
  const [showHidePassword, setShowHidePassword] = useState(false);
  
  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className="mx-auto">
        {dataLoading && <FormLoader loading={dataLoading} />}
        <Formik
          initialValues={initialValues}
          validationSchema={addAWSKinesisValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            submitHandler({
              ...values,
              AwsKinesisParameters: { ...values.AwsKinesisParameters },
            });
          }}
        >
          {({ values }) => {
            return (
              <Form id="formBody">
                <div className="position-relative px-4 pt-3">
                <div className="row form-group justify-content-end align-items-center">
                  <div className=" col-md-4">
                    <Label
                      className="label-style fw-semibold"
                      required={true}
                      label={"source_protocol"}
                    />
                  </div>

                  <div className="formS et col-md-8">
                    <Field
                      data-testid="test_source_protocol"
                      className="form-control"
                      name="SourceProtocol"
                      placeholder="source_protocol"
                      type="text"
                      options={SourceProtocol}
                      as={FormikFormSelect}
                    />
                  </div>

                  
                  <div className="col-sm-8 mt-2 formSet d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      fill="currentColor"
                      className="bi bi-info-circle me-1"
                      viewBox="0 0 16 16"
                      data-tooltip-id="my-tooltip2"
                      data-tooltip-html={topicInfo}
                      data-tooltip-variant="dark"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <label className="label-style text-dark fw-semibold">
                      <FormattedMessage id="topic_pattern" />
                    </label>
                    <p className="mx-1">{eg}</p>
                  </div>
                </div>
                <Tooltip id="my-tooltip2" place="top"  className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                </div>
                <div className="py-3 mt-3 px-4 bg-light rounded-3">
                  <h4 className="divider_heading">
                    <FormattedMessage id="destination_protocol" />
                    <span className="ms-2 badge bg-primary">
                      <FormattedMessage id="aws_kinesis" />
                    </span>
                  </h4>
                  <div className="row">
                    <div className=" col-sm-12 form-group formSet">
                      <Label
                        className="label-style text-dark fw-semibold"
                        required={true}
                        label="config_name"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        className="bi bi-info-circle ms-2"
                        viewBox="0 0 16 16"
                        data-tooltip-id="my-tooltip-config"
                        data-tooltip-html={formatMessage("name_validation")}
                        data-tooltip-variant="dark"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                      </svg>
                      <Tooltip id="my-tooltip-config" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                      <Field
                        data-testid="test_config_name"
                        className="form-control"
                        name="Name"
                        placeholder="config_name"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        type="text"
                        as={FormikFormInput}
                      />
                    </div>
                  </div>

                  <div className="form-group formSet">
                    <label className="label-style text-dark fw-semibold">
                      <FormattedMessage id="desc" />
                    </label>
                    <Field
                      data-testid="testjson"
                      name="Description"
                      type="text"
                      className="form-control"
                      placeholder="enter_text"
                      rows={3}
                      style={{ resize: "none" }}
                      as={FormikFormTextArea}
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style text-dark"
                          required={true}
                          label="stream_name"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-info-circle ms-2"
                          viewBox="0 0 16 16"
                          data-tooltip-id="my-tooltip-aws-s3-bucket"
                          data-tooltip-html={formatMessage("name_validation")}
                          data-tooltip-variant="dark"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <Tooltip id="my-tooltip-aws-s3-bucket" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                        <Field
                          data-testid="StreamName"
                          className="form-control"
                          name="AwsKinesisParameters.StreamName"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          placeholder="stream_name"
                          type="text"
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style text-dark"
                          required={true}
                          label="available_regions"
                        />
                        <Field
                          id="Region"
                          className="form-control"
                          name="AwsKinesisParameters.Region.Id"
                          options={props.regionOptions}
                          // getData={true}
                          as={FormikFormSelect}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style text-dark"
                          required={true}
                          label="access_key"
                        />
                        <Field
                          data-testid="AccessKey"
                          className="form-control"
                          name="AwsKinesisParameters.AccessKey"
                          placeholder="access_key"
                          type="text"
                          invalidcharacter={[" "]}
                          validcharacter={[
                            "~",
                            "`",
                            "!",
                            "@",
                            "#",
                            "$",
                            "%",
                            "^",
                            "&",
                            "*",
                            "(",
                            ")",
                            "_",
                            "-",
                            "+",
                            "=",
                            "{",
                            "[",
                            "}",
                            "]",
                            "|",
                            "\\",
                            ":",
                            ";",
                            "'",
                            '"',
                            "<",
                            ",",
                            ">",
                            ".",
                            "?",
                            "/",
                          ]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <Label
                          className="label-style text-dark"
                          required={true}
                          label="secret_key"
                        />
                      <div className="form-group formSet">
                        
                        <Field
                          data-testid="SecretKey"
                          className="form-control"
                          name="AwsKinesisParameters.SecretKey"
                          placeholder="secret_key"
                          type={showHidePassword ? "text" : "password"}
                          autoComplete="new-password"
                          invalidcharacter={[" "]}
                          validcharacter={[
                            "~",
                            "`",
                            "!",
                            "@",
                            "#",
                            "$",
                            "%",
                            "^",
                            "&",
                            "*",
                            "(",
                            ")",
                            "_",
                            "-",
                            "+",
                            "=",
                            "{",
                            "[",
                            "}",
                            "]",
                            "|",
                            "\\",
                            ":",
                            ";",
                            "'",
                            '"',
                            "<",
                            ",",
                            ">",
                            ".",
                            "?",
                            "/",
                          ]}
                          as={FormikFormInput}
                        />
                        <ShowHidePasswordButton  
                          showHidePassword={showHidePassword}
                          setShowHidePassword={setShowHidePassword}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer border-0">
                  <LoaderButton
                    type="submit"
                    isLoading={actionLoading}
                    text={props.Id > 0 ? "update" : "button_save"}
                  />
                  <Button
                    variant="secondary"
                    onClick={modalClosed}
                    disabled={actionLoading}
                    type="button"
                    className=""
                  >
                    <FormattedMessage id="close" />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default observer(AddAWSKinesisConfigForm);
