/*    
<summary>
   This file is used to provide initial values or state to the Bar Chart Module.
   Developer: Mohammad Saquib Khan, Created Date: 18-Jan-2024
</summary>
*/

import { formatMessage } from "../../translations/format-message";

export const initialState: any = {
	options: {
	  animation: false,
	  responsive: true,
	  scales:{
		x: {
			x: 0	,
		  type: 'category',
		},
		y: {
			y:0,
		  type: 'linear',
		},
	  },
	  plugins: {
		legend: {
            display: false,// Set this to false to hide the legend
			// position: "bottom",
			// labels: {
			// 	boxWidth: 10,
			// 	boxHeight: 10,
			// 	padding: 40,
			// 	usePointStyle: true,
			// }  
        },
		tooltip: true,
		// zoom: {
		//   pan: {
		// 	enabled: true,
		// 	mode: 'x',
		// 	modifierKey: 'ctrl',
		// 	threshold: 5,
		//   },
		//   zoom: {
		// 	wheel: {
		// 	  enabled: true
		// 	},
		// 	pinch: {
		// 	  enabled: true
		// 	},
		// 	drag: {
		// 	  enabled: true
		// 	},
		// 	mode: 'x',
		//   },
		// }
	  },
	},
	data: {
	  labels: ["Jan", "Feb", "Mar", "Apr", "May", "June"],
	  datasets: [
		{
		  label: formatMessage("devices_added"),
		  data: [0, 0, 0, 0, 0, 0],
		  backgroundColor: "#00d1ba",
		},
		{
		  label: formatMessage("device_deleted"),
		  data: [0, 0, 0, 0, 0, 0],
		  backgroundColor: "#ff6384",
		},
		{
		  label: formatMessage("devices_activated"),
		  data: [0, 0, 0, 0, 0, 0],
		  backgroundColor: "#ff8700",
		},
		// {
		//   label: formatMessage("in_active_devices"),
		//   data: [0, 0, 0, 0, 0, 0],
		//   backgroundColor: "#c3c2ba",
		// },
	  ],
	},
  };
