/*    
<summary>
  This functional component "AddUpdateDeviceRegisterForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date: 11-June-2024,
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import "./add-edit-registered-device-form.css";
import { observer } from "mobx-react-lite";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { Button } from "react-bootstrap";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { addDeviceRegisterValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import IAddDeviceRegister from "../../../models/Form/IAddUpdateDeviceRegister";
import Label from "../../../shared-components/Label/Label";
import { Tooltip } from "react-tooltip";
import { formatMessage } from "../../../translations/format-message";
import { IOption } from "../../../models/ICommon";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { useStore } from "../../../contexts/StoreProvider";
import AddUpdateDeviceRegisterDetail from "./add-edit-device-register-detail";
import { getThemeFromLocalStorage } from "../../../helpers/local-stotrage-helper";

interface IProps {
  Id: number;
  initialValues: IAddDeviceRegister;
  selectedTenantId: number;
  setSelectedTenantId: React.Dispatch<React.SetStateAction<number>>;
  tenantOptions: IOption[];
  deliveredOrderList: IOption[];
  submitHandler: (data: any) => void;
  isLoading: boolean;
  modalClosed: () => void;
}

const AddUpdateDeviceRegisterForm = (props: IProps) => {
  const { orderStore } = useStore();
  const {
    initialValues,
    submitHandler,
    isLoading,
    modalClosed,
    deliveredOrderList,
    selectedTenantId,
    setSelectedTenantId
  } = props;
  const {GetAllDeliveredOrderByTenantIdService} = orderStore;
  
  
  useEffect(() => {
    if(selectedTenantId > -1)
      GetAllDeliveredOrderByTenantIdService(selectedTenantId);
  }, [selectedTenantId]);
  
  console.log(initialValues)
  return (
    <>
      <div className="mx-auto">
        {isLoading && <FormLoader loading={isLoading} />}
        <Formik
          initialValues={initialValues}
          validationSchema={addDeviceRegisterValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: IAddDeviceRegister) => {
            let tempValues= {...values,
              TenantId: selectedTenantId
            }
            submitHandler({ ...tempValues });
          }}
          render={({ values }) => (
            <Form id="formBody">
              <div className="row px-4  pt-4">
              {
                          initialValues.TenantId === -1 &&
                          <div className="col-12">
                          <div className="form-group formSet">
                            <Label
                              className="label-style col-form-label"
                              required={true}
                              label="Tenant"
                            />
                            <Field
                              data-testid="tenantId"
                              className="form-control"
                              name="TenantId"
                              disabled={props.Id > 0 ? true : false}
                              options={props.tenantOptions}
                              setLocalState={(id:any)=>{setSelectedTenantId(id)}}
                              as={FormikFormSelect}
                            />
                          </div>
                          </div>
                        }
                <div className="col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style mb-0"
                      required={true}
                      label="order_no"
                    />
                    <Field
                      data-testid="orderNo"
                      className="form-control"
                      name="OrderNo"
                      getData={true}
                      options={deliveredOrderList}
                      as={FormikFormSelect}
                    />
                  </div>
                  <p className="small mb-2">
                    <AddUpdateDeviceRegisterDetail orderNo={values.OrderNo} />
                  </p>
                </div>
                <div className="col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="device_name"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-info-circle ms-2"
                      viewBox="0 0 16 16"
                      data-tooltip-id="my-tooltip-device"
                      data-tooltip-html={formatMessage(
                        "device_name_validation"
                      )}
                      data-tooltip-variant="dark"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <Tooltip id="my-tooltip-device" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                    <Field
                      data-testid="DeviceName"
                      className="form-control"
                      name="Name"
                      validcharacter={["@", "#", "$", "-", "_", "."]}
                      placeholder="device_name"
                      type="text"
                      as={FormikFormInput}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="iccid"
                    />

                    <Field
                      data-testid="ICCID"
                      className="form-control"
                      name="ICCID"
                      placeholder="iccid"
                      type="number"
                      as={FormikFormInput}
                      disabled={props.Id > 0}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 pt-0">
                <LoaderButton
                  type="submit"
                  id="SaveDeviceRegister"
                  isLoading={isLoading}
                  text={props.Id > 0 ? "update" :"button_save"}
                />
                <Button
                  data-testid="Cancel"
                  variant="secondary"
                  onClick={modalClosed}
                  disabled={isLoading}
                  type="button"
                  className=""
                >
                  <FormattedMessage id="close" />
                </Button>
              </div>
            </Form>
          )}
        ></Formik>
      </div>
    </>
  );
};

export default observer(AddUpdateDeviceRegisterForm);
