/*    
<summary>
   This File contains all the routes constant. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
*/
import config from "../../helpers/config-helper";

const appConfig = config();
// export const baseURL = appConfig.REACT_APP_PUBLIC_URL + "/monoZLink";
export const baseURL = appConfig.REACT_APP_PUBLIC_URL;
const RoutesConstants = {
  Login: baseURL + "/Login",
  Logout: baseURL + "/Logout",
  User: baseURL + "/User",
  Device: baseURL + "/Device",
  AddBulk: baseURL + "/AddBulk",
  Dashboard: baseURL+ "/Dashboard",
  Group: baseURL+"/Group",
  Configurations: baseURL+"/Configuration",
  Tenants:baseURL+"/Tenants",
  Bill:baseURL+"/Bill",
  Documentation:baseURL+"/Documentation",
  SingleView:baseURL+"/SingleView",
  AuditLog:baseURL+"/AuditLog",
  ForgetPassword: baseURL+"/ForgetPassword",
  CreateAccount: baseURL+"/CreateAccount",
  UserRegistration: baseURL + "/UserResgistration",
  PricingMenu: baseURL + "/Statistics",
  PricingPlan: baseURL + "/PricingPlan",
  Order: baseURL + "/Order",
  DeviceRegister: baseURL + "/RegisterDevice",
  BulkDeviceRegister: baseURL + "/BulkDeviceRegister",
  PaymentHistory: baseURL + "/PaymentHistory",
  OrganizationAccountActivation: baseURL + "/Organization/Account/Activation",
  UserAccountActivation: baseURL + "/User/Account/Activation",
  ResendMail: baseURL + "/Activation/ResendMail",
  Fota:baseURL + "/Fota"
};
export default RoutesConstants;
