/*    
<summary>
   This file is used to provide initial values or state to the Add Configuration Form.
   Developer: Mohammad Saquib Khan, Created Date: 19-April-2023
</summary>
*/

import IAddConfiguration, { IHttpsParameters } from "../../models/Form/IAddUpdateConfiguration";

export const initialState: IAddConfiguration = {

	SourceProtocol: 1,
	Name: "",
	Description: "",
	TopicPattern: "",
	DestinationProtocol: 0,
	PayloadFormat: 1,
	IsSuccessPacketsStoreInDb: false,
	IsFailedPacketsStoreInDb: false,
	HttpsParameters: {
		Host: "",
		AuthorizationType: 0,
		UserId: "",
		Password: "",
		Key: "",
		Value: "",
		SendApiKeyValueType: "0",
		Token: "",
		AccessKey: "",
		SecretKey: "",
		CustomHeaders: [],
		IsSendICCID: false,
		IsSendTopic: false,
		SendType: "0",
	},
	MqttsParameters: {
		Host: "",
		ClientId: "",
		UserId: "",
		Password: "",
		Advanced: false,
		Topic: "",
		TopicType: "0",
		Qos: "0",
		UseCertificate: false,
		ServerCertificate: "",
		ClientCertificate: "",
		ClientKey: "",
		CustomTopic: "",
	},
	AwsIoTCoreParameters: {
		Host: "",
		UserId: "",
		Password: "",
		Topic: "",
		TopicType: "0",
		Qos: "0",
		ServerCertificate: "",
		CustomTopic: "",
	},
	AwsS3BucketParameters: {
		BucketName: "",
		AccessKey: "",
		SecretKey: "",
		RegionId: -1,
		Region: {Id:-1, RegionName:"", Region:""},
	},
	AwsKinesisParameters: {
		StreamName: "",
		AccessKey: "",
		SecretKey: "",
		RegionId: -1,
		Region: {Id:-1, RegionName:"", Region:""},
	},
	KeyCustom: "",
    ValueCustom: "",
}
