/*    
<summary>
   This file is used to provide initial values or state to the AddDeviceForm.
   Developer:Ashish Singh, Created Date: 03-April-2023
</summary>
*/
import IAddDevices from "../../models/Form/IAddUpdateDevice";
import { IDeviceDetailVm } from "../../models/response/IDeviceResponse";

export const initialState: IAddDevices = {
    GroupId: -1,
    Name: '',
    ICCID: '',
    Tags: [],
    ClientCertificate: '',
    ClientKey: '',
    Protocol: -1
}

export const initialDeviceDetailInfoState: IDeviceDetailVm = {
    Id: -1,
    Name: '',
    ICCID: '',
    IsActive: false,
    FirstPacketTimestamp: '',
    LastPacketTimestamp: '',
    ExpiryDateTimestamp: '',
    OrderNo: '',
    PlanName: ""
}