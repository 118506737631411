/*    
<summary>
   This class component is all about Managing deviceRegistereds.
   Developer: Aashish Singh, Created Date: 11-June-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { ICommonState } from "../../models/state/ICommonState";
import * as baseService from '../service/base-service';
import URLConstants from "../../constants/url.constants";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import toast, { Toaster } from 'react-hot-toast';
import IReisterDeviceIccid, { IUpdateRegisteredDevice } from "../../models/Form/IAddUpdateDeviceRegister";
import { IDeviceRegisterFilter, IDeviceRegisterList, IDeviceRegisterationVm, IRegisteredDeviceListVm } from "../../models/response/IRegisterDeviceResponse";
import { initialState as addDeviceRegisterInitialState } from "../initial-state/add-device-iccid-state";
import { initialState as allDeviceRegisterInitialState } from "../initial-state/get-all-registered-device-state";
import IAddDeviceRegisterBulk from "../../models/Form/IAddDeviceRegisterInBulk";
import moment from "moment";
import config from "../../helpers/config-helper";
import Papa from 'papaparse';
import JSZip from 'jszip';
import { emptyValue } from "../../constants/common-constants";
import { getLanguageFromLocalStorage } from "../../helpers/local-stotrage-helper";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;
const fileNameDateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_FILENAME;

export class DeviceRegisterStore implements ICommonState {
    inProgress = false;
    error = '';
    deviceRegisteredDataList: IRegisteredDeviceListVm = allDeviceRegisterInitialState;
    recentDeviceRegisterList: IRegisteredDeviceListVm = allDeviceRegisterInitialState;

    initialStateValue = {
        success: false,
        error: '',
        inProgress: false
    }
    registerDeviceDetail: any = undefined;
    registerDeviceDetailState = { ... this.initialStateValue }
    registerDeviceCount: number = 0;
    registerDeviceCountState = { ... this.initialStateValue }
    addUpdateDeviceRegisterState = { ...this.initialStateValue }
    moveDeviceRegisterState = { ...this.initialStateValue }
    deleteDeviceRegisterState = { ...this.initialStateValue }
    deleteAllDeviceRegisterState = { ...this.initialStateValue }
    deleteSelectedDeviceRegisterState = { ...this.initialStateValue }
    addBulkDeviceRegisterState = { ... this.initialStateValue }
    deleteFilteredDeviceRegisterState = { ... this.initialStateValue }
    addDeviceRegisterCSVState = { ... this.initialStateValue }

    initialFilter: IDeviceRegisterFilter = {
        SearchBy:-1,
        OrderNo:"",
        DeviceName:"",
        ICCID:"",
        isFilterActive: false
    }

    filter: IDeviceRegisterFilter = {
        ...this.initialFilter
    }
    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            filter: observable,
            registerDeviceDetail: observable,
            registerDeviceDetailState: observable,
            registerDeviceCount: observable,
            registerDeviceCountState: observable,
            deviceRegisteredDataList: observable,
            addUpdateDeviceRegisterState: observable,
            deleteDeviceRegisterState: observable,
            deleteAllDeviceRegisterState: observable,
            deleteSelectedDeviceRegisterState: observable,
            moveDeviceRegisterState: observable,
            addBulkDeviceRegisterState: observable,
            deleteFilteredDeviceRegisterState: observable,
            addDeviceRegisterCSVState: observable,
            getAllDeviceRegister: action,
            addDeviceRegister: action,
            UpdateDeviceRegisterService: action,
            getRegisteredDeviceByIdService: action,
            GetRegisteredDeviceCountByTenantIdService: action,
            addDeviceRegisterInBulk: action,
            deleteDeviceRegister: action,
            deleteAllDeviceRegister: action,
            deleteSelectedDeviceRegister: action,
            moveDeviceRegisterToGroups: action,
            addDeviceRegisterCSV: action,
            // downloadLog: action,
            // generateLogContent: action,
            deleteFilteredDeviceRegister: action,
            reset: action,
            resetStore: action,
            resetAddUpdateDeviceRegister: action,
            resetGetRegisterDeviceDetail: action,
            resetAddBulkDeviceRegisterState: action,
            resetMoveDeviceRegisterToGroups: action,
            resetRegisterDeviceCountState: action,
            setFilterDetail: action,
            allDeviceRegisterlist: computed,
            getDevieIccidDetail: computed,
        });
    }

    /**
    * This function is used to get All deviceRegistereds list from API by group id.
    * @param data: An object that have data like groupId, DeviceRegisterName & tags
    */
    getAllDeviceRegister = (data: any) => {
        this.inProgress = true;
        let filter = data.Filter;
        let url = URLConstants.RegisterDevice + "/List";
        let tempSearchBy:number = parseInt(filter.SearchBy)
        let requestBody = {
            TenantId: data.TenantId,
            OrderNo: filter.OrderNo,
            SearchBy: tempSearchBy > 0 ? tempSearchBy : 0,
            DeviceName: filter.DeviceName,
            ICCID: filter.ICCID,
            PagingDetails: {
                ...data.PagingDetails,
                TotalPages: 0,
                TotalRecords: 0
            },
        }
        return baseService.postRequest(url, requestBody)
            .then((response: IApiResponse<IApiSuccessResponse<IRegisteredDeviceListVm>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.deviceRegisteredDataList = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.deviceRegisteredDataList = demoDeviceRegister;
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
     * This function is used to Get Device Data Details by Id from API.
     * @param id : Device Identifier
     * @returns
     */
    getRegisteredDeviceByIdService = (id: number) => {
        this.registerDeviceDetailState.inProgress = true;
        const url = URLConstants.RegisterDevice + "/" + id;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IDeviceRegisterationVm>>) => {
                if (response.data.Error) {
                    this.registerDeviceDetailState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.registerDeviceDetail = response.data.Data;
                    this.registerDeviceDetailState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.registerDeviceDetailState.inProgress = false }));
    }

    /**
     * This function is used to Get registered devices count by tenant Id from API.
     * @param id : Device Identifier
     * @returns
     */
    GetRegisteredDeviceCountByTenantIdService = (tenantId: number) => {
        this.registerDeviceCountState.inProgress = true;
        const url = URLConstants.RegisterDevice + "/Tenant/" + tenantId +"/Count";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<number>>) => {
                if (response.data.Error) {
                    this.registerDeviceCountState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.registerDeviceCount = response.data.Data;
                    this.registerDeviceCountState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.registerDeviceCountState.inProgress = false }));
    }

    resetRegisterDeviceCountState = () =>{
        this.registerDeviceCountState = {...this.initialStateValue};
        this.registerDeviceCount = 0;
    }
    
    /**
     * This function is used to Add New DeviceRegister. 
     * @param deviceRegistered : DeviceRegister Details
     * @returns
     */
    addDeviceRegister = (deviceRegistered: IReisterDeviceIccid, tenantId: number) => {
        this.addUpdateDeviceRegisterState.inProgress = true;
        let url = URLConstants.RegisterDevice;
        return baseService.postRequest(url, {...deviceRegistered, TenantId: tenantId})
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateDeviceRegisterState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.addUpdateDeviceRegisterState.success = true;
                    toast.success(formatMessage(response.data.Message));
                }
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
                } else { toast.error(formatMessage(err)); }
            })
            .finally(action(() => { this.addUpdateDeviceRegisterState.inProgress = false; }));
    }

    /**
     * This function is used to update existing device details. 
     * @param id : Device identifier
     * @param device : Device Details
     * @returns
     */
    UpdateDeviceRegisterService = (id: number, device: IReisterDeviceIccid, tenantId: number) => {
        this.addUpdateDeviceRegisterState.inProgress = true;
        let data: IUpdateRegisteredDevice = {
            Id: id,
            ...device
        }
        let url = URLConstants.RegisterDevice;
        return baseService.putRequest(url, {...data, TenantId: tenantId})
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateDeviceRegisterState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdateDeviceRegisterState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addUpdateDeviceRegisterState.inProgress = false; }));
    }

    /**
     * This function is used to Move DeviceRegister to Groups. 
     * @param data : DeviceRegister & Group Details
     * @returns
     */
    moveDeviceRegisterToGroups = (data: any) => {
        this.moveDeviceRegisterState.inProgress = true;
        let url = URLConstants.RegisterDevice + "/Move";
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.moveDeviceRegisterState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.moveDeviceRegisterState.success = true;
                    toast.success(formatMessage("registered_device_move_successfully"));
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.moveDeviceRegisterState.inProgress = false; }));
    }

    /**
     * This function is used to delete existing deviceRegistered. 
     * @param id : DeviceRegister identifier
     * @returns
     */
    deleteDeviceRegister = (id: number) => {
        this.deleteDeviceRegisterState.inProgress = true;
        const url = URLConstants.RegisterDevice + "/" + id;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteDeviceRegisterState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteDeviceRegisterState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteDeviceRegisterState.inProgress = false; }));
    }

    /**
     * This function is used to delete existing deviceRegistered. 
     * @param id : Group identifier
     * @returns
     */
    deleteAllDeviceRegister = (id: number) => {
        this.deleteAllDeviceRegisterState.inProgress = true;
        const url = URLConstants.RegisterDevice + "/All/Tenant/" + id;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteAllDeviceRegisterState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteAllDeviceRegisterState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteAllDeviceRegisterState.inProgress = false; }));
    }

    /**
     * This function is used to delete existing deviceRegistered. 
     * @param id : Group identifier
     * @returns
     */
    deleteSelectedDeviceRegister = (obj: any) => {
        this.deleteSelectedDeviceRegisterState.inProgress = true;
        let url = URLConstants.RegisterDevice + "/Delete/Multiple"
        return baseService.postRequest(url, obj)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteSelectedDeviceRegisterState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteSelectedDeviceRegisterState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteSelectedDeviceRegisterState.inProgress = false; }));
    }

    /**
 * This function is used to delete existing deviceRegistered. 
 * @param id : Group identifier
 * @returns
 */
    deleteFilteredDeviceRegister = (obj: any) => {
        this.deleteFilteredDeviceRegisterState.inProgress = true;
        return baseService.postRequest(URLConstants.DeleteFiltered, obj)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteFilteredDeviceRegisterState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteFilteredDeviceRegisterState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteFilteredDeviceRegisterState.inProgress = false; }));
    }

    /**
    * This function is used to Add New DeviceRegister using Bulk DeviceRegister Service. 
    * @param deviceRegistereds : Bulk DeviceRegister Details
    * @returns
    */
    addDeviceRegisterInBulk = (deviceRegistered: IAddDeviceRegisterBulk) => {
        this.addBulkDeviceRegisterState.inProgress = true;
        let url = URLConstants.RegisterDevice + "/Bulk";
        return baseService.postRequest(url, deviceRegistered)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addBulkDeviceRegisterState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.addBulkDeviceRegisterState.success = true;
                    toast.success(formatMessage(response.data.Message));
                }

            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(formatMessage(errorMess[0]) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }

            })
            .finally(action(() => { this.addBulkDeviceRegisterState.inProgress = false; }));
    }

    /**
    * This function is used to Add New DeviceRegister using CSV Service. 
    * @param deviceRegistereds : Add DeviceRegister CSV 
    * @returns
    */
    addDeviceRegisterCSV = (deviceRegistereds: IAddDeviceRegisterBulk) => {
        this.addDeviceRegisterCSVState.inProgress = true;
        let url = URLConstants.RegisterDevice + "/CSV";
        return baseService.postRequest(url, deviceRegistereds)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addDeviceRegisterCSVState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    if(response.data){
                        this.downloadLog(response.data);
                        this.addDeviceRegisterCSVState.success = true;
                        toast.success(formatMessage(response.data.Message));
                    }
                }

            })
            .catch((err: string) => {
                this.addDeviceRegisterCSVState.error = err;
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(formatMessage(errorMess[0]) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }

            })
            .finally(action(() => { this.addDeviceRegisterCSVState.inProgress = false; }));
    }


    /**
    * This function is used to download Log. 
    * @param deviceRegistereds : DeviceRegister CSV 
    * @returns
    */
    downloadLog = (data: any) => {
        const currentDate = new Date();
        const logContent = this.generateLogContent(data.Data);
        const blob = new Blob([logContent], { type: "text/plain" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = moment(currentDate).format(fileNameDateTimeFormat) + " log.txt";
        a.click();
        URL.revokeObjectURL(a.href);
    };


    generateLogContent = (data: any) => {
        const currentDate = new Date();
      
        const generateErrorList = (values: string[] | number, itemsPerLine: number = 5) => {
            if (!Array.isArray(values) || values.length === 0) {
              return 'None';
            }
        
            const formattedList = values.map((item, index) => {
              const separator = (index + 1) % itemsPerLine === 0 ? '\n' : ', ';
              return `${item}${separator}`;
            });
        
            return formattedList.join('').trim();
          };
      
        const logContent = `
Log generated on: ${currentDate}
Total Register Device Count (To be added): ${data.TotalDevices}
Register Device Added: ${data.FilteredDevices - data.FailedDueToSpace?.length}

**Errors**

Register Device Failed: ${data.TotalDevices - data.FilteredDevices + data.FailedDueToSpace?.length}
[Row No's] Register Device Name Validation Breached : ${data.NameValidation?.length > 0 ? data.NameValidation : 0}
[Row No's] Register Device Name Contains Invalid Character: ${data.NameHasInvalidChar > 0 ? data.NameHasInvalidChar : 0}          
[Row No's] ICCID Validation Breached : ${data.IccidValidation?.length > 0 ? data.IccidValidation : 0}          
[Row No's] ICCID is Alphanumeric : ${data.IccidAlphanumerics?.length > 0 ? data.IccidAlphanumerics : 0}      
[Row No's] Duplicate Data in CSV: ${data.DuplicateIndex?.length > 0 ? data.DuplicateIndex : 0}
[Row No's] Register Device Failed due to Register Device Limit: ${data.FailedDueToSpace?.length > 0 ? data.FailedDueToSpace : 0}
[Cell No's] Missing Fields in CSV: ${data.MissingCellsIndex.length > 0 ?
    data.MissingCellsIndex.filter(
        (index: string) => !index.includes("C") && !index.includes("D")
    ) : 0}

**DeviceRegister Names Already exist (Count ${data.RegisterationDeviceNameAlreadyExist?.length}) ** :
${data?.RegisterationDeviceNameAlreadyExist?.length > 0 ? generateErrorList(data.RegisterationDeviceNameAlreadyExist) : 0}

 **DeviceRegister ICCID's Already exist (Count: ${data.RegisterationDeviceICCIDAlreadyExist?.length}) ** :
 ${data?.RegisterationDeviceICCIDAlreadyExist?.length > 0 ? generateErrorList(data.RegisterationDeviceICCIDAlreadyExist) : 0}

**DeviceRegister ICCID's Unavailable (Count: ${data.IccidNotAvailable?.length}) ** :
 ${data?.IccidNotAvailable?.length > 0 ? generateErrorList(data.IccidNotAvailable) : 0}
`;
      
        return logContent;
      };
      
    /**
     * This function is used to reset observables to their initial values.
     * @returns
     */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.deleteDeviceRegisterState = {
            ...this.initialStateValue
        };
        this.deleteAllDeviceRegisterState = {
            ...this.initialStateValue
        };
        this.deleteSelectedDeviceRegisterState = {
            ...this.initialStateValue
        };
        this.deleteFilteredDeviceRegisterState = {
            ...this.initialStateValue
        }
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.filter = {...this.initialFilter};
        this.deviceRegisteredDataList = allDeviceRegisterInitialState;
        this.recentDeviceRegisterList = allDeviceRegisterInitialState;
        this.addUpdateDeviceRegisterState = { ...this.initialStateValue }
        this.moveDeviceRegisterState = { ...this.initialStateValue }
        this.deleteDeviceRegisterState = { ...this.initialStateValue }
        this.deleteAllDeviceRegisterState = { ...this.initialStateValue }
        this.deleteSelectedDeviceRegisterState = { ...this.initialStateValue }
        this.addBulkDeviceRegisterState = { ... this.initialStateValue }
        this.addDeviceRegisterCSVState = { ... this.initialStateValue }

        this.registerDeviceDetail = undefined;
        this.registerDeviceDetailState = { ... this.initialStateValue }
        this.deleteFilteredDeviceRegisterState = { ... this.initialStateValue }
    }

    /**
     * This function is used to reset addUpdateDeviceRegister observables to their initial values.
     * @returns
     */
    resetAddUpdateDeviceRegister = () => {
        this.addUpdateDeviceRegisterState = { ...this.initialStateValue }
    }

    /**
     * This function is used to reset addBulkDeviceRegisterState observables to their initial values.
     */
    resetAddBulkDeviceRegisterState = () => {
        this.addBulkDeviceRegisterState = { ...this.initialStateValue }
    }


    /**
     * This function is used to reset addUpdateDeviceRegister observables to their initial values.
     * @returns
    */
    resetMoveDeviceRegisterToGroups = () => {
        this.moveDeviceRegisterState = { ...this.initialStateValue }
    }

    /**
     * This function is used to reset Export CSV observables to their initial values.
     * @returns
     */
    resetDeleteFilteredDeviceRegister = () => {
        this.deleteFilteredDeviceRegisterState = { ...this.initialStateValue }
    }

    /**
     * This function is used to reset Export CSV observables to their initial values.
     * @returns
     */
    resetAddDeviceRegisterCSV = () => {
        this.addDeviceRegisterCSVState = { ...this.initialStateValue }
    }

    /*
    This function is used to set the filter details to the order observable.  
    */
    setFilterDetail = (filter: IDeviceRegisterFilter) => {
        this.filter = {...filter};
    }

    /**
     * This function is used to map deviceRegisteredDataList to allDeviceRegisterlist suitable for Grid Component.
     * @returns Initial DeviceRegister Details
     */
    get allDeviceRegisterlist(): IDeviceRegisterList[] {
        if (this.deviceRegisteredDataList?.Devices && this.deviceRegisteredDataList.Devices.length > 0)
            return this.deviceRegisteredDataList?.Devices.map((deviceRegistered) => {
                let language = getLanguageFromLocalStorage();
                return {
                    Id: deviceRegistered?.Id,
                    ICCID: deviceRegistered?.ICCID,
                    Name: deviceRegistered?.Name,
                    OrderNo: deviceRegistered?.OrderNo,
                    TenantName: deviceRegistered.Tenant ? (deviceRegistered.Tenant.TenantName ? deviceRegistered.Tenant.TenantName : emptyValue) : emptyValue,
                    Language: language,
                    CreatedDate: deviceRegistered.CreatedDate,
                    isDelete: true,
                    isEdit: true,
                    isData: true,
                }
            })
            
        return [];
    }

    get getDevieIccidDetail(): IReisterDeviceIccid {
        if(this.registerDeviceDetail)
            {
                return {
                    OrderNo: this.registerDeviceDetail.OrderNo,
                    ICCID: this.registerDeviceDetail.ICCID,
                    Name: this.registerDeviceDetail.Name,
                    TenantId: this.registerDeviceDetail.Tenant ? this.registerDeviceDetail.Tenant.Id : -1
                }
            }
        return addDeviceRegisterInitialState;
    }

     /**
     * This function is used to reset resetGetRegisterDeviceDetail observables to their initial values.
     * @returns
     */
     resetGetRegisterDeviceDetail = () => {
        this.registerDeviceDetail = undefined;
        this.registerDeviceDetailState = { ...this.initialStateValue }
    }
}

export default new DeviceRegisterStore();