/*    
<summary>
   This functional common component provides us custom select tag with options & also has error handler that shows 
   validation error.
   Developer:Aashish Singh, Created Date:25-Aug-2022 
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { injectIntl } from "react-intl";
import FormikFormErrorHandler from "./FormikFormErrorHandler";
import { useField } from "formik";
import { formatMessage } from "../../translations/format-message";
import { IOption } from "../../models/ICommon";

type Option = {
  id: number;
  value: string;
  disabled:boolean;
};

interface IProps {
  getData?: boolean;
  children: React.ReactNode;
  className: string;
  intl: any; //IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  setLocalState?: (id:any) => void;
  placeholder: string;
  type: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  spaceAllowed: boolean;
  validcharacter?: Array<string>;
  invalidcharacter?: Array<string>;
  options: IOption[];
  id: string;
}

const FormikFormSelect = (props: IProps) => {
  const { name, options, disabled } = props;
  const [field, meta, helpers]: any = useField(props);

  return (
    <>
      <select
        name={name}
        {...field}
        className="form-control"
        data-testid={props.id ? props.id : undefined}
        disabled={props.disabled}
        onChange={(event)=>{  
          let value = event.target.value;
          if(props.setLocalState != undefined)
            props.setLocalState(value);
          helpers.setValue(value);
        }}
      >
        {options?.map((data: IOption, i: number) => (
          <option key={i} value={props.getData === true ? data.value : data.id} id={props.getData === true ? data.value.toString() : data.id.toString()} disabled={data.disabled}>
            {
              data.name ? data.name : formatMessage(data.value)
            }
          </option>
        ))}
      </select>
      <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormSelect);
