/*    
<summary>
  This functional component "AddUpdateDeviceForm" provides form to add new user or update existing user.
  Developer:Mohammad Saquib Khan, Created Date: 29-March-2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import "./add-edit-mqtts-config-form.css";
import { observer } from "mobx-react-lite";

import FormikFormInput from "../../../../../shared-components/FormikFormHandlers/FormikFormInput";
import { Button } from "react-bootstrap";
import LoaderButton from "../../../../../shared-components/Button/LoaderButton";
import FormikFormCheckBox from "../../../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import FormikFormTextArea from "../../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { addMQTTSValidateSchema } from "../../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormLoader from "../../../../../shared-components/FormLoader/FormLoader";
import toast, { Toaster } from "react-hot-toast";
import FormikFormSelect from "../../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { SourceProtocol } from "../../../../../constants/options.constant";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import config from "../../../../../helpers/config-helper";
import { formatMessage } from "../../../../../translations/format-message";
import { getThemeFromLocalStorage } from "../../../../../helpers/local-stotrage-helper";

interface IProps {
  Id: number;
  actionLoading: boolean;
  dataLoading: boolean;
  IsDataPakcetsStoreInDB: boolean;
  initialValues: any;
  submitHandler: (data: any) => void;
  modalClosed: () => void;
}

const appConfig = config();
const isStoreinDbSuccess = appConfig.IS_STORE_IN_DB_SUCCESS;
const isStoreinDbFailed = appConfig.IS_STORE_IN_DB_FAILED;

const AddMQTTSConfigForm = (props: IProps) => {
  const [useCertificate, setUseCertificate] = useState<boolean>(false);
  const [ServerCertificateFileName, setServerCertificateFileName] =
    useState<any>(props.Id > 0 ? "Update File" : "Choose File");
  const [ClientCertificateFileName, setClientCertificateFileName] =
    useState<any>(props.Id > 0 ? "Update File" : "Choose File");
  const [ClientKeyFileName, setClientKeyFileName] =
    useState<any>(props.Id > 0 ? "Update File" : "Choose File");

  const [ServerCertificate, setServerCertificate] =
    useState<any>("");
  const [ClientCertificate, setClientCertificate] =
    useState<any>("");
  const [ClientKey, setClientKey] =
    useState<any>("");

  /**
   * Area Block for Get functions/variables from store class
   * CreatedBy:Mohammad Saquib Khan , Date:20-April-2023
   */

  const { initialValues, submitHandler, modalClosed, actionLoading, dataLoading } = props;

  /**End */

  const handleFileReadServerCertificate = (event: any) => {
    const content = event.target.result;
    setServerCertificate(content);
    // or do whatever you need with the file content
  };

  const handleFileReadClientCertificate = (event: any) => {
    const content = event.target.result;
    setClientCertificate(content);
    // or do whatever you need with the file content
  };

  const handleFileReadClientKey = (event: any) => {
    const content = event.target.result;
    setClientKey(content);
    // or do whatever you need with the file content
  };

  const updateUseCertificates = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseCertificate(e.target.checked);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className="mx-auto">
        {dataLoading && <FormLoader loading={dataLoading} />}
        <Formik
          initialValues={initialValues}
          validationSchema={addMQTTSValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            console.log(values.TopicPattern)
            submitHandler({...values, MqttsParameters:{...values.MqttsParameters}});
            // values.DestinationProtocol = 3;
            // values.SourceProtocol = values.SourceProtocol;
            // values.MqttsParameters.Qos = Number(values.MqttsParameters.Qos);
            // values.HttpsParameters = null;
            // if (values.MqttsParameters.top == "0") {
            //   values.MqttsParameters.Topic = "";
            // } else if (values.MqttsParameters.top == "1") {
            //   values.MqttsParameters.Topic = values.MqttsParameters.Topic;
            // }
            // if (values.MqttsParameters.Advanced == false) {
            //   values.MqttsParameters.Topic = "";
            // }
            // if (values.MqttsParameters.UseCertificate == false) {
            //   values.MqttsParameters.ServerCertificate = ""
            //   values.MqttsParameters.ClientCertificate = ""
            //   values.MqttsParameters.ClientKey = ""
            // }
            // if (values.MqttsParameters.UseCertificate == true) {
            //   values.MqttsParameters.ServerCertificate = ServerCertificate;
            //   values.MqttsParameters.ClientCertificate = ClientCertificate;
            //   values.MqttsParameters.ClientKey = ClientKey;
            // }
            // delete values.top;
            // setServerCertificate("");
            // setClientCertificate("");
            // setClientKey("");
            // submitHandler(values);
          }}
          render={({ values }) => (
            <Form id="formBody">
              <div className="position-relative  px-4 py-3">
                <div className="row form-group align-items-center">
                  <div className=" col-md-4">
                    <label className="label-style fw-semibold">
                      <FormattedMessage id="source_protocol" />
                    </label>
                  </div>

                  <div className="formS et col-md-8">
                    <Field
                      data-testid="test_source_protocol"
                      className="form-control"
                      name="SourceProtocol"
                      placeholder="source_protocol"
                      type="text"
                      options={SourceProtocol}
                      as={FormikFormSelect}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className=" col-sm-12 form-group formSet">
                    <label className="label-style text-dark fw-semibold">
                      <FormattedMessage id="topic_pattern" />
                    </label>
                    <Field
                      data-testid="test_topic_pattern"
                      className="form-control"
                      name="TopicPattern"
                      placeholder="e.g-/topic1/{iccid}/topic2"
                      type="text"
                      as={FormikFormInput}
                    />
                  </div>
                </div>
              </div>
              <div className="py-3 px-4 bg-light rounded-3">
                <h4 className="divider_heading">
                  <FormattedMessage id="destination_protocol" />
                  <span className="ms-2 badge bg-success">MQTTS</span>
                </h4>
                <div className="row">
                  <div className="form-group formSet col-sm-12">
                    <label className="label-style text-dark fw-semibold">
                      <FormattedMessage id="config_name" />
                    </label>
                    <Field
                      data-testid="test_devicename"
                      className="form-control"
                      name="Name"
                      placeholder="config_name"
                      type="text"
                      as={FormikFormInput}
                    />
                  </div>
                </div>
                <div className="form-group formSet">
                  <label className="label-style text-dark fw-semibold">
                    <FormattedMessage id="desc" />
                  </label>
                  <Field
                    data-testid="testjson"
                    name="Description"
                    type="text"
                    className="form-control"
                    placeholder="enter_text"
                    rows={3}
                    style={{ resize: "none" }}
                    as={FormikFormTextArea}
                  />

                  {/* <div className="checkbox">
                    <label>
                      <Field
                        data-testid="testRememberMe"
                        className="form-control"
                        name="IsStoreInDb"
                        type="checkbox"
                        id="inlineFormCheck"
                        as={FormikFormCheckBox}
                      />
                      <span className="cr">
                        <i className="cr-icon fas fa-check"></i>
                      </span>
                    </label>
                    <label className="h">
                      <FormattedMessage id="store_in_DB" />
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-info-circle ms-2"
                      viewBox="0 0 16 16"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html="Store in DB will store the data in Database <br /> else will directly operate on client API"
                      data-tooltip-variant="dark"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <Tooltip id="my-tooltip" place="right" />
                  </div> */}

{
                    (isStoreinDbSuccess || isStoreinDbFailed) &&
                    props.IsDataPakcetsStoreInDB == true ?
                    <div className="row">
                      <div className="col-md-4">
                        <label className="h">
                          <FormattedMessage id="store_in_DB" />
                        </label>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-info-circle ms-2"
                          viewBox="0 0 16 16"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-html={formatMessage("store_in_db_info")}
                          data-tooltip-variant="dark"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <Tooltip id="my-tooltip" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                      </div>
                      {
                        isStoreinDbSuccess &&
                        <div className="checkbox col-md-4">
                          <label>
                            <Field
                              data-testid="testRememberMe"
                              className="form-control"
                              name="IsSuccessPacketsStoreInDb"
                              type="checkbox"
                              id="inlineFormCheck"
                              as={FormikFormCheckBox}
                            />
                            <span className="cr">
                              <i className="cr-icon fas fa-check"></i>
                            </span>
                          </label>
                          <label className="h">
                            <FormattedMessage id="store_in_db_success" />
                          </label>
                        </div>
                      }
                      {
                        isStoreinDbFailed &&
                        <div className="checkbox col-md-4">
                          <label>
                            <Field
                              data-testid="testRememberMe"
                              className="form-control"
                              name="IsFailedPacketsStoreInDb"
                              type="checkbox"
                              id="inlineFormCheck"
                              as={FormikFormCheckBox}
                            />
                            <span className="cr">
                              <i className="cr-icon fas fa-check"></i>
                            </span>
                          </label>
                          <label className="h">
                            <FormattedMessage id="store_in_db_failed" />
                          </label>
                        </div>
                      }
                    </div>
                    :""
                  }
                </div>

                <div className="position-relative">
                  <span className="fs14 text-dark fw-semibold">
                    <FormattedMessage id="parameters" />
                  </span>
                  <div className="form-group formSet">
                    <div className="input-group">
                      
                        <label className="input-group-text label-style">
                          <FormattedMessage id="host" />
                        </label>
                      
                      
                        <label className="input-group-text bg-light label-style">
                          mqtts://
                        </label>
                      
                      <Field
                        data-testid="test_host"
                        className="form-control"
                        name="MqttsParameters.Host"
                        placeholder="host"
                        type="text"
                        validcharacter={[
                          "-",
                          "_",
                          ":",
                          "#",
                          "@",
                          ".",
                          "/",
                          "?",
                          "=",
                          "%",
                        ]}
                        as={FormikFormInput}
                      />
                    </div>

                    <div className="input-group">
                      
                        <label className="label-style input-group-text">
                          <FormattedMessage id="client_id" />
                        </label>
                      
                      <Field
                        data-testid="test_client_id"
                        className="form-control"
                        name="MqttsParameters.ClientId"
                        placeholder="client_id"
                        type="text"
                        as={FormikFormInput}
                      />
                    </div>
                    <div className="input-group">
                      
                        <label className="label-style input-group-text">
                          <FormattedMessage id="user_id" />
                        </label>
                      
                      <Field
                        data-testid="test_user_id"
                        className="form-control"
                        name="MqttsParameters.UserId"
                        placeholder="user_id"
                        type="text"
                        as={FormikFormInput}
                      />
                    </div>
                    <div className="input-group">
                      
                        <label className="label-style input-group-text">
                          <FormattedMessage id="password" />
                        </label>
                      
                      <Field
                        data-testid="test_password"
                        className="form-control"
                        name="MqttsParameters.Password"
                        placeholder="password"
                        type="password"
                        autoComplete="new-password"
                        as={FormikFormInput}
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <h4 className="m-0">
                      <FormattedMessage id="advanced_options" />
                    </h4>
                    <div className="p-0 form-switch ms-2">
                      <label className="switch">
                        <Field
                          data-testid="testRememberMe"
                          className="form-control"
                          name="MqttsParameters.Advanced"
                          type="checkbox"
                          id="inlineFormCheck"
                          as={FormikFormCheckBox}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {values.MqttsParameters.Advanced == true ? (
                    <>
                      <div className="d-flex align-items-center">
                        <h4 className="m-0">
                          <FormattedMessage id="use_certificates" />
                        </h4>
                        <div className="p-0 form-switch ms-2">
                          <label className="switch">
                            <Field
                              data-testid="testRememberMe"
                              className="form-control"
                              name="MqttsParameters.UseCertificate"
                              type="checkbox"
                              id="inlineFormCheck"
                              as={FormikFormCheckBox}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="form-group formSet mt-3">
                        {values.MqttsParameters.UseCertificate == true ? (

                          <React.Fragment>
                            {props.Id > 0 ?
                              <div className="alert alert-warning">*<FormattedMessage id={"file_upload_message"} /></div> : ""}
                            <div className="input-group mt-3">
                              
                                <label className="label-style input-group-text">
                                  <FormattedMessage id="ca_file" />
                                  <small>
                              <FormattedMessage id="server_certificate" />
                                  </small>
                                </label>
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="ckf_file"
                                  name="MqttsParameters.ServerCertificate"
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const reader = new FileReader();
                                    if (e.target.files != null) {
                                      reader.onload =
                                        handleFileReadServerCertificate;
                                      reader.readAsText(e.target.files[0]);
                                      setServerCertificateFileName(
                                        e.target.files[0].name
                                      );
                                    } else {
                                      setServerCertificateFileName(
                                        props.Id > 0 ? "Update File" : "Choose File"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="ckf_file"
                                >
                                  {
                                    <FormattedMessage
                                      id={ServerCertificateFileName}
                                    />
                                  }
                                </label>
                              </div>
                            </div>
                            <div className="input-group mt-0">
                              
                                <label className="label-style input-group-text file_pre">
                                  <FormattedMessage id="ccf" />
                                </label>
                              
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="ccf_file"
                                  name="MqttsParameters.ClientCertificate"
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const reader = new FileReader();
                                    if (e.target.files != null) {
                                      reader.onload =
                                        handleFileReadClientCertificate;
                                      reader.readAsText(e.target.files[0]);
                                      setClientCertificateFileName(
                                        e.target.files[0].name
                                      );
                                    } else {
                                      setClientCertificateFileName(
                                        props.Id > 0 ? "Update File" : "Choose File"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="ccf_file"
                                >
                                  {
                                    <FormattedMessage
                                      id={ClientCertificateFileName}
                                    />
                                  }
                                </label>
                              </div>
                            </div>
                            <div className="input-group mt-0 mb-3">
                              
                                <label className="label-style input-group-text file_pre">
                                  <FormattedMessage id="ckf" />
                                </label>
                              
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="ckf_file"
                                  name="MqttsParameters.ClientKey"
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const reader = new FileReader();
                                    if (e.target.files != null) {
                                      reader.onload = handleFileReadClientKey;
                                      reader.readAsText(e.target.files[0]);
                                      setClientKeyFileName(
                                        e.target.files[0].name
                                      );
                                    } else {
                                      setClientKeyFileName(props.Id > 0 ? "Update File" : "Choose File");
                                    }
                                  }}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="ckf_file"
                                >
                                  {<FormattedMessage id={ClientKeyFileName} />}
                                </label>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        <div className="row">
                          <div className="col-sm-8">
                            <div className="row form-group btn-radioCheckbox">
                              <div className="col-12">
                                <label className="label-style">
                                  <FormattedMessage id="topic" />
                                </label>
                              </div>
                              <div className="col-6">
                                <Field
                                  type="radio"
                                  value="0"
                                  name="MqttsParameters.TopicType"
                                  id="incoming-tab"
                                  className="nav-link active"
                                />
                                <label htmlFor="incoming-tab">
                                  <FormattedMessage id="same_as_incoming" />
                                </label>
                              </div>
                              <div className="col-6">
                                <Field
                                  type="radio"
                                  value="1"
                                  name="MqttsParameters.TopicType"
                                  id="custom-tab"
                                  className="nav-link active"
                                />
                                <label htmlFor="custom-tab">
                                  <FormattedMessage id="custom" />

                                  {values.MqttsParameters.TopicType == "1" ? (
                                    <React.Fragment>
                                      <Field
                                        data-testid="test_custom"
                                        className="form-control"
                                        name="MqttsParameters.CustomTopic"
                                        placeholder="provide_custom"
                                        type="text"
                                        as={FormikFormInput}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="row form-group btn-radioCheckbox">
                              <div className="col-12">
                                <label className="label-style">
                                  <FormattedMessage id="qos" />
                                </label>
                              </div>

                              <div className="col-6">
                                <Field
                                  type="radio"
                                  value="0"
                                  name="MqttsParameters.Qos"
                                  id="zero-tab"
                                  className="nav-link active"
                                />
                                <label htmlFor="zero-tab">
                                  <FormattedMessage id="zero" />
                                </label>
                              </div>
                              <div className="col-6">
                                <Field
                                  type="radio"
                                  value="1"
                                  name="MqttsParameters.Qos"
                                  id="one-tab"
                                  className="nav-link active"
                                />
                                <label htmlFor="one-tab" className="d-flex">
                                  <FormattedMessage id="one" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>

                  ) : (
                    ""
                  )}
                </div>

              </div>


              <div className="modal-footer border-0">
                <LoaderButton
                  type="submit"
                  isLoading={actionLoading}
                  text={props.Id > 0 ? "update" :"button_save"}
                />
                <Button
                  variant="secondary"
                  onClick={modalClosed}
                  disabled={actionLoading}
                  className=""
                >
                  Close
                </Button>
              </div>
            </Form>
          )}
        ></Formik>
      </div>
    </>
  );
};

export default observer(AddMQTTSConfigForm);
