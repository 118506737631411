/*    
<summary>
  This functional component "AddUserForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import "./user-registration-form.css";
import { Formik, Form, Field, useFormikContext, FormikState } from "formik";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import {
  addTrialTenantValidateSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/Label/Label";
import { formatMessage } from "../../../translations/format-message";
import { useStore } from "../../../contexts/StoreProvider";
import { Tooltip } from "react-tooltip";
import { useHistory } from "react-router-dom";
import IAddTenant from "../../../models/Form/IAddUpdateTenant";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import FormikFormCheckBox from "../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import { DEFAULT_TRIAL_ACCOUNT_MAX_DEVICES_LIMIT, TRIAL_ACCOUNT_MAX_USERS_LIMIT } from "../../../constants/common-constants";
import { getThemeFromLocalStorage } from "../../../helpers/local-stotrage-helper";

interface IProps {
  initialValues: IAddTenant;
  submitHandler: (data: any) => void;
  actionSuccess:boolean;
}

const UserRegistrationForm = (props: IProps) => {
  const history = useHistory();
  const { preferencesStore } = useStore();
  const { setUpdateIsSignupActive } = preferencesStore;
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<{
    language: string;
    isLanguageChanged: boolean;
  }>({ language: preferencesStore.language, isLanguageChanged: false });

  let resetPresForm :(nextState?: Partial<FormikState<unknown>> | undefined) => void;
  
  /**
 * This functional component stores the reset form function in the resetPresentForm variable
 */
  const ResettingForm = () => {
    const { resetForm } = useFormikContext();
    resetPresForm = resetForm; // Store the value of resetForm in this variable
    return null;
  };

  const FormObserver: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<IAddTenant>();

    useEffect(() => {
      if(JSON.stringify(props.initialValues) != JSON.stringify(values) && values.IsBillingAddressSame) {
        setFieldValue("ShippingAddress", values.BillingAddress);
      }
    }, [values.BillingAddress, values.IsBillingAddressSame]);
    
    useEffect(() => {
      if (JSON.stringify(props.initialValues) != JSON.stringify(values) && values.IsInfoSame) {
        setFieldValue("ContactPersonName", values.TenantName);
        setFieldValue("ContactEmail", values.Email);
      }
    }, [values.TenantName, values.Email, values.IsInfoSame]);

    return null;
  };

  useEffect(() => {
    if (props.actionSuccess) {
      resetPresForm();
    }
  }, [props.actionSuccess])

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "selectedLanguage.isLanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (selectedLanguage.isLanguageChanged) {
      preferencesStore.changeLanguage(selectedLanguage.language);
      setSelectedLanguage({ ...selectedLanguage, isLanguageChanged: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage.isLanguageChanged]);

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        validationSchema={addTrialTenantValidateSchema}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={async (values) => {
          let tempValues = values;
          tempValues.MaxUserAllow = Number(TRIAL_ACCOUNT_MAX_USERS_LIMIT);
          tempValues.MaxDeviceAllow = Number(DEFAULT_TRIAL_ACCOUNT_MAX_DEVICES_LIMIT);
          tempValues.TenantName = values.TenantName?.trim();
          tempValues.OrganizationName = values.OrganizationName?.trim();
          tempValues.ContactPersonName = values.ContactPersonName?.trim();
          tempValues.VatNo = values.VatNo?.trim();
          tempValues.ShippingAddress = values.ShippingAddress != "" ? values.ShippingAddress.trim() : values.ShippingAddress?.trim();
          tempValues.BillingAddress = tempValues.IsBillingAddressSame === true ? values.ShippingAddress : values.BillingAddress;
          props.submitHandler(values);
        }}
      >
        {({ values }) => {
            return (
        <Form className="signUp">
          <FormObserver />
          <div className="display d-flex justify-content-between align-items-center">
            <h1 className="login_heading">
              <FormattedMessage id="create_account_title" />
            </h1>
            <select
              className="language_select"
              value={selectedLanguage.language}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                if (selectedLanguage.language != e.target.value)
                  setSelectedLanguage({
                    language: e.target.value,
                    isLanguageChanged: true,
                  });
              }}
            >
              <option key="en" value="en">
                EN
              </option>
              <option key="jp" value="jp">
                JP
              </option>
            </select>
          </div>
          <div className="loginBox">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <Label
                    required={true}
                    label="name"
                    htmlFor="name"
                  />
                  <Field
                    data-testid="name"
                    className="form-control"
                    name="TenantName"
                    type="text"
                    placeholder="name"
                    validcharacter={["@", "#", "$", "-", "_", "."]}
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group">
                  <Label required={true} label="email" htmlFor="Email" />
                  <Field
                    data-testid="Email"
                    className="form-control"
                    name="Email"
                    type="text"
                    invalidcharacter={[" "]}
                    autoComplete="new-email"
                    placeholder="email"
                    validcharacter={[
                      "-",
                      "_",
                      "[",
                      "]",
                      "(",
                      ")",
                      ":",
                      "#",
                      "@",
                      ".",
                    ]}
                    as={FormikFormInput}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="showHideOuterDiv form-group">
                <Label
                  required={true}
                  label="user_label_password"
                  htmlFor="password"
                />
                <Field
                  data-testid="Password"
                  className="form-control"
                  name="Password"
                  autoComplete="new-password"
                  type={showHidePassword ? "text" : "password"}
                  invalidcharacter={[" "]}
                  validcharacter={[
                    "~",
                    "`",
                    "!",
                    "@",
                    "#",
                    "$",
                    "%",
                    "^",
                    "&",
                    "*",
                    "(",
                    ")",
                    "_",
                    "-",
                    "+",
                    "=",
                    "{",
                    "[",
                    "}",
                    "]",
                    "|",
                    "\\",
                    ":",
                    ";",
                    "'",
                    '"',
                    "<",
                    ",",
                    ">",
                    ".",
                    "?",
                    "/",
                  ]}
                  placeholder="user_label_password"
                  as={FormikFormInput}
                />

                {showHidePassword ? (
                  <svg
                    type="button"
                    onClick={() => {
                      setShowHidePassword(!showHidePassword);
                    }}
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="showHidePassSignup css-i6dzq1"
                  >
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg>
                ) : (
                  <svg
                    type="button"
                    onClick={() => {
                      setShowHidePassword(!showHidePassword);
                    }}
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="showHidePassSignup css-i6dzq1"
                  >
                    <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                    <line x1="1" y1="1" x2="23" y2="23"></line>
                  </svg>
                )}
              </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style"
                    required={true}
                    label="organization_name"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-info-circle ms-2"
                    viewBox="0 0 16 16"
                    data-tooltip-id="my-tooltip-org-name"
                    data-tooltip-html={formatMessage("name_validation")}
                    data-tooltip-variant="dark"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                  <Tooltip id="my-tooltip-org-name" place="bottom" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                  <Field
                    data-testid="OrganizationName"
                    className="form-control"
                    name="OrganizationName"
                    placeholder="organization_name"
                    type="text"
                    validcharacter={["@", "#", "$", "-", "_", "."]}
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style"
                    required={true}
                    label="account_id"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-info-circle ms-2"
                    viewBox="0 0 16 16"
                    data-tooltip-id="my-tooltip-org-id"
                    data-tooltip-html={formatMessage("org_id_valid_chars")}
                    data-tooltip-variant="dark"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                  <Tooltip id="my-tooltip-org-id" place="bottom" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                  <Field
                    data-testid="OrganizationId"
                    className="form-control"
                    name="OrganizationId"
                    placeholder="account_id"
                    invalidcharacter={[" "]}
                    as={FormikFormInput}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      // required={true}
                      label="vat_no"
                    />
                    <Field
                      data-testid="VatNo"
                      className="form-control"
                      name="VatNo"
                      placeholder="vat_no"
                      invalidcharacter={[" "]}
                      as={FormikFormInput}
                    />
                  </div>
                </div>
                <div className="col-12 my-1">
                  <div className="checkbox">
                    <label>
                      <Field
                        data-testid="IsInfoSame"
                        className="form-control"
                        name="IsInfoSame"
                        role="switch"
                        type="checkbox"
                        id="IsInfoSame"
                        as={FormikFormCheckBox}
                      />
                      <span className="cr">
                        <i className="cr-icon fas fa-check"></i>
                      </span>
                    </label>
                    <label htmlFor="IsInfoSame" className="h">
                      <FormattedMessage id="is_info_same" />
                    </label>
                  </div>
                </div>
              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style"
                    required={true}
                    label="contact_person_name"
                  />

                  <Field
                    data-testid="ContactPersonName"
                    className="form-control"
                    name="ContactPersonName"
                    placeholder="contact_person_name"
                    type="text"
                    disabled={values.IsInfoSame}
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style"
                    required={true}
                    label="contact_email"
                  />
                  <Field
                    id="ContactEmail"
                    className="form-control"
                    name="ContactEmail"
                    placeholder="contact_email"
                    type="text"
                    invalidcharacter={[" "]}
                    validcharacter={[
                      "-",
                      "_",
                      "[",
                      "]",
                      "(",
                      ")",
                      ":",
                      "#",
                      "@",
                      ".",
                    ]}
                    disabled={values.IsInfoSame}
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style"
                    required={false}
                    label="contact_phone_number"
                  />
                  <Field
                    id="ContactPhoneNumber"
                    className="form-control"
                    name="ContactPhoneNumber"
                    placeholder="contact_phone_number"
                    type="number"
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style"
                    required={true}
                    label="billing_address"
                  />
                  <Field
                    rows={2}
                    id="BillingAddress"
                    className="form-control"
                    name="BillingAddress"
                    placeholder="billing_address"
                    type="text"
                    as={FormikFormTextArea}
                  />
                </div>
              </div>
                <div className="col-12 my-1">
                  <div className="checkbox">
                    <label>
                      <Field
                        data-testid="IsBillingAddressSame"
                        className="form-control"
                        name="IsBillingAddressSame"
                        role="switch"
                        type="checkbox"
                        id="IsBillingAddressSame"
                        as={FormikFormCheckBox}
                      />
                      <span className="cr">
                        <i className="cr-icon fas fa-check"></i>
                      </span>
                    </label>
                    <label htmlFor="IsBillingAddressSame" className="h">
                      <FormattedMessage id="is_billing_address_same" />
                    </label>
                  </div>
                </div>
                {
                  !values.IsBillingAddressSame &&
                    <div className={`col-12 billing_wrap`}>
                      <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="shipping_address"
                      />
                      <Field
                        rows={2}
                        id="ShippingAddress"
                        className="form-control"
                        name="ShippingAddress"
                        disabled={values.IsBillingAddressSame}
                        placeholder="shipping_address"
                        type="text"
                        as={FormikFormTextArea}
                      />
                    </div>
                    
                  </div>
                }
            </div>
          </div>
          <LoaderButton
            type="submit"
            id="LoginButton"
            className="btn btn-primary btn-user"
            text="create_account"
          />

          <p
            className="mt-1 back_to_login"
            onClick={() => {
              setUpdateIsSignupActive(false);
              history.push(RoutesConstants.Login);
            }}
          >
            <FormattedMessage id={"back_to_login"} />
          </p>
          <ResettingForm />
        </Form>
        )
      }}
      </Formik>
    </>
  );
};

export default UserRegistrationForm;
