/*    
<summary>
   This file is used to provide initial values or state to the AddDeviceForm.
   Developer:Ashish Singh, Created Date: 11-June-2024
</summary>
*/
import IReisterDeviceIccid from "../../models/Form/IAddUpdateDeviceRegister";

export const initialState: IReisterDeviceIccid = {
    OrderNo: 'please_select',
    ICCID: '',
    Name: '',
    TenantId: -1
}