/*    
<summary>
  This functional component "DefaultGroup" defines the props like initial values & functions for the child form component.
  Developer:Aashish Singhn, Created Date: 11-December2023.
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../contexts/StoreProvider";
import SelectDefaultGroupForm from "./select-default-group-form";
import userTypeEnum from "../../../constants/user-type-enum";
import { getUserTypeFromLocalStorage, getTenantIdFromLocalStorage } from "../../../helpers/local-stotrage-helper";

interface IProps {
  modalClosed: () => void;
}

const DefaultGroup = (props: IProps) => {
  const { groupStore, tenantStore } = useStore();
  const { allAvailableGroups, GetAllGroupService, updateDefaultGroup, updateDefaultGroupState, resetUpdateDefaultGroupState, defaultGroup } = groupStore;
  const groupList = allAvailableGroups;
  const {selectedTenantId} = tenantStore;
  const onSubmitForm = (groupId: number) => {
    updateDefaultGroup(groupId);
  };

  const onModalClosed = () => {
    resetUpdateDefaultGroupState();
    props.modalClosed();
  };

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if(getUserTypeFromLocalStorage() === userTypeEnum.SystemUser)
      GetAllGroupService(selectedTenantId);
    else
      GetAllGroupService(getTenantIdFromLocalStorage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "updateDefaultGroupState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (updateDefaultGroupState.success) {
      // toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [updateDefaultGroupState.success]);

  return (
    <React.Fragment>
          <SelectDefaultGroupForm
            initialValues={{GroupId:-1}}
            groupList={groupList.map(item => {
              return{
                ...item, disabled: (item.id === defaultGroup) ? true : item.disabled
              }
            })}
            submitHandler={onSubmitForm}
            isLoading={updateDefaultGroupState.inProgress}
            modalClosed={onModalClosed}
          />
    </React.Fragment>
  );
};

export default observer(DefaultGroup);
