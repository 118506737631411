/*    
<summary>
   This file is used to provide initial values or state to the pricing plan Data observables.
   Developer:Ashish Singh, Created Date: 17-May-2024
</summary>
*/

import { IAddUpdatePricingPlan } from "../../models/Form/IAddUpdatePricingPlan";


export const initialState:IAddUpdatePricingPlan = {
    PlanName: "",
    OrderDescriptionType: {
      Id: -1,
      Type: 0,
      TypeString: "",
      Description: ""
    },
    MonthlyDataOperations: "",
    MaxNoOfDevicesSupport: "",
    YearlyPlanExpiryPeriod: 3,
    MonthlyInventoryPeriod: 3,
    CountryCurrencyId: "",
    PlanPrice: "",
    ExcessDataSurcharge: "",
    Tax:"",
}