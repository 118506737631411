import React from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import { observer } from "mobx-react";
import { DATE_FORMAT, emptyValue } from "../../../constants/common-constants";
import moment from "moment";
import IAddDeviceRegister from "../../../models/Form/IAddUpdateDeviceRegister";
import OrderStatusEnum from "../../../constants/order-status-enum";

interface IProps {
  orderNo: string;
}

const AddUpdateDeviceRegisterDetail = (props: IProps) => {
  const { orderStore } = useStore();
  const { allDeliveredOrders } = orderStore;
  const {orderNo,} = props;
  const tempOrderDetails = allDeliveredOrders.filter((order) => order.OrderNo == orderNo);
  return (
    <>
      {
        tempOrderDetails.length > 0 && 
        <>
          <span><strong className="me-1"><FormattedMessage id="order_description_type_string" />:</strong>
            { tempOrderDetails[0].OrderDescriptionType ? tempOrderDetails[0].OrderDescriptionType?.TypeString : emptyValue}
          </span>
          <span className="ms-2 border-start ps-2"><strong className="me-1"><FormattedMessage id="plan_name" />:</strong>
            {(tempOrderDetails[0].Plan && tempOrderDetails[0].Plan?.PlanName) ? tempOrderDetails[0].Plan?.PlanName : emptyValue}
          </span>
          <span className="ms-2 border-start ps-2"><strong className="me-1"><FormattedMessage id="no_of_devices_order" />:</strong>
            {tempOrderDetails[0].NoOfDevicesOrder ? tempOrderDetails[0].NoOfDevicesOrder : emptyValue}
          </span>
          <span className="ms-2 border-start ps-2"><strong className="me-1"><FormattedMessage id="order_status" />:</strong>
            { tempOrderDetails[0].OrderStatus !== null ? OrderStatusEnum[tempOrderDetails[0].OrderStatus] : emptyValue}
          </span>
        </>
      }
    </>
  )
};

export default observer(AddUpdateDeviceRegisterDetail);