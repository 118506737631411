/*    
<summary>
   This file is used to provide initial values or state to the AddTenantForm.
   Developer:Mohammad Saquib Khan, Created Date: 30-August-2023
</summary>
*/

import IAddTenant from "../../models/Form/IAddUpdateTenant";

export const initialState: IAddTenant = {
    TenantName: "",
    Password: "",
    Email: "",
    OrganizationId: "",
    OrganizationName: "",
    ContactPersonName: "",
    ContactEmail: "",
    ContactPhoneNumber: "",
    ShippingAddress: "",
    BillingAddress: "",
    MaxUserAllow: 5,
    MaxDeviceAllow: 10000,
    IsActive: true,
    VatNo: "",
    IsBillingAddressSame: true,
    IsInfoSame: true,
    IsDataPakcetsStoreInDB: false,
    SendThresholdAlert: false,
    BlockTenantOnThresholdLimitReached: false,
    UserId:-1,

}