/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   Developer: Aashish Singh, Created Date: 14-June-2024 
</summary>
*/
enum PaymentStatusEnum{
    Pending = 0,
    Partial = 1,
    Paid = 2
}

export default PaymentStatusEnum;