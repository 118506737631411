import React from "react";
import { Form, Formik, Field } from "formik";
import "./add-edit-order-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/Label/Label";
import { getOrderPaymentValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { observer } from "mobx-react";
import { IOrderList, IOrderPayment } from "../../../models/response/IOrderResponse";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { DATE_FORMAT, floatRegex } from "../../../constants/common-constants";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/FormikFormInputForRegexCheck";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikDateFieldMUI";
import moment from "moment";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  id: number;
  selectedData: IOrderList;
  initialValues: IOrderPayment;
  submitHandler: (data: IOrderPayment) => void;
  onModalClosed: () => void;
  isLoading: boolean;
}

const AddOrderPaymentForm = (props: IProps) => {

  return (
    <>
      <Modal className="correction-modal fancy-modal" show={true} centered onHide={() => { }}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="order_payment" />
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
              title={formatMessage("close")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={getOrderPaymentValidateSchema(props.selectedData.PendingAmount)}
          validateOnBlur={false}
          onSubmit={async (values) => {
            let tempValues = { ...values };
            props.submitHandler({ ...tempValues});
          }}
        >
          {({ values }) => {
            return (
              <Form className="formBody">
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 form-group">
                        <Label
                          className="label-style col-form-label"
                          required={false}
                          label="total_order_amount"
                        />
                        <input 
                          type="number"
                          disabled={true}
                          className="form-control"
                          value={Number(props.selectedData.TotalOrderAmount).toFixed(2)}
                          />
                      </div>
                      <div className="col-md-6 form-group">
                        <Label
                          className="label-style col-form-label"
                          required={false}
                          label="pending_amount"
                        />
                        <input 
                          type="number"
                          disabled={true}
                          className="form-control"
                          value={Number(props.selectedData.PendingAmount).toFixed(2)}
                          />
                      </div>
                    </div>
                  <div className="row">                    
                    <div className="col-12">
                      <div className="form-group">
                      <Label
                          className="label-style"
                          required={true}
                          label="payment_date"
                        />
                        <Field name="PaymentReceviedDate" 
                          as={FormikDateFieldMUI} 
                          min={moment().subtract(6, 'months').format(DATE_FORMAT)}
                        />
                      </div>
                      <div className="form-group">
                        <Label
                          className="label-style col-form-label"
                          required={true}
                          label="payment"
                        />
                        <Field
                          data-testid="dataAmount"
                          className="form-control"
                          name="Amount"
                          type="text"
                          placeholder="payment"
                          regex={floatRegex}
                          as={FormikFormInputForRegexCheck}
                        />
                      </div>
                      <div className="d-flex justify-content-end">
                        <span className="text-muted small ms-auto"><FormattedMessage id="balance_pending" /> : &nbsp;
                          {
                            values.Amount !== "" ? 
                            <>
                              {
                                  (props.selectedData.PendingAmount - parseInt(values.Amount) > 0) ?
                                  Number(Number(props.selectedData.PendingAmount) - Number(values.Amount)).toFixed(2) :
                                  0
                              }
                            </> :
                            Number(props.selectedData.PendingAmount).toFixed(2)
                          }
                        </span>
                      </div>
                      
                      <div className="form-set form-group">
                        <Label
                          className="label-style"
                          required={false}
                          label="remarks"
                        />
                        <Field
                          rows={2}
                          id="Remarks"
                          className="form-control"
                          name="Remarks"
                          placeholder="remarks"
                          type="text"
                          as={FormikFormTextArea}
                        />
                      </div>
                    </div>
                  </div>

                </Modal.Body>
                <div className="modal-footer pt-0 border-0">
                  <div className="form-group row g-0 tab-action">
                    <div className="col-12">
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.isLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default observer(AddOrderPaymentForm);