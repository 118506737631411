/*    
<summary>
   This is the PaymentHistorys Component which shows the PaymentHistorys Data.
   Developer: Aashish Singh, Created Date:14-June-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import IPageInfo, {
  IPageLimitInfo,
} from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import Grid from "../../shared-components/Grid/Grid";
import { observer } from "mobx-react";
import "react-sliding-side-panel/lib/index.css";
import {
  IPaymentHistoryList,
} from "../../models/response/IPaymentHistoryResponse";
import userSettingEnum from "../../constants/user-setting-enum";
import toast from "react-hot-toast";
import Pagination from "../../shared-components/Grid/Pagination";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import userTypeEnum from "../../constants/user-type-enum";
import { emptyValue, orderStatusClasses } from "../../constants/common-constants";
import {
  getTenantIdFromLocalStorage,
} from "../../helpers/local-stotrage-helper";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import { useHistory } from "react-router-dom";
import { IOrderList } from "../../models/response/IOrderResponse";

const PaymentHistory = (props: any) => {
  const { paymentHistoryStore, preferencesStore } = useStore();
  const {
    inProgress,
    GetPaymentHistoryListService,
    allPaymentHistory,
    paymentHistoryList,
    reset,
    error,
  } = paymentHistoryStore;
  const { userSetting, language, setUserSetting } = preferencesStore;
  let lang: string;
  const [openPanel, setOpenPanel] = useState(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allPaymentHistorysList: Array<IPaymentHistoryList> = allPaymentHistory;
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.orderLimitConfig,
    isPageLimitChange: false,
  });
  let selctedOrderFromLocalStorage: string | null  = localStorage.getItem("mzl_selected_order_detail");
  let selectedOrder: IOrderList | null = null
  if(selctedOrderFromLocalStorage)
    selectedOrder = JSON.parse(selctedOrderFromLocalStorage);
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });
  const history = useHistory();
  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(
      userSettingEnum.PaymentHistoryLimitConfig,
      parseInt(evt.target.value)
    );
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetPaymentHistoryListService = () => {
    if(selectedOrder?.Id)
      GetPaymentHistoryListService(
        pageInfo.currentPage,
        pageLimitInfo.pageLimit,
        selectedOrder.Id,
      );
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetPaymentHistoryListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: paymentHistoryList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPaymentHistory]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetPaymentHistoryListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "error" of the useEffect changed.
   */
  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetPaymentHistoryListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * The column constant defines the column description for the order grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "Amount",
      text: formatMessage("payment_recieved"),
    },
    {
      dataField: "PaymentReceviedDate",
      text: formatMessage("payment_received_date"),
    },
    {
      dataField: "PaymentReceviedBy",
      text: formatMessage("payment_received_by"),
    },
    {
      dataField: "PaymentConfirmationDate",
      text: formatMessage("payment_confirmation_date"),
    },
    {
      dataField: "Remarks",
      text: formatMessage("remarks"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row g-0  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                  <li title={formatMessage("back")}
                        className="me-2 ps-5">
                          <button role="" className="btn btn-secondary" onClick={(
                              event: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                              >
                            ) => {
                                localStorage.removeItem("mzl_selected_order_detail");
                                history.push(RoutesConstants.Order)}}>
                              <i className="fa fa-arrow-left"></i>
                          </button>
                      </li>
                    <li>
                      <p className="h5 mb-0">
                        <FormattedMessage id="payment_history_title" />
                      </p>
                    </li>
                    
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
      <div className="table_items_list">
        {inProgress && <FormLoader loading={inProgress} />}
        {allPaymentHistorysList.length > 0 ? (
          <div className="row  mt-3 "> 
            <div className="col-xl-9">
              <div className="card changingPanel">
                <div className="tableDesign row-eq-height tableDevices ">
                  <div className="table-responsive" data-testid="table">
                    {allPaymentHistorysList && (
                      <Grid
                        data={allPaymentHistorysList}
                        columns={columns}
                        keyField="Id"
                        loading={inProgress}
                      />
                    )}
                  </div>
                </div>
                {allPaymentHistorysList.length > 0 ? (
                  <div className="row g-0 p-2 tableFooter">
                    <div className="col-sm-6 pt-1">
                      <FormattedMessage id="show" />
                      <select
                        data-testid="pageSelect"
                        className="pageLimit"
                        onChange={onPageLimitChanged}
                        value={pageLimitInfo.pageLimit}
                      >
                        {pageLimitOptions.map((op: any) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-sm-end">
                      <Pagination
                        isRefreshPage={false}
                        totalPages={pageInfo.totalPages}
                        pageLimit={pageLimitInfo.pageLimit}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                        setIsRefreshPage={setIsRefreshPage}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-xl-3">
              <div className="card card-body h-100">
                <p className="text-muted"><FormattedMessage id="order_no" /></p>
                <h3>{selectedOrder?.OrderNo}</h3> 

                <p className="text-muted mt-3">
                  <FormattedMessage id="plan_name" />
                </p>
                <h3>{selectedOrder?.PlanName}</h3>

                <p className="text-muted mt-3">
                <FormattedMessage id="order_date" />
                </p>
                <h3>{selectedOrder?.CreatedDate}</h3>
                
                {/* <p className="text-muted mt-3">
                <FormattedMessage id="remarks" />
                </p>
                <h3>{selectedOrder?.Remarks}</h3> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="message text-center">
            {/* <img src={no_payment_history} alt="" className="no_message" /> */}
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_payment_history" />
            </p>
            <p className="mb-3">{formatMessage("no_payment_history_message")}</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default observer(PaymentHistory);
