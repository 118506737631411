/*    
<summary>
   This is the Pricing Menu Component which shows the Pricing Data.
   Developer: Mohammad Saquib Khan, Created Date:24-Jan-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { SyntheticEvent, useEffect, useState } from "react";
import "./pricing-menu.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import { observer } from "mobx-react";
import "react-sliding-side-panel/lib/index.css";
import _, { cloneDeep } from "lodash";
import { formatMessage } from "../../translations/format-message";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import no_group from "../../assets/Images/svg/search-tenant.svg";
import active_device from "../../assets/Images/svg/active-device.svg";
import new_device from "../../assets/Images/svg/smartphone.svg";
import delete_device from "../../assets/Images/svg/delete-device.svg";
import message_count from "../../assets/Images/svg/comment.svg";
import dlMessageIcon from "../../assets/Images/svg/dl_message.svg";
import ul_message_count from "../../assets/Images/svg/ul_message.svg";
import lastmonth from "../../assets/Images/svg/lastmonth.svg";
import tenancy_days from "../../assets/Images/svg/calendar.svg";
import toast from "react-hot-toast";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  ScriptableContext,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Field, Form, Formik } from "formik";
import Label from "../../shared-components/Label/Label";
import FormikFormInput from "../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import { billingStatsIccidValidateSchema } from "../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { initialState as dailyPacketCountICCIDGraphState } from "../../core/initial-state/daily-packet-count-graph-state";
import CustomTooltip from "../../shared-components/Tooltip/tooltip";

const PricingMenu = (props: any) => {
  const {
    tenantStore,
    pricingMenuStore,
  } = useStore();
  const date = new Date();
  const { GetAllTenantsService, allTenantOptions, resetAllTenantList } = tenantStore;
  const { GetPricingMenuStatsService, pricingMenuStats,pricingMenuStatsState, allMonthOptions, allYearOptions, GetDailyMessageCountByICCID, resetAllIccidPacketCountData,GetAllIccidsMessageCount, dailyPacketCountAllIccidGraphData ,dailyPacketCountByICCIDGraphData, resetPacketCountData} = pricingMenuStore;
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [selectedTenant, setSelectedTenantId] = useState<number>(-1);
  const [selectedMonth, setSelectedMonthId] = useState<number>(-1);
  const [selectedYear, setSelectedYear] = useState<number>(-1);
  const [selectedIccid, setSelectedIccid] = useState<string>("");
  const [lineChartData, setLineChartData] = useState(dailyPacketCountICCIDGraphState);
  const [lineChartDataAll, setLineChartDataAll] = useState(dailyPacketCountICCIDGraphState);
  const [searchedData, setSearchedData] = useState<any>({    TenantId: -1,
    Year: -1,
    Month:-1});
  const [selectedMsgCountType, setSelectedMsgCountType] = useState<string>("0");

  const lineChartDays: any[] = dailyPacketCountByICCIDGraphData?.Data;
  const lineChartDaysAllIccid: any[] = dailyPacketCountAllIccidGraphData?.Data;
  const lineChartLabels: any[] = dailyPacketCountByICCIDGraphData?.Labels
  const lineChartLabelsAllIccid: any[] = dailyPacketCountAllIccidGraphData?.Labels

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    GetAllTenantsService();
    return(()=>{
      resetAllTenantList();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(isSearchActive == true &&  selectedMsgCountType == "0"){
      GetAllIccidsMessageCount(searchedData.TenantId,searchedData.Year,searchedData.Month);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMsgCountType]);
  

  useEffect(() => {
    if(isSearchActive == true &&  selectedMsgCountType == "1"){
      const lineChart = cloneDeep(lineChartData);
      lineChart.data.datasets[0].data = lineChartDays;
      lineChart.data.labels = lineChartLabels;
      setLineChartData(lineChart);
      resetAllIccidPacketCountData();
    }
  }, [dailyPacketCountByICCIDGraphData,selectedMsgCountType]);

  useEffect(() => {
    if(isSearchActive == true &&  selectedMsgCountType == "0"){
      const lineChart = cloneDeep(lineChartDataAll);
      lineChart.data.datasets[0].data = lineChartDaysAllIccid;
      lineChart.data.labels = lineChartLabelsAllIccid;
      setLineChartDataAll(lineChart);
      resetPacketCountData();
    }
  }, [dailyPacketCountAllIccidGraphData,selectedMsgCountType]);


  const onTenantChange = (event: any) => {
    const selectedOption = event.target.value;
    setSelectedTenantId(selectedOption);
  };

  const onMonthChange = (event: any) => {
    const selectedOption = event.target.value;
    setSelectedMonthId(selectedOption);
  };

  const onYearChange = (event: any) => {
    const selectedOption = event.target.value;
    setSelectedYear(selectedOption);
  };

  const callPricingMenuService =()=>{
    if(selectedTenant != -1 && selectedMonth != -1 && selectedYear != -1 ){
      GetPricingMenuStatsService(
        selectedTenant,
        selectedMonth,
        selectedYear
      );
    }
  }

  const callGetAllIccidMessageCount = () =>{
      GetAllIccidsMessageCount(selectedTenant,selectedYear,selectedMonth);
  }
  
  const handleRadioChange = (value:string) => {
    setSelectedMsgCountType(value);
  };

  const getPricingMenu = () => {
    if(selectedMonth > date.getMonth()+1 && selectedYear >= date.getFullYear()){
      toast.error(formatMessage("bill_month_year_validation"));
    }else{
      if(selectedIccid != ""){
        GetDailyMessageCountByICCID(selectedTenant,selectedIccid,selectedYear,selectedMonth);
      }
      callPricingMenuService();
      if(selectedMsgCountType == "0"){
        callGetAllIccidMessageCount();
      }
      setSearchedData({    
        TenantId: selectedTenant,
        Year: selectedYear,
        Month:selectedMonth})
      setIsSearchActive(true);
    }
  }

  const reset = () => {
    setSelectedMonthId(-1);
    setSelectedYear(-1);
    setSelectedTenantId(-1);
    setSelectedIccid("");
    resetPacketCountData();
    resetAllIccidPacketCountData();
    setIsSearchActive(false);
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement, Tooltip, Legend, Filler
  );

  return (
    <React.Fragment>
      {pricingMenuStatsState.inProgress && (
        <FormLoader loading={pricingMenuStatsState.inProgress} />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar BillingTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row g-0  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 ps-5 mb-0">
                        <FormattedMessage id="pricing_menu_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
        <div className="mx-auto">
          <div className="pricingWrapper card">
            <div className="card-header">
              <div className="row align-items-end">
                <div className="col-12 col-xl-4 col-lg-6 form-group">
                  <label className="">
                    <FormattedMessage id={"select_tenant"} />
                  </label>
                  <select
                    data-testid="tenantSelect"
                    className="form-control"
                    onChange={onTenantChange}
                    value={selectedTenant}
                  >
                    {allTenantOptions.map((op: any) => (
                      <option key={op.value} value={op.id}>
                        {formatMessage(op?.value)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-xl-3 col-lg-6 form-group">
                  <label className="">
                    <FormattedMessage id={"year"} />
                  </label>
                  <select
                    data-testid="tenantSelect"
                    className="form-control"
                    onChange={onYearChange}
                    value={selectedYear}
                  >
                    {allYearOptions.map((op: any) => (
                      <option key={op.value} value={op.id}>
                        {formatMessage(op?.value)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-xl-3 col-lg-6 form-group">
                  <label className="">
                    <FormattedMessage id={"month"} />
                  </label>
                  <select
                    data-testid="tenantSelect"
                    className="form-control"
                    disabled={selectedYear == -1 ? true : false}
                    onChange={onMonthChange}
                    value={selectedMonth}
                  >
                    {allMonthOptions.map((op: any) => (
                      <option key={op.value} value={op.id}>
                        {formatMessage(op?.value)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-xl-2 col-lg-6 form-group col-12">
                  <button className="btn btn-primary"
                  disabled={selectedTenant > 0 && selectedMonth>0 && selectedYear > 0 ? false : true}
                  // disabled={true}
                  onClick={()=>{getPricingMenu()}}>
                    <FormattedMessage id="search" />
                  </button>
                  <button className="btn btn-secondary ms-3"
                  onClick={()=>{reset()}}
                  >
                    <FormattedMessage id="reset" />
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body scrolable-card">
              {selectedTenant > 0 && selectedMonth > 0 && selectedYear > 0 && isSearchActive === true? (
                <div className="row align-items-center">
                  <div className="col-lg-4 col-xxl-5 col-xl-4 col-md-5 col-12 row align-items-start">
                    <div className="col-xxl-12 col-12 d-flex mb-3">
                      <img src={new_device} alt="" className="count_card" />
                      <div>
                        <p className="text-muted"><FormattedMessage id="new_device_registration" />
                          <CustomTooltip id="total_message_count" message="new_device_registration_info" />
                        </p>
                        <h2 className="mb-0">{pricingMenuStats?.RegisteredDeviceCount}</h2>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-12 d-flex mb-3">
                      <img src={active_device} alt="" className="count_card" />
                      <div>
                        <p className="text-muted"><FormattedMessage id="newly_active_device_count" />
                          <CustomTooltip id="total_message_count" message="newly_active_device_count_info" />
                        </p>
                        <h2 className="mb-0">{pricingMenuStats?.ActiveDeviceCount}</h2>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-12 d-flex mb-3">
                      <img src={delete_device} alt="" className="count_card" />
                      <div>
                        <p className="text-muted"><FormattedMessage id="deleted_device_count" />
                          <CustomTooltip id="total_message_count" message="deleted_device_count_info" />
                        </p>
                        <h2 className="mb-0">{pricingMenuStats?.DeletedDeviceCount}</h2>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-12 d-flex mb-3">
                      <img src={active_device} alt="" className="count_card" />
                      <div>
                        <p className="text-muted"><FormattedMessage id="active_device_count" />
                          <CustomTooltip id="total_message_count" message="active_device_count_info" />
                        </p>
                        <h2 className="mb-0">{pricingMenuStats?.TotalActiveDeviceCountAtLastDayOfMonth}</h2>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-12 d-flex mb-3">
                      <img src={lastmonth} alt="" className="count_card" />
                      <div>
                        <p className="text-muted"><FormattedMessage id="total_device_count" />
                          <CustomTooltip id="total_message_count" message="total_device_count_info" />
                        </p>
                        <h2 className="mb-0">{pricingMenuStats?.TotalDeviceCountAtLastDayOfMonth}</h2>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-12 d-flex mb-3">
                      <img src={ul_message_count} alt="" className="count_card" />
                      <div>
                        <p className="text-muted"><FormattedMessage id="ul_message_count" />
                          <CustomTooltip id="total_message_count" message="ul_message_count_info" />
                        </p>
                        <h2 className="mb-0">{pricingMenuStats?.ULMessageCount}</h2>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-12 d-flex mb-3">
                      <img src={dlMessageIcon} alt="" className="count_card" />
                      <div>
                        <p className="text-muted"><FormattedMessage id="dl_message_count" />
                          <CustomTooltip id="total_message_count" message="dl_message_count_info" />
                        </p>
                        <h2 className="mb-0">{pricingMenuStats?.DLMessageCount}</h2>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-12 d-flex mb-3">
                      <img src={message_count} alt="" className="count_card" />
                      <div>
                        <p className="text-muted"><FormattedMessage id="total_message_count" />
                          <CustomTooltip id="total_message_count" message="total_message_count_info" />
                        </p>
                        <h2 className="mb-0">{pricingMenuStats?.TotalMessageCount}</h2>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-12 d-flex mb-3">
                      <img src={tenancy_days} alt="" className="count_card" />
                      <div>
                        <p className="text-muted"><FormattedMessage id="expose_api_request_count" />
                          <CustomTooltip id="total_message_count" message="expose_api_request_count_info" />
                        </p>
                        <h2 className="mb-0">{pricingMenuStats?.ExposeApiRequestCount}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-xxl-7   col-xl-8 col-md-7 col-12">
                    <div className="DeviceLineWrap">
                      <div className="form-group mb-3">
                      <Formik
                          initialValues={{ ICCID: "", MessageCountType: selectedMsgCountType}}
                          validationSchema={billingStatsIccidValidateSchema}
                          enableReinitialize
                          validateOnBlur={false}
                          onSubmit={async (values: any) => {
                            setSelectedIccid(values?.ICCID);
                            GetDailyMessageCountByICCID(searchedData?.TenantId,values.ICCID,searchedData?.Year,searchedData?.Month);
                          }}
                          >
                          {({ values  }) => {
                            return (
                              <Form id="formBody">
                              <div className="row g-0">
                                <div className="col-lg-6 col-12">
                                  <div className="row g-0 pb-2 mt-2">
                                    <div className="col-sm-5">
                                        <label className="m-0">
                                          <FormattedMessage id="message_count" />
                                        </label>
                                    </div>
                                    <div className="col-3 btn-radioCheckbox">
                                      <Field
                                        type="radio"
                                        value="0"
                                        name="MessageCountType"
                                        id="all"
                                        onChange={() => handleRadioChange('0')}
                                        className="nav-link active"
                                      />
                                      <label htmlFor="all">
                                        <FormattedMessage id="all_iccid" />
                                      </label>
                                    </div>

                                    <div className="col-4 btn-radioCheckbox">
                                      <Field
                                        type="radio"
                                        value="1"
                                        name="MessageCountType"
                                        id="single"
                                        onChange={() => handleRadioChange('1')}
                                        className="nav-link active"
                                      />
                                      <label htmlFor="single">
                                        <FormattedMessage id="single_iccid" />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                
                                {/* <Label
                                  className="label-style text-dark col-lg-4 col-xl-1 col-12"
                                  required={true}
                                  label="iccid"
                                /> */}
                                {values?.MessageCountType == "1" &&
                                <>
                                <div className="col-lg-4 col-12">
                                  <Field
                                    data-testid="ICCID"
                                    className="form-control"
                                    name="ICCID"
                                    placeholder="iccid"
                                    type="number"
                                    as={FormikFormInput}
                                  />
                                </div>
                                <div className="col-lg-2 col-xl-2 col-12">
                                  <LoaderButton
                                    isLoading={props.isLoading}
                                    type="submit"
                                    id={"SaveGroup"}
                                    text="submit"
                                  />
                                </div>
                                </>
                                }
                              </div>
                            </Form>
                            );
                          }}
                        </Formik>
                        </div>
                      <div className="DeviceLineChart">
                        {selectedMsgCountType == "1" ?
                        <Line data={lineChartData?.data} options={lineChartData?.options} />
                         :
                        <Line data={lineChartDataAll?.data} options={lineChartDataAll?.options} />
                         }
                        <br/>
                        { selectedIccid != "" && selectedMsgCountType == "1"? <div className="text-center"><p className="text-muted"><FormattedMessage id="iccid" /> :  <span>{selectedIccid}</span></p></div> : ""}
                      </div>
                      </div>
                    </div>
                  </div>
              ): (
                <div className="text-center">
                  <img src={no_group} alt="" className="no_message" />
                  <p className="noData text-muted text-center h5 mb-0">
                    <FormattedMessage id="select_tenant_message"/>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      
    </React.Fragment>
  );
};

export default observer(PricingMenu);
