/*    
<summary>
  This functional component "AddPricingPlan" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:17-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-pricing-plan.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import AddUpdatePricingPlanForm from "../forms/add-edit-pricing-plan-form";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/StoreProvider";
import { IAddUpdatePricingPlan } from "../../../models/Form/IAddUpdatePricingPlan";
import { formatMessage } from "../../../translations/format-message";
import { cloneDeep } from "lodash";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddPricingPlan = (props: IProps) => {
  const { pricingPlanStore, currencyStore, orderStore } = useStore();
  const {
    GetPricingPlanByIdService,
    resetAddUpdatePricingPlanState,
    resetPricingPlanDetail,
    pricingPlanState,
    pricingPlanDetail,
    addUpdatePricingPlanState,
    AddPricingPlanService,
    UpdatePricingPlanService,
    pricingPlanCountryCurrencyDetail
  } = pricingPlanStore;
  const {
    allCountryCurrencyOptions,
    GetAllCountryCurrencyService,
    countryCurrencyState
  } = currencyStore;
  const {GetAllOrderDescriptionTypeService, allOrderDescriptionTypeOptions, allOrderState } = orderStore;

  const initialValues: IAddUpdatePricingPlan = pricingPlanDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered 
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    GetAllCountryCurrencyService();
    GetAllOrderDescriptionTypeService();
    if (props.id > 0) {
      GetPricingPlanByIdService(props.id);
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdatePricingPlanSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdatePricingPlanState.success) {
      if (props.id === -1)
        toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdatePricingPlanState.success]);

  /**
   * This function adds the pricingPlan when "Id < 0" and updates the Existing pricingPlan when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: IAddUpdatePricingPlan) => {
    let tempValues = cloneDeep(values);
    if (props.id < 0) AddPricingPlanService(tempValues);
    else UpdatePricingPlanService(props.id, tempValues);
  };

  /**
   * This function closes the pop up modal and reset getpricingPlandetails, addUpdate pricingPlan observables (success, inprogress 
   * and error).
   */
  const onModalClosed = () => {
    resetPricingPlanDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUpdatePricingPlanForm
        initialValues={initialValues}
        id={props.id}
        countryCurrencyOptions={allCountryCurrencyOptions}
        typeOptions={allOrderDescriptionTypeOptions}
        currencyDetail={pricingPlanCountryCurrencyDetail}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getPricingPlanLoading={pricingPlanState.inProgress || countryCurrencyState.inProgress || allOrderState.inProgress}
        addUpdatePricingPlanLoading={addUpdatePricingPlanState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddPricingPlan);

