/*    
<summary>
   This file is used to provide initial values or state to the User Data observables.
   Developer:Ashish Singh, Created Date: 29-August-2023
</summary>
*/

import { IFotaSysAdmin, IFotaTenantAdmin } from "../../models/Form/IFotaForm";
import { IFotaTenantReleaseLatest } from "../../models/response/IFotaResponse";

export const initialValuesSysAdmin: IFotaSysAdmin = {
  JetVersion: "",
  JetVersionFile: "",
  JetVersionFileContent: "",
  ModemVersion: "",
  ModemVersionFile: "",
  ModemVersionFileContent: "",
  ModemVersionFileType:0,
  IsModemVersionSameAsPrevious: false,
  JetVersionFileType:0,
  IsJetVersionSameAsPrevious: false,
  Description: "",
};

export const initialValuesTenantAdmin: IFotaTenantAdmin = {
  HostVersion: "",
  HostVersionFile: "",
  HostVersionFileType: 0,
  HostVersionFileContent: "",
  IsHostVersionSameAsPrevious: false,
  MzlFotaReleaseId: -1,
  IsAppliedOnGroup: "true",
  GroupId: -1,
  ICCIDs: [],
  ICCIDsFile:"",
  ICCIDsFileContent:"",
  Description: "",
};

export const initialValuesTenantLatestReleaseAdmin: IFotaTenantReleaseLatest = {
  ModemVersion: "",
  JetVersion: "",
  MzlFotaReleaseId: -1,
  HostVersionFileContent: "",
  HostVersion: "",
  HostVersionFile: "",
};