/*    
<summary>
   This class component is all about Managing currency data functionality.
   Developer:Aashish Singh, Created Date:25-Oct-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { ICurrencyVM } from '../../models/response/ICurrencyResponse';
import { ICountryCurrencyOption, IObservableInitialState } from '../../models/ICommon';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import { ICurrencyState } from '../../models/state/ICurrencyState';

export class CurrencyStore implements ICurrencyState, ICommonState {
    inProgress = false;
    error = '';
    
    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    
    allCountryCurrencies: ICurrencyVM[] = [];
    countryCurrencyState:IObservableInitialState ={...this.initialStateValue}

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            allCountryCurrencies: observable,
            countryCurrencyState: observable,
            GetAllCountryCurrencyService: action,
            reset: action,
            resetStore: action,
            allCountryCurrencyOptions: computed,
        });
    }
    
    /*
    This function is used to Get All Country Currencies data from API.  
    */
    GetAllCountryCurrencyService = () => {
        this.countryCurrencyState.inProgress = true;
        const url = URLConstants.CurrencyList;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ICurrencyVM[]>>) => {
                if (response.data.Error) {
                    this.countryCurrencyState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allCountryCurrencies = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.countryCurrencyState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.countryCurrencyState.inProgress = false; }));
    }

    /*
    This function is used to map allCountryCurrencies to allCountryCurrencyOptions List suitable for Grid Component.  
    */
    get allCountryCurrencyOptions(): ICountryCurrencyOption[] {
        const options: any[] = [];
        if (this.allCountryCurrencies && this.allCountryCurrencies.length > 0)
            this.allCountryCurrencies.map((currencyDetails) => {
                let tempPlaceholder = currencyDetails.CurrencyName +" (" + currencyDetails.Symbol +")";
                options.push({
                    data: JSON.stringify({id:currencyDetails.Id, symbol:currencyDetails.Symbol, placeholder:tempPlaceholder}),
                    placeholder: tempPlaceholder,
                })
            })

        return options;
    }

    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
    }
}

export default new CurrencyStore();
