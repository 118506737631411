/*    
<summary>
   This file is used to provide initial values or state to the PricingPlan Module observables.
    Developer:Aashish Singh, Created Date:17-May-2024
</summary>
*/

import { IPricingPlanListVM } from "../../models/response/IPricingPlanResponse";

export const initialState: IPricingPlanListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Plans: []
}