/*    
<summary>
   This rootStore combines all Store into a single one.
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns RootStore Type</returns>
<returns>rootStore</returns>
*/

import oneNceStore, { OneNceStore } from './oneNce-store';
import authStore, { AuthStore } from './auth-store';
import configurationStore, { ConfigurationStore } from './configuration-store';
import deviceStore, { DeviceStore } from './device-store';
import groupStore, { GroupStore } from './group-store';
import preferencesStore, { PreferencesStore } from './preferences-store';
import tenantStore, { TenantStore } from './tenant-store';
import userStore, { UserStore } from './user-store';
import currencyStore, { CurrencyStore } from './currency-store';
import auditLogStore, { AuditLogStore } from './audit-log-store';
import pricingMenuStore, { PricingMenuStore } from './pricing-menu-store';
import pricingPlanStore, { PricingPlanStore } from './pricing-plan-store';
import orderStore, { OrderStore } from './order-store';
import roleStore, { RoleStore } from './role-store';
import deviceRegisterStore, { DeviceRegisterStore } from './device-register-store';
import paymentHistoryStore,{ PaymentHistoryStore } from './payment-history-store';
import regionStore, { RegionStore } from './region-store';
import fotaStore ,{FotaStore} from './fota-store';

export type RootStore = {
    authStore: AuthStore;
    preferencesStore: PreferencesStore;
    deviceStore: DeviceStore;
    groupStore: GroupStore;
    tenantStore: TenantStore;
    configurationStore: ConfigurationStore;
    userStore: UserStore;
    oneNceStore: OneNceStore;
    currencyStore: CurrencyStore;
    auditLogStore: AuditLogStore;
    pricingMenuStore: PricingMenuStore;
    pricingPlanStore: PricingPlanStore;
    orderStore: OrderStore;
    roleStore: RoleStore;
    deviceRegisterStore: DeviceRegisterStore;
    paymentHistoryStore: PaymentHistoryStore;
    regionStore: RegionStore;
    fotaStore:FotaStore;
}

const rootStore: RootStore = {
    authStore,
    preferencesStore,
    deviceStore,
    groupStore,
    tenantStore,
    configurationStore,
    userStore,
    oneNceStore,
    currencyStore,
    auditLogStore,
    pricingMenuStore,
    pricingPlanStore,
    orderStore,
    roleStore,
    deviceRegisterStore,
    paymentHistoryStore,
    regionStore,
    fotaStore
};

export default rootStore;