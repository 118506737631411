/*    
<summary>
   This is the PricingPlans Component which shows the PricingPlans Data.
   Developer: Aashish Singh, Created Date:17-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import "./pricing-plan.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import IPageInfo, { IActionFormatter, IPageLimitInfo } from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import Grid from "../../shared-components/Grid/Grid";
import { observer } from "mobx-react";
import "react-sliding-side-panel/lib/index.css";
import {
  ActionFormatter,
  ActiveInactiveButtonFormatter,
} from "../../shared-components/Grid/GridFormatter";
import {
  faKey,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import AddPopupHOC from "../../shared-components/popup/add/add-popup-hoc";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import ChangePasswordPopupHOC from "../../shared-components/popup/change-password/change-password-popup-hoc";
import { IPricingPlanList } from "../../models/response/IPricingPlanResponse";
import userSettingEnum from "../../constants/user-setting-enum";
import toast from "react-hot-toast";
import Pagination from "../../shared-components/Grid/Pagination";
import AddPricingPlan from "./components/add-edit-pricing-plan";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
// import no_pricingPlan from "../../assets/Images/svg/no-pricingPlan.svg";
import userTypeEnum from "../../constants/user-type-enum";
import { emptyValue } from "../../constants/common-constants";
import { isOperationPermitted, showIcons } from "../../helpers/action-permission-helper";
import systemModuleEnum from "../../constants/system-module-enum";

const PricingPlan = (props: any) => {
  const { pricingPlanStore, preferencesStore, authStore } = useStore();
  const {
    inProgress,
    pricingPlanList,
    GetPricingPlanListService,
    resetAddUpdatePricingPlanState,
    allPricingPlanList,
    reset,
    DeletePricingPlanService,
    deletePricingPlanState,
    addUpdatePricingPlanState,
    error,
  } = pricingPlanStore;
  const { userSetting, language, setUserSetting } = preferencesStore;
  let lang: string;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allPricingPlansList: Array<IPricingPlanList> = allPricingPlanList;
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pricingPlanLimitConfig,
    isPageLimitChange: false,
  });
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.PricingPlanLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetPricingPlanListService = () => {
    GetPricingPlanListService(pageInfo.currentPage, pageLimitInfo.pageLimit);
  };

  /**
   * This function shows the add group pop up from where we can add new group and update existing group prootcol. we use id to identify
   * that we are adding or updating group.
   * "Id == -1" : we are adding new group
   * "Id != -1" : means we are updating existing group
   */
  const openAddPricingPlanHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.addToggleHandler();
  };

  /**
   * This function will update the shows the  confirm popup
   */
  const activeInactivePopupHandler = (
    row: IPricingPlanList,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedRow({ ...row, isActive: e.target.checked });
    props.confirmPopupToggleHandler();
  };

  /**
   * This function shows the delete pricingPlan confirmation popup component and sets the seleted pricingPlan Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.deletePopupToggleHandler();
  };

  /**
   * This function deletes the pricingPlan by providing Id to the Api
   */
  const deleteHandler = () => {
    DeletePricingPlanService(selectedId);
  };

  /**
   * This function shows the change password popup component and sets the seleted pricingPlan Id
   */
  const changePasswordClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.changePasswordToggleHandler();
  };

  /**
   * This function provides manual refresh functionality 
   * This function is used when we give refresh functionality to pricingPlan.
   */
  const refreshClickHandler = () => {
    callGetPricingPlanListService();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetPricingPlanListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: pricingPlanList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPricingPlanList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetPricingPlanListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateGroupstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdatePricingPlanState.success) {
      resetAddUpdatePricingPlanState();
      callGetPricingPlanListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdatePricingPlanState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "error" of the useEffect changed.
   */
  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deletePricingPlanSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (deletePricingPlanState.success) {
      toast.success(formatMessage("deleted_success"));
      reset();
      props.deletePopupToggleHandler();
      callGetPricingPlanListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePricingPlanState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deletePricingPlanError" of the useEffect changed.
   */
  useEffect(() => {
    if (deletePricingPlanState.error) {
      toast.error(formatMessage(deletePricingPlanState.error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePricingPlanState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetPricingPlanListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const actionIcons: IActionFormatter[] = showIcons([
    {
      dataField: "isEdit",
      handler: openAddPricingPlanHandler,
      icon: faPencilAlt,
      isDisable: true,
      title: "edit",
      action: "edit",
      varient: "primary",
    },
    {
      dataField: "isDelete",
      handler: deleteClickHandler,
      icon: faTrashAlt,
      isDisable: true,
      title: "delete",
      varient: "danger",
    },
  ], systemModuleEnum.Plan)
  /**
   * The column constant defines the column description for the pricingPlan grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
        dataField: "PlanName",
        text: formatMessage("plan_name"),
        title: (cell: string, row: any) => {
            return (cell?.length > 10 ? cell : undefined);
        },
    },
    {
        dataField: "OrderDescriptionTypeString",
        text: formatMessage("order_description_type"),
        title: (cell: string, row: any) => {
            return (cell?.length > 10 ? cell : undefined);
        },
    },
    {
        dataField: "MaxNoOfDevicesSupport",
        text: formatMessage("no_of_devices_support"),
    },
    {
        dataField: "MonthlyDataOperations",
        text: formatMessage("data_operations"),
    },
    {
        dataField: "YearlyPlanExpiryPeriod",
        text: formatMessage("life_time"),
        formatter: (cell: number, row: any) => {
            return (cell > 0 ? <> {cell} <FormattedMessage id={ cell == 1 ? "year" : "years"} /> </> : cell);
        },
    },
    {
        dataField: "MonthlyInventoryPeriod",
        text: formatMessage("inventory_period"),
        formatter: (cell: number, row: any) => {
            return (cell > 0 ? <> {cell} <FormattedMessage id={ cell == 1 ? "month" : "months"} /> </> : cell);
        },
    },
    {
        dataField: "Currency",
        formatter: (row: any) => {
            return (<span>{row ? (row.CurrencyName +" (" + row.Symbol +")" ): emptyValue}</span>)},
        text: formatMessage("currency"),
    },
    {
        dataField: "PlanPrice",
        // formatter: (row: any) => <span>{row ? (row.CurrencyName +" (" + row.Symbol +")" ): emptyValue}</span>,
        text: formatMessage("plan_price"),
    },
    {
        dataField: "ExcessDataSurcharge",
        // formatter: (row: any) => <span>{row ? (row.CurrencyName +" (" + row.Symbol +")" ): emptyValue}</span>,
        text: formatMessage("unit_price"),
    },
    {
        dataField: "Tax",
        formatter: (cell: any) => <span>{cell}</span>,
        text: formatMessage("tax_heading"),
    },
    {
      dataField: "",
      formatter: ActionFormatter(actionIcons),
      text: formatMessage("actions"),
      hidden: actionIcons.length === 0 ? true : false,
    },
  ];

  return (
    <React.Fragment>
      {props.showAddPopup && (
        <AddPricingPlan id={selectedId} modalClosed={props.addToggleHandler} />
      )}
      {props.showDeletePopup && (
        <DeletePopup
          title="delete_pricing_plan"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_pricing_plan_confirm"
          isLoading={deletePricingPlanState.inProgress}
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row g-0  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 ps-5 mb-0">
                        <FormattedMessage id="pricing_plan_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                    {/* <li className="mx-2" title={formatMessage("refresh")}>
                      <LoaderIconButton
                        id="RefreshPricingPlans"
                        onClick={() => refreshClickHandler()}
                        svg={
                          <svg
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-arrow-repeat m-0"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                            <path
                              fillRule="evenodd"
                              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                            />
                          </svg>
                        }
                        className="btn-sm"
                        variant="light"
                      />
                    </li> */}
                    {
                      isOperationPermitted(systemModuleEnum.Plan, "Add") &&
                      <li title={formatMessage("add_pricing_plan")}>
                        <LoaderButton
                          id="AddPricingPlans"
                          text="+_add_pricing_plan"
                          onClick={() => openAddPricingPlanHandler({ Id: -1 })}
                        />
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_items_list">
      {inProgress && <FormLoader loading={inProgress} />}
        {allPricingPlansList.length > 0 ? (
          <div className="card mt-3">
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allPricingPlansList && (
                  <Grid
                    data={allPricingPlansList}
                    columns={columns}
                    keyField="Id"
                    loading={false}
                  />
                )}
                
              </div>
            </div>
            {allPricingPlansList.length > 0 ? (
              <div className="row g-0 p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={false}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            {/* <img src={no_pricingPlan} alt="" className="no_message" /> */}
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_pricing_plan" />
            </p>
            <p className="mb-3">{formatMessage("no_pricing_plan_message")}</p>
            {
              isOperationPermitted(systemModuleEnum.Plan, "Add") &&
              <LoaderButton
                text="+_add_pricing_plan"
                onClick={() => openAddPricingPlanHandler({ Id: -1 })}
              />
            }
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AddPopupHOC(DeletePopupHOC(observer(PricingPlan)));
