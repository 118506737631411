/*    
<summary>
   This class component is all about Managing devices.
   Developer: Aashish Singh, Created Date: 03-April-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { ICommonState } from "../../models/state/ICommonState";
import * as baseService from '../service/base-service';
import URLConstants from "../../constants/url.constants";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import toast from 'react-hot-toast';
import { initialState as addConfigurationInitialState } from "../initial-state/add-configuration-state";
import { initialState as allConfigurationInitialState } from "../initial-state/get-all-configuration-state";
import { IConfigurationState } from "../../models/state/IConfigurationState";
import IAddConfiguration, { IUpdateConfiguration } from "../../models/Form/IAddUpdateConfiguration";
import { IConfigurationVM, IConfigurationList, IConfigurationListVM } from "../../models/response/IConfigurationResponse";
import { IOption } from "../../models/ICommon";
import { getLanguageFromLocalStorage } from "../../helpers/local-stotrage-helper";

export class ConfigurationStore implements IConfigurationState, ICommonState {
    inProgress = false;
    error = '';
    configurationList: IConfigurationListVM = allConfigurationInitialState;
    allconfigurations: Array<IConfigurationVM> = [];


    initialStateValue = {
        success: false,
        error: '',
        inProgress: false
    }
    addUpdateConfigurationstate = { ...this.initialStateValue }
    deleteConfigurationState = { ...this.initialStateValue }
    configurationDetail: any = undefined;
    configurationDetailState = { ...this.initialStateValue }
    IsAssociatedState = {...this.initialStateValue}
    IsAssociated : boolean | undefined = undefined;

    selectedAssignedGroup = 0;

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            configurationList: observable,
            allconfigurations: observable,
            addUpdateConfigurationstate: observable,
            configurationDetail: observable,
            configurationDetailState: observable,
            deleteConfigurationState: observable,
            IsAssociatedState: observable,
            IsAssociated: observable,
            selectedAssignedGroup: observable,
            GetConfigurationListService: action,
            GetAllConfigurationService: action,
            GetConfigurationByIdService: action,
            GetConfigurationAssociatedInfoService: action,
            AddConfigurationService: action,
            UpdateConfigurationService: action,
            DeleteConfigurationService: action,
            reset: action,
            resetStore: action,
            resetIsAssociatedState: action,
            resetAddUpdateConfiguration: action,
            resetGetConfigurationDetail: action,
            setSelectedAssinedGroupId: action,
            configDetail: computed,
            allConfigurationslist: computed,
            allAvailableConfigurationsOptions: computed,
        });
    }

    /**
    * This function is used to get All Configurations list from API by group id.
    * @param pageNumber : Page Number
    * @param pageSize : Page Size / No of records in a page
    * @returns
    */
    GetConfigurationListService = (pageNumber: number, pageSize: number, configurationId: number) => {
        this.inProgress = true;
        const url = URLConstants.GetConfigurationList + "?PageNo=" + pageNumber + "&PageSize=" + pageSize + "&ConfigurationId="+configurationId;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IConfigurationListVM>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.configurationList = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

     /**
     * This function is used to map deviceDataList to allDeviceslist suitable for Grid Component.
     * @returns Initial Device Details
     */
     get allConfigurationslist(): IConfigurationList[] {
        if (this.configurationList && this.configurationList?.ConfigurationProtocols.length > 0)
            return this.configurationList?.ConfigurationProtocols.map((configuration) => {
                let assignedSelected = this.selectedAssignedGroup == configuration.Id;
                let language = getLanguageFromLocalStorage();
                return {
                    Id: configuration.Id,
                    Name: configuration.Name,
                    Description: configuration.Description,
                    Groups: configuration.Groups,
                    SourceProtocol: configuration.SourceProtocol,
                    HttpsParameters: configuration.HttpsParameters,
                    DestinationProtocol: configuration.DestinationProtocol,
                    IsStoreInDb: configuration.IsStoreInDb,
                    Language: language,
                    isDelete: true,
                    isEdit: true,
                    isData: true,
                    isAssignedSelected : assignedSelected,
                }
            })
        return [];
    }

    /**
    * This function is used to get All Configurations list from API without pagination
    */
    GetAllConfigurationService = () => {
        this.inProgress = true;
        const url = URLConstants.GetConfigurations;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<Array<IConfigurationVM>>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allconfigurations = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
    * This function is used to map deviceDataList to allDeviceslist suitable for Grid Component.
    * @returns Initial Device Details
    */
    get allAvailableConfigurationsOptions(): any[] {
        const configOptions: IOption[] = [{
            id: -1,
            value: "please_select",
        }];
        if (this.allconfigurations && this.allconfigurations?.length > 0)
            this.allconfigurations?.map((configuration) => {
                configOptions.push({
                    id: configuration.Id,
                    value: configuration.Name,
                })
            })
        return configOptions;
    }

    /**
     * This function is used to Get Configuration Data Details by Id from API.
     * @param id : Configuration Identifier
     * @returns
     */
    GetConfigurationByIdService = (id: number) => {
        this.configurationDetailState.inProgress = true;
        const url = URLConstants.GetConfigurationById + "/" + id;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IConfigurationVM>>) => {
                if (response.data.Error) {
                    this.configurationDetailState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.configurationDetail = response.data.Data;
                    this.configurationDetailState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.configurationDetailState.inProgress = false }));
    }

    /**
      * This function provides initail values to the Add Update Configuration Form.
      * @returns Initial Configuration Details
      */
    get configDetail(): IAddConfiguration {
        if (this.configurationDetail)
            return {
                SourceProtocol: this.configurationDetail.SourceProtocol,
                Name: this.configurationDetail.Name,
                Description: this.configurationDetail.Description,
                TopicPattern: this.configurationDetail.MqttsParameters != null ? this.configurationDetail.MqttsParameters.Topic : "", 
                DestinationProtocol: this.configurationDetail.DestinationProtocol,
                PayloadFormat:this.configurationDetail.PayloadFormat,
                IsSuccessPacketsStoreInDb: this.configurationDetail.IsSuccessPacketsStoreInDb,
                IsFailedPacketsStoreInDb: this.configurationDetail.IsFailedPacketsStoreInDb,
                HttpsParameters: {...this.configurationDetail.HttpsParameters,
                SendType: this.configurationDetail.HttpsParameters != null ? (this.configurationDetail.HttpsParameters.IsSendICCID == true ? "0" : "1") : "0",
                SendApiKeyValueType: this.configurationDetail.HttpsParameters != null ? (this.configurationDetail.HttpsParameters.AuthorizationType != 2 ? "0" : this.configurationDetail.HttpsParameters.SendApiKeyValueType.toString()) : "0"},
                Groups: this.configurationDetail.Groups,
                MqttsParameters: {...this.configurationDetail.MqttsParameters,
                Qos: this.configurationDetail.MqttsParameters != null ? (this.configurationDetail.MqttsParameters.Qos == 0 ? "0" : "1") : "0",
                TopicType: "0",
                CustomTopic: "",
                },
                AwsIoTCoreParameters: {...this.configurationDetail.AwsIoTCoreParameters,
                Qos: this.configurationDetail.AwsIoTCoreParameters != null ? (this.configurationDetail.AwsIoTCoreParameters.Qos == 0 ? "0" : "1") : "0",
                ServerCertificate: "",
                ServerCertificateData: this.configurationDetail?.AwsIoTCoreParameters != null ? this.configurationDetail?.AwsIoTCoreParameters?.ServerCertificate : "",
                TopicType: this.configurationDetail?.AwsIoTCoreParameters != null ? (this.configurationDetail?.AwsIoTCoreParameters?.Topic != "" ? "1" : "0") : "0",
                CustomTopic: this.configurationDetail?.AwsIoTCoreParameters != null ? (this.configurationDetail?.AwsIoTCoreParameters?.Topic) : ""
                },
                AwsS3BucketParameters: {...this.configurationDetail.AwsS3BucketParameters,
                BucketName: this.configurationDetail.AwsS3BucketParameters != null ? (this.configurationDetail?.AwsS3BucketParameters?.BucketName) : "",
                AccessKey: this.configurationDetail.AwsS3BucketParameters != null ? (this.configurationDetail?.AwsS3BucketParameters?.AccessKey) : "",
                SecretKey: this.configurationDetail.AwsS3BucketParameters != null ? (this.configurationDetail?.AwsS3BucketParameters?.SecretKey) : "",
                Region: this.configurationDetail.AwsS3BucketParameters != null ? (this.configurationDetail?.AwsS3BucketParameters?.Region?.Id > 0 ? 
                    this.configurationDetail?.AwsS3BucketParameters?.Region : {Id:-1, RegionName:"", Region:""}) : {Id:-1, RegionName:"", Region:""},
                },
                AwsKinesisParameters: {...this.configurationDetail.AwsKinesisParameters,
                BucketName: this.configurationDetail.AwsKinesisParameters != null ? (this.configurationDetail?.AwsKinesisParameters?.BucketName) : "",
                AccessKey: this.configurationDetail.AwsKinesisParameters != null ? (this.configurationDetail?.AwsKinesisParameters?.AccessKey) : "",
                SecretKey: this.configurationDetail.AwsKinesisParameters != null ? (this.configurationDetail?.AwsKinesisParameters?.SecretKey) : "",
                Region: this.configurationDetail.AwsKinesisParameters != null ? (this.configurationDetail?.AwsKinesisParameters?.Region?.Id > 0 ? 
                    this.configurationDetail?.AwsKinesisParameters?.Region : {Id:-1, RegionName:"", Region:""}) : {Id:-1, RegionName:"", Region:""},
                },
                KeyCustom: "",
                ValueCustom: "",
            };
        return addConfigurationInitialState;
    }

    /**
     * This function is used to reset getConfigurationDetail observables to their initial values.
     * @returns
     */
    resetGetConfigurationDetail = () => {
        this.configurationDetail = undefined;
        this.configurationDetailState = { ...this.initialStateValue }
    }

    /**
     * This function is used to Get Configuration info wheather group is associated or not from API.
     * @param id : Configuration Identifier
     * @returns
     */
    GetConfigurationAssociatedInfoService = (id: number) => {
        this.IsAssociatedState.inProgress = true;
        const url = URLConstants.GetConfigurationGroupAssociatedInfo + "/" + id;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.IsAssociatedState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.IsAssociated = response.data.Data;
                    this.IsAssociatedState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.IsAssociatedState.inProgress = false }));
    }

    /**
     * This function is used to reset IsAssociatedState observables to their initial values.
     */
    resetIsAssociatedState = () => {
        this.IsAssociatedState = { ...this.initialStateValue }
        this.IsAssociated = undefined;
    }

    /**
     * This function is used to Add New Configuration. 
     * @param configuration : Configuration Details
     * @returns
     */
    AddConfigurationService = (configuration: IAddConfiguration) => {
        this.addUpdateConfigurationstate.inProgress = true;
        return baseService.postRequest(URLConstants.AddConfiguration, configuration)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateConfigurationstate.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.addUpdateConfigurationstate.success = true;
                }
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error( formatMessage(errorMess[0]) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }
            })
            .finally(action(() => { this.addUpdateConfigurationstate.inProgress = false; }));
    }

    /**
     * This function is used to reset addUpdateConfigurations observables to their initial values.
     */
    resetAddUpdateConfiguration = () => {
        this.addUpdateConfigurationstate = { ...this.initialStateValue }
    }

    /**
     * This function is used to update existing configuration details. 
     * @param id : Configuration identifier
     * @param configuration : Configuration Details
     * @returns
     */
    UpdateConfigurationService = (id: number, configuration: IAddConfiguration) => {
        let data: IUpdateConfiguration = {
            Id: id,
            ...configuration
        }
        this.addUpdateConfigurationstate.inProgress = true;
        return baseService.putRequest(URLConstants.UpdateConfiguration, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateConfigurationstate.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdateConfigurationstate.success = true;
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error( formatMessage(errorMess[0]) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }
            })
            .finally(action(() => { this.addUpdateConfigurationstate.inProgress = false; }));
    }

    /**
    * This function is used to delete existing configuration. 
    * @param id : Configuration identifier
    * @returns
    */
    DeleteConfigurationService = (id: number) => {
        this.deleteConfigurationState.inProgress = true;
        const url = URLConstants.DeleteConfiguration + "/" + id;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteConfigurationState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteConfigurationState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteConfigurationState.inProgress = false; }));
    }

    /**
     * This function is used to reset observables to their initial values.
     * @returns
     */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.deleteConfigurationState = {
            ...this.initialStateValue
        };
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.deleteConfigurationState = {...this.initialStateValue };
        this.configurationList = allConfigurationInitialState;
        this.addUpdateConfigurationstate = { ...this.initialStateValue }
        this.deleteConfigurationState = { ...this.initialStateValue }
        this.configurationDetail = undefined;
        this.configurationDetailState = { ...this.initialStateValue }
        this.IsAssociatedState = {...this.initialStateValue}
        this.IsAssociated = undefined;
    }

    setSelectedAssinedGroupId = (id:number) =>{
        this.selectedAssignedGroup = id;
    }
}

export default new ConfigurationStore();