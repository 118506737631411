/*    
<summary>
   This is the Orders Component which shows the Orders Data.
   Developer: Aashish Singh, Created Date:28-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { SyntheticEvent, useEffect, useState } from "react";
import "./order.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import IPageInfo, {
  IActionFormatter,
  IOption,
  IPageLimitInfo,
} from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import Grid from "../../shared-components/Grid/Grid";
import { observer } from "mobx-react";
import "react-sliding-side-panel/lib/index.css";
import {
  ActionFormatter,
  ActiveInactiveButtonFormatter,
} from "../../shared-components/Grid/GridFormatter";
import {
  faClock,
  faDownload,
  faMoneyCheck,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import AddPopupHOC from "../../shared-components/popup/add/add-popup-hoc";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import {
  IOrderFilterDetails,
  IOrderList,
} from "../../models/response/IOrderResponse";
import userSettingEnum from "../../constants/user-setting-enum";
import toast from "react-hot-toast";
import Pagination from "../../shared-components/Grid/Pagination";
import AddUpdateOrder from "./components/add-edit-order";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
// import no_order from "../../assets/Images/svg/no-order.svg";
import userTypeEnum from "../../constants/user-type-enum";
import { emptyValue, orderStatusClasses } from "../../constants/common-constants";
import {
  isOperationPermitted,
  showIcons,
} from "../../helpers/action-permission-helper";
import systemModuleEnum from "../../constants/system-module-enum";
import {
  getRoleTypeFromLocalStorage,
  getTenantIdFromLocalStorage,
  getUserTypeFromLocalStorage,
} from "../../helpers/local-stotrage-helper";
import roleTypeEnum from "../../constants/role-type-enum";
import OrderStatusEnum from "../../constants/order-status-enum";
import ConfirmPopupHOC from "../../shared-components/popup/confirm/confirm-popup-hoc";
import ConfirmPopup from "../../shared-components/popup/confirm/confirm-popup";
import SlidingPanel from "react-sliding-side-panel";
import OrderDetail from "./components/order-detail";
import EditStatusPopupHOC from "../../shared-components/popup/edit-status/edit-status-popup-hoc";
import UpdateOrderStatusForm from "./forms/edit-order-status-form";
import PaymentPopupHOC from "../../shared-components/popup/payment/payment-popup-hoc";
import ShowPopupHOC from "../../shared-components/popup/show/show-popup-hoc";
import AddOrderPayment from "./components/add-order-payment";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import OrderFilter from "./components/order-filter";
import OrderFilterPopupHOC from "./hoc/OrderFilterPopupHOC";

const Order = (props: any) => {
  const { orderStore, preferencesStore, authStore, tenantStore } = useStore();
  const {
    inProgress,
    orderList,
    GetOrderListService,
    resetAddUpdateOrderState,
    allOrder,
    reset,
    DeleteOrderService,
    deleteOrderState,
    addUpdateOrderState,
    error,
    UpdateOrderStatusService,
    orderStatusState,
    resetOrderStatusStateState,
    resetOrderDetail,
    addOrderPaymentState,
    resetAddOrderPaymentState,
    exportAssociatedOrderService,
    exportAssociatedTopUpOrderService,
    orderFilter,
    setFilterDetail,
    resetStore
  } = orderStore;
  const { GetAllTenantsService, allTenantOptions, allTenantOptionsWithAll, selectedOrderModuleTenantId, setSelectedOrderModuleTenantId, allTenants, resetAllTenantList } = tenantStore;
  const { userSetting, setUserSetting, islanguageChanged, updateLanguageChangedFlag } = preferencesStore;
  let lang: string;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allOrdersList: Array<IOrderList> = allOrder;
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.orderLimitConfig,
    isPageLimitChange: false,
  });
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const history = useHistory();

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(
      userSettingEnum.OrderLimitConfig,
      parseInt(evt.target.value)
    );
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetOrderListService = () => {
    if (getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser) {
      if(allTenants.length > 0)
      GetOrderListService(
        pageInfo.currentPage,
        pageLimitInfo.pageLimit,
        selectedOrderModuleTenantId,
        orderFilter
      );
    } else
      GetOrderListService(
        pageInfo.currentPage,
        pageLimitInfo.pageLimit,
        getTenantIdFromLocalStorage(),
        orderFilter
      );
  };

  /**
   * This function shows the add group pop up from where we can add new group and update existing group prootcol. we use id to identify
   * that we are adding or updating group.
   * "Id == -1" : we are adding new group
   * "Id != -1" : means we are updating existing group
   */
  const openAddOrderHandler = (row: { Id: number }, event:any) => {
    event.stopPropagation();
    closePanelhandler();
    setSelectedId(row.Id);
    props.addToggleHandler();
  };
  
  const openAddPaymentHandler = (row: { Id: number }, event:any) => {
    event.stopPropagation();
    closePanelhandler();
    setSelectedRow(row);
    props.paymentPopupToggleHandler();
  };
  
  const downloadOrderAssociatedDevicesHandler = (row: IOrderList, event:any) => {
    event.stopPropagation();
    closePanelhandler();
    if(row?.OrderDescriptionType?.Id == 3)
    { 
      exportAssociatedTopUpOrderService(row.OrderNo)
    }
    else
      exportAssociatedOrderService(row.OrderNo)
  };
  
  const closePaymentHandler = () => {
    setOpenPanel(false);
    setSelectedRow({});
    props.paymentPopupToggleHandler();
  };
  
  const redirectToHistoryModuleHandler = (row: IOrderList, event:any) => {
    localStorage.setItem("mzl_selected_order_detail", JSON.stringify(row));
    event.stopPropagation();
    closePanelhandler();
    history.push(RoutesConstants.PaymentHistory);
    // props.showPopupToggleHandler();
  };
  
  const openUpdateOrderStatusHandler = (row: IOrderList, event:any) => {
    event.stopPropagation();
    closePanelhandler();
    setSelectedRow(row);
    props.editStatusToggleHandler();
  };

  /**
   * This function shows the delete order confirmation popup component and sets the seleted order Id
   */
  const deleteClickHandler = (row: { Id: number }, event:any) => {
    event.stopPropagation();
    closePanelhandler();
    setSelectedId(row.Id);
    props.deletePopupToggleHandler();
  };

  /**
   * This function deletes the order by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteOrderService(selectedId);
  };

  /**
   * This function provides manual refresh functionality
   * This function is used when we give refresh functionality to order.
   */
  const refreshClickHandler = () => {
    callGetOrderListService();
  };

  // const filterClickHandler = () => {
  //   if(selectedOrderModuleTenantId > 0)
  //   {
  //     closePanelhandler();
  //     setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage: true });
  //     setFilterDetail({ TenantId: tenantId, isFilterActive: true });
  //   }
  //   else
  //     toast.error(formatMessage("please_select_any_tenant"));
  // };

  // const resetFilter = () => {
  //   closePanelhandler();
  //   setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage: true });
  //   setFilterDetail({
  //     ...initialFilter,
  //   });
  //   setTenantId(-1);
  // };

  useEffect(() => {
    if (
      getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser
    )
      GetAllTenantsService(systemModuleEnum.Order);
      return(()=>{
        resetAllTenantList();
        resetStore();
      })
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "filter" of the useEffect changed.
   */
  useEffect(() => {
    if (selectedOrderModuleTenantId > 0) {
      callGetOrderListService();
    }
    else{
      callGetOrderListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderModuleTenantId, orderFilter, allTenants]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetOrderListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: orderList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrder]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetOrderListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateOrderState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateOrderState.success) {
      resetAddUpdateOrderState();
      callGetOrderListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateOrderState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addOrderPaymentState" of the useEffect changed.
   */
  useEffect(() => {
    if (addOrderPaymentState.success) {
      resetAddOrderPaymentState();
      callGetOrderListService();
    }
  }, [addOrderPaymentState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "error" of the useEffect changed.
   */
  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteOrderSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteOrderState.success) {
      toast.success(formatMessage("deleted_success"));
      reset();
      props.deletePopupToggleHandler();
      callGetOrderListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrderState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteOrderSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (orderStatusState.success) {
      toast.success(formatMessage("updated_success"));
      callGetOrderListService();
      // reset();
      updateOrderStatusPopupCloseHandler();
      resetOrderStatusStateState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteOrderError" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteOrderState.error) {
      toast.error(formatMessage(deleteOrderState.error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrderState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "islanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetOrderListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  const orderStatusHandler = (orderStatus:number) => {
    setOpenPanel(false);
    const status =  orderStatus;
    UpdateOrderStatusService(selectedRow.Id, status);
  };
  
  const closeOrderStatusHandler = () => {
    setSelectedId(-1);
    props.confirmPopupToggleHandler();
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedId && row.Id === selectedId) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedId(row.Id);
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelhandler = () => {
    resetOrderDetail();
    setOpenPanel(false);
    setSelectedId(0);
    setSelectedRow({});
    // tenantStore.reset();
  };
  
  const updateOrderStatusPopupCloseHandler = () => {
    setSelectedRow({});
    props.editStatusToggleHandler();
  };

  const openFilterPopUpHandler = () => {
    props.orderFilterToggleHandler();
  };

  const closeFilterPopupHandler = () => {
    props.orderFilterToggleHandler();
  };

  const filterSubmitHandler = (values: IOrderFilterDetails) => {
    closeFilterPopupHandler();
    setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage: true });
    // setFilter({ ...values, isFilterActive: true });
    setFilterDetail({...values, isFilterActive: true});
    closePanelhandler();
  };

  const resetFilter = () => {
    closePanelhandler();
    setFilterDetail({
      SearchBy:-1,
        OrderNo:"",
        PaymentStatus:-1,
        OrderStatus:-1,
        isFilterActive: false
    });
    setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage: true });
  };

  const getIcons = () =>{
    let icons:IActionFormatter[] = [];
    if(getUserTypeFromLocalStorage() == userTypeEnum.SystemUser)
      icons = [...EditDeleteActionIcons, ...paymentHistoryActionIcons, ...downloadActionIcon];
    // else if(getUserTypeFromLocalStorage() == userTypeEnum.SystemUser)
    //   icons = [...paymentHistoryActionIcons];
    else
      icons = [...EditDeleteActionIcons, ...downloadActionIcon]
    return icons;
  }

  const EditDeleteActionIcons: IActionFormatter[] = showIcons(
    [
      {
        dataField: "isEdit",
        handler: openAddOrderHandler,
        icon: faPencilAlt,
        isDisable: true,
        title: "edit",
        action: "edit",
        varient: "primary",
      },
      {
        dataField: "isDelete",
        handler: deleteClickHandler,
        icon: faTrashAlt,
        isDisable: true,
        title: "delete",
        action: "delete",
        varient: "danger",
      }
    ],
    systemModuleEnum.Order
  );
  const paymentHistoryActionIcons: IActionFormatter[] = showIcons(
    [
      {
        dataField: "isPayment",
        handler: openAddPaymentHandler,
        icon: faMoneyCheck,
        isDisable: true,
        title: "payment",
        action: "edit",
        varient: "danger",
      },
      {
        dataField: "isHistory",
        handler: redirectToHistoryModuleHandler,
        icon: faClock,
        isDisable: true,
        title: "history",
        action: "view",
        varient: "danger",
      },
    ],
    systemModuleEnum.Order
  );
  const downloadActionIcon: IActionFormatter[] = showIcons(
    [
      {
        dataField: "isDownload",
        handler: downloadOrderAssociatedDevicesHandler,
        icon: faDownload,
        isDisable: true,
        title: "download",
        action: "view",
        varient: "danger",
      },
    ],
    systemModuleEnum.Order
  );
  /**
   * The column constant defines the column description for the order grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    // {
    //   dataField: "TenantName",
    //   text: formatMessage("tenant_name"),
    //   hidden:
    //     selectedOrderModuleTenantId == -1 &&
    //     getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser
    //       ? false
    //       : true,
    // },
    {
      dataField: "OrderNo",
      text: formatMessage("order_no"),
      title: (cell: string, row: any) => {
        return (cell?.length > 10 ? cell : undefined);
    },
    },
    {
      dataField: "OrderDescriptionTypeString",
      text: formatMessage("order_description_type"),
      title: (cell: string, row: any) => {
          return (cell?.length > 10 ? cell : undefined);
      },
    },
    {
      dataField: "CreatedDate",
      text: formatMessage("order_date"),
    },
    {
      dataField: "PlanName",
      text: formatMessage("plan_name"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "NoOfDevicesOrder",
      text: formatMessage("no_of_devices_order"),
    },
    {
      dataField: "TotalOrderAmount",
      text: formatMessage("total_order_amount"),
    },
    {
      dataField: "OrderStatus",
      text: formatMessage("order_status"),
      formatter: (cell: any, row: any) => {
        return (getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser && isOperationPermitted(systemModuleEnum.Order, "Edit")) ? (
            <button 
              className={`btn btn-sm btn-${orderStatusClasses[row.StatusValue]}`}
              onClick={(event)=>{
                openUpdateOrderStatusHandler(row, event);
            }}><FormattedMessage id={cell} />&nbsp; <i className="fa fa-chevron-down"></i></button>

        ) : (
          <span>{cell ?
            <span className={`btn btn-sm btn-${orderStatusClasses[row.StatusValue]}`}>
              <FormattedMessage id={cell} />
            </span>
            : emptyValue}
          </span>
        );
      },
    },
    {
      dataField: "PaymentStatus",
      text: formatMessage("payment_status"),
    },
    {
      dataField: "Remarks",
      text: formatMessage("remarks"),
      hidden: true
    },
    {
      dataField: "",
      formatter: ActionFormatter(getIcons()),
      text: formatMessage("actions"),
    },
  ];

  return (
    <React.Fragment>
      {props.showAddPopup && (
        <AddUpdateOrder 
          id={selectedId} 
          tenantId={getUserTypeFromLocalStorage() == userTypeEnum.SystemUser ? selectedOrderModuleTenantId : getTenantIdFromLocalStorage()} 
          modalClosed={()=>{
            closePanelhandler();
            props.addToggleHandler();
          }} />
      )}
      {props.showPaymentPopup && (
        <AddOrderPayment selectedData={selectedRow} modalClosed={closePaymentHandler} />
      )}
      {props.showEditStatusPopup && (
        <UpdateOrderStatusForm 
        selectedData={selectedRow}
        initialValue={{StatusValue: selectedRow.StatusValue}}
        submitHandler={orderStatusHandler}
        onModalClosed={updateOrderStatusPopupCloseHandler}
        isLoading={orderStatusState.inProgress}/>
      )}
      {props.showOrderFilterPopup && (
        <OrderFilter
          initialValues={_.omit(orderFilter, ["isFilterActive"])}
          isFilterActive={orderFilter.isFilterActive}
          submitHandler={filterSubmitHandler}
          modalClosed={closeFilterPopupHandler}
        />
      )}
      {props.showDeletePopup && (
        <DeletePopup
          title="delete_order"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_order_confirm"
          isLoading={deleteOrderState.inProgress}
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row g-0  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 ps-5 mb-0">
                        <FormattedMessage id="order_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                    {/* <li className="mx-2" title={formatMessage("refresh")}>
                      <LoaderIconButton
                        id="RefreshOrders"
                        onClick={() => refreshClickHandler()}
                        svg={
                          <svg
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-arrow-repeat m-0"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                            <path
                              fillRule="evenodd"
                              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                            />
                          </svg>
                        }
                        className="btn-sm"
                        variant="light"
                      />
                    </li> */}
                    {getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser && (
                      <li>
                        <select
                          className="custom-select form-control me-2"
                          value={selectedOrderModuleTenantId}
                          onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            let value = parseInt(e.target.value);
                            if(selectedOrderModuleTenantId !== value){
                              closePanelhandler();
                              setPageInfo((prevState)=>{ 
                                return { ...prevState, currentPage:1, isRefreshPage: true }
                              })
                              setSelectedOrderModuleTenantId(value);
                            }
                          }}
                        >
                          {allTenantOptionsWithAll.map((data: IOption, i: number) => (
                            <option key={i} value={data.id}>
                              {i == 0 ? formatMessage(data.value) : data.value}
                            </option>
                          ))}
                        </select>

                        {/* <li title={formatMessage("filter")}>
                          <button
                            data-testid="FilterButton"
                            className="btn btn-light btn-sm me-1"
                            type="button"
                            onClick={() => filterClickHandler()}
                            title={formatMessage("filter")}
                          >
                            <svg
                              className="svg-icon"
                              width="20px"
                              height="20px"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                                fill="currentColor"
                              />
                              <path
                                d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                        </li>
                        {orderFilter.isFilterActive && (
                          <>
                            <li>
                              <button
                                data-testid="FilterButton"
                                className="btn btn-light btn-sm me-1"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={resetFilter}
                                title={formatMessage("reset_filter")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  id="Layer_1"
                                  x="0px"
                                  y="0px"
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 122.88 110.668"
                                  enable-background="new 0 0 122.88 110.668"
                                >
                                  <g>
                                    <path
                                      fill="currentColor"
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </>
                        )} */}
                      </li>
                    )}
                    {orderFilter.isFilterActive == true ? (
                      <li>
                        <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm me-1"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={resetFilter}
                          title={formatMessage("reset_filter")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="20px"
                            height="20px"
                            viewBox="0 0 122.88 110.668"
                            enable-background="new 0 0 122.88 110.668"
                          >
                            <g>
                              <path
                                fill="currentColor"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                              />
                            </g>
                          </svg>
                        </button>
                      </li>
                    ) : (
                      ""
                    )}

                    <li data-testid="Filter">
                      <div className="dropdown">
                        <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm me-1"
                          type="button"
                          onClick={openFilterPopUpHandler}
                          title={formatMessage("filter")}
                        >
                          <svg
                            className="svg-icon"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                              fill="currentColor"
                            />
                            <path
                              d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </div>
                    </li>
                    {((getUserTypeFromLocalStorage() ===  userTypeEnum.SystemUser) || getUserTypeFromLocalStorage() !==  userTypeEnum.SystemUser) && isOperationPermitted(systemModuleEnum.Order, "Add") && (
                      <li title={formatMessage("add_order")}>
                        <LoaderButton
                          id="AddOrders"
                          text="+_add_order"
                          className="ms-2"
                          onClick={(event:any) => openAddOrderHandler({ Id: -1 }, event)}
                        />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_items_list">
        {inProgress && <FormLoader loading={inProgress} />}
        {allOrdersList.length > 0 ? (
          <div
            className={`card mt-3 changingPanel ${openPanel ? "panelOpen" : ""
            }`}
            > 
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive orders_list_table" data-testid="table">
                {allOrdersList && (
                  <Grid
                    data={allOrdersList}
                    columns={columns}
                    keyField="Id"
                    loading={inProgress}
                    rowEvents={{ onClick: rowEvents }}
                    rowClasses={rowClassFormat}
                  />
                )}
              </div>
            </div>
            {allOrdersList.length > 0 ? (
              <div className="row g-0 p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={pageInfo.isRefreshPage}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            {/* <img src={no_order} alt="" className="no_message" /> */}
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_order" />
            </p>
            <p className="mb-3">
              {
                orderFilter.isFilterActive ? 
                <FormattedMessage id="no_record_after_filter" /> :
                <FormattedMessage id="no_order_message" />
              }
            </p>
            {(!orderFilter.isFilterActive && 
            ((getUserTypeFromLocalStorage() ===  userTypeEnum.SystemUser) || getUserTypeFromLocalStorage() !==  userTypeEnum.SystemUser) && isOperationPermitted(systemModuleEnum.Order, "Add")) && (
              <LoaderButton
                text="+_add_order"
                onClick={(event:any) => openAddOrderHandler({ Id: -1 }, event)}
              />
            )}
          </div>
        )}
      </div>
      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel order_details_panel">
          <div className="row bb p-2 g-0">
            <div className="col-lg-11">
              <h2 className="fw-bold mb-0 mt-2">
                {/* <FormattedMessage id={"order_details"} /> */}
                {selectedRow.OrderNo}
              </h2>
            </div>
            <div className="col-lg-1 text-end">
              <button onClick={closePanelhandler} className="Crossicons" title={formatMessage("close")}>
                <svg
                  role="button"
                  xlinkTitle="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
          </div>
          <OrderDetail selectedData={selectedRow} />
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default OrderFilterPopupHOC(ShowPopupHOC(PaymentPopupHOC(EditStatusPopupHOC(ConfirmPopupHOC(AddPopupHOC(DeletePopupHOC(observer(Order))))))));
