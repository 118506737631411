/*    
<summary>
   This class component is all about Managing order data functionality.
   Developer:Aashish Singh, Created Date:29-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { initialState } from '../initial-state/add-edit-order-state';
import { initialState as GetAllOrderState } from '../initial-state/get-all-order-state';
import { action, computed, makeObservable, observable } from 'mobx';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { IObservableInitialState, IOption } from '../../models/ICommon';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import { DATE_TIME_FORMAT, emptyValue } from '../../constants/common-constants';
import { IOrderListVM, IOrderVM, IOrderList, IOrderDescription, IOrderFilter, IOrderPayment } from '../../models/response/IOrderResponse';
import moment from 'moment';
import config from '../../helpers/config-helper';
import { getLanguageFromLocalStorage, getUserTypeFromLocalStorage } from '../../helpers/local-stotrage-helper';
import OrderStatusEnum from '../../constants/order-status-enum';
import userTypeEnum from '../../constants/user-type-enum';
import PaymentStatusEnum from '../../constants/payment-status-enum';
import Papa from 'papaparse';
import PaymentOptionEnum from '../../constants/payment-option-enum';

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

export class OrderStore implements ICommonState {
    inProgress = false;
    error = '';
    
    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    
    orderList: IOrderListVM = GetAllOrderState;
    addUpdateOrderState ={...this.initialStateValue }
    deleteOrderState ={...this.initialStateValue }
    addOrderPaymentState ={...this.initialStateValue }
    exportDevicesState ={...this.initialStateValue }
    
    allDeliveredOrders: IOrderVM[] = [];
    allDeliveredOrderState ={...this.initialStateValue }
    
    allOrders: IOrderDescription[] = [];
    allOrderState ={...this.initialStateValue }
    orderStatusState ={...this.initialStateValue }
    order: IOrderVM | undefined = undefined;
    orderState ={...this.initialStateValue }
    selectedOrderDescriptionId = -1;
    selectedTenantId: number = -1;

    initialFilter: IOrderFilter = {
        SearchBy:-1,
        OrderNo:"",
        PaymentStatus:-1,
        OrderStatus:-1,
        isFilterActive: false
    }

    orderFilter: IOrderFilter = {
        ...this.initialFilter
    }
    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            orderList: observable,
            allOrders: observable,
            allOrderState: observable,
            orderStatusState: observable,
            allDeliveredOrders: observable,
            allDeliveredOrderState: observable,
            addOrderPaymentState: observable,
            exportDevicesState: observable,
            order: observable,
            addUpdateOrderState: observable,
            deleteOrderState: observable,
            orderState: observable,
            selectedOrderDescriptionId: observable,
            selectedTenantId: observable,
            orderFilter: observable,
            GetOrderListService: action,
            GetAllDeliveredOrderByTenantIdService: action,
            GetAllOrderDescriptionTypeService: action,
            AddOrderService: action,
            UpdateOrderService: action,
            UpdateOrderStatusService: action,
            AddOrderPaymentService: action,
            DeleteOrderService: action,
            GetOrderByIdService: action,
            setSelectedTenantId: action,
            exportAssociatedOrderService: action, 
            exportAssociatedTopUpOrderService: action, 
            reset: action,
            resetStore: action,
            resetOrderDetail: action,
            resetAddUpdateOrderState: action,
            resetAddOrderPaymentState: action,
            resetOrderStatusStateState: action,
            setFilterDetail: action,
            resetAllDeliveredOrderState: action,
            allOrder: computed,
            orderDetail: computed,
            allOrderDescriptionTypeOptions: computed,
            getAllDeliveredOrderOptions: computed,
        });
    }

    /**
     * This function is used to Get All Orders List data with pagination information by calling an API.
     * @param currentPage : Current Page Number
     * @param pagerSize : Page Size
     * @returns 
     */
    GetOrderListService = (currentPage: number, pagerSize: number, tenantId: number, filter:any) => {
        this.inProgress = true;
        let url = URLConstants.Order + "/List";
        let tempSearchBy:number = parseInt(filter.SearchBy)
        let requestBody = {
            TenantId: tenantId,
            OrderNo: filter.OrderNo,
            SearchBy: tempSearchBy > 0 ? tempSearchBy : 0,
            OrderStatus: filter.OrderStatus,
            PaymentStatus: filter.PaymentStatus,
            PagingDetails: {
                PageNo: currentPage,
                PageSize: pagerSize,
                TotalPages: 0,
                TotalRecords: 0
            },
        }
        // this.orderList = {...this.orderList, Orders: []};
        return baseService.postRequest(url,{...requestBody})
            .then((response: IApiResponse<IApiSuccessResponse<IOrderListVM>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.orderList = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

   /**
    * This function is used to map orderList to allOrder List suitable for Grid Component.
    * @returns allOrdersList
    */
    get allOrder(): IOrderList[] {
        if (this.orderList?.Orders && this.orderList.Orders?.length > 0)
            return this.orderList.Orders.map((order:IOrderVM) => {
                let language = getLanguageFromLocalStorage();
                let isDelete = true;
                let isEdit = true;
                let isPayment = true;
                let isHistory = true;
                let isDownload = true;
                return {
                    Id: order.Id,
                    NoOfDevicesOrder: order?.NoOfDevicesOrder,
                    TotalOrderAmount: order?.TotalOrderAmount,
                    PendingAmount: order?.PendingAmount,
                    OrderDescriptionId: order.OrderDescriptionType ? order.OrderDescriptionType?.Id : -1,
                    TenantId: order.Tenant ? order.Tenant?.Id : -1,
                    PlanId: order.Plan ? order.Plan?.Id : -1,
                    OrderDescriptionType: order.OrderDescriptionType,
                    OrderDescriptionTypeString: order.OrderDescriptionType ? order.OrderDescriptionType?.TypeString : emptyValue,
                    Tenant: order.Tenant,
                    TenantName: order.Tenant ? (order.Tenant.TenantName ? order.Tenant.TenantName : emptyValue) : emptyValue,
                    Plan: order.Plan,
                    PlanName: order.Plan ? (order.Plan.PlanName ? order.Plan.PlanName : emptyValue) : emptyValue,
                    ShippingAddress: order.ShippingAddress,
                    IsBillingAddressSame: order.ShippingAddress?.trim() == order.BillingAddress?.trim() ? true : false,
                    OrderNo: order.OrderNo ? order.OrderNo : "",
                    OrderStatus: OrderStatusEnum[order.OrderStatus],
                    PaymentStatus: PaymentStatusEnum[order.PaymentStatus],
                    PaymentOption: PaymentOptionEnum[order.PaymentOption],
                    StatusValue: order.OrderStatus,
                    Remarks: order.Remarks,
                    Language: language,
                    CreatedBy: order.CreatedBy,
                    UpdatedBy: order.UpdatedBy,
                    CreatedDate: moment(order.CreatedDate).format(dateTimeFormat),
                    UpdatedDate: moment(order.UpdatedDate).format(dateTimeFormat),
                    isEdit: order.OrderStatus !== 0 ? false : isEdit,
                    isDelete: order.OrderStatus === 0 ? isDelete : (order.OrderStatus < 5 ? false : 
                        (getUserTypeFromLocalStorage() == userTypeEnum.SystemUser) ? isDelete : false),
                    isPayment: ((order.OrderStatus == 0 || order.OrderStatus == 5) || order.PaymentStatus == 2) ? false : isPayment,
                    isDownload: (order.OrderStatus !== 0 && order.OrderStatus !== 5) ? isDownload : false,
                    isHistory: !order.PaymentStatus ? false : isHistory,
                }
            })
        return [];
    }

    /**
    * This function is used to get All Tenants without pagination by calling an API.
    * @returns 
    */
    GetAllDeliveredOrderByTenantIdService = (tenantId:number) => {
        this.allDeliveredOrderState.inProgress = true;
        const url = URLConstants.Order + "/Tenant/" +tenantId+ "/All/Confirmed/Orders";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IOrderVM[]>>) => {
                if (response.data.Error) {
                    this.allDeliveredOrderState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allDeliveredOrders = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.allDeliveredOrderState.inProgress = false }));
    }

    /**
    * This function is used to map allOrders to allTenantOptions suitable for FormikFormSelect Component.
    * @returns Tenant Options List containing values & id
    */
    get getAllDeliveredOrderOptions(): IOption[] {
        const options: IOption[] = [{
            id: -1,
            value: "please_select",
        }];
        if (this.allDeliveredOrders && this.allDeliveredOrders.length > 0)
            this.allDeliveredOrders.map((order) => {
                options.push({
                    id: order.Id,
                    value: order.OrderNo,
                })
            })

        return options;
    }

    resetAllDeliveredOrderState = () =>{
        this.allDeliveredOrderState = {...this.initialStateValue};
        this.allDeliveredOrders = [];
    }
    /**
    * This function is used to get All Tenants without pagination by calling an API.
    * @returns 
    */
    GetAllOrderDescriptionTypeService = () => {
        this.allOrderState.inProgress = true;
        const url = URLConstants.Order + "/" + "All/DescriptionTypes";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IOrderDescription[]>>) => {
                if (response.data.Error) {
                    this.allOrderState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allOrders = response.data.Data;
                    this.selectedOrderDescriptionId = this.allOrders.length > 0 ? this.allOrders[0].Id : -1;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.allOrderState.inProgress = false }));
    }

    /**
    * This function is used to map allOrders to allTenantOptions suitable for FormikFormSelect Component.
    * @returns Tenant Options List containing values & id
    */
    get allOrderDescriptionTypeOptions(): IOption[] {
        const options: IOption[] = [{
            id: -1,
            value: "please_select",
        }];
        if (this.allOrders && this.allOrders.length > 0)
            this.allOrders.map((order) => {
                options.push({
                    id: order.Id,
                    value: order.TypeString,
                })
            })

        return options;
    }

   /**
    * This function is used to Get Order Details by Id by calling an API.
    * @param id : The Order Identifier
    * @returns 
    */
    GetOrderByIdService = (id: number, tenantId: number) => {
        this.orderState.inProgress = true;
        let url = `${URLConstants.Order}/${id}`;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IOrderVM>>) => {
                if (response.data.Error) {
                    // this.orderState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    let data = response.data.Data;
                    this.order = data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.orderState.error = err;
            }).finally(action(() => { this.orderState.inProgress = false; }));
    }
    
   /**
    * This function provides initail values to the Add Order Form. 
    * @returns Order Details
    */
   get orderDetail() {
        if (this.order) {
            return {
                NoOfDevicesOrder: this.order.NoOfDevicesOrder,
                Plan: this.order.Plan ? this.order.Plan : {...initialState.Plan},
                Tenant: this.order.Tenant,
                OrderDescriptionType: this.order.OrderDescriptionType,
                ShippingAddress: this.order.ShippingAddress,
                BillingAddress: this.order.BillingAddress,
                IsBillingAddressSame: this.order.ShippingAddress?.trim() == this.order.BillingAddress?.trim() ? true : false,
                Remarks: this.order.Remarks,
                OrderStatus: this.order.OrderStatus,
                PaymentStatus: this.order.PaymentStatus,
                TotalOrderAmount: this.order.TotalOrderAmount ? this.order.TotalOrderAmount : 0,
                PendingAmount: this.order.PendingAmount ? this.order.PendingAmount : 0,
                PaymentOption:this.order.PaymentOption,
                CreatedDate: moment(this.order.CreatedDate).format(DATE_TIME_FORMAT),
                CSVFile: "",
            }
        }
        // let tenantId = getTenantIdFromLocalStorage();
        return {...initialState}}
    
    /*
    This function is used to reset all order observables to their initial values.  
    */
    resetOrderDetail = () => {
        this.order = undefined;
        this.orderState = {...this.initialStateValue}
    }

    setSelectedTenantId = (tenantId: number) => {
        this.selectedTenantId = tenantId;
    }
   /**
    * This function is used to Add New Order by calling an API.
    * @param data : Order Data
    * @returns 
    */
    AddOrderService = (data: any) => {
        this.addUpdateOrderState.inProgress = true;
        let url = URLConstants.Order;
        let orderData: any = {
            NoOfDevicesOrder: parseInt(data.NoOfDevicesOrder),
            TotalOrderAmount: data.TotalOrderAmount,
            OrderDescriptionTypeId: parseInt(data.OrderDescriptionType.Id),
            PlanId: parseInt(data.Plan.Id),
            TenantId: parseInt(data.Tenant.Id),
            ShippingAddress: data.ShippingAddress,
            BillingAddress: data.BillingAddress,
            Remarks: data.Remarks,
            PaymentOption: data.PaymentOption,
            TopUpICCIDs: data.TopUpICCIDs ? data.TopUpICCIDs : [],
        };
        return baseService.postRequest(url, orderData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    let err = response.data.Message
                    if (err.includes(":")) {
                        let errorMess = err.split(":");
                        toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                    } else { toast.error(formatMessage(err)); }
                }
                else this.addUpdateOrderState.success = true;
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }
                // this.addUpdateOrderState.error = err;
            })
            .finally(action(() => { this.addUpdateOrderState.inProgress = false; }));
    }

    /**
     * This function is used to Updating Existing Order Details by calling an API.
     * @param id : The Order Identifier
     * @param data 
     * @returns 
     */
    UpdateOrderService = (id: number, data: any) => {
        this.addUpdateOrderState.inProgress = true;
        let url = URLConstants.Order;
        let orderData: any = {
                Id: id,
                NoOfDevicesOrder: parseInt(data.NoOfDevicesOrder),
                TotalOrderAmount: data.TotalOrderAmount,
                OrderDescriptionTypeId: parseInt(data.OrderDescriptionType.Id),
                PlanId: parseInt(data.Plan.Id),
                TenantId: parseInt(data.Tenant.Id),
                ShippingAddress: data.ShippingAddress,
                BillingAddress: data.BillingAddress,
                Remarks: data.Remarks,
                PaymentOption: data.PaymentOption,
                TopUpICCIDs: data.TopUpICCIDs,
            };
        return baseService.putRequest(url, orderData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    let err = response.data.Message
                    if (err.includes(":")) {
                        let errorMess = err.split(":");
                        toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                    } else { toast.error(formatMessage(err)); }
                }
                else this.addUpdateOrderState.success = true;
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }
                // this.addUpdateOrderState.error = err;
            })
            .finally(action(() => { this.addUpdateOrderState.inProgress = false; }));

    }
    
    /*
    This function is used to reset all AddOrder observables to their initial values.  
    */
    resetAddUpdateOrderState = () => {
        this.addUpdateOrderState = {...this.initialStateValue}
        this.selectedOrderDescriptionId = -1;
    }

    AddOrderPaymentService = (id: number, data: IOrderPayment) => {
        this.addOrderPaymentState.inProgress = true;
        let url = URLConstants.Order + "/Payment";
        let orderData: any = {
                OrderId: id,
                Amount: data.Amount ? parseFloat(data.Amount) : 0,
                PaymentReceviedDate: data.PaymentReceviedDate,
                Remarks: data.Remarks,
            };
        return baseService.postRequest(url, {...orderData})
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    let err = response.data.Message
                    if (err.includes(":")) {
                        let errorMess = err.split(":");
                        toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                    } else { toast.error(formatMessage(err)); }
                }
                else this.addOrderPaymentState.success = true;
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }
                // this.addOrderPaymentState.error = err;
            })
            .finally(action(() => { this.addOrderPaymentState.inProgress = false; }));

    }
    
    /*
    This function is used to reset all Add Order payment observables to their initial values.  
    */
    resetAddOrderPaymentState = () => {
        this.addOrderPaymentState = {...this.initialStateValue}
    }

    /**
    * This function is used to Updating Existing Order state details by calling an API.
    * @param id : The Order Identifier
    * @param state : State (true/false for active/inactive) 
    * @returns 
    */
    UpdateOrderStatusService = (id: number, status: number) => {
        this.orderStatusState.inProgress = true;
        let requestData: any = {
            Id: id,
            Status: status
        };
        let url = URLConstants.OrderStatus;
        return baseService.putRequest(url, requestData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    let err = response.data.Message
                    if (err.includes(":")) {
                        let errorMess = err.split(":");
                        toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                    } else { toast.error(formatMessage(err)); }
                }
                else this.orderStatusState.success = true;
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }
                // this.orderStatusState.error = err;
            })
            .finally(action(() => { this.orderStatusState.inProgress = false; }));
    }

    /*
    This function is used to reset all AddOrder observables to their initial values.  
    */
    resetOrderStatusStateState = () => {
        this.orderStatusState = {...this.initialStateValue}
    }

   /**
    * This function is used to Delete Order by calling an API.
    * @param id : The Order Identifier
    * @returns 
    */
    DeleteOrderService = (id: number) => {
        this.deleteOrderState.inProgress = true;
        let url = `${URLConstants.Order}/${id}`;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteOrderState.error = response.data.Message;
                    // toast.error(formatMessage(response.data.Message));
                }
                else this.deleteOrderState.success = true;
            })
            .catch((err: string) => {
                this.deleteOrderState.error = err;
            }).finally(action(() => { this.deleteOrderState.inProgress = false; }));
    }

    generateAndDownloadCSV = (selectedDevices: any, fileName: string) => {
        const csv = Papa.unparse(selectedDevices);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download =  fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        toast.success(formatMessage("download_started_successfully"));
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    /**
    * This function is used to Export orders associated. 
    * @param OrderNo : Order no
    * @returns
    */
    exportAssociatedOrderService = (OrderNo: string) => {
        this.exportDevicesState.inProgress = true;
        let url = URLConstants.Order + "/" + OrderNo + "/All/Associated/Devices";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<any[]>>) => {
                if (response.data.Error) {
                    this.exportDevicesState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.exportDevicesState.success = true;
                    let data = response.data.Data ? response.data.Data : []
                    if(data.length > 0)
                        this.generateAndDownloadCSV(response.data.Data, "AssociatedDevices_" + OrderNo);
                    else
                        toast.error(formatMessage("no_device_associated_with_order"));
                }
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }

            })
            .finally(action(() => { this.exportDevicesState.inProgress = false; }));
    }
    
    /**
    * This function is used to Export orders associated. 
    * @param OrderNo : Order no
    * @returns
    */
    exportAssociatedTopUpOrderService = (OrderNo: string) => {
        this.exportDevicesState.inProgress = true;
        let url = URLConstants.Order + "/" + OrderNo + "/All/TopUp/Associated/Devices";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<any[]>>) => {
                if (response.data.Error) {
                    this.exportDevicesState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.exportDevicesState.success = true;
                    let data = response.data.Data ? response.data.Data : []
                    if(data.length > 0)
                    {
                        let iccidDataList = data.map((value:string)=>{return {ICCID: value}})
                        this.generateAndDownloadCSV(iccidDataList, "ToppedUpDevices_" + OrderNo);
                    }
                    else
                    toast.error(formatMessage("no_device_associated_with_order"));
                }
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }

            })
            .finally(action(() => { this.exportDevicesState.inProgress = false; }));
    }

    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.addUpdateOrderState.success = false;
        this.addUpdateOrderState.error = '';
        this.deleteOrderState = {...this.initialStateValue}
        this.addUpdateOrderState.inProgress = false;
        this.orderState = {...this.initialStateValue}
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.orderList = GetAllOrderState;

        this.addUpdateOrderState ={...this.initialStateValue }
        this.deleteOrderState ={...this.initialStateValue }
        this.addOrderPaymentState ={...this.initialStateValue }
        this.exportDevicesState ={...this.initialStateValue }
        
        this.allDeliveredOrders = [];
        this.allDeliveredOrderState ={...this.initialStateValue }
        
        this.allOrders = [];
        this.allOrderState ={...this.initialStateValue }
        this.orderStatusState ={...this.initialStateValue }
        this.order = undefined;
        this.orderState ={...this.initialStateValue }
        this.selectedOrderDescriptionId = -1;
        this.selectedTenantId = -1;

        this.orderFilter = {
            ...this.initialFilter
        }
    }

    /*
    This function is used to set the filter details to the order observable.  
    */
    setFilterDetail = (filter: IOrderFilter) => {
        this.orderFilter = {...filter};
    }
}

export default new OrderStore();
