/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   Developer: Aashish Singh, Created Date: 09-August-2024
</summary>
*/
enum actionModuleEnum{
    Device = 0,
    Configuration = 1,
    Group = 2,
    Tenant = 3,
    User = 4,
    Account = 5,
    SIM = 6,
    Order = 7,
    PricingPlan=8,
    RegisteredDevice=9,
    Fota=10
}

export default actionModuleEnum;