/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in unit data modules to provide default unit data.
   Developer: Aashish Singh, Created Date:19-Oct-2023
</summary>
*/

import moment from "moment";
import { MonthYearTuple } from "../models/response/IDeviceResponse";
import { IOption } from "../models/ICommon";
import monoZlogo from "../assets/Images/Lottie/monoZLink.json";
import protocolEnum from "./protocol-enum";
import Papa from "papaparse";

export const DATE_FORMAT= "YYYY-MM-DD";
export const DATE_TIME_FORMAT_WITH_MILLSECONDS= "YYYY-MM-DD HH:mm:ss.SS";
export const DATE_TIME_FORMAT= "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_FORMAT_FILENAME= "YYYY-MM-DD HH-mm-ss";

export const TRIAL_ACCOUNT_MAX_USERS_LIMIT = 5;
export const DEFAULT_TRIAL_ACCOUNT_MAX_DEVICES_LIMIT = 10000;

export const emptyValue='NA';
export const emptyValueHyphen='-';
export const defaultCountryCurrency = {id:2, symbol:"¥", placeholder:"Japanese Yen (¥)"};
export const infiniteDate = "0001/01/01 00:00:00";

export const integerRegex = /^(?:\d+|)$/;
export const floatRegex = /^(?:\d+(\.\d*)?|)$/;
export const readOnly = "Read Only";
export const resendMailTimer = 120;

export const getMonthName = (monthNumber:number) => {
  var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
 
   return mL[monthNumber-1]
 }

  export const getShortMonthName = (monthNumber:number) => {
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return mS[monthNumber-1];
}

  export const getPastMonths = (currentMonth: number, currentYear: number, numberOfMonths: number): MonthYearTuple[] => {
    const pastMonths: MonthYearTuple[] = [];
    for (let i = 1; i <= numberOfMonths; i++) {
      const pastMonth = (currentMonth - i + 12) % 12 || 12;
      const pastYear = currentMonth - i <= 0 ? currentYear - 1 : currentYear;      
      pastMonths.push([pastYear, pastMonth]);
    }
    return pastMonths.reverse();
  };

  export const getPastMonthsIncCurrent = (currentMonth: number, currentYear: number, numberOfMonths: number): MonthYearTuple[] => {
    const pastMonths: MonthYearTuple[] = [];
    for (let i = 1; i < numberOfMonths; i++) {
      const pastMonth = (currentMonth - i + 12) % 12 || 12;
      const pastYear = currentMonth - i <= 0 ? currentYear - 1 : currentYear; 
      if(i == 1) { pastMonths.push([currentYear,currentMonth]) }     
      pastMonths.push([pastYear, pastMonth]);
    }
    return pastMonths.reverse();
};


export const getYearsOptions = (noOfYears: number) => {
  let options: IOption[] = [{ id: -1, value: "please_select" }];
  for(let i=1; i < noOfYears; i++)
    options.push({id: i, value: `${i}_year`})
  return options;
}

export const getMonthOptions = (noOfMonths: number) => {
  let options: IOption[] = [{ id: -1, value: "please_select" }];
  for(let i=0; i < noOfMonths; i++)
    options.push({id: i, value: `${i}_month`})
  return options;
}

  export const getDaysInMonth =(month: number, year: number): number => {
    // Month is 0-indexed in JavaScript Date object (0 for January, 1 for February, etc.)
    const date = new Date(year, month, 0);
    return date.getDate();
}
  export const dailyPacketCountGraphDefaultLabel = () => {
    let label = ["Day1","Day2","Day3","Day4","Day5","Day6","Day7","Day8","Day9","Day10","Day11","Day12","Day13","Day14","Day15","Day16","Day17","Day18","Day19","Day20","Day21","Day22","Day23","Day24","Day25","Day26","Day27","Day28","Day29","Day30","Day31"]
    return label;
  }

  /**
   * This function is used to get all the dates from month start to the current date of current month. 
   */
  export const getPastDays = () => {
    const currentMonthDays = [];
    const currentDate = new Date().getDate();
    for (let i = currentDate-1; i >= 0; i--) {
      const pastDate = moment(new Date()).subtract(i,'day').format("YYYY-MM-DD")    
      currentMonthDays.push(pastDate);
    }
    return currentMonthDays;
  };

 export const getFormattedDateWithName = (monthNumber:number, yearNumber:number) => {
   const date = new Date();
   date.setMonth(monthNumber - 1);
   date.setFullYear(yearNumber);
 
   return moment(date).format("MMM,YYYY");
 }

 export const defaultCountryCurrencyId = () => {
  return 2;
 }

 export const base64ToArrayBuffer = (base64:any) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export const orderStatusClasses = ["warning", "primary", "info", "success", "success", "danger"];


export const roundWithPrecision = (num: number, precision: number) => {
  var multiplier = Math.pow(10, precision);
  return Math.round(num * multiplier) / multiplier;
}

export const orderStatusOptions: IOption[] = [
  { id: 0, value: "Pending" },
  { id: 1, value: "Processing" },
  { id: 2, value: "Shipped" },
  { id: 3, value: "Delivered" },
  { id: 4, value: "TopUpSuccessful" },
  { id: 5, value: "Cancelled" },
];

export const paymentStatusOptions: IOption[] = [
  { id: -1, value: "please_select" },
  { id: 0, value: "Pending" },
  { id: 1, value: "Partial" },
  { id: 2, value: "Paid" }
];

export const orderSearchByOptions: IOption[] = [
  { id: -1, value: "please_select" },
  { id: 1, value: "order_no" },
  { id: 2, value: "order_status" },
  { id: 3, value: "payment_status" }
];

export const deviceRegisterSearchByOptions: IOption[] = [
  { id: -1, value: "please_select" },
  { id: 1, value: "order_no" },
  { id: 2, value: "device_name" },
  { id: 3, value: "iccid" }
];

export const paymentOptions: IOption[] = [
  { id: 0, value: "Invoice", disabled:true }
];

export const fotaFileTypeOptions: IOption[] = [
  { id: 0, value: "Hex", disabled:false },
  { id: 1, value: "ASCII", disabled:false }
];

export const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: monoZlogo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const formatNumberWithCommas = (num: number) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2 // No decimal places
}).format(num);
}

export const getProtocol = (type: any) => {
  return protocolEnum[type];
};

export const downloadDeviceICCIDDummyCSVHandler = (title: string) => {
  const currentDate = new Date();
  const csv = Papa.unparse([{ICCID:"123456"},{}]);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download =  title + moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) + ".csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};


export const stringToByteArrayConversion = (data: string) =>{
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(data);
  return byteArray.length > 0 ? btoa(String.fromCharCode(...byteArray)) : "";
}