/*    
<summary>
   This functional common component provides modal popup that shows stop Alert confirmation message. 
   Developer:Mohammad Saquib Khan, Created Date: 20-Sept-2022
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { formatMessage } from "../../translations/format-message";
import Alert from "../../assets/Images/alert.svg";

interface Props {
  modalClosed: () => void;
  isLoading: boolean;
  title: string;
  message: string;
  name?: string;
  dialogClassName: string;
}

const InfoPopup: React.FC<Props> = (props) => {
  let message:string;
  if(props.name) 
    message = formatMessage(props.message).replace('{0}', props.name);
  else
    message = formatMessage(props.message)
  return (
    <Modal
      show={true}
      className="text-center"
      centered
      dialogClassName={props.dialogClassName}
      onHide={() => {}}
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title className="text-center">
          <FormattedMessage id={props.title} />
          <button
            onClick={props.modalClosed}
            disabled={props.isLoading}
            className="Crossicons"
            title={formatMessage("close")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bootbox-body">
            <img src={Alert} className="alert_popup_img"/>
            {message}</div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center">
        <Button
          variant="secondary"
          onClick={props.modalClosed}
          disabled={props.isLoading}
          className="btnOkSize"
        >
          <FormattedMessage id="close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoPopup;
