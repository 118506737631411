/*    
<summary>
   This class component is all about Managing AuditLogs data functionality.
   Developer:Aashish Singh, Created Date:22-Nov-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import moment from 'moment';
import config from '../../helpers/config-helper';
import { initialState as GetAllAuditLogState } from '../initial-state/get-all-audit-logs-state';
import { IAuditLogs, IAuditLogsCSV, IAuditLogsList, IGetAllAuditLogState, ILogsFilter } from '../../models/response/IAuditLogsResponse';
import userActionEnum from '../../constants/user-action-eum';
import actionModuleEnum from '../../constants/action-module-enum';
import { IAuditLogState } from '../../models/state/IAuditLogState';
import { emptyValue, infiniteDate } from '../../constants/common-constants';
import { getLanguageFromLocalStorage } from '../../helpers/local-stotrage-helper';

const appConfig = config();
const dateFormat =  appConfig.REACT_APP_DATE_FORMAT;
const dateTimeFormat =  appConfig.REACT_APP_DATE_TIME_FORMAT;

export class AuditLogStore implements IAuditLogState, ICommonState {
    inProgress = false;
    error = '';
    auditLogList: IGetAllAuditLogState = GetAllAuditLogState;
    initialStateValue = {
        success: false,
        error: '',
        inProgress: false
    }
    initialFilter: ILogsFilter = {
        StartDate: moment(new Date(), dateFormat).subtract(7,"days").format(dateFormat),
        EndDate: moment(new Date(), dateFormat).format(dateFormat),
        isFilterActive: false
    }
    logsFilter: ILogsFilter = {
        ...this.initialFilter
    }

    auditLog: IAuditLogs | undefined = undefined;
    auditLogState = { ...this.initialStateValue }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            auditLogList: observable,
            logsFilter: observable,
            auditLog:observable,
            auditLogState:observable,
            GetAuditLogListService: action,
            GetAuditLogDetailsService: action,
            setLogsFilterDetail: action,
            reset: action,
            resetGetAuditLogDetail: action,
            resetStore: action,
            allAuditLog: computed,
            exportAuditLogs: computed,
            auditLogDetail: computed
        });
    }

   /**
    * This function is used to Get AuditLogs list with paging details data from API.
    * @param pageNumber : Current Page Number
    * @param pageSize : Number of records in a page
    * @param filterDetails : Filter information 
    * @returns 
    */
    GetAuditLogListService = (pageNumber: number, pageSize: number, filterDetails: any) => {
        this.inProgress = true;
        const PagingDetails = {
            PageNo: pageNumber,
            PageSize: pageSize,
        }
        const requestBody = {
            StartDate: filterDetails.StartDate + " 00:00:00",
            EndDate: filterDetails.EndDate + " 23:59:59",
        }
        const url = URLConstants.AuditLog;
        return baseService.postRequest(url, { PagingDetails, ...requestBody })
            .then((response: IApiResponse<IApiSuccessResponse<IGetAllAuditLogState>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.auditLogList = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    /*
    This function is used to map auditLogList to allAuditLog List suitable for Grid Component.  
    */
    get allAuditLog(): IAuditLogsList[] {
        if (this.auditLogList?.Logs && this.auditLogList.Logs?.length > 0)
            return this.auditLogList.Logs.map((AuditLog:IAuditLogs) => {
                let language = getLanguageFromLocalStorage();
                return {
                    Id: AuditLog.Id,
                    User: AuditLog.User,
                    UserName: AuditLog.UserName ? AuditLog.UserName  : emptyValue,
                    Module: actionModuleEnum[AuditLog.Module],
                    Action: userActionEnum[AuditLog.Action],
                    ActionTimestamp: AuditLog.ActionTimestamp ? AuditLog.ActionTimestamp == infiniteDate ?  emptyValue : (moment(AuditLog.ActionTimestamp).format(dateTimeFormat))  
                        : emptyValue,
                    Remarks: AuditLog.Remarks,
                    Language: language
                }
            })
        return [];
    }

    /*
    This function is used to map auditLogList data to the data suitable for creating CSV.  
    */
    get exportAuditLogs(): IAuditLogsCSV[] {
        if (this.auditLogList?.Logs && this.auditLogList.Logs?.length > 0)
            return this.auditLogList.Logs.map((AuditLog:any) => {
                return {
                    UserName: AuditLog.UserName ? AuditLog.UserName  : emptyValue,
                    Module: actionModuleEnum[AuditLog.Module],
                    Action: userActionEnum[AuditLog.Action],
                    ActionTimestamp: AuditLog.ActionTimestamp ? AuditLog.ActionTimestamp == infiniteDate ?  emptyValue : (moment(AuditLog.ActionTimestamp).format(dateTimeFormat))  
                        : emptyValue,
                }
            })
        return [];
    }
    
    /*
    This function is used to Get audit log detail from API by providing id.  
    */
    GetAuditLogDetailsService = (id: number) => {
        this.auditLogState.inProgress = true;
        const url = URLConstants.AuditLogDetail + "/" + id;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IAuditLogs>>) => {
                if (response.data.Error) {
                    this.auditLogState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.auditLog = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                this.auditLogState.error = err;
            })
            .finally(action(() => { this.auditLogState.inProgress = false; }));
    }

    get auditLogDetail():any {
        if (this.auditLog) {
            const actionPerformed = this.auditLog.ActionPerformedOn ? this.auditLog.ActionPerformedOn.replaceAll(",",", ") : "";
            return {
                Id: this.auditLog.Id,
                UserName: this.auditLog?.UserName,
                UserEmail: this.auditLog?.UserEmail,
                Module: actionModuleEnum[this.auditLog.Module],
                Action: userActionEnum[this.auditLog.Action],
                ActionTimestamp: this.auditLog?.ActionTimestamp,
                ActionPerformedOn: actionPerformed,
                Remarks: this.auditLog?.Remarks,
                RemarksType: this.auditLog?.RemarksType,
            }
        }
        return undefined;
    }

    /**
     * This function is used to reset get auditLog observables to their initial values.
     * @returns
     */
    resetGetAuditLogDetail = () => {
        this.auditLog = undefined;
        this.auditLogState = { ...this.initialStateValue }
    }

    /*
    This function is used to reset all observables to their initial values.  
    */

    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.auditLogList = GetAllAuditLogState;
        this.logsFilter = {...this.initialFilter}
        this.auditLog = undefined;
        this.auditLogState = {...this.initialStateValue}
    }
    
    /*
    This function is used to set the filter details to the billingFilter observable.  
    */
    setLogsFilterDetail = (filter: ILogsFilter) => {
        this.logsFilter = {...filter};
    }
}

export default new AuditLogStore();
