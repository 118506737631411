import React, { useState } from "react";
import "./add-edit-order-form.css";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { observer } from "mobx-react";
import { IOrderList } from "../../../models/response/IOrderResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { Field, Form, Formik } from "formik";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { IOption } from "../../../models/ICommon";
import OrderStatusEnum from "../../../constants/order-status-enum";
import { orderStatusClasses, orderStatusOptions } from "../../../constants/common-constants";
import { formatMessage } from "../../../translations/format-message";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";

interface IProps {
  selectedData: IOrderList;
  initialValue: {StatusValue: number};
  submitHandler: (values: number) => void;
  onModalClosed: () => void;
  isLoading: boolean;
}

const UpdateOrderStatusForm = (props: IProps) => {
  const {selectedData, initialValue} = props;
  const [isError, SetIsError] = useState<boolean>(false);
  const [isConfirmButtonActive, setConfirmButtonActive] = useState<boolean>(true);

  const disableCopyPaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
  };

  const handleComfirmText = (event: any) => {
    if (event.target.value == "confirm") {
      setConfirmButtonActive(false);
    } else {
      setConfirmButtonActive(true);
    }
  };

  let orderStatus:IOption[] = [...orderStatusOptions];
  if(selectedData.OrderDescriptionType.Id == 3)
    orderStatus = orderStatusOptions.filter((status:IOption)=> status.id !== 2 && status.id !== 3);
  else 
    orderStatus = orderStatusOptions.filter((status:IOption)=> status.id !== 4);

  return (
    <>
      <Modal className="invoice-menu" dialogClassName="delete_popup" show={true} centered onHide={() => { }}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="update_order_status" />
            <button
              onClick={props.onModalClosed}
              disabled={false}
              className="Crossicons"
              title={formatMessage("close")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{StatusValue: initialValue.StatusValue}}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values:any) => {
              props.submitHandler(parseInt(values.StatusValue));   
          }}
        >
          {({ values }) => {
            return (
              <Form className="user-form">
                {props.isLoading &&
                  <FormLoader loading={props.isLoading} />}
                <Modal.Body>
                  <div className="form-group align-items-center d-flex justify-content-center">
                    <div className="text-center">
                      <span className={`alert ${orderStatusClasses[initialValue.StatusValue]} p-2`}>
                        {selectedData.OrderStatus}
                      </span>
                    </div>
                    <div className="mx-3 text-center">
                      <i className="fas fa-arrow-right text-muted"></i>
                    </div>


                    <div>
                      <Field
                        name="StatusValue"
                        as={FormikFormSelect}
                        options={orderStatus}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="align-items-start justify-content-center d-flex mt-3">
                      <label className="h">
                        <FormattedMessage id="is_confirm_prefix" /><br /> <b>
                          {<FormattedMessage id={selectedData.OrderStatus} />} </b> 
                          <FormattedMessage id="to" /> <b>
                          {<FormattedMessage id={OrderStatusEnum[values.StatusValue]} />}</b> ?
                      </label>
                    </div>
                    <div>
                        <input
                          type="text"
                          className="form-control text-center mx-auto w-50 mt-2"
                          placeholder="confirm"
                          onPaste={disableCopyPaste}
                          onCopy={disableCopyPaste}
                          onChange={handleComfirmText}
                        />
                      </div>
                      <p className="mt-2 small">
                        <FormattedMessage id={"update_order_confirmation"} />
                      </p>
                  </div>
                  {
                      isError &&
                      <span data-testid="testError" className="text-danger errorMessage">
                        <FormattedMessage id="field_required" />
                      </span>
                    }
                </Modal.Body>
                <div className="modal-footer pt-0 border-0">
                  <LoaderButton
                    type="submit"
                    disabled={isConfirmButtonActive}
                    isLoading={props.isLoading}
                    text="button_save"
                  />
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={props.onModalClosed}
                    disabled={props.isLoading}
                    className="ms-2"
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default observer(UpdateOrderStatusForm);