/*    
<summary>
   This functional component provides us functionalities like redirection to other modules, logout & User Profile.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useRef, useState } from "react";
import "./LeftNavBar.css";
import { NavLink, withRouter } from "react-router-dom";
import RoutesConstants from "../Routes/routes.constants";
import { FormattedMessage, injectIntl } from "react-intl";
import { formatMessage } from "../../translations/format-message";
import UserProfilePopupHOC from "../../modules/auth/hoc/UserProfilePopupHOC";
import UserProfilePopup from "../../modules/auth/UserProfilePopup";
import { useStore } from "../../contexts/StoreProvider";
import { observer } from "mobx-react";
import LogoutPopup from "../../modules/auth/components/Logout/LogoutPopup";
import LogoutHOC from "../../modules/auth/hoc/LogoutHOC";
import monoz_logo from "../../assets/Images/monoZ_Light-Grey_icon.png";
import monoz_logo_lg from "../../assets/Images/monoZ_Light-Grey.png";
import userTypeEnum from "../../constants/user-type-enum";
import dashboard from "../../assets/Images/svg/001-house.svg"
import configration from "../../assets/Images/svg/002-control-panel.svg"
import groupimg from "../../assets/Images/svg/003-hexagonal.svg"
import deviceimg from "../../assets/Images/svg/004-server.svg"
import userimg from "../../assets/Images/svg/005-user.svg"
import tenantimg from "../../assets/Images/svg/team.svg"
import registerDeviceIcon from "../../assets/Images/svg/check.svg"
import documentimg from "../../assets/Images/svg/006-project.svg"
import sun from "../../assets/Images/svg/007-sun.svg"
import auditLog from "../../assets/Images/svg/audit-icon.svg"
import moon from "../../assets/Images/svg/008-moon.svg"
import profileimg from "../../assets/Images/svg/009-user-1.svg"
import logoutimg from "../../assets/Images/svg/010-log-out.svg"
import priceList from "../../assets/Images/svg/tariff.svg";
import orderIcon from "../../assets/Images/svg/sim-card.svg";
import fotaIcon from "../../assets/Images/svg/fota.svg";
import { findRouteAccess } from "../../helpers/route-helper";
import systemModuleEnum from "../../constants/system-module-enum";
import { getLoggedInTenantIdFromLocalStorage, getUserTypeFromLocalStorage } from "../../helpers/local-stotrage-helper";
import pricingmenu from "../../assets/Images/svg/bar-chart.svg";

const LeftNavBar = (props: any) => {
  /* eslint-disable jsx-a11y/anchor-is-valid */
  let t: NodeJS.Timeout;
  const [darkTheme, setDarkTheme] = useState<boolean>(false);
  const { preferencesStore, authStore, tenantStore, oneNceStore } = useStore();
  const {getOneNceTokenService, oneNcetoken} = oneNceStore;
  const { resetGetTenantDetail, GetTenantService, dashboardTenantInfo } = tenantStore;
  const { getTenantId, getUserId, getIsTrialTenant } = authStore;
  const { setUpdateTheme, getLanguage } = preferencesStore;
  const themeToggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let theme = localStorage.getItem('mzl_darkTheme') == "false" ? "true" : "false";
    setUpdateTheme(theme);
    if (theme == "false") {
      setDarkTheme(false);
    } else {
      setDarkTheme(true);
    }
  };
  const myRef = useRef<any>();
  const { getVersionFromStore } = preferencesStore;

  const handleChange = () => {
    const div = document.getElementById("left-sidebarNav");
    const move = document.getElementById("moveContent");
    if (div) {
      if (!div.className.includes("closeNav")) {
        div.classList.add("closeNav");
        if (move) move.classList.remove("ShiftLeftSide");
      } else {
        div.classList.remove("closeNav");
        if (move) move.classList.add("ShiftLeftSide");
      }
    }
  };

  const handleChange2 = () => {
    const div = document.getElementById("left-sidebarNav");
    const move = document.getElementById("moveContent");
    if (div !== null) {
      div.classList.add("closeNav");
      if (move) move.classList.remove("ShiftLeftSide");
    }
  };

  const closeOnOutsideClick = () => {
    var div = document.getElementById("left-sidebarNav");
    const move = document.getElementById("moveContent");
    if (div) {
      if (!div.className.includes("closeNav")) {
        div.classList.add("closeNav");
        if (move) move.classList.remove("ShiftLeftSide");
      }
    }
  };

  const handleClickOutside = (e: any) => {
    if (!myRef.current.contains(e.target)) {
      closeOnOutsideClick();
    }
  };

  useEffect(() => {
    let theme = localStorage.getItem('mzl_darkTheme') ? localStorage.getItem('mzl_darkTheme') : "false";
    setUpdateTheme(theme!);
    if (theme == "false") setDarkTheme(false) 
    else setDarkTheme(true);

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      clearInterval(t);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  useEffect(() => {
    t = setInterval(() => getOneNceTokenService(), 3300000);
    return () => {
      clearInterval(t);
    };
  }, [oneNcetoken]);

  return (
    <React.Fragment>
      {props.showUserProfilePopup && (
        <UserProfilePopup
          userName={props.email}
          userType={props.userType}
          modalClosed={() => {
            resetGetTenantDetail();
            props.UserProfileToggleHandler();
          }}
        />
      )}
      {props.showLogoutPopup && (
        <LogoutPopup modalClosed={props.logoutPopupToggleHandler} />
      )}

      <aside id="left-sidebarNav" ref={myRef} className="closeNav">
        <button
          id="nav-toogle"
          data-testid="navToogle"
          className="menu-toggle"
          onClick={handleChange}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="nav-inner">
          <nav>
            <div className="sidebar-header">
              <div className="sidebar-logo position-relative">
                <img
                  src={monoz_logo_lg}
                  alt="logo"
                  className="openNav-logo img-fluid"
                ></img>
                <img
                  src={monoz_logo}
                  alt="logo"
                  className="closeNav-logo img-fluid"
                ></img>
                {/* {dashboardTenantInfo?.IsTrialTenant == true || (getIsTrialTenant === "true") ?  
                  <span className={ getLanguage == "jp" ? "trial badge bg-warning text-dark jpLang" : "trial badge bg-warning text-dark"}><FormattedMessage id="trial" /></span>
                :""} */}
              </div>
            </div>
            <ul className="list-unstyled">
            {
                findRouteAccess(systemModuleEnum.Tenant) &&
                  <li
                  data-testid="Tenant"
                  title={formatMessage("tenants")}
                  onClick={handleChange2}
                  >
                    <NavLink to={RoutesConstants.Tenants}>
                      <img src={tenantimg} alt="" />
                      <span className="nav-text">
                        {<FormattedMessage id="tenants" />}
                      </span>
                    </NavLink>
                  </li>
              }
              {
                findRouteAccess(systemModuleEnum.Dashboard) &&
                  <li
                    data-testid="Dashboard"
                    title={formatMessage("dashboard")}
                    onClick={handleChange2}
                  >
                    <NavLink to={RoutesConstants.Dashboard}>
                      <img src={dashboard} alt="" />
                      <span className="nav-text">
                        {<FormattedMessage id="dashboard" />}
                      </span>
                    </NavLink>
                  </li>
              }
              {
                findRouteAccess(systemModuleEnum.Configuration) &&
                  <li
                  data-testid="Configuration"
                  title={formatMessage("configurations")}
                  onClick={handleChange2}
                  >
                    <NavLink to={RoutesConstants.Configurations}>
                    <img src={configration} alt="" />
                      <span className="nav-text">
                        {<FormattedMessage id="configurations" />}
                      </span>
                    </NavLink>
                  </li>
              }
              {
                findRouteAccess(systemModuleEnum.Group) &&
                  <li
                  data-testid="Group"
                  title={formatMessage("groups")}
                  onClick={handleChange2}
                  >
                    <NavLink to={RoutesConstants.Group}>
                      <img src={groupimg} alt="" />
                      <span className="nav-text">
                        {<FormattedMessage id="groups" />}
                      </span>
                    </NavLink>
                  </li>
              }
              {
                findRouteAccess(systemModuleEnum.Device) &&
                  <li
                      data-testid="Device"
                      title={formatMessage("device_title")}
                      onClick={handleChange2}
                    >
                    <NavLink to={RoutesConstants.Device}>
                      <img src={deviceimg} alt="" />
                      <span className="nav-text">
                        {<FormattedMessage id="device_title" />}
                      </span>
                    </NavLink>
                  </li>
              }
              {
                (findRouteAccess(systemModuleEnum.DeviceRegisteration) && getUserTypeFromLocalStorage() === userTypeEnum.SystemUser) &&
                  <li
                      title={formatMessage("device_iccid_title")}
                      onClick={handleChange2}
                    >
                    <NavLink to={RoutesConstants.DeviceRegister}>
                      <img src={registerDeviceIcon} alt="" />
                      <span className="nav-text">
                        {<FormattedMessage id="device_iccid_title" />}
                      </span>
                    </NavLink>
                  </li>
              }
               {
                findRouteAccess(systemModuleEnum.Plan) &&
                <li title={formatMessage("pricing_plan_title")} onClick={handleChange2}>
                  <NavLink to={RoutesConstants.PricingPlan}>
                    <img src={priceList} alt="" />
                    <span className="nav-text">
                      {<FormattedMessage id="pricing_plan_title" />}
                    </span>
                  </NavLink>
                </li>
              }
              {
                ( findRouteAccess(systemModuleEnum.Order) && getUserTypeFromLocalStorage() == userTypeEnum.SystemUser) &&
                <li title={formatMessage("order_title")} onClick={handleChange2}>
                  <NavLink to={RoutesConstants.Order}>
                    <img src={orderIcon} alt="" />
                    <span className="nav-text">
                      {<FormattedMessage id="order_title" />}
                    </span>
                  </NavLink>
                </li> 
              }
              {
                findRouteAccess(systemModuleEnum.Statistics) &&
                <li title={formatMessage("pricing_menu_title")} onClick={handleChange2}>
                  <NavLink to={RoutesConstants.PricingMenu}>
                    <img src={pricingmenu} alt="" />
                    <span className="nav-text">
                      {<FormattedMessage id="pricing_menu_title" />}
                    </span>
                  </NavLink>
                </li>
              }
              {
                findRouteAccess(systemModuleEnum.User) &&
                <li title={formatMessage("user_title")} onClick={handleChange2}>
                  <NavLink to={RoutesConstants.User}>
                    <img src={userimg} alt="" />
                    <span className="nav-text">
                      {<FormattedMessage id="user_title" />}
                    </span>
                  </NavLink>
                </li> 
              }
              {/* {
                findRouteAccess(systemModuleEnum.Fota) &&
                <li title={formatMessage("fota_title")} onClick={handleChange2}>
                  <NavLink to={RoutesConstants.Fota}>
                    <img src={fotaIcon} alt="" /> 
                    <span className="nav-text">
                      {<FormattedMessage id="fota_title" />}
                    </span>
                  </NavLink>
                </li>
              } */}
              {
                findRouteAccess(systemModuleEnum.AuditLog) &&
                <li title={formatMessage("audit_log_title")} onClick={handleChange2}>
                  <NavLink to={RoutesConstants.AuditLog}>
                    <img src={auditLog} alt="" /> 
                    <span className="nav-text">
                      {<FormattedMessage id="audit_log_title" />}
                    </span>
                  </NavLink>
                </li>
              }
                              
            </ul>
          </nav>
        </div>

        <div className="aside-footer">
          <ul>
          <li title={formatMessage("documentation_title")} onClick={handleChange2}>
                <NavLink to={RoutesConstants.Documentation}>
                <img src={documentimg} alt="" data-testid="LightTheme" />
                  <span className="nav-text">
                    <FormattedMessage id="documentation_title" />
                  </span>
                </NavLink>
              </li>
              
            <li className="toggler" title={formatMessage("theme")}>
              <label className="d-flex">
                <input
                  type="checkbox"
                  data-testid="DarkLightTheme"
                  className="toggle-checkbox"
                  checked={darkTheme}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    themeToggleHandler(e);
                  }}
                />
                <div className="toggle-slot">
                  <div className="sun-icon-wrapper">
                    <img src={sun} alt="" data-testid="LightTheme" />
                  </div>

                  <div className="moon-icon-wrapper">
                    <img src={moon} alt="" data-testid="DarkTheme" />
                  </div>
                </div>
                <span className="nav-text mt-2 me-2">
                  {<FormattedMessage id="theme" />}
                </span>
              </label>
              
            </li>

            <li
              data-testid="UserProfile"
              title={formatMessage("user_profile")}
              onClick={() => {
                GetTenantService(getLoggedInTenantIdFromLocalStorage(), getUserId);
                props.UserProfileToggleHandler();
                handleChange2();
              }}
            >
              <img src={profileimg} alt="" />
              <span className="nav-text ms-1">
                <FormattedMessage id="user_profile" />
              </span>
            </li>
            <li
              data-testid="Logout"
              title={formatMessage("logout")}
              className="logout"
              onClick={props.logoutPopupToggleHandler}
            >
              <img src={logoutimg} alt="" />
              <span className="nav-text">
                <FormattedMessage id="logout" />
              </span>
            </li>
          </ul>
          {getVersionFromStore !== null && (
            <p className="version">{`Version ${getVersionFromStore}`}</p>
          )}
        </div>
      </aside>
    </React.Fragment>
  );
};

export default withRouter(
  UserProfilePopupHOC(LogoutHOC(injectIntl(observer(LeftNavBar))))
);
