/*    
<summary>
  This functional component "SelectDefaultGroupForm" provides form to select default group from all available groups.
  Developer:Aashish Singhn, Created Date: 11-December2023.
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { Button, Modal } from "react-bootstrap";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { changeGroupValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/Label/Label";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  initialValues: any;
  groupList: any;
  submitHandler: (data: any) => void;
  isLoading: boolean;
  modalClosed: () => void;
}

const SelectDefaultGroupForm = (props: IProps) => {

  const { initialValues, submitHandler, groupList, isLoading, modalClosed } = props;

  return (
    <>
      <Modal
        className="add-user-modal correction-modal fancy-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="update_default_group" />
          </Modal.Title>
          <button
            onClick={props.modalClosed}
            data-testid="Close"
            className="Crossicons"
            title={formatMessage("close")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={changeGroupValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            submitHandler(values.GroupId);
          }}
          render={({ values }) => {
            return (
              <Form id="formBody">
                {isLoading && <FormLoader loading={isLoading} />}
                <div className="row p-4">
                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="available_group"
                      />
                      <Field
                        id="Groups"
                        className="form-control"
                        name="GroupId"
                        options={groupList}
                        as={FormikFormSelect}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer border-0 pt-0">
                  <LoaderButton
                    type="submit"
                    id="SaveDevice"
                    isLoading={isLoading}
                    text="submit"
                  />
                  <Button
                    data-testid="Cancel"
                    variant="secondary"
                    onClick={modalClosed}
                    disabled={isLoading}
                    className=""
                  >
                    <FormattedMessage id="close" />
                  </Button>
                </div>
              </Form>
            );
          }}
        ></Formik>
      </Modal>
    </>
  );
};

export default observer(SelectDefaultGroupForm);
