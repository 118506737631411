/*    
<summary>
  This functional component "AddPricingPlanForm" provides form to add new pricingPlan or update existing pricingPlan.
  Developer:Aashish Singh, Created Date:17-May-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { Form, Formik, Field } from "formik";
import "./add-edit-pricing-plan-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import {ICountryCurrencyDetail, ICountryCurrencyOption, IOption} from "../../../models/ICommon";
import { IAddUpdatePricingPlan } from "../../../models/Form/IAddUpdatePricingPlan";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import Label from "../../../shared-components/Label/Label";
import { pricingPlanValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { floatRegex, getMonthOptions, getYearsOptions, integerRegex } from "../../../constants/common-constants";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/FormikFormInputForRegexCheck";
import { Tooltip } from "react-tooltip";
import { getThemeFromLocalStorage } from "../../../helpers/local-stotrage-helper";

interface IProps {
  id: number;
  countryCurrencyOptions: ICountryCurrencyOption[];
  typeOptions: IOption[];
  submitHandler: (data: IAddUpdatePricingPlan) => void;
  onModalClosed: () => void;
  getPricingPlanLoading: boolean;
  addUpdatePricingPlanLoading: boolean;
  initialValues: IAddUpdatePricingPlan;
  currencyDetail: ICountryCurrencyDetail;
}

const AddPricingPlanForm = (props: IProps) => {
  const [selectedCurrencySymbol, SetSelectedCurrencySymbol] = useState<string>(JSON.stringify(props.currencyDetail));
  const [selectedCurrencyOption, SetSelectedCurrencyOption] = useState<any>(props.currencyDetail);
  
  useEffect(()=>{
    SetSelectedCurrencySymbol(JSON.stringify(props.currencyDetail))
    SetSelectedCurrencyOption(props.currencyDetail)
  },[props.currencyDetail]);

  return (
    <>
      <Modal className="fancy-modal" size="lg" show={true} centered onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>
            {props.id === -1 ? (
              <FormattedMessage id="add_pricing_plan" />
            ) : (
              <FormattedMessage id="edit_pricing_plan" />
            )}
            <button
              onClick={props.onModalClosed}
              disabled={props.addUpdatePricingPlanLoading}
              className="Crossicons"
              title={formatMessage("close")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={pricingPlanValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            let tempValues:any = {...values};
            tempValues.CountryCurrencyId = selectedCurrencyOption.id;
            if (values.OrderDescriptionType.Id == 3 ) {
              tempValues.MonthlyDataOperations = 0;
              tempValues.MaxNoOfDevicesSupport = 0;
              tempValues.YearlyPlanExpiryPeriod = 0;
              tempValues.MonthlyInventoryPeriod = 0;
              tempValues.ExcessDataSurcharge = 0;
            }
            props.submitHandler({...tempValues});
          }}
        >
          {({values}) => {
            return (
              <Form className="formBody">
                {props.getPricingPlanLoading ? (
                  <FormLoader loading={props.getPricingPlanLoading} />
                ) : null}
                <Modal.Body>
                <div className="row">
                  <div className="form-group col-md-6">
                    <Label
                        className="label-style col-form-label"
                        required={true}
                        label="order_description_type"
                      />
                      <Field
                        name="OrderDescriptionType.Id"
                        options={props.typeOptions}
                        as={FormikFormSelect}
                      />
                  </div>
                  <div className="form-group col-md-6">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="plan_name"
                      />
                      <Field
                        data-testid="testPlanName"
                        className="form-control"
                        name="PlanName"
                        type="text"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        placeholder="plan_name"
                        as={FormikFormInput}
                      />
                  </div>
                  {
                    (values.OrderDescriptionType.Id != 3) &&
                    <>
                      <div className="form-group col-md-6 col-lg-4">
                        <Label
                          className="label-style col-form-label"
                          required={true}
                          label="no_of_devices_support"
                        />
                        <Field
                          data-testid="dataMaxNoOfDevicesSupport"
                          className="form-control"
                          name="MaxNoOfDevicesSupport"
                          type="text"
                          placeholder="no_of_devices_support"
                          regex={integerRegex}
                          as={FormikFormInputForRegexCheck}
                        />
                      </div>
                      <div className="form-group col-md-6 col-lg-4">
                        <Label
                          className="label-style col-form-label"
                          required={true}
                          label="data_operations"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-info-circle ms-2"
                          viewBox="0 0 16 16"
                          data-tooltip-id="my-tooltip-org-id"
                          data-tooltip-html={formatMessage("data_operations_form_label")}
                          data-tooltip-variant="dark"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <Tooltip id="my-tooltip-org-id" place="top" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                        <Field
                          data-testid="dataOperations"
                          className="form-control"
                          name="MonthlyDataOperations"
                          type="text"
                          placeholder="data_operations"
                          regex={integerRegex}
                          as={FormikFormInputForRegexCheck}
                        />
                      </div>
                      <div className="form-group col-md-6 col-lg-4">
                        <Label
                          className="label-style col-form-label"
                          required={true}
                          label="life_time"
                        />
                        <Field
                          name="YearlyPlanExpiryPeriod"
                          options={getYearsOptions(10)}
                          as={FormikFormSelect}
                        />
                      </div>
                      <div className="form-group col-md-6 col-lg-4">
                        <Label
                          className="label-style col-form-label"
                          required={true}
                          label="inventory_period"
                        />
                        <Field
                          name="MonthlyInventoryPeriod"
                          options={getMonthOptions(12)}
                          as={FormikFormSelect}
                        />
                      </div>
                    </>
                  }
                  <div className="form-group col-md-6 col-lg-4">
                    <Label
                      className="label-style col-form-label"
                      label="currency"
                    />
                    <select value={selectedCurrencySymbol}
                      className="form-control"
                      onChange={(event)=>{
                        let selectedData = JSON.parse(event.target.value)
                        SetSelectedCurrencySymbol(event.target.value);
                        SetSelectedCurrencyOption(selectedData)
                        }}>
                        {props.countryCurrencyOptions.map((data: any, i: number) => (
                          <option key={data.id} value={data.data} disabled={data.disabled}>
                            {data.placeholder}
                          </option>
                        ))}
                      </select>
                  </div>
                  <div className="form-group col-md-6 col-lg-4">
                    <Label
                      className="label-style col-form-label"
                      required={true}
                      label="plan_price"
                    />
                    <Field
                      data-testid="planPrice"
                      className="form-control"
                      name="PlanPrice"
                      type="text"
                      placeholder="plan_price"
                      regex={floatRegex}
                      as={FormikFormInputForRegexCheck}
                    />
                  </div>
                  {
                    (values.OrderDescriptionType.Id != 3) &&
                    <div className="form-group col-md-6 col-lg-4">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="unit_price"
                      />
                      <Field
                        data-testid="unitPrice"
                        className="form-control"
                        name="ExcessDataSurcharge"
                        type="text"
                        placeholder="unit_price"
                        regex={floatRegex}
                        as={FormikFormInputForRegexCheck}
                      />
                    </div>
                  }
                  <div className="form-group col-md-6 col-lg-4">
                    <Label
                      className="label-style col-form-label"
                      required={true}
                      label="tax_heading"
                    />
                    <Field
                      data-testid="tax"
                      className="form-control"
                      name="Tax"
                      type="text"
                      placeholder="tax"
                      regex={floatRegex}
                      as={FormikFormInputForRegexCheck}
                    />
                  </div>
                </div>
                
                </Modal.Body>
                <div className="modal-footer pt-0 border-0">
                  <div className="form-group row g-0 tab-action">
                    <div className="col-12">
                      <LoaderButton
                        type="submit"
                        isLoading={props.addUpdatePricingPlanLoading}
                        text={props.id > 0 ? "update" :"button_save"}
                      />
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.addUpdatePricingPlanLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddPricingPlanForm;
