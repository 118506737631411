/*    
<summary>
   This file defines axios interceptors have request handler and error handler. 
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
*/
import axios, { AxiosResponse } from 'axios';
import { errorMessage } from '../../constants/error.constants'; 
import config from '../../helpers/config-helper';
import RoutesConstants from '../../shared-components/Routes/routes.constants'; 
import { AuthStore } from '../stores/auth-store';

const appConfig = config();
export const baseURL =  appConfig.REACT_APP_API_URL;
export const baseAPI = axios.create({ baseURL });
const requestHandler = (request: any) => {
    const token = new AuthStore().getToken;
    request.headers['authorization'] = 'Bearer ' + token;
    return request;
};

const errorHandler = async (error: any) => {
    let message: string = errorMessage.ErrorOccured; 
    if (error.response) {
        if (error.response.status === 401 && 
            error.response.data.Message != errorMessage.InvalidEmailOrPassword && 
            error.response.data.Message != errorMessage.YourAccountInactive && 
            error.response.data.Message != errorMessage.UserEmailNotFound && 
            error.response.data.Message != errorMessage.YourOrgaizationAccountInactive &&
            error.response.data.Message != errorMessage.YourAccountInactiveByOrganization
        ) {
            message = errorMessage.Unauthorized;
            const lang:any = localStorage.getItem('mzl_language');
            localStorage.clear();
            localStorage.setItem('mzl_language', lang === "jp" ? "jp" : "en");
            setTimeout(()=>{window.location.href = RoutesConstants.Login},2000);
            return Promise.reject(message);
        } else if (error.response.status === 403) {
            message = errorMessage.Forbidden;
        } 
        else if (error.response.data && error.response.data?.Message) {
            message = error.response.data.Message
        }
    }
    return Promise.reject(message);
};

const successHandler = (response: AxiosResponse): AxiosResponse => {
    return response;
};

baseAPI.interceptors.request.use(request => requestHandler(request));
baseAPI.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);
