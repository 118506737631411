import systemModuleEnum from "../constants/system-module-enum";
import roleTypeEnum from "../constants/role-type-enum";
import userTypeEnum from "../constants/user-type-enum";
import authStore from "../core/stores/auth-store";
import { IPermissions } from "../models/response/IRoleResponse";
import RoutesConstants from "../shared-components/Routes/routes.constants";
import { getRoleTypeFromLocalStorage, getTokenFromLocalStorage, getUserTypeFromLocalStorage } from "./local-stotrage-helper";

export const findRouteAccess = (module: number) => {
    let userPermission:any = authStore.getAllPermission;
    if(JSON.stringify(userPermission) === "[]") {
      const permissions = localStorage.getItem('mzl_permissions'); 
      if(permissions)
          userPermission = JSON.parse(permissions);
    }
    let modulePermission = userPermission.filter((per:IPermissions)=> per.Module == module);
    if(modulePermission.length === 1 && modulePermission[0].Module == systemModuleEnum.Plan)
        return (modulePermission[0].View && modulePermission[0].Add);
    else if(modulePermission.length === 1)
        return (modulePermission[0].View || modulePermission[0].Add || modulePermission[0].Edit || modulePermission[0].Delete);
    return false;
}

export const redirectToDefaultRoute = () => {
    let roleType = authStore.userType ? authStore.userType : localStorage.getItem('mzl_role_type');
    if(roleType)
        if(getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser && getRoleTypeFromLocalStorage() == roleTypeEnum.SystemAdmin )
            return RoutesConstants.Tenants;
        else if(getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser && getRoleTypeFromLocalStorage() == roleTypeEnum.TenantSupport )
            return RoutesConstants.DeviceRegister;
        else if(getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser && getRoleTypeFromLocalStorage() == roleTypeEnum.SystemAccounts )
            return RoutesConstants.Order;
    else return RoutesConstants.Documentation;
    else if(getTokenFromLocalStorage() === "")
        return RoutesConstants.Login;
    else
        return RoutesConstants.Login;
}
  