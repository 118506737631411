/*    
<summary>
   This component is all about Managing DeviceRegister & performing CRUD operations on the users.
   CRUD (Create, Read, Update, Delete)
   Developer: Aashish Singh, Created Date:11-June-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import "./device-register.css";
import { observer } from "mobx-react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Grid from "../../shared-components/Grid/Grid";
import { useStore } from "../../contexts/StoreProvider";
import AddDeviceRegister from "./components/add-edit-registered-device";
import Pagination from "../../shared-components/Grid/Pagination";
import IPageInfo, { IActionFormatter, IOption, IPageLimitInfo } from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { faTrashAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import ConfirmPopup from "../../shared-components/confirmationPopup/confirm-popup";
import DeletePopupHOC from "../../shared-components/confirmationPopup/hoc/confirmation-popup-hoc";
import {
  DeviceActionFormatter,
} from "../../shared-components/Grid/GridFormatter";
import SlidingPanel from "react-sliding-side-panel";
import config from "../../helpers/config-helper";
import no_device from "../../assets/Images/svg/no-device.svg";
import {
  IDeviceRegisterFilterDetails,
} from "../../models/response/IRegisterDeviceResponse";
import _ from "lodash";
import userSettingEnum from "../../constants/user-setting-enum";
import LoaderIconButton from "../../shared-components/Button/LoaderIconButton";
import systemModuleEnum from "../../constants/system-module-enum";
import { isOperationPermitted, showIcons } from "../../helpers/action-permission-helper";
import { getRoleTypeFromLocalStorage, getTenantIdFromLocalStorage, getUserTypeFromLocalStorage } from "../../helpers/local-stotrage-helper";
import roleTypeEnum from "../../constants/role-type-enum";
import SelectGroupPopupHOC from "../device/hoc/SelectGroupPopupHOC";
import PublishMessagePopupHOC from "../device/hoc/PublishMessagePopupHOC";
import ChooseGroupPopupHOC from "../device/hoc/ChooseGroupPopupHOC";
import AllDeviceDeletePopupHOC from "../device/hoc/all-device-delete-popup-hoc";
import SelectedDeviceDeletePopupHOC from "../device/hoc/selected-device-delete-popup-hoc";
import AddDevicePopupHOC from "../device/hoc/AddDevicePopupHOC";
import userTypeEnum from "../../constants/user-type-enum";
import AddDeviceRegisterByCsv from "./components/register-device-csv"
import MoveDeviceRegisterToGroup from "./components/move-device-iccid-to-groups"
import { useHistory } from "react-router-dom";
import FilterPopupHOC from "../../shared-components/popup/filter/filter-popup-hoc";
import DeviceRegisterFilter from "./components/device-register-filter";
import DeviceRegisterDetails from "./components/device-register-detail";

const DeviceRegister = (props: any) => {
  const { preferencesStore, deviceRegisterStore, oneNceStore,tenantStore } =
    useStore();
  const {
    userSetting,
    activeGroupId,
    getAddBulkCount,
    setUserSetting,
    islanguageChanged, 
    updateLanguageChangedFlag,
  } = preferencesStore;
  const {
    getAllDeviceRegister,
    deleteAllDeviceRegister,
    deleteSelectedDeviceRegisterState,
    deleteSelectedDeviceRegister,
    deviceRegisteredDataList,
    inProgress,
    allDeviceRegisterlist,
    addUpdateDeviceRegisterState,
    deleteDeviceRegisterState,
    deleteAllDeviceRegisterState,
    error,
    reset,
    deleteDeviceRegister,
    moveDeviceRegisterState,
    resetMoveDeviceRegisterToGroups,
    addBulkDeviceRegisterState,
    resetAddBulkDeviceRegisterState,
    resetDeleteFilteredDeviceRegister,
    deleteFilteredDeviceRegister,
    deleteFilteredDeviceRegisterState,
    addDeviceRegisterCSVState,
    filter,
    setFilterDetail,
    initialFilter,
    resetStore,
    resetGetRegisterDeviceDetail
  } = deviceRegisterStore;
  const { GetAllTenantsService, resetAllTenantList, allTenantOptions, allTenants, selectedTenantId, setSelectedTenantId } = tenantStore;
  let lang: string;
  let localStorageUserType: number = getUserTypeFromLocalStorage();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [openPanel, setOpenPanel] = useState(false);
  const [updateDeviceRegisterObj, setupdateDeviceRegisterObj] = useState<any>({});
  const [groupId, setGroupId] = useState<number>(activeGroupId);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  // console.log(">>>>>Sss", allDeviceRegisterlist);
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.deviceLimitConfig,
    isPageLimitChange: false,
  });

  const [singleSelectedDeviceRegister, setSingleSelectedDeviceRegister] = useState<
    Array<number>
  >([]);
  const [selectedDeviceRegister, setSelectedDeviceRegister] = useState<Array<any>>([]);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const history = useHistory();
  /**
   * This function calls the "getAllDeviceRegister" store funtion that get device list from API
   */
  const callGetAllDeviceRegisterService = () => {
    if(localStorageUserType === userTypeEnum.SystemUser){
      if (selectedTenantId > 0 && allTenants.length > 0) {
        let obj = {
          TenantId: selectedTenantId,
          Filter: filter,
          PagingDetails: {
            PageNo: pageInfo.currentPage,
            PageSize: pageLimitInfo.pageLimit,
          },
        };
        getAllDeviceRegister(obj);
      }
      if(selectedTenantId === -1)
        setPageInfo({ ...pageInfo, isRefreshPage: false });
    }
    else{
      let default_obj = {
        TenantId: getTenantIdFromLocalStorage(),
        Filter: filter,
        PagingDetails: {
          PageNo: pageInfo.currentPage,
          PageSize: pageLimitInfo.pageLimit,
        },
      };
      getAllDeviceRegister(default_obj);
    }
  };

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.DeviceRegisterLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function shows the add device pop up from where we can add new device and update existing device. we use id to identify
   * that we are adding or updating device.
   * "Id == -1" : we are adding new device
   * "Id != -1" : means we are updating existing device
   */
  const openAddDeviceRegisterHandler = (row: { Id: number }, event?: any) => {
    event.stopPropagation();
    closePanelHandler();
    setSelectedId(row.Id);
    props.addDeviceToggleHandler();
  };

  /**
   * This function deletes the device by providing Id to the Api
   */
  const deleteHandler = () => {
    closePanelHandler();
    deleteDeviceRegister(selectedId);
  };

  /**
   * This function shows the device data popup component and sets the seleted device Id
   */
  const dataHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    // props.deletePopupToggleHandler();
  };

  /**
   * This function shows the delete device confirmation popup component and sets the seleted device Id
   */
  const deleteClickHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    closePanelHandler();
    setSelectedId(row.Id);
    props.confirmToggleHandler();
  };

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetAllDeviceRegisterService();
  };

  useEffect(() => {
    if (
      getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser
    )
      GetAllTenantsService(systemModuleEnum.DeviceRegisteration);
      return(()=>{
        resetAllTenantList();
        resetStore();
      })
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "filter" of the useEffect changed.
   */
  useEffect(() => {
    if (selectedTenantId > 0) {
      callGetAllDeviceRegisterService();
    }
    else{
      callGetAllDeviceRegisterService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenantId, filter, allTenants]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {

      callGetAllDeviceRegisterService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deviceRegisteredDataList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: deviceRegisteredDataList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceRegisteredDataList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetAllDeviceRegisterService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateDeviceRegisterState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateDeviceRegisterState.success) {

      callGetAllDeviceRegisterService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateDeviceRegisterState.success]);

  useEffect(() => {
    if (addDeviceRegisterCSVState.success) {

      callGetAllDeviceRegisterService();
    }
  }, [addDeviceRegisterCSVState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteDeviceRegisterState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteDeviceRegisterState.success) {

      toast.success(formatMessage("deleted_success"));
      reset();
      props.confirmToggleHandler();
      callGetAllDeviceRegisterService();
      setSelectedDeviceRegister([]);
      setSingleSelectedDeviceRegister([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDeviceRegisterState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addBulkDeviceRegisterState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addBulkDeviceRegisterState.success) {
      resetAddBulkDeviceRegisterState();
      callGetAllDeviceRegisterService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBulkDeviceRegisterState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteFilteredDeviceRegisterState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteFilteredDeviceRegisterState.success) {

      toast.success(formatMessage("deleted_success"));
      resetDeleteFilteredDeviceRegister();
      callGetAllDeviceRegisterService();
      // setSelectedDeviceRegister([]);
      // setSingleSelectedDeviceRegister([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFilteredDeviceRegisterState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteAllDeviceRegisterState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteAllDeviceRegisterState.success) {

      toast.success(formatMessage("deleted_success"));
      reset();
      props.allDeviceDeleteToggleHandler();
      callGetAllDeviceRegisterService();
      // setSelectedDeviceRegister([]);
      // setSingleSelectedDeviceRegister([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAllDeviceRegisterState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteSelectedDeviceRegisterState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteSelectedDeviceRegisterState.success) {

      toast.success(formatMessage("deleted_success"));
      reset();
      props.selectedDeviceDeleteToggleHandler();
      if ((selectedDeviceRegister.length >= pageLimitInfo.pageLimit) || (pageInfo.currentPage == pageInfo.totalPages && selectedDeviceRegister.length > allDeviceRegisterlist.length)) {
        setPageInfo({
          ...pageInfo,
          currentPage: 1,
          isPagerChange: true,
          isRefreshPage: true,
        });
      } else {
        callGetAllDeviceRegisterService();
      }
      setSelectedDeviceRegister([]);
      setSingleSelectedDeviceRegister([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSelectedDeviceRegisterState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteDeviceRegisterState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteDeviceRegisterState.error) {
      toast.error(formatMessage(deleteDeviceRegisterState.error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDeviceRegisterState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteAllDeviceRegisterState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteAllDeviceRegisterState.error) {
      toast.error(formatMessage(deleteAllDeviceRegisterState.error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAllDeviceRegisterState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteSelectedDeviceRegisterState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteSelectedDeviceRegisterState.error) {
      toast.error(formatMessage(deleteSelectedDeviceRegisterState.error));
      setSingleSelectedDeviceRegister([]);
      setSelectedDeviceRegister([]);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSelectedDeviceRegisterState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "islanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetAllDeviceRegisterService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "moveDeviceRegisterState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (moveDeviceRegisterState.success) {
      if ((selectedDeviceRegister.length >= pageLimitInfo.pageLimit) || (pageInfo.currentPage == pageInfo.totalPages && selectedDeviceRegister.length > allDeviceRegisterlist.length)) {
        if(pageInfo.currentPage != 1){
          setPageInfo({
            ...pageInfo,
            currentPage: 1,
            isPagerChange: true,
            isRefreshPage: true,
          });
        }
        else
        callGetAllDeviceRegisterService();
      } else {
        callGetAllDeviceRegisterService();
      }
      setSingleSelectedDeviceRegister([]);
      setSelectedDeviceRegister([]);
      resetMoveDeviceRegisterToGroups();
      // callGetAllDeviceRegisterService();
    }
  }, [moveDeviceRegisterState.success]);

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedId(row.Id);
    setSelectedRow(row);
    setOpenPanel(true);
  };

  // const closePanelhandler = () => {
  //   resetGetRegisterDeviceDetail();
  //   setOpenPanel(false);
  //   setSelectedId(0);
  //   setSelectedRow({});
  //   // tenantStore.reset();
  // };

  function handleRowSelect(row: any, isSelect: any, rowIndex: any, e: any) {
    if (isSelect) {
      setSingleSelectedDeviceRegister((prevState) => {
        const array: any = [...prevState];
        array.push(row.Id);
        return array;
      });

      setSelectedDeviceRegister((prevState) => {
        const array: any = [...prevState];
        var obj = {
          Id: row.Id,
          Name: row.Name,
          ICCID: row.ICCID,
          OrderNo: row.OrderNo,
          CreatedDate: row.CreatedDate
        };
        array.push(obj);
        return array;
      });
    } else {
      setSingleSelectedDeviceRegister((prevState) => {
        const array: any = [...prevState];
        let index = array.findIndex((item: any) => item === row.Id);
        if (index > -1) array.splice(index, 1);
        return array;
      });
      setSelectedDeviceRegister((prevState) => {
        const array: any = [...prevState];
        let index = array.findIndex((item: any) => item.ICCID === row.ICCID);
        if (index > -1) array.splice(index, 1);
        return array;
      });
    }
  }

  function handleSelectAll(isSelect: any, rows: any, e: any) {
    if (isSelect) {
      setSingleSelectedDeviceRegister((prevState) => {
        const array: any = [...prevState];
        rows.forEach((row: any) => {
          array.push(row.Id);
        });
        return array;
      });

      setSelectedDeviceRegister((prevState) => {
        const array: any = [...prevState];
        rows.forEach((row: any) => {
          var obj = {
            Id: row.Id,
            Name: row.Name,
            ICCID: row.ICCID,
            OrderNo: row.OrderNo,
            CreatedDate: row.CreatedDate
          };
          array.push(obj);
        });
        return array;
      });
    } else {
      setSingleSelectedDeviceRegister([]);
      setSelectedDeviceRegister([]);
    }
  }

  const selectRow = {
    mode: "checkbox",
    selected: singleSelectedDeviceRegister,
    onSelect: handleRowSelect,
    onSelectAll: handleSelectAll,
  };
  
  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const openFilterPopUpHandler = () => {
    props.filterToggleHandler();
  };

  const closeFilterPopupHandler = () => {
    props.filterToggleHandler();
  };

  const filterSubmitHandler = (values: IDeviceRegisterFilterDetails) => {
    closeFilterPopupHandler();
    setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage: true });
    // setFilter({ ...values, isFilterActive: true });
    setFilterDetail({...values, isFilterActive: true});
    // closePanelhandler();
  };

  const resetFilter = () => {
    // closePanelhandler();
    setFilterDetail({...initialFilter});
    setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage: true });
  };

  const actionIcons: IActionFormatter[] = showIcons([
    {
      dataField: "isEdit",
      handler: openAddDeviceRegisterHandler,
      icon: faPencilAlt,
      isDisable: true,
      title: "edit",
      action: "edit",
      varient: "primary",
    },
    {
      dataField: "isDelete",
      handler: deleteClickHandler,
      icon: faTrashAlt,
      isDisable: true,
      title: "delete",
      action: "delete",
      varient: "danger",
    },
  ], systemModuleEnum.DeviceRegisteration)
  /**
   * The column constant defines the column description for the user grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("id"),
      hidden: true,
    },
    {
      dataField: "Name",
      text: formatMessage("device_name"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
    },
    {
      dataField: "TenantName",
      text: formatMessage("tenant_name"),
      hidden:
        selectedTenantId == -1 &&
        localStorageUserType ==  userTypeEnum.SystemUser
          ? false
          : true,
    },
    {
      dataField: "ICCID",
      text: formatMessage("iccid"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
    },
    {
      dataField: "OrderNo",
      text: formatMessage("order_no"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
    },
    {
      dataField: "",
      formatter: DeviceActionFormatter(actionIcons),
      text: formatMessage("actions"),
      hidden: (localStorageUserType !== userTypeEnum.SystemUser) ? true : false,
    },
  ];

  const deleteAll = () => {
    deleteAllDeviceRegister(selectedTenantId);
  };

  const deleteSelected = () => {
    let obj = { 
      TenantId: selectedTenantId,
      ICCIDs: selectedDeviceRegister.map((device:any)=>{ return device.ICCID }) 
    };
    console.log(obj)
    deleteSelectedDeviceRegister(obj);
  };

  const deleteSelectedConfirm = () => {
    closePanelHandler();
    props.selectedDeviceDeleteToggleHandler();
  };

  const chooseGroupHandler = () => {
    closePanelHandler();
    props.chooseGroupToggleHandler();
  };

  const moveDeviceRegister = () => {
    closePanelHandler();
    props.selectGroupToggleHandler();
  };

  const deleteAllDeviceRegisterConfirm = () => {
    closePanelHandler();
    props.allDeviceDeleteToggleHandler();
  };

  const closePanelHandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
    oneNceStore.reset();
  };

  return (
    <React.Fragment>
      {(moveDeviceRegisterState.inProgress || inProgress || tenantStore.inProgress) && (
        <FormLoader loading={(moveDeviceRegisterState.inProgress || inProgress || tenantStore.inProgress)} />
      )}
      {props.showAddDevicePopup && (
        <AddDeviceRegister 
          tenantId={localStorageUserType ==  userTypeEnum.SystemUser ? selectedTenantId : getTenantIdFromLocalStorage()} 
          id={selectedId} 
          modalClosed={props.addDeviceToggleHandler} />
      )}
      {props.showFilterPopup && (
        <DeviceRegisterFilter
          initialValues={_.omit(filter, ["isFilterActive"])}
          isFilterActive={filter.isFilterActive}
          submitHandler={filterSubmitHandler}
          modalClosed={closeFilterPopupHandler}
        />
      )}

      {props.showConfirmPopup && (
        <ConfirmPopup
          title="delete_device"
          modalSubmit={deleteHandler}
          modalClosed={props.confirmToggleHandler}
          message="delete_device_confirm"
          dialogClassName="delete_popup"
          category="delete"
          isLoading={deleteDeviceRegisterState.inProgress}
        />
      )}

      {props.showAllDeviceDeletePopup && (
        <ConfirmPopup
          title="delete_device"
          modalSubmit={deleteAll}
          modalClosed={props.allDeviceDeleteToggleHandler}
          message="delete_all_device_confirm"
          dialogClassName="delete_popup"
          category="delete"
          groupId={groupId}
          groups={[]}
          isLoading={deleteAllDeviceRegisterState.inProgress}
        />
      )}

      {props.showSelectedDeviceDeletePopup && (
        <ConfirmPopup
          title="delete_device"
          modalSubmit={deleteSelected}
          modalClosed={props.selectedDeviceDeleteToggleHandler}
          message="delete_selected_device_confirm"
          dialogClassName="delete_popup"
          category="delete"
          isLoading={deleteSelectedDeviceRegisterState.inProgress}
        />
      )}

      {props.showChooseGroupPopup && (
        <AddDeviceRegisterByCsv
          tenantId={localStorageUserType ==  userTypeEnum.SystemUser ? selectedTenantId : getTenantIdFromLocalStorage()}
          modalClosed={props.chooseGroupToggleHandler}
        />
      )}

      {props.showSelectGroupPopup && (
        <MoveDeviceRegisterToGroup
          selectedDeviceRegister={selectedDeviceRegister}
          modalClosed={props.selectGroupToggleHandler}
        />
      )}

      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row g-0 align-items-center">
              <div className="col-lg-5 col-md-4 col-sm-3 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                  {
                    localStorageUserType !== userTypeEnum.SystemUser &&
                    <li className="ps-5 me-2" title={formatMessage("back")}>
                      <button role="button" className="btn btn-secondary"onClick={(
                          event: React.MouseEvent<
                            HTMLButtonElement,
                            MouseEvent
                          >
                        ) => history.push(RoutesConstants.Device)}>
                          <i className="fa fa-arrow-left"></i>
                        </button>
                      </li>
                    }
                    <li>
                      <p className={`h5 ${localStorageUserType !== userTypeEnum.SystemUser ? "" : "ps-5"} mb-0 `}>
                        <FormattedMessage id="device_iccid_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-sm-9 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                    <li className="" title={formatMessage("refresh")}>
                      <LoaderIconButton
                        id="RefreshDevices"
                        onClick={() => refreshClickHandler()}
                        svg={
                          <svg
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-arrow-repeat m-0"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                            <path
                              fillRule="evenodd"
                              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                            />
                          </svg>
                        }
                        className="btn-sm me-2"
                        variant="light"
                      />
                    </li>
                    {localStorageUserType ==  userTypeEnum.SystemUser &&
                        <li className="me-2">
                          <select
                            className="custom-select form-control me-2"
                            value={selectedTenantId}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              let value = parseInt(e.target.value);
                              if (value < 0) toast.error(formatMessage("please_select_any_tenant"));
                              else {
                                if(selectedTenantId !== value){
                                  closePanelHandler();
                                  setPageInfo((prevState)=>{ 
                                    return { ...prevState, currentPage:1, isRefreshPage: true }
                                  })
                                  setSelectedTenantId(value);
                                }
                              };
                            }}
                          >
                            {allTenantOptions.map((data: IOption, i: number) => (
                              <option key={i} value={data.id}>
                                {i == 0 ? formatMessage(data.value) : data.value}
                              </option>
                            ))}
                          </select>
                        </li>
                      }
                      {filter.isFilterActive == true ? (
                      <li>
                        <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm me-2"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={resetFilter}
                          title={formatMessage("reset_filter")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="20px"
                            height="20px"
                            viewBox="0 0 122.88 110.668"
                            enable-background="new 0 0 122.88 110.668"
                          >
                            <g>
                              <path
                                fill="currentColor"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                              />
                            </g>
                          </svg>
                        </button>
                      </li>
                    ) : (
                      ""
                    )}
                    <li data-testid="Filter">
                      <div className="dropdown">
                        <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm me-2"
                          type="button"
                          onClick={openFilterPopUpHandler}
                          title={formatMessage("filter")}
                        >
                          <svg
                            className="svg-icon"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                              fill="currentColor"
                            />
                            <path
                              d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </div>
                    </li>
                    {
                      // isOperationPermitted(systemModuleEnum.DeviceRegister, "Add") &&
                      (localStorageUserType ==  userTypeEnum.SystemUser  && selectedTenantId > 0) &&
                      <li className="me-1">
                        <div className="dropdown">
                          <button
                            title={formatMessage("register_device")}
                            data-testid="BulkAction"
                            className="btn custom-select bulkActionButton"
                            type="button"
                            data-bs-toggle="dropdown"
                            disabled={getAddBulkCount > 0 ? true : false}
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-plus"
                              viewBox="0 0 20 20"
                            >
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            <FormattedMessage id={"register_device"} />
                          </button>
                          <ul className="dropdown-menu bulkaction-menu">
                            <li title={formatMessage("register_device")}>
                              <a
                                data-toggle="modal"
                                onClick={(event: any) =>
                                  openAddDeviceRegisterHandler({ Id: -1 }, event)
                                }
                                className="enabled_link text-dark"
                                data-testid="AddDeviceRegister"
                                data-target="#addDeviceRegister"
                              >
                                <span>
                                  <FormattedMessage id="register_device" />
                                </span>
                              </a>
                            </li>

                            <li title={formatMessage("bulk_register_device")}>
                              <a
                                data-toggle="modal"
                                onClick={() =>
                                  history.push({
                                    pathname: RoutesConstants.BulkDeviceRegister,
                                    state: {
                                      tenantId: selectedTenantId
                                    }
                                  })
                                }
                                className="enabled_link text-dark"
                                data-testid="AddBulk"
                                data-target="#addUser"
                              >
                                <span>
                                  <FormattedMessage id="bulk_register_device" />
                                </span>
                              </a>
                            </li>

                            <li title={formatMessage("add_using_csv")}>
                            <a
                              data-toggle="modal"
                              onClick={() => chooseGroupHandler()}
                              data-testid="AddDeviceRegister"
                              data-target="#addDeviceRegister"
                              className="d-block px-2 py-1 text-dark"
                            >
                              <FormattedMessage id="add_using_csv" />
                            </a>
                          </li>
                          </ul>
                        </div>
                      </li>
                    }
                    {
                      (localStorageUserType ==  userTypeEnum.SystemUser && selectedTenantId > 0) &&
                      <li>
                        <div className="dropdown">
                          <button
                            title={formatMessage("delete_device")}
                            data-testid="BulkAction"
                            className="btn custom-select bulkActionButton"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 20 20"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                            </svg>
                            <FormattedMessage id={"delete_device"} />
                          </button>
                          <ul className="dropdown-menu bulkaction-menu">
                            <li>
                              {singleSelectedDeviceRegister.length > 0 ? (
                                <a
                                  data-testid="deleteAll"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={deleteSelectedConfirm}
                                  className="mx-2"
                                >
                                  <FormattedMessage id="delete_selected" />
                                </a>
                              ) : (
                                <span
                                  className="disabled_link"
                                  title={formatMessage("select_devices")}
                                >
                                  <FormattedMessage id="delete_selected" />
                                </span>
                              )}
                            </li>

                            <li>
                              {allDeviceRegisterlist.length > 0 && selectedTenantId > 0 ? (
                                <a
                                  data-testid="deleteAll"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={deleteAllDeviceRegisterConfirm}
                                  className="mx-2"
                                >
                                  <FormattedMessage id="delete_all_devices" />
                                </a>
                              ) : (
                                <span
                                  className="disabled_link"
                                  title={formatMessage("delete_all_registered_option")}
                                >
                                  <FormattedMessage id="delete_all_devices" />
                                </span>
                              )}
                            </li>

                            {/* <li>
                              {allDeviceRegisterlist.length > 0 && selectedTenantId  > 0 ? (
                                <a
                                  data-testid="deleteAll"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={deleteAllFiltered}
                                  className="mx-2"
                                >
                                  <FormattedMessage id="delete_all_filtered" />
                                </a>
                              ) : (
                                <span
                                  className="disabled_link"
                                  title={formatMessage("delete_all_filtered")}
                                >
                                  <FormattedMessage id="delete_all_filtered" />
                                </span>
                              )}
                            </li> */}
                          </ul>
                        </div>
                      </li>
                    }
                    {
                      (localStorageUserType !== userTypeEnum.SystemUser && 
                        isOperationPermitted(systemModuleEnum.DeviceRegisteration, "Edit")) &&

                        <li title={formatMessage("Move")}>
                          <LoaderButton
                          id="Move"
                          text="Move"
                          disabled={selectedDeviceRegister.length > 0 ? false : true}
                          onClick={moveDeviceRegister}
                        />
                      </li>
                    }
                    {/* {
                      (localStorageUserType !== userTypeEnum.SystemUser && 
                        isOperationPermitted(systemModuleEnum.DeviceRegisteration, "Edit")) &&
                      <li className="ms-1">
                        <div className="dropdown">
                          <button
                            data-testid="CSVOperations"
                            className="btn custom-select bulkActionButton"
                            type="button"
                            data-bs-toggle="dropdown"
                            disabled={getAddBulkCount > 0 ? true : false}
                            aria-expanded="false"
                          >
                            <FormattedMessage id="actions" />
                          </button>
                          <ul className="dropdown-menu bulkaction-menu wide-menu">

                            <li>
                                  {
                                    selectedDeviceRegister.length > 0 ? 
                                  (
                                   <a
                                     data-testid="moveDeviceRegister"
                                     data-bs-toggle="dropdown"
                                     aria-expanded="false"
                                     onClick={moveDeviceRegister}
                                     className="enabled_link"
                                   >
                                     <FormattedMessage id="Move" />
                                   </a>
                                 ) : (<span
                                   className="disabled_link"
                                   title={formatMessage("Move")}
                                 >
                                   <FormattedMessage id="Move" />
                                 </span>
                                 )
                              }
                              </li>
                          </ul>
                        </div>
                      </li>
                    } */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_items_list">
        {(inProgress || oneNceStore.inProgress) && (
          <FormLoader loading={inProgress || oneNceStore.inProgress} />
        )}
        {allDeviceRegisterlist.length > 0 ? (
          <div
            className={`card mt-3 changingPanel ${
              openPanel ? "panelOpen" : ""
            }`}
          >
            <div
              className={`tableDesign row-eq-height tableDevices ${
                getRoleTypeFromLocalStorage() == roleTypeEnum.ReadOnly  ? "" : "normal_view"
              }`}
            >
              <div
                className="table-responsive checkbox_table"
                data-testid="table"
              >
                {allDeviceRegisterlist && (
                  <Grid
                    data={allDeviceRegisterlist}
                    columns={columns}
                    keyField="Id"
                    loading={inProgress}
                    rowClasses={rowClassFormat}
                    selectRow={
                      getRoleTypeFromLocalStorage() != roleTypeEnum.ReadOnly
                        ? selectRow
                        : undefined
                    }
                    // rowEvents={
                    //   singleSelectedDeviceRegister.length === 0
                    //     ? { onClick: rowEvents }
                    //     : undefined
                    // }
                  />
                )}
              </div>
            </div>
            {allDeviceRegisterlist.length > 0 ? (
              <div className="row g-0 p-2 tableFooter">
                <div className="col-sm-6 d-flex pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                  <p className="ms-2">
                    {singleSelectedDeviceRegister.length > 0 ? (
                      <span>
                        {" "}
                        <FormattedMessage id={"selected_devices_count"} />:{" "}
                        {singleSelectedDeviceRegister.length}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={pageInfo.isRefreshPage}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            <img src={no_device} alt="" className="no_message" />
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_device" />
            </p>
            <p className="mb-3">
              <FormattedMessage id="no_device_collection" />
            </p>

            { (localStorageUserType ==  userTypeEnum.SystemUser && selectedTenantId > 0) &&
              <LoaderButton
                type="button"
                text="+_register_device"
                data-toggle="modal"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => openAddDeviceRegisterHandler({ Id: -1 }, event)}
                data-testid="modalbtn"
                data-target="#addUser"
                className="btn btn-primary me-2"
              />
            }
          </div>
        )}
      </div>
      {/* <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel order_details_panel">
          <div className="row bb p-2 g-0">
            <div className="col-lg-11">
              <h2 className="fw-bold mb-0 mt-2">
                {selectedRow.OrderNo}
              </h2>
            </div>
            <div className="col-lg-1 text-end">
              <button onClick={closePanelhandler} className="Crossicons" title={formatMessage("close")}>
                <svg
                  role="button"
                  xlinkTitle="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
          </div>
          <DeviceRegisterDetails selectedData={selectedRow} />
        </div>
      </SlidingPanel> */}
    </React.Fragment>
  );
};
export default FilterPopupHOC(
  PublishMessagePopupHOC(
  ChooseGroupPopupHOC(
    SelectGroupPopupHOC(
        AllDeviceDeletePopupHOC(
          SelectedDeviceDeletePopupHOC(
            DeletePopupHOC(AddDevicePopupHOC(observer(DeviceRegister)))
          )
        )
      )
    )
  )
);