import React, { useEffect, useState } from "react";
import { Form, Formik, Field, useFormikContext } from "formik";
import "./add-edit-order-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { IOption } from "../../../models/ICommon";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import Label from "../../../shared-components/Label/Label";
import { orderValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { useStore } from "../../../contexts/StoreProvider";
import { observer } from "mobx-react";
import { IOrderVM } from "../../../models/response/IOrderResponse";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import monoz_logo from "../../../assets/Images/monoZ_Light-Grey_icon.png";
import { getUserTypeFromLocalStorage } from "../../../helpers/local-stotrage-helper";
import { downloadDeviceICCIDDummyCSVHandler, integerRegex, paymentOptions, roundWithPrecision } from "../../../constants/common-constants";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/FormikFormInputForRegexCheck";
import userTypeEnum from "../../../constants/user-type-enum";
import { IPricingPlanVM } from "../../../models/response/IPricingPlanResponse";
import FormikFormCheckBox from "../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import moment from "moment";
import config from "../../../helpers/config-helper";
import { formatMessage } from "../../../translations/format-message";
import toast from "react-hot-toast";
import FormikFormFileInput from "../../../shared-components/FormikFormHandlers/FormikFormFileInput";
import Papa from "papaparse";
import OrderInvoice from "./order-invoice";

const appConfig = config();
const dateFormat =  appConfig.REACT_APP_DATE_FORMAT;

interface IProps {
  id: number;
  tenantId: number;
  submitHandler: (data: IOrderVM) => void;
  onModalClosed: () => void;
  getOrderLoading: boolean;
  addUpdateOrderLoading: boolean;
  initialValues: IOrderVM;
  tenantOptions: IOption[];
  planOptions: IOption[];
  orderDescriptionOptions: IOption[];
}

const AddUpdateOrderForm = (props: IProps) => {
  const { tenantStore, orderStore, pricingPlanStore } = useStore();
  const { allTenants } = tenantStore;
  const { allOrders, selectedOrderDescriptionId } = orderStore;
  const { allPricingPlans, GetAllPlansByOrderDesTypeService, allPricingPlansOptions } = pricingPlanStore;
  const [initialValues, setInitialValues] = useState<any>(props.initialValues);
  const [orderDescriptionId, setOrderDescriptionId] = useState<number>(props.initialValues.OrderDescriptionType.Id);
  const [targetData, setTargetData] = useState("");
  const [noOfRecords, setNoOfRecords] = useState<number>(0);
  const [fileType, setFileType] = useState<string>("");
  const [formRendered, setFormRendered] = useState<boolean>(false);
  console.log(props.initialValues)

  let totalEnteriesInCSV: number = 0;

  const FormObserver: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<IOrderVM>();

    useEffect(() => {
      if (values.IsBillingAddressSame) {
        // setSelectedSearchId(values.SearchId)
        setFieldValue("ShippingAddress", values.BillingAddress);
      }
    }, [values.BillingAddress, values.IsBillingAddressSame]);
    
    useEffect(() => {
      if (values.Tenant && values.Tenant?.Id > 0 && values.Tenant?.Id !== props.initialValues.Tenant.Id) {
        const tempShippingAddress = allTenants.filter((tenant)=> tenant.Id == values.Tenant?.Id);
        const shippingAddress = tempShippingAddress.length > 0 ? tempShippingAddress[0].ShippingAddress : "";
        const billingAddress = tempShippingAddress.length > 0 ? tempShippingAddress[0].BillingAddress : "";
        let isBillingAddressSame = shippingAddress?.trim() == billingAddress?.trim() ? true : false;
        // setSelectedSearchId(values.SearchId)
        setFieldValue("ShippingAddress", shippingAddress);
        setFieldValue("BillingAddress", billingAddress);
        setFieldValue("IsBillingAddressSame", isBillingAddressSame);
      }
    }, [values.Tenant.Id]);


    return null;
  };
  
  const getHeaderArray = (csvRecordsArr: any): string[] => {
    let headerArray = [];
    let headers = csvRecordsArr[0].split(",");
    for (let i = 0; i < headers.length; i++) {
      headerArray.push(headers[i]);
    }
    return headerArray;
  };

  const headerInvalidError = (headersRow: any) => {
    var isInvalid: boolean = false;
    if (headersRow.length > 1) {
      isInvalid = true;
      return isInvalid;
    } else if (
      headersRow[0].trim() != "ICCID"
    ) {
      isInvalid = true;
      return isInvalid;
    }
    return isInvalid;
  };

  const handleFileUpload = (event: any) => {
    var files = event?.target?.result;
    setTargetData(files.toString());
  };
  
  const getFileName = (file: string) => {
    let val;
    if(file){
      val = file.split('.');
      setFileType(val[1].toLowerCase());
    }
    else
      setFileType("");

  };

  const startFileDecode = () => {
    if (targetData) {
      let csvData: string = targetData;
      let csvRecordsArray = csvData?.split(/\r\n|\n/);
      let headersRow = getHeaderArray(csvRecordsArray);
      if (csvRecordsArray.length > 0) {
        totalEnteriesInCSV = csvRecordsArray.length - 2;
      }
      let invalid = headerInvalidError(headersRow);
      if (invalid) {
        toast.error(formatMessage("invalid_headers"));
      } else {
        if (csvRecordsArray[1] != "") {
          let csv = CSVDataConditions(headersRow, csvRecordsArray);
          if (csv && csv?.length > 0) {
            return csv;
          }
        } else {
          toast.error(formatMessage("empty_csv"));
          return [];
        }
      }
    } else {
      toast.error(formatMessage("invalid_csv_format"));
    }
  };
  
  const getNoOfRecordsFromFile = (values: IOrderVM) => {
    let totalEnteries: number = 0;
    if(values.CSVFile == ""){
      setTargetData("");
    }
    else{
      if (targetData && fileType === "csv") {
        let csvData: string = targetData;
        let csvRecordsArray = csvData?.split(/\r\n|\n/);
        if (csvRecordsArray.length > 0) {
          totalEnteries = csvRecordsArray.length - 2;
        }
      }
      setNoOfRecords(totalEnteries);
    }
    return totalEnteries;
  };
  
  const getMaxNoOfDevicesSupport = (values:IOrderVM) => {
    const tempPricingPlan = allPricingPlans.filter(
      (plan) => plan.Id == values.Plan.Id
    );
    return tempPricingPlan.length > 0 ? tempPricingPlan[0].MaxNoOfDevicesSupport : 0;
  };

  const CSVDataConditions = (headersRow: any, csvRecordsArray: any) => {
    let dataManipulated = getDataRecordsArrayFromCSVFile(
      csvRecordsArray,
      headersRow.length
    );
    return dataManipulated;
  };

  const getDataRecordsArrayFromCSVFile = (
    csvRecordsArray: any,
    headerLength: number
    ) => {
    var ImportDataArr = [];
    var totalColumns = 1;
    csvRecordsArray.splice(csvRecordsArray.length - 1, 1);
    for (let i = 1; i < csvRecordsArray?.length; i++) {
      let data = csvRecordsArray[i]?.split(",");
      let splits = data[2]?.split(".");
      if (data[2] != "") {
        splits = splits?.map((str:any) => str.replace(/\\/g, "").replace(/"/g, "")).filter(Boolean);
      }
      if (data.length > totalColumns) {
        data.splice(totalColumns);
      }
      if (data.length == totalColumns) {
        // var CSVdata = data[0]?.trim()
        ImportDataArr.push(data[0]?.trim());
      }
    }
    return ImportDataArr;
  };
  
  useEffect(()=>{
    setInitialValues(props.initialValues)
    setOrderDescriptionId(props.initialValues.OrderDescriptionType.Id)
  },[props.initialValues])

  useEffect(() => {
    if(orderDescriptionId > -1){
      GetAllPlansByOrderDesTypeService(orderDescriptionId);
      const tempOrderDescription = allOrders.filter((order) => order.Id == orderDescriptionId)
      if(props.id == -1 || formRendered){
        setInitialValues({
          ...initialValues,
          IsBillingAddressSame:true,
          Plan: {
            Id: -1,
            PlanName: "",
            MaxNoOfDevicesSupport: 0,
            MonthlyDataOperations: 0,
            YearlyPlanExpiryPeriod: 0,
            MonthlyInventoryPeriod: 0,
            OrderDescriptionType: {
              Id: -1,
              Type: 0,
              TypeString: "",
              Description: ""
            },
            Currency: {
              Id: 0,
              CountryName: "",
              CurrencyName: "",
              Symbol: "",
              Abbrevation: ""
            },
            PlanPrice: 0,
            ExcessDataSurcharge: 0,
            Tax: 0,
          },
          NoOfDevicesOrder: "",
          PaymentOption: 0,
          OrderDescriptionType:{...tempOrderDescription[0]}
        })
      }
      else
        setFormRendered(true);
    }
      // GetAllDeliveredOrderByTenantIdService(selectedTenantId);
  }, [orderDescriptionId]);

  const calculateTotalOrderAmount = (values: IOrderVM) => {
    const tempPricingPlan: IPricingPlanVM[] = allPricingPlans.filter((plan:IPricingPlanVM) => plan.Id == values.Plan.Id);
    let planPriceForOneDevice=0, addedDevicePlanPrice = 0, tax=0, totalAmount=0;
    if(tempPricingPlan.length === 1){
      planPriceForOneDevice = tempPricingPlan[0].PlanPrice;
      addedDevicePlanPrice = values.OrderDescriptionType.Id == 3 ? noOfRecords*planPriceForOneDevice : (values.NoOfDevicesOrder != "" ? planPriceForOneDevice*parseInt(values.NoOfDevicesOrder) : 0);
      totalAmount = totalAmount + addedDevicePlanPrice;      
      tax = (totalAmount/100)* tempPricingPlan[0].Tax;
    }
    return roundWithPrecision(totalAmount + tax, 2);
  }

  return (
    <>
      <Modal className="invoice-menu correction-modal fancy-modal" show={true} centered onHide={() => { }}>
        <Modal.Header>
          <Modal.Title>
            {props.id === -1 ? (
              <FormattedMessage id="add_order" />
            ) : (
              <FormattedMessage id="edit_order" />
            )}
            <button
              onClick={props.onModalClosed}
              disabled={props.addUpdateOrderLoading}
              className="Crossicons"
              title={formatMessage("close")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={orderValidateSchema(allPricingPlans)}
          validateOnBlur={false}
          onSubmit={async (values) => {
            let totalOrderAmount = calculateTotalOrderAmount(values);
            let tempValues:any = {...values, TopUpICCIDs: []};
            tempValues.ShippingAddress = values.IsBillingAddressSame === true ? values.BillingAddress : values.ShippingAddress
            if (values.OrderDescriptionType.Id == 3) {
              let data:Array<{ICCID:string}> | undefined = startFileDecode();
              tempValues.TopUpICCIDs = data ? data : [];
              tempValues.NoOfDevicesOrder = data ? data.length : 0;
              tempValues.ShippingAddress = "";
              if(data && data?.length > 0)
                props.submitHandler({...tempValues, TotalOrderAmount: totalOrderAmount});
            }
            else
              props.submitHandler({...tempValues, TotalOrderAmount: totalOrderAmount});
          }}
        >
          {({ values }) => {
            return (
              <Form className="formBody">
                {props.getOrderLoading || props.addUpdateOrderLoading &&
                  <FormLoader loading={props.getOrderLoading || props.addUpdateOrderLoading} />}
                <FormObserver />
                {props.getOrderLoading ? (
                  <FormLoader loading={props.getOrderLoading} />
                ) : null}
                <Modal.Body>
                  <div className="row">                    
                    <div className="col-5">
                      <div className="form-group">
                        <Label
                          className="label-style col-form-label"
                          required={true}
                          label="order_description_type"
                        />
                        <Field
                          name="OrderDescriptionType.Id"
                          options={props.orderDescriptionOptions}
                          as={FormikFormSelect}
                          setLocalState={(id:any)=>{setOrderDescriptionId(id)}}
                        />
                      </div>
                      {/* <div className="form-group">
                        <Label
                          className="label-style col-form-label"
                          required={true}
                          label="Tenant"
                        />
                        <Field
                          name="Tenant.Id"
                          options={props.tenantOptions}
                          as={FormikFormSelect}
                        />
                      </div> */}
                      {
                        getUserTypeFromLocalStorage() ==  userTypeEnum.SystemUser &&
                        <div className="form-group">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="Tenant"
                          />
                          <Field
                            name="Tenant.Id"
                            options={props.tenantOptions}
                            as={FormikFormSelect}
                          />
                        </div>
                      }
                      <div className="form-group">
                        <Label
                          className="label-style col-form-label"
                          required={true}
                          label="pricing_plan_title"
                        />
                        <Field
                          name="Plan.Id"
                          options={props.planOptions}
                          as={FormikFormSelect}
                        />
                      </div>
                      <div className="form-group">
                        <Label
                          className="label-style col-form-label"
                          required={true}
                          label="payment_options"
                        />
                        <Field
                          name="PaymentOption"
                          disabled={true}
                          options={paymentOptions}
                          as={FormikFormSelect}
                        />
                      </div>
                      { values.OrderDescriptionType.Id == 3 ?
                          <div className="form-group">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="import_csv_file"
                          />
                            <a href="javascript:;"
                              className="download_icon"
                              onClick={()=> downloadDeviceICCIDDummyCSVHandler("DataTopupSampleCSV")}
                              title={formatMessage("download_sample_csv")}>
                              <svg
                                version="1.1"
                                x="0px"
                                y="0px"
                                fill="currentColor"
                                height="13"
                                width="13"
                                viewBox="0 0 512 512"
                              >
                                <g>
                                  <g>
                                    <path
                                      d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
        c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
        c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"
                                    />
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z" />
                                  </g>
                                </g>
                              </svg>
                            </a>
                          <Field
                            className="form-control"
                            id="ckf_file"
                            name="CSVFile"
                            handleFileUpload={handleFileUpload}
                            getFileName={getFileName}
                            as={FormikFormFileInput}
                          />
                          <div className="d-flex justify-content-end">
                            <span className="text-muted small ms-auto"><FormattedMessage id="no_of_iccids" /> : &nbsp;
                              {getNoOfRecordsFromFile(values)}
                            </span>
                          </div>
                        </div> : 
                        <div className="form-group">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="no_of_devices_order"
                          />
                          <Field
                            data-testid="dataNoOfDevicesOrder"
                            className="form-control"
                            name="NoOfDevicesOrder"
                            type="text"
                            placeholder="no_of_devices_order"
                            regex={integerRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                          <div className="d-flex justify-content-end">
                            <span className="text-muted small ms-auto"><FormattedMessage id="max_allowed_order_quantity" /> : &nbsp;
                              {getMaxNoOfDevicesSupport(values)}
                            </span>
                          </div>
                        </div>
                      }
                      <div className="form-group ">
                        <Label
                            className="label-style"
                            required={true}
                            label="billing_address"
                          />
                          <Field
                            rows={2}
                            id="BillingAddress"
                            className="form-control"
                            name="BillingAddress"
                            placeholder="billing_address"
                            type="text"
                            as={FormikFormTextArea}
                          />
                      </div>
                      { values.OrderDescriptionType.Id != 3 &&
                        <>
                          <div className="checkbox">
                            <label>
                              <Field
                                data-testid="IsBillingAddressSame"
                                className="form-control"
                                name="IsBillingAddressSame"
                                role="switch"
                                type="checkbox"
                                id="IsBillingAddressSame"
                                disabled={values.OrderDescriptionType.Id == 3}
                                as={FormikFormCheckBox}
                              />
                              <span className="cr">
                                <i className="cr-icon fas fa-check"></i>
                              </span>
                            </label>
                            <label htmlFor="IsBillingAddressSame" className="h">
                              <FormattedMessage id="is_billing_address_same" />
                            </label>
                          </div> 
                          <div className={`form-group formSet ${values.IsBillingAddressSame} billing_wrap`}>
                            <Label
                              className="label-style"
                              required={true}
                              label="shipping_address"
                            />
                            <Field
                              rows={2}
                              id="ShippingAddress"
                              className="form-control"
                              name="ShippingAddress"
                              disabled={values.IsBillingAddressSame}
                              // value={getAddress(values)}
                              placeholder="address"
                              type="text"
                              as={FormikFormTextArea}
                            />
                          </div>
                        </>
                      }
                      <div className="form-set">
                        <Label
                          className="label-style"
                          required={false}
                          label="remarks"
                        />
                        <Field
                          rows={2}
                          id="Remarks"
                          className="form-control"
                          name="Remarks"
                          placeholder="remarks"
                          type="text"
                          as={FormikFormTextArea}
                        />
                      </div>
                    </div>
                    <div className="col-7">
                        <div className="invoice_card">
                          <OrderInvoice values={values} noOfRecords={noOfRecords} id={props.id} />
                        </div>
                    </div>
                  </div>

                </Modal.Body>
                <div className="modal-footer pt-0 border-0">
                  <div className="form-group row g-0 tab-action">
                    <div className="col-12">
                      <LoaderButton
                        type="submit"
                        isLoading={props.addUpdateOrderLoading}
                        text={props.id > 0 ? "update" :"create_order"}
                      />
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.addUpdateOrderLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default observer(AddUpdateOrderForm);