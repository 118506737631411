/*    
<summary>
  This functional component "BulkDeviceRegisterForm" provides form to add new configuration settingForm or 
  update configuration setting of IoT device simulator.
  Developer:Aashish Singh, Created Date: 11-June-2024,
</summary>
<returns>Returns JSX</returns>
*/
import { Formik, Form, Field, useFormikContext } from "formik";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "./bulk-device-register-form.css";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import Grid from "../../../shared-components/Grid/Grid";
import { formatMessage } from "../../../translations/format-message";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import { bulkDeviceRegisterSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import toast from "react-hot-toast";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import { NavLink } from "react-router-dom";
import Label from "../../../shared-components/Label/Label";
import { Tooltip } from "react-tooltip";
import { useStore } from "../../../contexts/StoreProvider";
import IAddDeviceIccidBulk, { IBulkDeviceRegisterFormInitialState } from "../../../models/Form/IAddDeviceRegisterInBulk";
import { IOption } from "../../../models/ICommon";
import AddUpdateDeviceRegisterDetail from "./add-edit-device-register-detail";
import { getThemeFromLocalStorage } from "../../../helpers/local-stotrage-helper";

interface IProps {
  initialValues: IBulkDeviceRegisterFormInitialState;
  tenantId: number;
  tenantOptions: IOption[];
  deliveredOrderList: IOption[];
  isAdding: boolean;
  submitHandler: (data: IAddDeviceIccidBulk) => void;
}

const BulkDeviceRegisterForm = (props: IProps) => {
  const { deviceRegisterStore, preferencesStore, orderStore } = useStore();
  const { getAddBulkCount } = preferencesStore;
  const { addBulkDeviceRegisterState, resetAddBulkDeviceRegisterState } = deviceRegisterStore;
  const { GetAllDeliveredOrderByTenantIdService, resetAllDeliveredOrderState } = orderStore;
  const [startIccid, setStartIccid] = useState(BigInt(0));
  const [endIccid, setEndIccid] = useState(BigInt(0));
  const [deviceName, setDeviceName] = useState<string>("");
  const [devices, setDevices] = useState<any>([]);
  const [loaders, setLoaders] = useState<boolean>(false);
  const [errorStartIccid, setErrorStartIccid] = useState<string>("");
  const [tenantId, setTenantId] = useState<number>(props.tenantId);
  let resetPresForm = () => { }; // This function dtores the resetForm function
  /**
   * Area Block for Get functions/variables from store class
   * UpdatedBy:Mohammad Saquib Khan ,Date:10-April-2023
   */

  const { submitHandler, isAdding } = props;

  const startIccidHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartIccid(BigInt(e.target.value));
  };
  const endIccidHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndIccid(BigInt(e.target.value));
  };
  const deviceNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceName(e.target.value);
  };

  useEffect(() => {
    if (tenantId > -1)
      GetAllDeliveredOrderByTenantIdService(tenantId);
  }, [tenantId]);

  const generateIccidDevices = () => {
    if (startIccid != BigInt(0) && endIccid != BigInt(0)) {
      let temp_arr: any = [];
      let totalDevices = endIccid - startIccid;
      if (totalDevices <= 10000) {
        setLoaders(true);
        setTimeout(() => {
          for (let i = startIccid; i <= endIccid; i++) {
            var obj = {
              Name: deviceName.trim() + " " + i,
              ICCID: i.toString(),
            };
            temp_arr.push(obj);
          }
          setDevices(temp_arr);
          setLoaders(false);
        }, 1000);
      }
      else {
        toast.error(formatMessage("MaxBulkDeviceCountExceed"));
        setDevices([])
      }
    }
  };

  const columns: any = [
    {
      dataField: "Name",
      text: formatMessage("device_name"),
    },
    {
      dataField: "ICCID",
      text: formatMessage("iccid"),
    },
  ];

  /**
   * This functional component stores the reset form function in the resetPresentForm variable
   */
  const ResettingForm = () => {
    const { resetForm } = useFormikContext();
    resetPresForm = resetForm; // Store the value of resetForm in this variable
    return null;
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addBulkDeviceRegisterState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addBulkDeviceRegisterState.success) {
      // toast.success(formatMessage("added_success"));
      setDevices([]);
      setTenantId(props.tenantId);
      resetPresForm();
      resetAddBulkDeviceRegisterState();
    }
  }, [addBulkDeviceRegisterState.success]);

  return (
    <>
      {<FormLoader loading={loaders} />}
      <div className="mx-auto">
        <Formik
          initialValues={props.initialValues}
          validationSchema={bulkDeviceRegisterSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            let data: IAddDeviceIccidBulk = {
              OrderNo: values.OrderNo,
              Devices: devices,
              TenantId: values.TenantId,
            };
            if (startIccid > endIccid) {
              setErrorStartIccid("iccid_validation");
            } else {
              setErrorStartIccid("");
            }

            if (errorStartIccid == "") {
              if (devices.length == 0) {
                toast.error(formatMessage("no_device_generated"));
              } else {
                submitHandler(data);
              }
            }
          }}
          render={({ values }) => {
            return (
              <Form id="formBody" className="mt-3">
                {isAdding && (
                  <FormLoader
                    loading={getAddBulkCount > 0 ? false : isAdding}
                  />
                )}
                <div className="card card-body">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group formSet">
                        <Label
                          className="label-style mb-0"
                          required={true}
                          label="order_no"
                        />
                        <Field
                          data-testid="orderNo"
                          className="form-control"
                          name="OrderNo"
                          getData={true}
                          options={props.deliveredOrderList}
                          as={FormikFormSelect}
                        />
                      </div>
                    </div>
                    {
                      props.tenantId === -1 &&
                      <div className="col-3">
                        <div className="form-group formSet">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="Tenant"
                          />
                          <Field
                            data-testid="tenantId"
                            className="form-control"
                            name="TenantId"
                            options={props.tenantOptions}
                            setLocalState={(id: any) => { setTenantId(id) }}
                            as={FormikFormSelect}
                          />
                        </div>
                      </div>
                    }
                    <div className="col-3">
                      <div className="form-group formSet">
                        <Label
                          className="label-style mb-0"
                          required={true}
                          label={formatMessage("device_name_prefix")}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-info-circle ms-2"
                          viewBox="0 0 16 16"
                          data-tooltip-id="my-tooltip-bulk"
                          data-tooltip-html={formatMessage(
                            "bulk_device_name_validation"
                          )}
                          data-tooltip-variant="dark"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <Tooltip id="my-tooltip-bulk" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                        <Field
                          data-testid="BulkDeviceName"
                          className="form-control"
                          name="Name"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          placeholder="device_name_prefix"
                          type="text"
                          onKeyUp={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            deviceNameHandler(e);
                          }}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                    
                    <div className="col-6">
                      <Label
                        className="label-style mb-0"
                        required={true}
                        label="iccid"
                      />
                      <div className="input-group bulkform_input">
                        <Field
                          data-testid="StartICCIDBulk"
                          className="form-control"
                          name="StartICCID"
                          placeholder="start_iccid"
                          type="number"
                          onKeyUp={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            startIccidHandler(e);
                          }}
                          as={FormikFormInput}
                        />
                      
                        {/* <Label
                          className="label-style"
                          required={true}
                          label="end_iccid"
                        /> */}
                        <Field
                          data-testid="EndICCIDBulk"
                          className="form-control"
                          name="EndICCID"
                          placeholder="end_iccid"
                          type="number"
                          onKeyUp={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            endIccidHandler(e);
                          }}
                          as={FormikFormInput}
                        />
                        <LoaderButton
                          id="GenerateBulkDevices"
                          onClick={generateIccidDevices}
                          variant="secondary"
                          disabled={
                            deviceName.trim() == "" || deviceName.trim().length > 9 ? true : false
                          }
                          isLoading={false}
                          text={"generate"}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <p className="small mb-2">
                        <AddUpdateDeviceRegisterDetail orderNo={values.OrderNo} />
                      </p>
                    </div>
                  </div>
                  <div className="bg-light my-3">
                    <div className="tableDesign bulkheightTable">
                      <div
                        className="table-responsive"
                        data-testid="BulkGeneratedDevices"
                      >
                        <Grid
                          data={devices}
                          columns={columns}
                          keyField="ICCID"
                        />
                      </div>
                      {devices?.length == 0 ? (
                        <div className="no_data_grid text-center text-muted">
                        <svg
                          width="80"
                          height="70"
                          viewBox="0 0 140 120"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M35.56 34.23H119.73C123.3 34.23 126.19 37.12 126.19 40.69V93.41C126.19 96.97 123.3 99.86 119.73 99.86H33.1C29.54 99.86 26.65 96.97 26.65 93.41V42.56"
                            stroke="#686868"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M30.39 21H19.17C16.3147 21 14 23.3147 14 26.17V37.39C14 40.2453 16.3147 42.56 19.17 42.56H30.39C33.2453 42.56 35.56 40.2453 35.56 37.39V26.17C35.56 23.3147 33.2453 21 30.39 21Z"
                            stroke="#686868"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M24.56 25.24L21.85 26.81V29.95L24.56 31.51L27.28 29.95V26.81L24.56 25.24Z"
                            stroke="#686868"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.66 31.3101L17.94 32.8801V36.0101L20.66 37.5801L23.37 36.0101V32.8801L20.66 31.3101Z"
                            stroke="#686868"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.7"
                            d="M27.13 32.1299C26.21 32.6599 25.64 33.6499 25.64 34.7199C25.64 35.7899 26.21 36.7699 27.13 37.3099C28.05 37.8399 29.19 37.8399 30.12 37.3099C31.04 36.7799 31.61 35.7899 31.61 34.7199C31.61 33.6499 31.04 32.6699 30.12 32.1299C29.2 31.5999 28.06 31.5999 27.13 32.1299V32.1299Z"
                            fill="url(#paint0_linear_4023_5161)"
                          />
                          <path
                            d="M27.13 32.1299C26.21 32.6599 25.64 33.6499 25.64 34.7199C25.64 35.7899 26.21 36.7699 27.13 37.3099C28.05 37.8399 29.19 37.8399 30.12 37.3099C31.04 36.7799 31.61 35.7899 31.61 34.7199C31.61 33.6499 31.04 32.6699 30.12 32.1299C29.2 31.5999 28.06 31.5999 27.13 32.1299V32.1299Z"
                            stroke="#686868"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M27.52 34.82L28.19 35.49L29.73 33.95"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.7"
                            d="M45.62 45.8201H37.03C36.5772 45.8201 36.21 46.1872 36.21 46.6401V55.2301C36.21 55.6829 36.5772 56.0501 37.03 56.0501H45.62C46.0729 56.0501 46.44 55.6829 46.44 55.2301V46.6401C46.44 46.1872 46.0729 45.8201 45.62 45.8201Z"
                            fill="url(#paint1_linear_4023_5161)"
                          />
                          <path
                            opacity="0.7"
                            d="M58.52 64.24H49.93C49.4772 64.24 49.11 64.6071 49.11 65.06V73.65C49.11 74.1029 49.4772 74.47 49.93 74.47H58.52C58.9729 74.47 59.34 74.1029 59.34 73.65V65.06C59.34 64.6071 58.9729 64.24 58.52 64.24Z"
                            fill="url(#paint2_linear_4023_5161)"
                          />
                          <path
                            d="M45.62 45.8201H37.03C36.5772 45.8201 36.21 46.1872 36.21 46.6401V55.2301C36.21 55.6829 36.5772 56.0501 37.03 56.0501H45.62C46.0729 56.0501 46.44 55.6829 46.44 55.2301V46.6401C46.44 46.1872 46.0729 45.8201 45.62 45.8201Z"
                            stroke="#686868"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M58.52 64.24H49.93C49.4772 64.24 49.11 64.6071 49.11 65.06V73.65C49.11 74.1029 49.4772 74.47 49.93 74.47H58.52C58.9729 74.47 59.34 74.1029 59.34 73.65V65.06C59.34 64.6071 58.9729 64.24 58.52 64.24Z"
                            stroke="#686868"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M58.52 80.6001H49.93C49.4772 80.6001 49.11 80.9672 49.11 81.4201V90.0101C49.11 90.463 49.4772 90.8301 49.93 90.8301H58.52C58.9729 90.8301 59.34 90.463 59.34 90.0101V81.4201C59.34 80.9672 58.9729 80.6001 58.52 80.6001Z"
                            stroke="#686868"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M53.56 48.25H69.59"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M76.23 48.25H78.82"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M83.53 48.25H98.77"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M53.56 53.6201H62.65"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M67.36 53.6201H86.05"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M90.63 53.6201H98.77"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M41.33 60.6802V90.3502"
                            stroke="#686868"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M67.24 66.73H81.27"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M87.91 66.73H98.77"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M66.83 71.98H85.37"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M90.63 71.98H98.77"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M67.24 83.0901H73.14"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M79.77 83.0901H98.77"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M66.83 88.3401H85.37"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.2"
                            d="M90.63 88.3401H98.77"
                            stroke="#686868"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M38.91 51.15L40.38 52.62L43.74 49.26"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M51.81 69.5702L53.28 71.0402L56.64 67.6702"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M107.63 49.3401H106.29C106.29 49.3401 106.27 49.3401 106.27 49.3601V50.6601C106.27 50.6601 106.27 50.6801 106.29 50.6801H107.11C107.11 50.6801 107.17 50.7001 107.17 50.7401V51.1201C107.17 51.1201 107.15 51.1801 107.11 51.1801H106.29C106.29 51.1801 106.27 51.1801 106.27 51.2001V52.9701C106.27 52.9701 106.25 53.0301 106.21 53.0301H105.75C105.75 53.0301 105.69 53.0101 105.69 52.9701V48.8901C105.69 48.8901 105.71 48.8301 105.75 48.8301H107.63C107.63 48.8301 107.69 48.8501 107.69 48.8901V49.2701C107.69 49.2701 107.67 49.3301 107.63 49.3301V49.3401Z"
                            fill="#686868"
                          />
                          <path
                            d="M108.14 52.9801V48.9001C108.14 48.9001 108.16 48.8401 108.2 48.8401H108.66C108.66 48.8401 108.72 48.8601 108.72 48.9001V52.9801C108.72 52.9801 108.7 53.0401 108.66 53.0401H108.2C108.2 53.0401 108.14 53.0201 108.14 52.9801Z"
                            fill="#686868"
                          />
                          <path
                            d="M111.41 49.3401H110.06C110.06 49.3401 110.04 49.3401 110.04 49.3601V50.6601C110.04 50.6601 110.04 50.6801 110.06 50.6801H110.89C110.89 50.6801 110.95 50.7001 110.95 50.7401V51.1201C110.95 51.1201 110.93 51.1801 110.89 51.1801H110.06C110.06 51.1801 110.04 51.1801 110.04 51.2001V52.5101C110.04 52.5101 110.04 52.5301 110.06 52.5301H111.41C111.41 52.5301 111.47 52.5501 111.47 52.5901V52.9701C111.47 52.9701 111.45 53.0301 111.41 53.0301H109.52C109.52 53.0301 109.46 53.0101 109.46 52.9701V48.8901C109.46 48.8901 109.48 48.8301 109.52 48.8301H111.41C111.41 48.8301 111.47 48.8501 111.47 48.8901V49.2701C111.47 49.2701 111.45 49.3301 111.41 49.3301V49.3401Z"
                            fill="#686868"
                          />
                          <path
                            d="M112.06 52.9801V48.9001C112.06 48.9001 112.08 48.8401 112.12 48.8401H112.58C112.58 48.8401 112.64 48.8601 112.64 48.9001V52.5201C112.64 52.5201 112.64 52.5401 112.66 52.5401H114C114 52.5401 114.06 52.5601 114.06 52.6001V52.9801C114.06 52.9801 114.04 53.0401 114 53.0401H112.12C112.12 53.0401 112.06 53.0201 112.06 52.9801V52.9801Z"
                            fill="#686868"
                          />
                          <path
                            d="M114.5 52.9801V48.9001C114.5 48.9001 114.52 48.8401 114.56 48.8401H115.54C115.87 48.8401 116.14 48.9401 116.33 49.1501C116.53 49.3501 116.62 49.6301 116.62 49.9801V51.9101C116.62 52.2601 116.52 52.5301 116.33 52.7401C116.14 52.9501 115.87 53.0501 115.54 53.0501H114.56C114.56 53.0501 114.5 53.0301 114.5 52.9901V52.9801ZM115.1 52.5401H115.54C115.69 52.5401 115.81 52.4801 115.91 52.3601C116 52.2401 116.05 52.0801 116.05 51.8801V50.0101C116.05 49.8101 116 49.6401 115.91 49.5201C115.82 49.4001 115.69 49.3401 115.54 49.3401H115.1C115.1 49.3401 115.08 49.3401 115.08 49.3601V52.5201C115.08 52.5201 115.08 52.5401 115.1 52.5401Z"
                            fill="#686868"
                          />
                          <path
                            d="M107.26 71.4L107.13 70.71C107.13 70.71 107.13 70.7 107.13 70.69C107.13 70.69 107.12 70.69 107.11 70.69H106.13C106.13 70.69 106.12 70.69 106.11 70.69C106.1 70.69 106.11 70.69 106.11 70.71L105.98 71.4C105.98 71.4 105.95 71.45 105.91 71.45H105.45C105.45 71.45 105.41 71.45 105.41 71.43C105.41 71.42 105.4 71.4 105.41 71.38L106.3 67.3C106.3 67.3 106.33 67.25 106.37 67.25H106.9C106.9 67.25 106.96 67.27 106.97 67.3L107.87 71.38C107.87 71.38 107.85 71.45 107.82 71.45H107.35C107.35 71.45 107.29 71.43 107.28 71.4H107.26ZM106.21 70.23H107.01C107.01 70.23 107.03 70.23 107.03 70.21L106.62 68.08C106.62 68.08 106.62 68.07 106.61 68.07C106.6 68.07 106.61 68.07 106.6 68.08L106.2 70.21C106.2 70.21 106.2 70.23 106.22 70.23H106.21Z"
                            fill="#686868"
                          />
                          <path
                            d="M109.9 71.41L109.35 69.62C109.35 69.62 109.34 69.6 109.33 69.6H108.91C108.91 69.6 108.89 69.6 108.89 69.62V71.39C108.89 71.39 108.87 71.45 108.83 71.45H108.37C108.37 71.45 108.31 71.43 108.31 71.39V67.31C108.31 67.31 108.33 67.25 108.37 67.25H109.44C109.64 67.25 109.82 67.3 109.98 67.4C110.14 67.5 110.26 67.64 110.34 67.82C110.42 68 110.47 68.21 110.47 68.44C110.47 68.69 110.42 68.91 110.31 69.1C110.21 69.29 110.06 69.42 109.88 69.5C109.87 69.51 109.86 69.52 109.87 69.54L110.48 71.38V71.4C110.48 71.4 110.47 71.44 110.43 71.44H109.96C109.96 71.44 109.9 71.42 109.89 71.39L109.9 71.41ZM108.88 67.78V69.12C108.88 69.12 108.88 69.14 108.9 69.14H109.34C109.5 69.14 109.64 69.08 109.74 68.95C109.84 68.82 109.89 68.66 109.89 68.45C109.89 68.24 109.84 68.06 109.74 67.94C109.64 67.81 109.51 67.75 109.34 67.75H108.9C108.9 67.75 108.88 67.75 108.88 67.77V67.78Z"
                            fill="#686868"
                          />
                          <path
                            d="M111.23 71.2099C111.03 71.0199 110.93 70.76 110.93 70.45V68.25C110.93 67.93 111.03 67.68 111.23 67.49C111.43 67.3 111.69 67.2 112.01 67.2C112.33 67.2 112.6 67.3 112.8 67.49C113 67.68 113.1 67.9399 113.1 68.2599V68.5099C113.1 68.5099 113.08 68.57 113.04 68.57H112.58C112.58 68.57 112.52 68.5499 112.52 68.5099V68.25C112.52 68.09 112.47 67.9499 112.38 67.8499C112.29 67.7499 112.16 67.7 112.01 67.7C111.86 67.7 111.73 67.7499 111.64 67.8499C111.55 67.9499 111.5 68.08 111.5 68.25V70.4599C111.5 70.6199 111.55 70.76 111.64 70.86C111.73 70.96 111.86 71.0099 112.01 71.0099C112.16 71.0099 112.28 70.96 112.38 70.86C112.47 70.76 112.52 70.6299 112.52 70.4599V69.7999C112.52 69.7999 112.52 69.7799 112.5 69.7799H112.04C112.04 69.7799 111.98 69.76 111.98 69.72V69.3499C111.98 69.3499 112 69.29 112.04 69.29H113.04C113.04 69.29 113.1 69.3099 113.1 69.3499V70.4599C113.1 70.7799 113 71.03 112.8 71.22C112.6 71.41 112.34 71.5099 112.01 71.5099C111.68 71.5099 111.43 71.41 111.23 71.22V71.2099Z"
                            fill="#686868"
                          />
                          <path
                            d="M107.63 84.1201H106.29C106.29 84.1201 106.27 84.1201 106.27 84.1401V85.4401C106.27 85.4401 106.27 85.4601 106.29 85.4601H107.11C107.11 85.4601 107.17 85.4801 107.17 85.5201V85.9001C107.17 85.9001 107.15 85.9601 107.11 85.9601H106.29C106.29 85.9601 106.27 85.9601 106.27 85.9801V87.7501C106.27 87.7501 106.25 87.8101 106.21 87.8101H105.75C105.75 87.8101 105.69 87.7901 105.69 87.7501V83.6701C105.69 83.6701 105.71 83.6101 105.75 83.6101H107.63C107.63 83.6101 107.69 83.6301 107.69 83.6701V84.0501C107.69 84.0501 107.67 84.1101 107.63 84.1101V84.1201Z"
                            fill="#686868"
                          />
                          <path
                            d="M108.14 87.7601V83.6801C108.14 83.6801 108.16 83.6201 108.2 83.6201H108.66C108.66 83.6201 108.72 83.6401 108.72 83.6801V87.7601C108.72 87.7601 108.7 87.8201 108.66 87.8201H108.2C108.2 87.8201 108.14 87.8001 108.14 87.7601Z"
                            fill="#686868"
                          />
                          <path
                            d="M111.41 84.1201H110.06C110.06 84.1201 110.04 84.1201 110.04 84.1401V85.4401C110.04 85.4401 110.04 85.4601 110.06 85.4601H110.89C110.89 85.4601 110.95 85.4801 110.95 85.5201V85.9001C110.95 85.9001 110.93 85.9601 110.89 85.9601H110.06C110.06 85.9601 110.04 85.9601 110.04 85.9801V87.2901C110.04 87.2901 110.04 87.3101 110.06 87.3101H111.41C111.41 87.3101 111.47 87.3301 111.47 87.3701V87.7501C111.47 87.7501 111.45 87.8101 111.41 87.8101H109.52C109.52 87.8101 109.46 87.7901 109.46 87.7501V83.6701C109.46 83.6701 109.48 83.6101 109.52 83.6101H111.41C111.41 83.6101 111.47 83.6301 111.47 83.6701V84.0501C111.47 84.0501 111.45 84.1101 111.41 84.1101V84.1201Z"
                            fill="#686868"
                          />
                          <path
                            d="M112.06 87.7601V83.6801C112.06 83.6801 112.08 83.6201 112.12 83.6201H112.58C112.58 83.6201 112.64 83.6401 112.64 83.6801V87.3001C112.64 87.3001 112.64 87.3201 112.66 87.3201H114C114 87.3201 114.06 87.3401 114.06 87.3801V87.7601C114.06 87.7601 114.04 87.8201 114 87.8201H112.12C112.12 87.8201 112.06 87.8001 112.06 87.7601Z"
                            fill="#686868"
                          />
                          <path
                            d="M114.5 87.7601V83.6801C114.5 83.6801 114.52 83.6201 114.56 83.6201H115.54C115.87 83.6201 116.14 83.7201 116.33 83.9301C116.53 84.1301 116.62 84.4101 116.62 84.7601V86.6901C116.62 87.0401 116.52 87.3101 116.33 87.5201C116.14 87.7301 115.87 87.8301 115.54 87.8301H114.56C114.56 87.8301 114.5 87.8101 114.5 87.7701V87.7601ZM115.1 87.3201H115.54C115.69 87.3201 115.81 87.2601 115.91 87.1401C116 87.0201 116.05 86.8601 116.05 86.6601V84.7901C116.05 84.5901 116 84.4201 115.91 84.3001C115.82 84.1801 115.69 84.1201 115.54 84.1201H115.1C115.1 84.1201 115.08 84.1201 115.08 84.1401V87.3001C115.08 87.3001 115.08 87.3201 115.1 87.3201V87.3201Z"
                            fill="#686868"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_4023_5161"
                              x1="25.64"
                              y1="34.7199"
                              x2="31.61"
                              y2="34.7199"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#FF7002" />
                              <stop offset="0.3" stopColor="#FE7203" />
                              <stop offset="0.5" stopColor="#FC7B0A" />
                              <stop offset="0.68" stopColor="#F88B15" />
                              <stop offset="0.84" stopColor="#F2A024" />
                              <stop offset="0.98" stopColor="#EBBC38" />
                              <stop offset="1" stopColor="#EBC03B" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_4023_5161"
                              x1="36.21"
                              y1="50.9401"
                              x2="46.44"
                              y2="50.9401"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#FF7002" />
                              <stop offset="0.3" stopColor="#FE7203" />
                              <stop offset="0.5" stopColor="#FC7B0A" />
                              <stop offset="0.68" stopColor="#F88B15" />
                              <stop offset="0.84" stopColor="#F2A024" />
                              <stop offset="0.98" stopColor="#EBBC38" />
                              <stop offset="1" stopColor="#EBC03B" />
                            </linearGradient>
                            <linearGradient
                              id="paint2_linear_4023_5161"
                              x1="49.11"
                              y1="69.35"
                              x2="59.34"
                              y2="69.35"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#FF7002" />
                              <stop offset="0.3" stopColor="#FE7203" />
                              <stop offset="0.5" stopColor="#FC7B0A" />
                              <stop offset="0.68" stopColor="#F88B15" />
                              <stop offset="0.84" stopColor="#F2A024" />
                              <stop offset="0.98" stopColor="#EBBC38" />
                              <stop offset="1" stopColor="#EBC03B" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <p className="mt-2 h6">
                          <FormattedMessage id="no_data" />
                        </p>
                      </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="align-items-start justify-content-start bulkFormFooter">
                    <div className="form-group m-0  ">
                      <LoaderButton
                        id="SaveBulkDevices"
                        type="submit"
                        variant="primary"
                        disabled={getAddBulkCount > 0 ? true : false}
                        text="button_save"
                      />

                      <NavLink
                        className="btn btn-secondary ms-2"
                        to={RoutesConstants.DeviceRegister}
                      >
                        <FormattedMessage id="button_cancel" />
                      </NavLink>
                    </div>
                  </div>
                  <ResettingForm />
                </div>
              </Form>
            );
          }}
        ></Formik>
      </div>
    </>
  );
};

export default observer(BulkDeviceRegisterForm);
