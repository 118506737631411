import authStore from "../core/stores/auth-store";
import { IActionFormatter } from "../models/ICommon";
import { IPermissions } from "../models/response/IRoleResponse";

export const showIcons = (AddIcons: IActionFormatter[], module: number): IActionFormatter[] => {
    let userPermission:any = authStore.getAllPermission;
    let icons: IActionFormatter[] = AddIcons;
    if(JSON.stringify(userPermission) === "[]") {
        const permissions = localStorage.getItem('mzl_permissions'); 
        if(permissions)
            userPermission = JSON.parse(permissions);
      }
    let modulePermission = userPermission.filter((per:IPermissions)=> per.Module == module);
    if(modulePermission.length === 1){    
        if(modulePermission[0].Edit == false)
            icons= icons.filter((icon : IActionFormatter) => (icon.action !== 'edit'));    
        if(modulePermission[0].Delete == false)
            icons= icons.filter((icon : IActionFormatter) => (icon.action !== 'delete'));    
    }
    return icons;
  }

export const isOperationPermitted = (module: number, action: any) => {
    let userPermission:any = authStore.getAllPermission;
    if(JSON.stringify(userPermission) === "[]") {
      const permissions = localStorage.getItem('mzl_permissions'); 
      if(permissions)
          userPermission = JSON.parse(permissions);
    }
    let modulePermission = userPermission.filter((per:IPermissions)=> per.Module == module);
    if(modulePermission.length === 1)
        return modulePermission[0][action];
    return false;
}
  