/*    
<summary>
   This is the Group Stats Component which shows the overall stats of the Group & Devices Flow.
   Developer: Mohammad Saquib Khan, Created Date:24-April-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { SyntheticEvent, useEffect, useState } from "react";
import "./group.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import IPageInfo, { IActionFormatter, IPageLimitInfo } from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import Grid from "../../shared-components/Grid/Grid";
import { observer } from "mobx-react";
import AddGroupPopupHOC from "./hoc/add-edit-group-popup-hoc";
import SlidingPanel from "react-sliding-side-panel";
import "react-sliding-side-panel/lib/index.css";
import {
  ActionFormatter,
} from "../../shared-components/Grid/GridFormatter";
import {
  faLock,
  faPencilAlt,
  faTrashAlt,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { IGroupList } from "../../models/response/IGroupResponse";
import GroupStats from "./group-stats/group-stats";
import AddEditGroup from "./add-edit-group/add-edit-group";
import ConfirmPopup from "../../shared-components/confirmationPopup/confirm-popup";
import toast from "react-hot-toast";
import DeletePopupHOC from "../../shared-components/confirmationPopup/hoc/confirmation-popup-hoc";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import InfoPopup from "../../shared-components/infoPopup/info-popup";
import InfoPopupHOC from "../../shared-components/infoPopup/hoc/info-popup-hoc";
import userTypeEnum from "../../constants/user-type-enum";
import Pagination from "../../shared-components/Grid/Pagination";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import protocolEnum from "../../constants/protocol-enum";
import no_group from "../../assets/Images/svg/no-group.svg";
import userSettingEnum from "../../constants/user-setting-enum";
import DefaultPopupHOC from "../../shared-components/popup/default/default-popup-hoc";
import DefaultGroup from "./default-group/default-group";
import ConfirmGroupUnblock from "../../shared-components/popup/confirm/confirm-popup";
import UnblockGroupPopupHOC from "./hoc/unblock-group-popup-hoc";
import { useHistory } from "react-router-dom";
import { isOperationPermitted, showIcons } from "../../helpers/action-permission-helper";
import systemModuleEnum from "../../constants/system-module-enum";
import { getProtocol } from "../../constants/common-constants";

const Group = (props: any) => {
  const history = useHistory();
  const { preferencesStore, groupStore, authStore } =
    useStore();
  const {
    deleteGroupState,
    reset,
    DeleteGroupService,
    GroupList,
    addUpdateGroupstate,
    IsAssociatedState,
    IsAssociated,
    resetIsAssociatedState,
    GetGroupAssociatedInfoService,
    groupInfoId,
    allGroupList,
    GetGroupListService,
    inProgress,
    resetUnblockGroupState,
    resetGetgroupDetail,
    updateDefaultGroupState,
    updateDefaultGroup,
    updateGroupIMEILockState,
    resetUpdateGroupIMEILockState,
    UnblockGroupService, 
    unblockGroupState,
    GetGroupInfoByIdService,
  } = groupStore;
  const { getRoleType } = authStore;
  const {
    userSetting,
    language,
    setUpdateActiveGroup,
    setUserSetting,
    setUpdateTableView,
    setUpdateFilterActive,
  } = preferencesStore;
  let lang: string;
  const [openPanel, setOpenPanel] = useState(false);
  const [tableView, setTableView] = useState<boolean>(
    localStorage.getItem('mzl_tableView') == "false" ? false : true
  );
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedGroupName, setSelectedGroupName] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [groupIsDefault, setGroupIsDefault] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const groupList: Array<IGroupList> = allGroupList;
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.groupLimitConfig,
    isPageLimitChange: false,
  });
  const tableViewToggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tableView =
      localStorage.getItem('mzl_tableView') == "false" ? "true" : "false";
    setUpdateTableView(tableView);
    if (tableView == "false") {
      setTableView(false);
    } else {
      setTableView(true);
    }
  };
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetAllGroups = () => {
    GetGroupListService(pageInfo.currentPage, pageLimitInfo.pageLimit);
  };

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(
      userSettingEnum.GroupLimitConfig,
      parseInt(evt.target.value)
    );
  };
  /**
   * This function shows the add group pop up from where we can add new group and update existing group prootcol. we use id to identify
   * that we are adding or updating group.
   * "Id == -1" : we are adding new group
   * "Id != -1" : means we are updating existing group
   */
  const openAddGroupHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    closePanelHandler();
    setSelectedId(row.Id);
    props.addGroupToggleHandler();
  };
  /**
   * This function shows the Group data popup component and sets the seleted group Id
   */
  const openGroupDataHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenPanel(true);
    setSelectedId(row.Id);
  };

  /**
   * This function shows the Group data popup component and sets the seleted group Id
   */
  const openDefaultGroupPopupHandler = () => {
    closePanelHandler();
    props.defaultPopupToggleHandler();
  };
  /**
   * This function shows the Group data popup component and sets the seleted group Id
   */
  const updateDefaultGroupHandler = (defaultGroupId: number) => {
    updateDefaultGroup(defaultGroupId);
  };

  /**
   * This function deletes the group by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteGroupService(selectedId);
  };

  const infoPopupCloseHandler = () => {
    props.infoToggleHandler();
    setGroupIsDefault(false);
    setSelectedGroupName("");
  };

  /**
   * This function shows the delete group confirmation popup component and sets the seleted group Id
   */
  const deleteClickHandler = (
    row: IGroupList,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    closePanelHandler();
    GetGroupAssociatedInfoService(row.Id);
    setSelectedId(row.Id);
    setSelectedGroupName(row.Name);
    setGroupIsDefault(row.IsDefault);
    // props.confirmToggleHandler();
  };

  /**
   * This function  unblocks the group by calling an API.
   */
  const updateGroupStatusHandler = () => {
    UnblockGroupService(JSON.stringify(selectedRow) !== "{}"  ? selectedRow.Id : selectedId);
  }

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetAllGroups();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: GroupList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GroupList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetAllGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateGroupstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateGroupstate.success) {
      callGetAllGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateGroupstate.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteGroupState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteGroupState.success) {
      toast.success(formatMessage("deleted_success"));
      reset();
      props.confirmToggleHandler();
      callGetAllGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteGroupState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteGroupState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteGroupState.error) {
      toast.error(formatMessage(deleteGroupState.error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteGroupState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetAllGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "IsAssociatedState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (IsAssociatedState.success) {
      closePanelHandler();
      if (IsAssociated) props.infoToggleHandler();
      else if (groupIsDefault) props.infoToggleHandler();
      else props.confirmToggleHandler();
      resetIsAssociatedState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsAssociatedState.success]);

  /* The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "unblockGroupState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (unblockGroupState.success) {
      toast.success(formatMessage("updated_success"));
      props.unblockGroupToggleHandler();
      resetUnblockGroupState();
      callGetAllGroups();
    }
  }, [unblockGroupState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "updateGroupIMEILockState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (updateGroupIMEILockState.success) {
      resetUpdateGroupIMEILockState();
      callGetAllGroups();
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateGroupIMEILockState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "updateDefaultGroupState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (updateDefaultGroupState.success) {
      callGetAllGroups();
    }
  }, [updateDefaultGroupState.success]);

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedId(row.Id);
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelHandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
    resetGetgroupDetail();
    groupStore.reset();
  };

  const configHandler = () => {
    history.push({
    pathname: RoutesConstants.Configurations,
    state: {
      configId: groupInfoId.ConfigurationProtocol?.Id,
      returnPath: RoutesConstants.Group,
      from: RoutesConstants.Group,
    },
  });
};

  /**
   * This function provides formatting to the Row of Alert viewer Grid(Table) on the basis of alert Type
   */
  function rowClassFormat(row: IGroupList, rowIdx: number) {
    let className: string = "";
    if (row.IsBlocked == true) {
      className = "table-disabled";
    }
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className =
        "bg-row " +
        (row.IsBlocked ? " table-disabled SelectedRow" : "SelectedRow");
    }
    return className;
  }

  const actionIcons: IActionFormatter[] = showIcons([
    {
      dataField: "isEdit",
      handler: openAddGroupHandler,
      icon: faPencilAlt,
      isDisable: true,
      title: "edit",
      action: "edit",
      varient: "primary",
    },
    {
      dataField: "isDelete",
      handler: deleteClickHandler,
      icon: faTrashAlt,
      isDisable: true,
      title: "delete",
      action: "delete",
      varient: "danger",
    },
  ], systemModuleEnum.Group);
  /**
   * The column constant defines the column description for the user grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("id"),
      hidden: true,
    },
    {
      dataField: "Name",
      text: formatMessage("group_name"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
      formatter: (cell: any, row: any) => {
        return (
          <>
            {cell}
            {row.IsDefault && (
              <span className="badge bg-success p-1 ms-1">
                <FormattedMessage id="default" />
              </span>
            )}
          </>
        );
      },
    },
    {
      dataField: "Description",
      text: formatMessage("desc"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "ConfigurationProtocol.Name",
      text: formatMessage("config_name"),
    },
    {
      dataField: "IsLocked",
      text: formatMessage("imei_lock"),
      formatter: (cell: any) => (
        <span title={formatMessage(cell ? "lock" : "un_lock")}>
          <FontAwesomeIcon
            className="me-2 largeData"
            icon={cell ? faLock : faUnlock}
          />
        </span>
      ),
    },
    {
      dataField: "",
      formatter: ActionFormatter(actionIcons),
      text: formatMessage("actions"),
      hidden: actionIcons.length === 0 ? true : false,
    },
  ];

  const viewDevicesHandler = () => {
    setUpdateActiveGroup(selectedId);
    setUpdateFilterActive(true);
    props.history.push({
      pathname: RoutesConstants.Device,
      state: {
        groupId: selectedId,
        returnPath: RoutesConstants.Group,
        from: RoutesConstants.Group,
      },
    });
  };

  return (
    <React.Fragment>
      {props.showAddGroupPopup && (
        <AddEditGroup
          id={selectedId}
          modalClosed={props.addGroupToggleHandler}
        />
      )}
      {props.showConfirmPopup && (
        <ConfirmPopup
          title="delete_group"
          modalSubmit={deleteHandler}
          modalClosed={props.confirmToggleHandler}
          message="delete_group_confirm"
          dialogClassName="delete_popup"
          category="delete"
          isLoading={deleteGroupState.inProgress}
        />
      )}
      {props.showInfoPopup && (
        <InfoPopup
          title="alert"
          name={selectedGroupName}
          modalClosed={infoPopupCloseHandler}
          message={
            groupIsDefault
              ? "default_group_warn_confirm"
              : "group_delete_warn_confirm"
          }
          dialogClassName="alert_popup"
          isLoading={IsAssociatedState.inProgress}
        />
      )}
      {props.showDefaultPopup && (
        <DefaultGroup modalClosed={props.defaultPopupToggleHandler} />
      )}
      {props.showUnblockGroupPopup && (
        <ConfirmGroupUnblock
          title="group_status_title"
          modalSubmit={updateGroupStatusHandler}
          modalClosed={props.unblockGroupToggleHandler}
          message="unblock_group_status"
          note="unblock_group_msg"
          isLoading={unblockGroupState.inProgress}
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row g-0  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 ps-5 mb-0">
                        <FormattedMessage id="groups" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                    {groupList.length > 0 && (
                      <li>
                        <div className="form-check tableAccordian-switch p-0 m-0">
                          <input
                            className="ms-1 form-check-input"
                            type="checkbox"
                            role="switch"
                            data-testid="SwitchConfigurationView"
                            checked={tableView}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              closePanelHandler();
                              tableViewToggleHandler(e);
                            }}
                            id="flexSwitchCheckDefault"
                          />
                          <label htmlFor="flexSwitchCheckDefault">
                            <span title={formatMessage('table')}><svg
                              width="17"
                              height="17"
                              version="1.1"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M-0.4-0.4v32.7h32.7V-0.4H-0.4z M2.6,2.6h7.4v7.4H2.6V2.6z M13,2.6H19v7.4H13V2.6z M21.9,2.6h7.4v7.4h-7.4V2.6z M2.6,13h7.4
    V19H2.6V13z M13,13H19V19H13V13z M21.9,13h7.4V19h-7.4V13z M2.6,21.9h7.4v7.4H2.6V21.9z M13,21.9H19v7.4H13V21.9z M21.9,21.9h7.4
    v7.4h-7.4V21.9z"
                              />
                            </svg></span>

                            <span title={formatMessage('accordian')}><svg
                              width="17"
                              height="17"
                              version="1.1"
                              viewBox="0 0 17 17"
                            >
                              <path
                                d="M4 0v9h13v-9h-13zM16 8h-11v-7h11v7zM0 3h3v-3h-3v3zM1 1h1v1h-1v-1zM4 13h13v-3h-13v3zM5 11h11v1h-11v-1zM0 13h3v-3h-3v3zM1 11h1v1h-1v-1zM4 17h13v-3h-13v3zM5 15h11v1h-11v-1zM0 17h3v-3h-3v3zM1 15h1v1h-1v-1z"
                                fill="currentColor"
                              />
                            </svg></span>
                          </label>
                        </div>
                      </li>
                    )}

                    {/* We can remove this code when we don't need it. 
                    {
                      getRoleType != userTypeEnum.ReadOnly && 
                      <li
                        title={formatMessage("select_default_group")}
                        className="mx-2"
                      >
                        <LoaderButton
                          id="defaultGroup"
                          text="select_default_group"
                          onClick={openDefaultGroupPopupHandler}
                        />
                      </li>
                    } */}
                    {/* <li className="mx-2" title={formatMessage("refresh")}>
                      <LoaderIconButton
                        id="RefreshGroups"
                        svg={
                          <svg
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-arrow-repeat m-0"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                            <path
                              fillRule="evenodd"
                              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                            />
                          </svg>
                        }
                        className="btn-sm"
                        variant="light"
                      />
                    </li> */}
                    {
                      isOperationPermitted(systemModuleEnum.Group, "Add") &&
                      <li title={formatMessage("add_group")}>
                        <LoaderButton
                          id="AddGroups"
                          text="+_add_group"
                          onClick={(
                            event: React.MouseEvent<
                              HTMLButtonElement,
                              MouseEvent
                            >
                          ) => openAddGroupHandler({ Id: -1 }, event)}
                        />
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        {tableView == true ? (
          <div className="table_items_list">
            {inProgress && <FormLoader loading={inProgress} />}
            {groupList.length > 0 ? (
              <div
                className={`card mt-3 changingPanel ${
                  openPanel && "panelOpen"
                }`}
              >
                <div className="tableDesign row-eq-height tableDevices_group ">
                  <div className="table-responsive" data-testid="table">
                    {groupList && (
                      <Grid
                        data={groupList}
                        columns={columns}
                        keyField="Id"
                        loading={false}
                        rowClasses={rowClassFormat}
                        rowEvents={{ onClick: rowEvents }}
                      />
                    )}
                  </div>
                </div>
                {groupList.length > 0 ? (
                  <div className="row g-0 p-2 tableFooter">
                    <div className="col-sm-6 d-flex pt-1">
                      <FormattedMessage id="show" />
                      <select
                        data-testid="pageSelect"
                        className="pageLimit"
                        onChange={onPageLimitChanged}
                        value={pageLimitInfo.pageLimit}
                      >
                        {pageLimitOptions.map((op: any) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-sm-end">
                      <Pagination
                        isRefreshPage={false}
                        totalPages={pageInfo.totalPages}
                        pageLimit={pageLimitInfo.pageLimit}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                        setIsRefreshPage={setIsRefreshPage}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="message text-center">
                <img src={no_group} alt="" className="no_message" />
                <p className="noData text-muted text-center h4 mb-0">
                  <FormattedMessage id="no_groups" />
                </p>
                <p className="mb-3">
                  <FormattedMessage id="no_group" />
                </p>

                {
                  isOperationPermitted(systemModuleEnum.Group, "Add") &&
                  <LoaderButton
                    text="+_add_group"
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => openAddGroupHandler({ Id: -1 }, event)}
                  />
                }
              </div>
            )}
          </div>
        ) : (
          <div className="accordian_items_list pt-3">
            <div className="tableDesign row-eq-height2 tableDevices ">
              <div className="mt-1 accordion blocked-group" id="groupAccordion">
                {groupList.map((group: IGroupList, index: any) => {
                  return (
                    <React.Fragment key={index}>
                        <div className="accordion-item">
                          <div className={`accordion-header position-relative ${group.IsBlocked ? "blocked-group" : ""}`}  id={"accordion" + group.Id}
                            onClick={()=>{
                              GetGroupInfoByIdService(group.Id);
                            }}>
                            {
                              isOperationPermitted(systemModuleEnum.Group, "Edit") &&
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                data-testid={"EditGroupId" + group.Id}
                                onClick={(event: any) =>
                                  openAddGroupHandler(
                                    {
                                      Id: group.Id,
                                    },
                                    event
                                  )
                                }
                                className="css-i6dzq1 editIcon text-primary"
                              />
                            }
                            {
                              isOperationPermitted(systemModuleEnum.Group, "Delete") &&
                              <FontAwesomeIcon
                                  data-testid={"DeleteGroupId" + group.Id}
                                  icon={faTrashAlt}
                                  onClick={(event: any) =>
                                    deleteClickHandler(group, event)
                                  }
                                  className="css-i6dzq1 deleteIcon text-danger"
                                />
                            }

                            <button
                              className="fs14 bg-monoz-light accordion-button collapsed"
                              type="button"
                              data-testid={"OpenCloseGroup" + group.Id}
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapse" + group.Id}
                              aria-expanded="false"
                              aria-controls={"collapse" + group.Id}
                            >
                              <span
                                className="text-dark"
                                title={formatMessage(
                                  group.IsLocked ? "lock" : "un_lock"
                                )}
                              >
                                <FontAwesomeIcon
                                  className="me-2 largeData"
                                  icon={group.IsLocked ? faLock : faUnlock}
                                />
                              </span>
                              {index + 1 + ": " + group.Name}
                              <div>
                                { group.ConfigurationProtocol &&
                                  <>
                                    <h4 className="badge bg_source  m-0 p-1 ms-2">
                                      {
                                        protocolEnum[
                                          group.ConfigurationProtocol.SourceProtocol
                                        ]
                                      }
                                    </h4>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="#AAB7B8"
                                      className="bi bi-arrow-right mx-1"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                      />
                                    </svg>
                                    <h4 className="badge bg_destination m-0 p-1">
                                      { group.ConfigurationProtocol &&
                                        <span>
                                        {group.ConfigurationProtocol?.DestinationProtocol == protocolEnum.HTTPS ? <FormattedMessage id="webhook" /> : 
                                        group.ConfigurationProtocol?.DestinationProtocol == protocolEnum.AWSIoT ? <FormattedMessage id="aws_connector" /> : 
                                        group.ConfigurationProtocol?.DestinationProtocol == protocolEnum.AWSS3Bucket ? <FormattedMessage id="aws_s3_bucket" /> : 
                                        group.ConfigurationProtocol?.DestinationProtocol == protocolEnum.AWSKinesis ? <FormattedMessage id="aws_kinesis" /> :
                                        getProtocol(group.ConfigurationProtocol?.DestinationProtocol)}
                                      </span>
                                      }
                                    </h4>
                                  </>
                                }
                              </div>
                            </button>
                          </div>
                          <div
                            id={"collapse" + group.Id}
                            className="accordion-collapse collapse"
                            aria-labelledby={"accordion" + group.Id}
                            data-bs-parent="#groupAccordion"
                            data-bs-toggle="collapse" 
                          >
                            <div className="accordion-body">
                              <div className="p-3">
                                <div className="row">
                                  <div className="pt-2 col-4">
                                    <label className="text-muted bold">
                                      <FormattedMessage id={"group_name"} />:{" "}
                                    </label>
                                    <p className="mw-100">
                                      {groupInfoId.Name}
                                      {groupInfoId.IsDefault && (
                                        <span className="badge bg-success p-1 ms-1">
                                          <FormattedMessage id="default" />
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                  {
                                    groupInfoId.ConfigurationProtocol &&
                                    <>
                                      <div className="pt-2 col-4">
                                        <label className="text-muted bold">
                                          <FormattedMessage id="config_name" />:{" "}
                                        </label>
                                        <p className="mw-100">
                                        <a href="javascript:;" onClick={configHandler} className="d-block text-dark">{groupInfoId.ConfigurationProtocol?.Name}</a>
                                        </p>
                                      </div>
                                      <div className="pt-2 col-4">
                                        <label className="text-muted bold">
                                          <FormattedMessage id={"protocol"} />:{" "}
                                        </label>
                                        <p className="mw-100">
                                          <span>
                                            {`${
                                              protocolEnum[
                                                groupInfoId.ConfigurationProtocol
                                                  .SourceProtocol
                                              ]
                                            } `}{" "}
                                            <i className="fa fa-arrow-right text-muted"></i>{" "}
                                            {` ${
                                              protocolEnum[
                                                groupInfoId.ConfigurationProtocol
                                                  .DestinationProtocol
                                              ]
                                            } `}
                                          </span>
                                        </p>
                                      </div>
                                    </>
                                  }
                                </div>
                                <div className="row">
                                  {
                                    groupInfoId.Description && 
                                    <div className="pt-2 col-4">
                                        <label className="text-muted bold">
                                          <FormattedMessage id="desc" />:{" "}
                                        </label>
                                        <p className="mw-100">
                                          {groupInfoId.Description}
                                        </p>
                                      </div>
                                  }
                                    <div className="pt-2 col-4">
                                      <label className="text-muted bold">
                                        <FormattedMessage id="active_device" />:{" "}
                                      </label>
                                      <p className="mw-100">
                                      <h2>{groupInfoId?.ActiveDevices} / {groupInfoId?.TotalDevices}</h2>
                                      </p>
                                    </div>
                                  {groupInfoId.ConfigurationProtocol && groupInfoId.ConfigurationProtocol.HttpsParameters && (
                                    <div className="pt-2 col-4">
                                      <label className="text-muted bold">
                                        <FormattedMessage id={"host"} />:{" "}
                                      </label>
                                      <p className="me-3 text-break">
                                        {
                                          groupInfoId.ConfigurationProtocol
                                            .HttpsParameters.Host
                                        }
                                      </p>
                                    </div>
                                  )}
                                  {
                                    groupInfoId.IsBlocked && 
                                    <>
                                      <div className="pt-2 col-4">
                                        <label className="text-muted bold"><FormattedMessage id={"block_timestamp"} />: </label>
                                        <p className="mw-100">
                                          {groupInfoId.BlockedTimestamp}
                                        </p>
                                      </div>
                                      {
                                        isOperationPermitted(systemModuleEnum.Group, "Edit") &&
                                        <div className="pt-2 col-4">
                                          <label className="text-muted bold"><FormattedMessage id={"is_blocked"} />: </label>
                                          <button type="button" className="d-block btn btn-primary btn-sm"
                                            title={formatMessage("un_block")}
                                            onClick={()=>{
                                              setSelectedId(groupInfoId.Id);
                                              props.unblockGroupToggleHandler();}}>
                                            <FormattedMessage id="un_block" />
                                          </button>
                                        </div>
                                      }
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                    </React.Fragment>
                  );
                })}
                </div>
              {groupList.length == 0 && (
                <div className="message text-center">
                  <img src={no_group} alt="" className="no_message" />
                  <p className="noData text-muted text-center h4 mb-0">
                    <FormattedMessage id="no_groups" />
                  </p>
                  <p className="mb-3">
                    <FormattedMessage id="no_group" />
                  </p>
                  {
                    isOperationPermitted(systemModuleEnum.Group, "Add") &&
                    <LoaderButton
                      text="+_add_group"
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => openAddGroupHandler({ Id: -1 }, event)}
                    />
                  }
                </div>
              )}
            </div>
            {groupList.length > 0 ? (
              <div className="row g-0 p-2 tableFooter">
                <div className="col-sm-6 d-flex pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={false}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <GroupStats 
            selectedData={selectedRow} 
            showConfirmUnblockPopup={props.unblockGroupToggleHandler} 
            closePanelHandler={closePanelHandler}
            viewDevicesHandler={viewDevicesHandler}/>
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default UnblockGroupPopupHOC(DefaultPopupHOC(
  InfoPopupHOC(DeletePopupHOC(AddGroupPopupHOC(observer(Group))))
));
