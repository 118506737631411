/*    
<summary>
   This component is Used for Activating self created user Account.
   Developer: Mohammad Saquib Khan, Created Date:22-July-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import "./organization-account-activation.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "react-sliding-side-panel/lib/index.css";
import successIcon from "../../assets/Images/svg/activatesucess.svg";
import mailsentIcon from "../../assets/Images/svg/arrow.svg";
import noMessageIcon from "../../assets/Images/svg/interface-design.svg";
import { formatMessage } from "../../translations/format-message";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "../../contexts/StoreProvider";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import { resendMailTimer } from "../../constants/common-constants";

const OrganizationAccountActivation = (props: any) => {
  const { tenantStore } = useStore();
  const {
    ActivateOrganizationService,
    activateOrganizationAccountState,
    resetAccountActivationState,
    ResendAccountActivateLinkService,
    resendActivationLinkState,
    resetAccountActivationLink,
    resetAddTrialTenantState,
  } = tenantStore;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const EmailId = params.get("EmailId");
  const VerificationCode = params.get("VerificationCode");
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const [timer, setTimer] = useState(resendMailTimer);
  const [isDisabled, setIsDisabled] = useState(false);
  const [success, setSuccess] = useState<boolean>(true);

  useEffect(() => {
    // resetAccountActivationState();
    if (EmailId && VerificationCode) {
      ActivateOrganizationService(VerificationCode, EmailId);
    }
    else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval: any;
    if (timer > 0) {
      setIsDisabled(true);
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setIsDisabled(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const handleButtonClick = () => {
    if (EmailId && EmailId != "") {
      resetAccountActivationLink();
      ResendAccountActivateLinkService({ Email: EmailId });
    }
  };

  useEffect(() => {
    if (resendActivationLinkState.success) {
      resetAccountActivationState();
      setSuccess(false);
      setTimer(resendMailTimer);
    }
  }, [resendActivationLinkState.success]);

  useEffect(() => {
    if (resendActivationLinkState.error) {
      resetAccountActivationState();
      setSuccess(false);
    }
  }, [resendActivationLinkState.error]);

  // useEffect(()=>{
  //   if(activateOrganizationAccountState.error || error){
  //     setError(false);
  //   }
  // })

  return (
    <div className="bg-img1 text-start background">
      <div className="d-flex justify-content-center align-items-center vh-100">
        {(activateOrganizationAccountState.inProgress || resendActivationLinkState.inProgress) &&
          <FormLoader loading={(activateOrganizationAccountState.inProgress || resendActivationLinkState.inProgress)} />}

        <div className="text-center activate_message_wrap">
          {
            activateOrganizationAccountState.inProgress &&
            <>
              <img src={successIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4">
                <FormattedMessage id="activating_account_message" />
              </p>
            </>
          }
          {
            (success && activateOrganizationAccountState.success) &&
            <>
              <img src={mailsentIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4 display-resendMailTimer ">
                <FormattedMessage id="account_created_successfully" />
              </p>
            </>
          }
          {resendActivationLinkState.success && (
            <>
              <img src={noMessageIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4 text-success">
                <FormattedMessage id="AccountVerificationMailSent" />
              </p>
            </>
          )}

          {resendActivationLinkState.error && (
            <>
              <img src={noMessageIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4 text-danger">
                {formatMessage(resendActivationLinkState.error)}
              </p>
            </>
          )}
          {
            error &&
            <>
              <img src={noMessageIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4 text-danger">
                <FormattedMessage id="invalind_link" />
              </p>
            </>
          }
          {
            activateOrganizationAccountState.error &&
            <>
              <img src={noMessageIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4 text-danger">
                {formatMessage(activateOrganizationAccountState.error)}
              </p>
            </>
          }
          <div className="d-flex align-items-center justify-content-center">
            {
              ((activateOrganizationAccountState.error && activateOrganizationAccountState.error != "AlreadyVerified") || resendActivationLinkState.error) &&
              <button
                onClick={handleButtonClick}
                disabled={isDisabled}
                className="btn btn-link col-md-6"
              >
                <FormattedMessage id="resend_link" />
                {isDisabled && <span className="ms-2">({timer})</span>}
              </button>
            }

            <button
              className={`btn btn-primary p-2`}
              onClick={() => {
                resetAddTrialTenantState();
                resetAccountActivationState();
                resetAccountActivationLink();
                localStorage.clear();
                setTimeout(() => {
                  history.push(RoutesConstants.Login);
                });
              }}
            >
              <FormattedMessage id={"back_to_login"} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(OrganizationAccountActivation);
