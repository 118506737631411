import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import {
  IFotaTenantRelease,
  IFotaTenantReleaseDetail,
  IFotaTenantReleaseList,
  IFotaTenantReleaseListVM,
  IMzlRelease,
  IMzlReleaseList,
  IMzlReleaseListVM,
  ITntFotaVm,
} from "../../models/response/IFotaResponse";
import * as baseService from "../service/base-service";
import URLConstants from "../../constants/url.constants";
import toast from "react-hot-toast";
import { formatMessage } from "../../translations/format-message";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { IGroupVM } from "../../models/response/IGroupResponse";
import { IFotaSysAdmin, IFotaTenantAdmin } from "../../models/Form/IFotaForm";
import { initialFotaMzlState, initialFotaTenantState } from "../initial-state/get-all-fota-state";
import { getLanguageFromLocalStorage } from "../../helpers/local-stotrage-helper";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../constants/common-constants";
import {initialValuesSysAdmin, initialValuesTenantAdmin, initialValuesTenantLatestReleaseAdmin} from '../initial-state/add-edit-system-admin-fota-state';

export class FotaStore {
  initialStateValue = {
    success: false,
    error: '',
    inProgress: false
  }
  error: string | null = null;
  inProgress: boolean = false;
  addUpdateState: IObservableInitialState = { ...this.initialStateValue }

  fotaMzlReleaseList: IMzlReleaseListVM = initialFotaMzlState;
  fotaMzlReleaseState: IObservableInitialState = { ...this.initialStateValue }
  fotaMzlReleaseLatest: IMzlRelease | undefined = undefined;
  fotMzlRelease: IMzlRelease | undefined = undefined;
  
  fotaTenantReleaseList: IFotaTenantReleaseListVM = initialFotaTenantState;
  fotaTenantReleaseState: IObservableInitialState = { ...this.initialStateValue }
  fotaTenantReleaseLatest: IFotaTenantRelease | undefined = undefined;
  fotaTenantRelease: ITntFotaVm | undefined = undefined;
  

  constructor() {
    makeObservable(this, {
      fotaMzlReleaseList: observable,
      fotMzlRelease: observable,
      fotaMzlReleaseState: observable,
      fotaMzlReleaseLatest: observable,
      fotaTenantReleaseList: observable,
      fotaTenantRelease: observable,
      fotaTenantReleaseState: observable,
      fotaTenantReleaseLatest: observable,
      addUpdateState: observable,
      inProgress: observable,
      error: observable,
      GetFotaMzlReleaseListService: action,
      GetFotaMzlReleaseByIdService: action,
      AddUpdateFotaMzlServiceSysAdminService: action,
      UpdateFotaMzlServiceSysAdminService: action,
      
      GetFotaTenantReleaseListService: action,
      GetFotaTenantReleaseByIdService: action,
      AddFotaTenantReleaseService: action,
      UpdateFotaTenantReleaseService: action,
      
      reset: action,
      resetAddUpdateState: action,
      
      resetFotaMzlReleaseState: action,
      resetFotaMzlReleaseLatestState: action,
      
      resetFotaTenantReleaseState: action,
      resetFotaTenantReleaseLatestState: action,
      
      getFotaMzlList: computed,
      getFotaTenantList: computed,
      getFotaMzlRelease: computed,
      getFotaMzlReleaseLatest: computed,
      getFotaTenantRelease: computed,
      getFotaTenantReleaseDetail: computed,
      getFotaTenantReleaseLatest: computed,
    });
  }

  /**
   * Fetches a list of Fota items.
   * @param currentPage - Current page number.
   * @param pageSize - Number of items per page.
   */
  GetFotaMzlReleaseListService = (currentPage: number, pageSize: number) => {
    runInAction(() => (this.inProgress = true));
    const url = `${URLConstants.FotaMzlReleaseList}?PageNo=${currentPage}&PageSize=${pageSize}`;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IMzlReleaseListVM>>) => {
        if (response.data.Error) {
          runInAction(
            () => (this.error = response.data.Message)
          );
        } else {
          runInAction(() => {
            this.fotaMzlReleaseList = response.data.Data;
          });
        }
      })
      .catch((err) => {
        runInAction(() => (this.error = err));
      })
      .finally(() => {
        runInAction(() => (this.inProgress = false));
      });
  };

  /**
    * This function is used to map fotaMzlReleaseList to Fota List suitable for Grid Component.
    * @returns Initial Fota Details
    */
  get getFotaMzlList(): IMzlReleaseList[] {
    if (this.fotaMzlReleaseList && this.fotaMzlReleaseList?.FotaReleases.length > 0)
        return this.fotaMzlReleaseList?.FotaReleases.map((fota) => {
            let language = getLanguageFromLocalStorage();
            return {
                Id: fota.Id,
                Description: fota.Description ? fota.Description : "",
                ModemVersion: fota.ModemVersion ? fota.ModemVersion : "",
                ModemVersionFile: fota.ModemVersionFile ? fota.ModemVersionFile : "",
                ModemVersionFileType: fota.ModemVersionFileType !== null ? fota.ModemVersionFileType : 0,
                ModemReleasedDate: fota.ModemReleasedDate ? moment(fota.ModemReleasedDate).format(DATE_TIME_FORMAT) : "",
                JetVersion: fota.JetVersion ? fota.JetVersion : "",
                JetVersionFile: fota.JetVersionFile ? fota.JetVersionFile : "",
                JetVersionFileType: fota.JetVersionFileType !== null ? fota.JetVersionFileType : 0,
                JetReleasedDate: fota.JetReleasedDate ? moment(fota.JetReleasedDate).format(DATE_TIME_FORMAT) : "",
                IsJetVersionSameAsPrevious: fota.IsJetVersionSameAsPrevious,
                IsModemVersionSameAsPrevious: fota.IsModemVersionSameAsPrevious,
                CreatedBy: fota.CreatedBy,
                UpdatedBy: fota.UpdatedBy,
                CreatedDate: fota.CreatedDate,
                UpdatedDate: fota.UpdatedDate,
                Language: language,
                isEdit: true,
                isData: true,
            }
        })
    return [];
  }

  /**
   * Fetches Fota item details by ID.
   * @param id - ID of the Fota item.
   */
  GetFotaMzlReleaseByIdService = (id: number) => {
    runInAction(() => (this.fotaMzlReleaseState.inProgress = true));
    const url = `${URLConstants.FotaMzlRelease}/${id}`;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IMzlRelease>>) => {
        if (response.data.Error) {
          // runInAction(
          //   () => (this.fotaMzlReleaseState.error = response.data.Message)
          // );
          toast.error(formatMessage(response.data.Message));
        } else {
          runInAction(() => {
            this.fotMzlRelease = {
              ...response.data.Data,
              IsJetVersionSameAsPrevious: false,
              IsModemVersionSameAsPrevious: false,
            };
            this.fotaMzlReleaseState.success = true;
          });
        }
      })
      .catch((err) => {
        toast.error(formatMessage(err));
        // runInAction(() => (this.fotaMzlReleaseState.error = err));
      })
      .finally(() => {
        runInAction(() => (this.fotaMzlReleaseState.inProgress = false));
      });
  };

  /**
   * Returns fotMzlRelease which were fetched by Id
   */
  get getFotaMzlRelease(): IFotaSysAdmin {
    if (this.fotMzlRelease) return {
      ...this.fotMzlRelease,
      ModemVersionFileContent: this.fotMzlRelease?.ModemVersionFile ? this.fotMzlRelease?.ModemVersionFile : "",
      JetVersionFileContent: this.fotMzlRelease?.JetVersionFile ? this.fotMzlRelease?.JetVersionFile : "",
      JetVersionFile: "",
      ModemVersionFile: "",
    };
    return initialValuesSysAdmin;
  }

  resetFotaMzlReleaseState = () =>{
    this.fotMzlRelease = undefined;
    this.fotaMzlReleaseState = {...this.initialStateValue};
  }
  /**
   * for getting latest fota update
   */

  GetFotaMzlLatestReleaseService = () => {
    runInAction(() => (this.fotaMzlReleaseState.inProgress = true));
    const url = URLConstants.FotaMzlLatestRelease;
    baseService
      .getRequest(url)
      .then((response) => {
        if (response.data.Error) {
          toast.error(formatMessage(response.data.Message));
          // runInAction(
          //   () => (this.fotaMzlReleaseState.error = response.data.Message)
          // );
        } else {
          runInAction(() => {
            this.fotaMzlReleaseLatest = response.data.Data;
            this.fotaMzlReleaseState.success = true;
          });
        }
      })
      .catch((err) => {
        toast.error(formatMessage(err));
        // runInAction(() => (this.fotaMzlReleaseState.error = err));
      })
      .finally(() => {
        runInAction(() => (this.fotaMzlReleaseState.inProgress = false));
      });
  };

  get getFotaMzlReleaseLatest() {
    if (this.fotaMzlReleaseLatest) return {
      ...this.fotaMzlReleaseLatest,
      ModemVersionFileContent: this.fotaMzlReleaseLatest?.ModemVersionFile ? this.fotaMzlReleaseLatest?.ModemVersionFile : "",
      JetVersionFileContent: this.fotaMzlReleaseLatest?.JetVersionFile ? this.fotaMzlReleaseLatest?.JetVersionFile : "",
      JetVersionFile: "",
      ModemVersionFile: "",
    };
    return {...initialValuesSysAdmin}
  }

  resetFotaMzlReleaseLatestState = () =>{
    this.fotaMzlReleaseLatest = undefined;
    this.fotaMzlReleaseState = {...this.initialStateValue};
  }

  /**
   * Adds or updates a Fota for SystemAdmin.
   * @param data - Fota item data.
   */
  AddUpdateFotaMzlServiceSysAdminService = (data: IFotaSysAdmin) => {
    this.addUpdateState.inProgress = true;
    const url = URLConstants.FotaMzlRelease;
    return baseService.postRequest(url, {...data}).then(
        (
          response: IApiResponse<IApiSuccessResponse<boolean>>
        ) => {
          if (response.data.Error) {
            let err = response.data.Message
            if (err.includes(":")) {
                let errorMess = err.split(":");
                toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
            } else { toast.error(formatMessage(err)); }
          } else {
            this.addUpdateState.success = true;
          }
        }
      )
      .catch((err: any) => {
        if (err.includes(":")) {
            let errorMess = err.split(":");
            toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
        } else { toast.error(formatMessage(err)); }
      })
      .finally(() => {
        this.addUpdateState.inProgress = false;
      });
  };

  /**
   * Updates a Fota for SystemAdmin.
   * @param data - Fota item data.
   */
  UpdateFotaMzlServiceSysAdminService = (data: IFotaSysAdmin, id: number) => {
    this.addUpdateState.inProgress = true;
    data.Id = id;
    const url = URLConstants.FotaMzlRelease;
    return baseService.putRequest(url, data)
      .then(
        (
          response: IApiResponse<IApiSuccessResponse<boolean>>
        ) => {
          if (response.data.Error) {
            let err = response.data.Message
            if (err.includes(":")) {
                let errorMess = err.split(":");
                toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
            } else { toast.error(formatMessage(err)); }
          } else {
            this.addUpdateState.success = true;
          }
        }
      )
      .catch((err: any) => {
        if (err.includes(":")) {
            let errorMess = err.split(":");
            toast.error( formatMessage(errorMess[0]?.trim()) + " : " + errorMess[1]);
        } else { toast.error(formatMessage(err)); }
      })
      .finally(() => {
        this.addUpdateState.inProgress = false;
      });
  };

  resetAddUpdateState = () =>{
    this.addUpdateState = {...this.initialStateValue};
  }

  /**
   * Fetches a list of Fota items.
   * @param currentPage - Current page number.
   * @param pageSize - Number of items per page.
   */
  GetFotaTenantReleaseListService = (currentPage: number, pageSize: number) => {
    runInAction(() => (this.inProgress = true));
    const url = `${URLConstants.FotaTenantReleaseList}?PageNo=${currentPage}&PageSize=${pageSize}`;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IFotaTenantReleaseListVM>>) => {
        if (response.data.Error) {
          runInAction(
            () => (this.error = response.data.Message)
          );
        } else {
          runInAction(() => {
            this.fotaTenantReleaseList = response.data.Data;
          });
        }
      })
      .catch((err) => {
        runInAction(() => (this.error = err));
      })
      .finally(() => {
        runInAction(() => (this.inProgress = false));
      });
  };

  /**
    * This function is used to map fotaTenantReleaseList to Fota List suitable for Grid Component.
    * @returns Initial Fota Details
    */
  get getFotaTenantList(): IFotaTenantReleaseList[] {
    if (this.fotaTenantReleaseList && this.fotaTenantReleaseList?.FotaReleases.length > 0)
        return this.fotaTenantReleaseList?.FotaReleases.map((fota) => {
            let language = getLanguageFromLocalStorage();
            return {
                Id: fota.Id,
                Description: fota.Description ? fota.Description : "",
                MzlFotaRelease: fota.MzlFotaRelease,
                ModemVersion: fota.MzlFotaRelease ? fota.MzlFotaRelease.ModemVersion : "",
                ModemVersionFileType: fota.MzlFotaRelease ? fota.MzlFotaRelease.ModemVersionFileType : 0,
                ModemVersionFile: fota.MzlFotaRelease ? fota.MzlFotaRelease.ModemVersionFile : "",
                ModemReleasedDate: (fota.MzlFotaRelease && fota.MzlFotaRelease.ModemReleasedDate) ? moment(fota.MzlFotaRelease.ModemReleasedDate).format(DATE_TIME_FORMAT) : "",
                JetVersion: fota.MzlFotaRelease ? fota.MzlFotaRelease.JetVersion : "",
                JetVersionFile: fota.MzlFotaRelease ? fota.MzlFotaRelease.JetVersionFile : "",
                JetVersionFileType: fota.MzlFotaRelease ? fota.MzlFotaRelease.JetVersionFileType : 0,
                JetReleasedDate: (fota.MzlFotaRelease && fota.MzlFotaRelease.JetReleasedDate) ? moment(fota.MzlFotaRelease.JetReleasedDate).format(DATE_TIME_FORMAT) : "",
                HostVersion: fota.HostVersion,
                HostVersionFile: fota.HostVersionFile,
                HostReleasedDate: fota.HostReleasedDate,
                IsAppliedOnGroup: fota.IsAppliedOnGroup,
                ICCIDs: fota.ICCIDs,
                CreatedBy: fota.CreatedBy,
                UpdatedBy: fota.UpdatedBy,
                CreatedDate: fota.CreatedDate ? moment(fota.CreatedDate).format(DATE_TIME_FORMAT) : "",
                UpdatedDate: fota.UpdatedDate,
                Language: language,
                isEdit: true,
                isData: true,
            }
        })
    return [];
  }

  /**
   * Fetches Fota item details by ID.
   * @param id - ID of the Fota item.
   */
  GetFotaTenantReleaseByIdService = (id: number) => {
    runInAction(() => (this.fotaTenantReleaseState.inProgress = true));
    const url = `${URLConstants.FotaTenantRelease}/${id}`;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<ITntFotaVm>>) => {
        if (response.data.Error) {
          // runInAction(
          //   () => (this.fotaTenantReleaseState.error = response.data.Message)
          // );
          toast.error(formatMessage(response.data.Message));
        } else {
          runInAction(() => {
            this.fotaTenantRelease = {
              ...response.data.Data,
            };
            this.fotaTenantReleaseState.success = true;
          });
        }
      })
      .catch((err) => {
        toast.error(formatMessage(err));
        // runInAction(() => (this.fotaTenantReleaseState.error = err));
      })
      .finally(() => {
        runInAction(() => (this.fotaTenantReleaseState.inProgress = false));
      });
  };

  /**
   * Returns fotaTenantRelease which were fetched by Id
   */
  get getFotaTenantRelease(): IFotaTenantAdmin {
    if (this.fotaTenantRelease) 
      return {
        HostVersion: this.fotaTenantRelease.HostVersion,
        HostVersionFile: "",
        HostVersionFileType: 0,
        HostVersionFileContent: this.fotaTenantRelease?.HostVersionFile ? this.fotaTenantRelease?.HostVersionFile : "",
        Description: this.fotaTenantRelease.Description,
        IsHostVersionSameAsPrevious: false,
        MzlFotaReleaseId: this.fotaTenantRelease.MzlFotaRelease ? this.fotaTenantRelease.MzlFotaRelease.Id : -1,
        IsAppliedOnGroup: this.fotaTenantRelease.IsAppliedOnGroup.toString(),
        ICCIDs: this.fotaTenantRelease.ICCIDs,
        ICCIDsFile: "",
        ICCIDsFileContent: this.fotaTenantRelease.ICCIDs ? this.fotaTenantRelease.ICCIDs : [],
        GroupId: this.fotaTenantRelease.GroupId,
        ModemVersion: this.fotaTenantRelease.MzlFotaRelease ? this.fotaTenantRelease.MzlFotaRelease?.ModemVersion : "",
        JetVersion: this.fotaTenantRelease.MzlFotaRelease ? this.fotaTenantRelease.MzlFotaRelease?.JetVersion : "",
    };
    return initialValuesTenantAdmin;
  }
  
  /**
   * Returns fotaTenantReleaseDetail which were fetched by Id
   */
  get getFotaTenantReleaseDetail(): IFotaTenantReleaseDetail | undefined {
    if (this.fotaTenantRelease) 
      return {
        HostVersion: this.fotaTenantRelease.HostVersion,
        HostVersionFileType: this.fotaTenantRelease.HostVersionFileType,
        HostReleasedDate: this.fotaTenantRelease.HostReleasedDate ? moment(this.fotaTenantRelease.HostReleasedDate).format(DATE_TIME_FORMAT) : "",
        Description: this.fotaTenantRelease.Description,
        IsHostVersionSameAsPrevious: false,
        MzlFotaReleaseId: this.fotaTenantRelease.MzlFotaRelease ? this.fotaTenantRelease.MzlFotaRelease.Id : -1,
        IsAppliedOnGroup: this.fotaTenantRelease.IsAppliedOnGroup.toString(),
        ICCIDs: this.fotaTenantRelease.ICCIDs,
        GroupId: this.fotaTenantRelease.GroupId,
        ModemVersion: this.fotaTenantRelease.MzlFotaRelease ? this.fotaTenantRelease.MzlFotaRelease?.ModemVersion : "",
        ModemReleasedDate: this.fotaTenantRelease.MzlFotaRelease ? 
          (this.fotaTenantRelease?.MzlFotaRelease?.ModemReleasedDate ? moment(this.fotaTenantRelease?.MzlFotaRelease?.ModemReleasedDate).format(DATE_TIME_FORMAT) : "" ) : "",
        JetVersion: this.fotaTenantRelease.MzlFotaRelease ? this.fotaTenantRelease.MzlFotaRelease?.JetVersion : "",
        JetReleasedDate: this.fotaTenantRelease.MzlFotaRelease ? 
            (this.fotaTenantRelease?.MzlFotaRelease?.JetReleasedDate ? moment(this.fotaTenantRelease?.MzlFotaRelease?.JetReleasedDate).format(DATE_TIME_FORMAT) : "" ) : "",
        
    };
  }

  resetFotaTenantReleaseState = () =>{
    this.fotaTenantRelease = undefined;
    this.fotaTenantReleaseState = {...this.initialStateValue};
  }
  /**
   * for getting latest fota update
   */

  GetFotaTenantLatestReleaseService = () => {
    runInAction(() => (this.fotaTenantReleaseState.inProgress = true));
    const url = URLConstants.FotaTenantLatestRelease;
    baseService
      .getRequest(url)
      .then((response) => {
        if (response.data.Error) {
          toast.error(formatMessage(response.data.Message));
          // runInAction(
          //   () => (this.fotaTenantReleaseState.error = response.data.Message)
          // );
        } else {
          runInAction(() => {
            this.fotaTenantReleaseLatest = response.data.Data;
            this.fotaTenantReleaseState.success = true;
          });
        }
      })
      .catch((err) => {
        // runInAction(() => (this.fotaTenantReleaseState.error = err));
        toast.error(formatMessage(err));
      })
      .finally(() => {
        runInAction(() => (this.fotaTenantReleaseState.inProgress = false));
      });
  };

  get getFotaTenantReleaseLatest() {
    if (this.fotaTenantReleaseLatest) return {
      ...this.fotaTenantReleaseLatest,
      HostVersionFileContent: this.fotaTenantReleaseLatest?.HostVersionFile ? this.fotaTenantReleaseLatest?.HostVersionFile : "",
      HostVersionFile: "",
      ModemVersion: this.fotaTenantReleaseLatest.MzlFotaRelease ? this.fotaTenantReleaseLatest.MzlFotaRelease?.ModemVersion : "",
      JetVersion: this.fotaTenantReleaseLatest.MzlFotaRelease ? this.fotaTenantReleaseLatest.MzlFotaRelease?.JetVersion : "",
      MzlFotaReleaseId: this.fotaTenantReleaseLatest.MzlFotaRelease ? this.fotaTenantReleaseLatest.MzlFotaRelease?.Id : -1,
    };
    return {...initialValuesTenantLatestReleaseAdmin}
  }

  resetFotaTenantReleaseLatestState = () =>{
    this.fotaTenantReleaseLatest = undefined;
    this.fotaTenantReleaseState = {...this.initialStateValue};
  }

  /**
   * Adds or updates a Fota for SystemAdmin.
   * @param data - Fota item data.
   */
  AddFotaTenantReleaseService = (data: IFotaTenantAdmin) => {
    this.addUpdateState.inProgress = true;
    const url = URLConstants.FotaTenantRelease;
    return baseService.postRequest(url, {...data}).then(
        (
          response: IApiResponse<IApiSuccessResponse<boolean>>
        ) => {
          if (response.data.Error) {
            toast.error(formatMessage(response.data.Message));
          } else {
            this.addUpdateState.success = true;
          }
        }
      )
      .catch((err: any) => {
        toast.error(formatMessage(err));
      })
      .finally(() => {
        this.addUpdateState.inProgress = false;
      });
  };

  /**
   * Updates a Fota for SystemAdmin.
   * @param data - Fota item data.
   */
  UpdateFotaTenantReleaseService = (data: IFotaTenantAdmin, id: number) => {
    this.addUpdateState.inProgress = true;
    const url = URLConstants.FotaTenantRelease;
    return baseService.putRequest(url, {...data, Id: id})
      .then(
        (
          response: IApiResponse<IApiSuccessResponse<boolean>>
        ) => {
          if (response.data.Error) {
            toast.error(formatMessage(response.data.Message));
          } else {
            this.addUpdateState.success = true;
          }
        }
      )
      .catch((err: any) => {
        toast.error(formatMessage(err));
      })
      .finally(() => {
        this.addUpdateState.inProgress = false;
      });
  };

  /**
   * Resets all observables to their initial values.
   */
  reset = () => {
    this.fotaMzlReleaseState = {...this.initialStateValue};
    this.addUpdateState = {...this.initialStateValue};
    this.fotaMzlReleaseList = initialFotaMzlState;
    this.fotMzlRelease = undefined;
    this.fotaMzlReleaseLatest = undefined;
  };

}
const fotaStore = new FotaStore();
export default fotaStore;
