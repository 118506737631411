/*    
<summary>
  This functional component "Publish Message" renders the device details.
  Developer:Mohammad Saquib Khan, Created Date: 06-Mar-2024
</summary>
<returns>Returns JSX</returns>
*/

import "./downlink-form.css";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../contexts/StoreProvider";
import config from "../../../../helpers/config-helper";
import { Field, Form, Formik } from "formik";
import { publishMessageValidationSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../../shared-components/Label/Label";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormTextArea from "../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { QOS } from "../../../../constants/options.constant";
import FormikFormCheckBox from "../../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;
const dateFormat = appConfig.REACT_APP_DATE_FORMAT;

interface IProps {
  iccid: string;
  orgId: string;
  initialValues: any;
  submitHandler: (values: any) => void;
  isLoading: boolean;
  modalClosed: () => void;
}

const DownlinkForm = (props: any) => {
  const { deviceStore, oneNceStore, authStore, preferencesStore } = useStore();
  const { publishMessageState } = deviceStore;
  let value = `/${props.orgId}/${props.iccid}/sub`

  return (
    <React.Fragment>
      {
        <>
          <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validateOnBlur={false}
            validationSchema={publishMessageValidationSchema}
            onSubmit={async (values: any) => {
              props.submitHandler({ ...values });
            }}
            render={({ values }) => {
              return (
                <Form className="user-form p-3">
                  <div className="form-group formSet mb-1">
                    <Label
                      className="label-style col-form-label"
                      label="topic"
                    />
                    <div className="input-group">
                    <span className="input-group-text">{value}</span>
                      <Field
                        data-testid="topic"
                        id="custom"
                        className="form-control"
                        name="Topic"
                        placeholder="topic"
                        type="text"
                        validcharacter={[
                          "s",
                          "_",
                          "~",
                          "@",
                          "*",
                          "(",
                          ")",
                          "-",
                          "[",
                          "]",
                          "{",
                          "}",
                          "/"
                        ]}
                        as={FormikFormInput}
                      />
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label className="label-style">
                        <FormattedMessage id={"format"} />
                        </label>
                      <div className="row mt-2">
                        <div className="col-6 btn-radioCheckbox">
                          <Field
                            type="radio"
                            value="0"
                            data-testid="json"
                            name="Format"
                            id="JSON"
                            className="nav-link active"
                          />
                          <label htmlFor="JSON">
                            <FormattedMessage id="json" />
                          </label>
                        </div>

                        <div className="col-6 btn-radioCheckbox">
                          <Field
                            type="radio"
                            value="1"
                            data-testid="raw"
                            name="Format"
                            id="Raw"
                            className="nav-link active"
                          />
                          <label htmlFor="Raw">
                            <FormattedMessage id="raw" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group formSet col-lg-6">
                      <label className="label-style">
                          <FormattedMessage id="qos" />
                        </label>
                        <Field
                          id="qos"
                          className="form-control"
                          name="QOS"
                          options={QOS}
                          as={FormikFormSelect}
                        />
                    </div>
                  </div>
                  <div className="form-group">
                    <Label
                      className="label-style"
                      label="message"
                    />
                    <Field
                      data-testid="message"
                      id="Message"
                      className="form-control"
                      name="Message"
                      placeholder="message"
                      type="text"
                      rows="6"
                      as={FormikFormTextArea}
                    />
                  </div>
                  <div className="col-12 my-1">
                    <div className="checkbox">
                      <label>
                        <Field
                          data-testid="RetainLastMessage"
                          className="form-control"
                          name="RetainLastMessage"
                          role="switch"
                          type="checkbox"
                          id="RetainLastMessage"
                          as={FormikFormCheckBox}
                        />
                        <span className="cr">
                          <i className="cr-icon fas fa-check"></i>
                        </span>
                      </label>
                      <label htmlFor="RetainLastMessage" className="h">
                        <FormattedMessage id="retain_last_message" />
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end pb-2">
                    <LoaderButton type="submit" className="me-2" isLoading={publishMessageState.inProgress} id="submit_publish" text="publish" />
                    <button
                      data-testid="cancel_publish"
                      className="btn btn-secondary"
                      type="button"
                      onClick={props.modalClosed}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FormattedMessage id="button_cancel" />
                    </button>

                  </div>
                </Form>
              );
            }}
          ></Formik>
        </>
      }
    </React.Fragment>
  );
};

export default observer(DownlinkForm);
