/*    
<summary>
  This functional component "AddUpdateOrder" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:28-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/StoreProvider";
import { formatMessage } from "../../../translations/format-message";
import { cloneDeep } from "lodash";
import AddUpdateOrderForm from "../forms/add-edit-order-form";
import { IOrderList, IOrderPayment, IOrderVM } from "../../../models/response/IOrderResponse";
import { getTenantIdFromLocalStorage } from "../../../helpers/local-stotrage-helper";
import AddOrderPaymentForm from "../forms/add-order-payment-form";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants/common-constants";

interface IProps {
  selectedData: IOrderList;
  modalClosed: () => void;
}

const AddOrderPayment = (props: IProps) => {
  const { orderStore } = useStore();
  const {
    resetAddOrderPaymentState,
    addOrderPaymentState,
    AddOrderPaymentService
  } = orderStore;

  const initialValues: IOrderPayment = {
    TotalOrderAmount:0,
    Amount:"",
    PaymentReceviedDate: moment(new Date(), DATE_FORMAT).format(DATE_FORMAT),
    Remarks:""
  }


  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addOrderPaymentState" of the useEffect changed.
   */
  useEffect(() => {
    if (addOrderPaymentState.success) {
      toast.success(formatMessage("acceptSuccess"));
      onModalClosed();
    }
  }, [addOrderPaymentState.success]);

  /**
   * This function adds the order when "Id < 0" and updates the Existing order when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: IOrderPayment) => {
    let tempValues = cloneDeep(values);
    AddOrderPaymentService(props?.selectedData?.Id, tempValues);
  };

  /**
   * This function closes the pop up modal and reset getorderdetails, addUpdate order observables (success, inprogress 
   * and error).
   */
  const onModalClosed = () => {
    props.modalClosed();
  };

  console.log(initialValues)
  return (
    <React.Fragment>
      <AddOrderPaymentForm
        initialValues={{...initialValues}}
        id={props?.selectedData?.Id}
        selectedData={props?.selectedData}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isLoading={addOrderPaymentState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddOrderPayment);

