/*    
<summary>
   This is the AuditLogs Component which shows the AuditLogs Data.
   Developer: Aashish Singh, Created Date:22-Nov-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { SyntheticEvent, useEffect, useState } from "react";
import "./audit-log.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import IPageInfo, { IPageLimitInfo } from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import Grid from "../../shared-components/Grid/Grid";
import { observer } from "mobx-react";
import "react-sliding-side-panel/lib/index.css";
import AddPopupHOC from "../../shared-components/popup/add/add-popup-hoc";
import userSettingEnum from "../../constants/user-setting-enum";
import toast from "react-hot-toast";
import Pagination from "../../shared-components/Grid/Pagination";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { IAuditLogFilterDetails, IAuditLogsList, ILogsFilter } from "../../models/response/IAuditLogsResponse";
import { AuditLogFilterForm } from "./forms/audit-log-filter-form";
import _ from "lodash";
import FilterPopupHOC from "../../shared-components/popup/filter/filter-popup-hoc";
import no_audit from "../../assets/Images/svg/no-audit.svg";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import config from "../../helpers/config-helper";
import moment from "moment";
import SlidingPanel from "react-sliding-side-panel";
import AuditLogDetails from "./audit-log-details";

const appConfig = config();
const fileNameDateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_FILENAME;
const AuditLog = (props: any) => {
  const { auditLogStore, preferencesStore } = useStore();
  const {
    inProgress,
    auditLogList,
    GetAuditLogListService,
    allAuditLog,
    reset,
    error,
    logsFilter, 
    setLogsFilterDetail,
    initialFilter,
    exportAuditLogs,
    resetGetAuditLogDetail
  } = auditLogStore;
  const { userSetting,  setUserSetting, islanguageChanged, updateLanguageChangedFlag, getSelectedLogs, setUpdateSelectedLogs } = preferencesStore;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allAuditLogsList: Array<IAuditLogsList> = allAuditLog;
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.auditLogLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });
  const [filter, setFilter] = useState<ILogsFilter>({...logsFilter});

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.AuditLogLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetAllAuditLogService = () => {
    GetAuditLogListService(pageInfo.currentPage, pageLimitInfo.pageLimit, _.omit(filter, [
      "isFilterActive",
    ]))
    setPageInfo({ ...pageInfo, isRefreshPage: false, isPagerChange:false });
  };

  const filterPopupHandler = () => {
    props.filterToggleHandler();
  };

  const filterSubmitHandler = (values: IAuditLogFilterDetails) => {
    closePanelhandler();
    setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage:true });
    setFilter({...values, isFilterActive: true});
    setLogsFilterDetail({...values, isFilterActive: true});
    props.filterToggleHandler();
  };
  
  const resetFilter = () => {
    closePanelhandler();
    setPageInfo({ ...pageInfo, currentPage: 1, isRefreshPage:true });
    setLogsFilterDetail({
      ...initialFilter
    });
    setFilter({
      ...initialFilter
    });
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "filter" of the useEffect changed.
   */
  useEffect(() => {
    if (filter.isFilterActive) {
      callGetAllAuditLogService();
    }
    else
      callGetAllAuditLogService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetAllAuditLogService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "allAuditLog" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: auditLogList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAuditLog]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetAllAuditLogService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage, pageInfo.isPagerChange]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "error" of the useEffect changed.
   */
  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "islanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetAllAuditLogService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (JSON.stringify(selectedRow) !== "{}" && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: IAuditLogsList, rowIndex: number) => {
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
    resetGetAuditLogDetail();
  };

  const exportFilteredLogsCSV = () =>{
   downloadLogsCSV(exportAuditLogs);
  }

  const convertToCSV = (data: any[]): string => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));
    return `${header}\n${rows.join("\n")}`;
  };

  const downloadLogsCSV = (data: any[]) => {
    const currentDate = new Date();
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download =  moment(currentDate).format(fileNameDateTimeFormat) + " Audit_Logs.csv";
    document.body.appendChild(a);
    a.click();
    toast.success(formatMessage("download_started_successfully"));
    document.body.removeChild(a);
  };

  /**
   * The column constant defines the column description for the auditLog grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "UserName",
      text: formatMessage("user_label_user_name"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
      classes: "p-2 audit-remarks"
    },
    {
      dataField: "Module",
      text: formatMessage("module"),
      formatter: (cell: any, row: any) => {
        return (
          <span>
            <FormattedMessage id={cell} />
          </span>
        );
      },
      classes: "p-2"
    },
    {
      dataField: "Action",
      text: formatMessage("action"),
      formatter: (cell: any, row: any) => {
        return (
          <span>
            <FormattedMessage id={cell} />
          </span>
        );
      },
      classes: "p-2"
    },
    {
        dataField: "ActionTimestamp",
        text: formatMessage("action_timestamp"),
        title: (cell: string, row: any) => {
          return (cell.length > 10 ? cell : undefined);
        },
    }
  ];

  const columnsDump: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "ICCID",
      text: formatMessage("iccid"),
      classes: "p-2"
    },
    {
      dataField: "DumpedBy",
      text: formatMessage("dumped_by"),
      formatter: (cell: any, row: any) => {
        return (
          <span>
            <FormattedMessage id={cell} />
          </span>
        );
      },
      classes: "p-2"
    },
    {
      dataField: "Reason",
      text: formatMessage("reason"),
      formatter: (cell: any, row: any) => {
        return (
          <span>
            <FormattedMessage id={cell} />
          </span>
        );
      },
      classes: "p-2"
    },
    {
        dataField: "DumpTimestamp",
        text: formatMessage("dump_timestamp"),
    },
  ];

  return (
    <React.Fragment>
      {props.showFilterPopup && (
        <AuditLogFilterForm
        initialValues={_.omit(filter, [
          "isFilterActive",
        ])}
        isFilterActive={filter.isFilterActive}
        submitHandler={filterSubmitHandler}
        modalClosed = {props.filterToggleHandler} />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row g-0  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 ps-5 mb-0">
                        <FormattedMessage id="audit_log_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center w-100 justify-content-end">
                  <li className="w-50">
                    </li>
                  {
                      filter.isFilterActive && 
                      <>
                        <li
                          title={formatMessage("export_filtered_csv")}
                          className={exportAuditLogs.length == 0 ? " disabled mx-2" : " mx-2"}
                        >
                          <LoaderButton
                            disabled={exportAuditLogs.length == 0 ? true : false}
                            className={exportAuditLogs.length == 0 ? " disabled" : ""}
                            id="ExportLogs"
                            text="export_filtered_csv"
                            onClick={() => exportFilteredLogsCSV()}
                          />
                        </li>     
                      </>
                    }

                    {filter.isFilterActive && 
                    
                    <>
                      <li>
                        <button
                            data-testid="FilterButton"
                            className="btn btn-light btn-sm me-2"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={resetFilter}
                            title={formatMessage("reset_filter")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              id="Layer_1"
                              x="0px"
                              y="0px"
                              width="20px"
                              height="20px"
                              viewBox="0 0 122.88 110.668"
                              enable-background="new 0 0 122.88 110.668"
                            >
                              <g>
                                <path
                                  fill="currentColor"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                                />
                              </g>
                            </svg>
                          </button>
                      </li> 
                    </>
                    }
                    <li>
                    <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm me-2"
                          type="button"
                          onClick={filterPopupHandler}
                          title={formatMessage("filter")}
                        >
                          <svg
                            className="svg-icon"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                              fill="currentColor"
                            />
                            <path
                              d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_items_list">
      {inProgress && <FormLoader loading={inProgress} />}

      {allAuditLogsList.length > 0 ? (
            <div
                className={`card mt-3 changingPanel ${
                  openPanel && "panelOpen"
                }`}
              >
              <div className="tableDesign row-eq-height tableDevices ">
                <div className="table-responsive" data-testid="table">
                  {allAuditLogsList && (
                    <Grid
                      data={allAuditLogsList}
                      columns={columns}
                      keyField="Id"
                      loading={inProgress}
                      rowEvents={{ onClick: rowEvents }}
                      rowClasses={rowClassFormat}
                    />
                  )}
                  
                </div>
              </div>
            
            {allAuditLogsList.length > 0 ? (
              <div className="row g-0 p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={pageInfo.isRefreshPage}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            </div>
        ) : (
          <div className="message text-center">
            <img src={no_audit} alt="" className="no_message" />
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_audit_log" />
            </p>
            <p className="mb-3">{formatMessage("no_audit_log_message")}</p>
          </div>
        )
      }
      </div>
      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="device_detailsWrapper">
          <div className="bg_slide panel">
            <div className="row bb p-2 g-0">
              <div className="col-lg-11">
                {
                  JSON.stringify(selectedRow) !== "{}" && 
                  <h2 className="fw-bold mb-0 mt-2" 
                    title={(selectedRow?.UserName.length> 10 ? selectedRow?.UserName : undefined)}>
                    {selectedRow.UserName}
                  </h2>
                }
              </div>
              <div className="col-lg-1 text-end">
                <button onClick={closePanelhandler} className="Crossicons" title={formatMessage("close")}>
                  <svg
                    role="button"
                    xlinkTitle="close"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
              </div>
            </div>
            <AuditLogDetails selectedData={selectedRow} />
          </div> 
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default FilterPopupHOC(AddPopupHOC(observer(AuditLog)));