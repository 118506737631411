/*    
<summary>
  This functional component "MoveDeviceIccidToGroup" defines the props like initial values & functions for the child form component.
  Developer:Aashish Singh, Created Date: 11-June-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import protocolEnum from "../../../constants/protocol-enum";
import SelectGroupForm from "../../device/add-edit-device/forms/group/select-group-form";
import { getTenantIdFromLocalStorage, getUserTypeFromLocalStorage } from "../../../helpers/local-stotrage-helper";
import userTypeEnum from "../../../constants/user-type-enum";
import { formatMessage } from "../../../translations/format-message";
interface IProps {
  selectedDeviceRegister: Array<number>;
  modalClosed: () => void;
}

const MoveDeviceIccidToGroup = (props: IProps) => {
  const { deviceRegisterStore,groupStore,tenantStore } = useStore();
  const { selectedTenantId } = tenantStore;

  const { allAvailableGroups, GetAllGroupService } = groupStore;
  const { moveDeviceRegisterToGroups, moveDeviceRegisterState, resetMoveDeviceRegisterToGroups } = deviceRegisterStore;

  const onSubmitForm = (values: any) => {
    var obj = {
      GroupId: values.GroupId,
      Devices: props.selectedDeviceRegister
    }
    moveDeviceRegisterToGroups(obj);
  };

  const onModalClosed = () => {
    resetMoveDeviceRegisterToGroups();
    props.modalClosed();
  };

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if(getUserTypeFromLocalStorage() === userTypeEnum.SystemUser)
      GetAllGroupService(selectedTenantId);
    else
      GetAllGroupService(getTenantIdFromLocalStorage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "moveDeviceRegisterState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (moveDeviceRegisterState.success) {
      onModalClosed();
    }
  }, [moveDeviceRegisterState.success]);

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
              <FormattedMessage id="select_group" />
          </Modal.Title>
          <button onClick={onModalClosed} data-testid="Close" className="Crossicons" title={formatMessage("close")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <SelectGroupForm
            initialValues={{GroupId:-1}}
            groupList={allAvailableGroups}
            submitHandler={onSubmitForm}
            isLoading={moveDeviceRegisterState.inProgress}
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(MoveDeviceIccidToGroup);
