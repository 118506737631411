/*    
<summary>
  This functional component "device register Filter" renders the registered device details.
  Developer:Aashish Singh, Created Date: 27-August-2024
</summary>
<returns>Returns JSX</returns>
*/

import React from "react";
import { observer } from "mobx-react";
import { Formik, Field, Form } from "formik";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import Label from "../../../shared-components/Label/Label";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { deviceRegisterSearchByOptions, orderStatusOptions, paymentStatusOptions } from "../../../constants/common-constants";
import { registerDeviceFilterDetailsValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { IDeviceRegisterFilterDetails } from "../../../models/response/IRegisterDeviceResponse";
import { formatMessage } from "../../../translations/format-message";
import { Tooltip } from "react-tooltip";
import { getThemeFromLocalStorage } from "../../../helpers/local-stotrage-helper";

interface IProps {
  initialValues: IDeviceRegisterFilterDetails;
  submitHandler: (values: IDeviceRegisterFilterDetails) => void;
  isFilterActive: boolean;
  modalClosed: () => void;
}

const DeviceRegisterFilterForm = (props: IProps) => {


  return (
    <React.Fragment>
      {
        <>
          <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validateOnBlur={false}
            validationSchema={registerDeviceFilterDetailsValidationSchema}
            onSubmit={async (values: IDeviceRegisterFilterDetails) => {
              let tempValues: IDeviceRegisterFilterDetails = {
                SearchBy: values.SearchBy,
                OrderNo: values.SearchBy == 1 ? values.OrderNo : "",
                DeviceName: values.SearchBy == 2 ? values.DeviceName : "",
                ICCID: values.SearchBy == 3 ? values.ICCID : "",
              };
              props.submitHandler({ ...tempValues });
            }}
          >
            {({ values }) => {
              return (
                <Form className="user-form p-3">
                      <div className="form-group row">
                        <Label
                          className="label-style col-sm-5 col-form-label"
                          required={true}
                          label="search_by"
                        />
                        <div className="col-sm-7">
                          <Field
                            name="SearchBy"
                            as={FormikFormSelect}
                            options={deviceRegisterSearchByOptions}
                          />
                        </div>
                      </div>

                      <div className="">
                          {
                            values.SearchBy == 1 &&
                            <div className="form-group row">
                              <Label
                                className="col-sm-5"
                                required={true}
                                label="order_no"
                              />
                              <div className="col-sm-7">
                                <Field
                                  data-testid="OrderNo"
                                  id="custom"
                                  className="form-control"
                                  name="OrderNo"
                                  placeholder="order_no"
                                  type="text"
                                  validcharacter={["@", "#", "$", "-", "_", "."]}
                                  as={FormikFormInput}
                                />
                              </div>
                            </div>
                          }
                          {
                            values.SearchBy == 2 &&
                            <div className="form-group row">
                              <div className="col-sm-5">
                              <Label
                                  className="label-style"
                                  required={true}
                                  label="device_name"
                                />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="currentColor"
                                className="bi bi-info-circle ms-2"
                                viewBox="0 0 16 16"
                                data-tooltip-id="my-tooltip-device"
                                data-tooltip-html={formatMessage(
                                  "device_name_validation"
                                )}
                                data-tooltip-variant="dark"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                              </svg>
                              <Tooltip id="my-tooltip-device" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                              </div>
                              <div className="col-sm-7">
                                <Field
                                    data-testid="DeviceName"
                                    className="form-control"
                                    name="DeviceName"
                                    validcharacter={["@", "#", "$", "-", "_", "."]}
                                    placeholder="device_name"
                                    type="text"
                                    as={FormikFormInput}
                                  />
                              </div>
                            </div>
                          }
                          {
                            values.SearchBy == 3 &&
                            <div className="form-group row">
                              <div className="col-sm-5">
                                <Label
                                  className="label-style"
                                  required={true}
                                  label="iccid"
                                />
                              </div>
                              <div className="col-sm-7">
                                <Field
                                  data-testid="ICCID"
                                  className="form-control"
                                  name="ICCID"
                                  placeholder="iccid"
                                  type="number"
                                  as={FormikFormInput}
                                />
                              </div>
                            </div>
                          }
                      </div>

                  <div className="d-flex justify-content-end pb-2">
                    <button
                      data-testid="apply"
                      className="btn btn-secondary"
                      type="button"
                      onClick={props.modalClosed}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FormattedMessage id="button_cancel" />
                    </button>

                    <LoaderButton type="submit" className="ms-2" text="apply" />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      }
    </React.Fragment>
  );
};

export default observer(DeviceRegisterFilterForm);
