/*    
<summary>
  This functional component "AddUpdateOrder" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:28-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-order.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/StoreProvider";
import { formatMessage } from "../../../translations/format-message";
import { cloneDeep } from "lodash";
import AddUpdateOrderForm from "../forms/add-edit-order-form";
import { IAddUpdateOrderVM, IOrderVM } from "../../../models/response/IOrderResponse";
import { getTenantIdFromLocalStorage, getUserTypeFromLocalStorage } from "../../../helpers/local-stotrage-helper";
import userTypeEnum from "../../../constants/user-type-enum";

interface IProps {
  id: number;
  tenantId: number;
  modalClosed: () => void;
}

const AddOrder = (props: IProps) => {
  const { orderStore, tenantStore, pricingPlanStore } = useStore();
  const {
    GetOrderByIdService,
    resetAddUpdateOrderState,
    resetOrderDetail,
    orderState,
    orderDetail,
    addUpdateOrderState,
    AddOrderService,
    UpdateOrderService,
    GetAllOrderDescriptionTypeService,
    allOrderDescriptionTypeOptions,
    allOrderState,
    selectedOrderDescriptionId
  } = orderStore;
  const { GetAllTenantsService, allTenants, selectedOrderModuleTenantId, allTenantOptions } = tenantStore;
  const { allPricingPlansOptions } = pricingPlanStore;
  const [initialValues, setInitialValues] = useState<any>(orderDetail);
  // const initialValues: any = orderDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered 
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if(getUserTypeFromLocalStorage() === userTypeEnum.TenantUser)
      GetAllTenantsService();
    GetAllOrderDescriptionTypeService();
    if (props.id > 0) {
      let tenantId:number = getUserTypeFromLocalStorage() === userTypeEnum.SystemUser ? selectedOrderModuleTenantId : getTenantIdFromLocalStorage();
      GetOrderByIdService(props.id, tenantId);
    }
  }, []);

  useEffect(() => {
    if(allTenants.length > 0){
      if(props.id > 0){
        setInitialValues({...orderDetail});
      }
      else{
        const tempShippingAddress = allTenants.filter((tenant)=> tenant.Id == props.tenantId);
        const shippingAddress = tempShippingAddress.length > 0 ? tempShippingAddress[0].ShippingAddress : "";
        const billingAddress = tempShippingAddress.length > 0 ? tempShippingAddress[0].BillingAddress : "";
        let isBillingAddressSame = shippingAddress?.trim() == billingAddress?.trim() ? true : false;
        const orderDescriptionType= {...orderDetail.OrderDescriptionType, Id: selectedOrderDescriptionId}
        if(props.id > 0)
          setInitialValues({...orderDetail, ShippingAddress:shippingAddress, BillingAddress: billingAddress, IsBillingAddressSame: isBillingAddressSame, Tenant:{...orderDetail.Tenant, Id: props.tenantId}});
        else  
          setInitialValues({...orderDetail, ShippingAddress:shippingAddress, BillingAddress: billingAddress, IsBillingAddressSame: isBillingAddressSame, OrderDescriptionType: orderDescriptionType, Tenant:{...orderDetail.Tenant, Id: props.tenantId}});
      }
    }
  }, [allTenants, orderDetail, selectedOrderDescriptionId]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateOrderSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateOrderState.success) {
      if (props.id === -1)
        toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateOrderState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateOrderError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateOrderState.error) {
      toast.error(formatMessage(addUpdateOrderState.error));
      resetAddUpdateOrderState();
    }
  }, [addUpdateOrderState.error]);

  /**
   * This function adds the order when "Id < 0" and updates the Existing order when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: IOrderVM) => {
    let tenantId:number = getUserTypeFromLocalStorage() === userTypeEnum.SystemUser ? values.Tenant.Id : getTenantIdFromLocalStorage();
    let tempValues = cloneDeep(values);
    tempValues.Tenant.Id = tenantId;  
    if (props.id < 0) AddOrderService(tempValues);
    else UpdateOrderService(props.id, tempValues);
  };

  /**
   * This function closes the pop up modal and reset getorderdetails, addUpdate order observables (success, inprogress 
   * and error).
   */
  const onModalClosed = () => {
    resetOrderDetail();
    props.modalClosed();
  };
  console.log(initialValues)
  return (
    <React.Fragment>
      <AddUpdateOrderForm
        initialValues={initialValues}
        tenantOptions={allTenantOptions}
        tenantId={props.tenantId}
        planOptions={allPricingPlansOptions}
        orderDescriptionOptions={allOrderDescriptionTypeOptions}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getOrderLoading={orderState.inProgress || tenantStore.inProgress || allOrderState.inProgress}
        addUpdateOrderLoading={addUpdateOrderState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddOrder);

