/*    
<summary>
  This functional component "AllGroupsPopup" defines the props like initial values & functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date: 15 September 2023,
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import SelectGroupForm from "./forms/group/select-group-form";
import protocolEnum from "../../../constants/protocol-enum";
import userTypeEnum from "../../../constants/user-type-enum";
import { getUserTypeFromLocalStorage, getTenantIdFromLocalStorage } from "../../../helpers/local-stotrage-helper";
import { formatMessage } from "../../../translations/format-message";
import { IOption } from "../../../models/ICommon";
interface IProps {
  selectedDevices: Array<any>;
  selectedDevicesGroup: Array<string>;
  modalClosed: () => void;
}

const AllGroupsPopup = (props: IProps) => {
  const { deviceStore,groupStore,preferencesStore, tenantStore} = useStore();
  const { allAvailableGroups, GetAllGroupService } = groupStore;
  const { activeGroupId } = preferencesStore;
  const { selectedTenantId } = tenantStore;
  const { moveDevicesToGroups, moveDevicesState, resetMoveDevicesToGroups } = deviceStore;

  const groupList = allAvailableGroups?.filter((group:any) => (
    group.id !== activeGroupId && group.value !== props.selectedDevicesGroup[0]));
  const onSubmitForm = (values: any) => {
    var obj = {
      GroupId: values.GroupId,
      ICCIDs: props.selectedDevices.map((device:any)=>{
        return device.ICCID
      })
    }
    moveDevicesToGroups(obj);
  };

  const onModalClosed = () => {
    resetMoveDevicesToGroups();
    props.modalClosed();
  };

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if(getUserTypeFromLocalStorage() === userTypeEnum.SystemUser)
      GetAllGroupService(selectedTenantId);
    else
      GetAllGroupService(getTenantIdFromLocalStorage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "moveDevicesState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (moveDevicesState.success) {
      onModalClosed();
    }
  }, [moveDevicesState.success]);

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
              <FormattedMessage id="select_group" />
          </Modal.Title>
          <button onClick={onModalClosed} data-testid="Close" className="Crossicons" title={formatMessage("close")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <SelectGroupForm
            initialValues={{GroupId:-1}}
            groupList={groupList.map((group:IOption)=>{return({...group, disabled: group?.protocol == protocolEnum.AWSIoT})})}
            submitHandler={onSubmitForm}
            isLoading={moveDevicesState.inProgress}
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AllGroupsPopup);
