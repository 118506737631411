import IAddTenant from "../../models/Form/IAddUpdateTenant";

export const initialState: IAddTenant = {
    TenantName: "",
    Password: "",
    Email: "",
    OrganizationId: "",
    OrganizationName: "",
    ContactPersonName: "",
    ContactEmail: "",
    ContactPhoneNumber: "",
    ShippingAddress: "",
    BillingAddress: "",
    MaxUserAllow: 1,
    MaxDeviceAllow: 4,
    IsActive: true,
    VatNo: "",
    IsBillingAddressSame: true,
    IsInfoSame: true,
    IsDataPakcetsStoreInDB: false,
    SendThresholdAlert: true,
    BlockTenantOnThresholdLimitReached: true,
    UserId:-1,

}