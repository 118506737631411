/*    
<summary>
   This file is used to provide initial values or state to the Statics data observables.
   Developer:Ashish Singh, Created Date: 16-Sept-2023
</summary>
*/

import { IGeneralStatisticsVm } from "../../models/response/IStaticsResponse";

export const initialState:IGeneralStatisticsVm = {
    RegisteredDeviceCount: 0,
    ActiveDeviceCount: 0,
    DeletedDeviceCount: 0,
    ExposeApiRequestCount: 0,
    ULMessageCount: 0,
    DLMessageCount: 0,
    TotalMessageCount: 0,
    TotalActiveDeviceCountAtLastDayOfMonth: 0,
    TotalDeviceCountAtLastDayOfMonth: 0,
  }