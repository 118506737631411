/*    
<summary>
  This functional component "OrderFilterPopupHOC" provides props to the child component.
  1. State "setShowOrderFilterPopup" that manages the show/hide of pop up
  2. Function "OrderFilterToggleHandler" to toggle the show/hide popup state "showOrderFilterPopup"
  Developer:Aashish Singh, Created Date:23-August-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";

const OrderFilterPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showOrderFilterPopup, setShowOrderFilterPopup] =
      useState<boolean>(false);

    const OrderFilterToggleHandler = () => {
      setShowOrderFilterPopup(!showOrderFilterPopup);
    };

    return (
      <Component
        showOrderFilterPopup={showOrderFilterPopup}
        orderFilterToggleHandler={OrderFilterToggleHandler}
        {...props}
      />
    );
  };

export default OrderFilterPopupHOC;
