enum OrderStatusEnum
 {
     Pending=0,
     Processing=1,
     Shipped=2,
     Delivered=3,
     TopUpSuccessful=4,
     Cancelled=5
 }

export default OrderStatusEnum;