/*    
<summary>
   This class component is all about Managing role data functionality.
   Developer:Aashish Singh, Created Date:29-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import { AuthStore } from './auth-store';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { IObservableInitialState, IOption } from '../../models/ICommon';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import { IRoleVM } from '../../models/response/IRoleResponse';

const authStore = new AuthStore();

export class RoleStore implements ICommonState {
    inProgress = false;
    error = '';
    
    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    
    allRoles: IRoleVM[] = [];
    allRoleState ={...this.initialStateValue }
    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            allRoles: observable,
            allRoleState: observable,
            GetAllRoleService: action,
            reset: action,
            resetStore: action,
            allRoleOptions: computed,
        });
    }

    /**
    * This function is used to get All Tenants without pagination by calling an API.
    * @returns 
    */
    GetAllRoleService = () => {
        this.inProgress = true;
        const url = URLConstants.Role;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IRoleVM[]>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allRoles = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
    * This function is used to map allRoles to allTenantOptions suitable for FormikFormSelect Component.
    * @returns Tenant Options List containing values & id
    */
    get allRoleOptions(): IOption[] {
        const options: IOption[] = [{
            id: -1,
            value: "please_select",
        }];
        if (this.allRoles && this.allRoles.length > 0)
            this.allRoles.map((role) => {
                options.push({
                    id: role.Id,
                    value: role.Name,
                })
            })

        return options;
    }

    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
    }

}

export default new RoleStore();
