import React, { useEffect, useState } from "react";
import "./add-edit-tenant-form.css";
import IAddTenant from "../../../../models/Form/IAddUpdateTenant";
import Label from "../../../../shared-components/Label/Label";
import FormikFormTextArea from "../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { formatMessage } from "../../../../translations/format-message";
import { Tooltip } from "react-tooltip";
import FormikFormCheckBox from "../../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import { FormattedMessage } from "react-intl";
import { addTenantDetailsValidateSchema, editTenantDetailsValidateSchema, organizationDetailValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { Field, Form, Formik, useFormikContext } from "formik";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import { getThemeFromLocalStorage } from "../../../../helpers/local-stotrage-helper";

interface IProps {
  Id: number;
  initialValues: IAddTenant;
  submitHandler: (data: any) => void;
  isLoading: boolean;
  modalClosed: () => void;
}

const AddUpdateTenantForm1 = (props:IProps) =>{
  const { initialValues, submitHandler, isLoading, modalClosed } = props;
  
  const [data, setData] = useState({...initialValues});
  
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(()=>{
    setData({...initialValues});
  }, [initialValues]);

  const handleNextStep = (newData:any, final:boolean = false) =>{
    setData(prev=>({...prev, ...newData}))
    if(final){
      let tempValues: IAddTenant = { ...newData };
      tempValues.MaxUserAllow = Number(newData.MaxUserAllow);
      tempValues.MaxDeviceAllow = Number(newData.MaxDeviceAllow);
        submitHandler(tempValues);
      return
    }
    setCurrentStep( prev => prev+1 );
  }
  
  const handlePrevStep = (newData:any) =>{
    setData(prev=>({...prev, ...newData}))
    setCurrentStep(prev=>prev-1);
  }
  
  const StepOne = (props1:any) =>{
    const [showHidePassword, setShowHidePassword] = useState(false);
    const handleSubmit = (values:any) =>{
      props1.next(values)
    }
    return(
      <Formik
      initialValues={props1.data}
      onSubmit ={handleSubmit}
      validationSchema={props.Id == -1 ? addTenantDetailsValidateSchema : editTenantDetailsValidateSchema}
      validateOnBlur={false}
      enableReinitialize
      >
        {
          ({values, isValid})=>(
            <Form id="formBody">
              <div className="pt-3">
                <div className="progress px-1 step-progress">
                  <div className="progress-bar" role="progressbar"></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle active">1</div>
                  <div className="step-circle">2</div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="tenant_name"
                    />
                    <Field
                      data-testid="TenantName"
                      className="form-control"
                      name="TenantName"
                      placeholder="tenant_name"
                      type="text"
                      validcharacter={["@", "#", "$", "-", "_", "."]}
                      as={FormikFormInput}
                    />
                  </div>
                </div>
  
                <div className="col-12 col-sm-6">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="tenant_email"
                    />
                    <Field
                      data-testid="Email"
                      className="form-control"
                      name="Email"
                      placeholder="tenant_email"
                      type="text"
                      invalidcharacter={[" "]}
                      validcharacter={[
                        "-",
                        "_",
                        "[",
                        "]",
                        "(",
                        ")",
                        ":",
                        "#",
                        "@",
                        ".",
                      ]}
                      as={FormikFormInput}
                    />
                  </div>
                </div>
  
                {props.Id == -1 && (
                  <div className="col-12 col-sm-4">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="password"
                      />
                      <Field
                        data-testid="Password"
                        className="form-control"
                        name="Password"
                        placeholder="password"
                        autoComplete="new-password"
                        type={showHidePassword ? "text" : "password"}
                        invalidcharacter={[" "]}
                        validcharacter={[
                          "~",
                          "`",
                          "!",
                          "@",
                          "#",
                          "$",
                          "%",
                          "^",
                          "&",
                          "*",
                          "(",
                          ")",
                          "_",
                          "-",
                          "+",
                          "=",
                          "{",
                          "[",
                          "}",
                          "]",
                          "|",
                          "\\",
                          ":",
                          ";",
                          "'",
                          '"',
                          "<",
                          ",",
                          ">",
                          ".",
                          "?",
                          "/",
                        ]}
                        as={FormikFormInput}
                      />
                      {showHidePassword ? (
                        <svg
                          type="button"
                          onClick={() => {
                            setShowHidePassword(!showHidePassword);
                          }}
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="showHidePass css-i6dzq1 tenantBox"
                        >
                          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                          <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                      ) : (
                        <svg
                          type="button"
                          onClick={() => {
                            setShowHidePassword(!showHidePassword);
                          }}
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="showHidePass css-i6dzq1 tenantBox"
                        >
                          <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                          <line x1="1" y1="1" x2="23" y2="23"></line>
                        </svg>
                      )}
                    </div>
                  </div>
                )}
                <div className={`col-12 ${props.Id > 0 ? "col-sm-6" : "col-sm-4"}`}>
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="user_limit"
                    />
                    <Field
                      data-testid="user_limit"
                      className="form-control"
                      name="MaxUserAllow"
                      placeholder="user_limit"
                      type="number"
                      as={FormikFormInput}
                    />
                  </div>
                </div>
  
                <div className={`col-12 ${props.Id > 0 ? "col-sm-6" : "col-sm-4"}`}>
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="device_limit"
                    />
                    <Field
                      data-testid="DeviceLimit"
                      className="form-control"
                      name="MaxDeviceAllow"
                      placeholder="device_limit"
                      type="number"
                      as={FormikFormInput}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-12 mt-1">
                  <div className="checkbox">
                    <label>
                      <Field
                        data-testid="IsActive"
                        className="form-control"
                        name="IsActive"
                        role="switch"
                        type="checkbox"
                        id="inlineFormCheck"
                        as={FormikFormCheckBox}
                      />
                      <span className="cr">
                        <i className="cr-icon fas fa-check"></i>
                      </span>
                    </label>
                    <label className="h">
                      <FormattedMessage id="is_active" />
                    </label>
                  </div>
                </div>
                <div className="col-md-3 col-12 mt-1">
                  <div className="checkbox">
                    <label>
                      <Field
                        data-testid="IsDataPakcetsStoreInDB"
                        className="form-control"
                        name="IsDataPakcetsStoreInDB"
                        role="switch"
                        type="checkbox"
                        id="inlineFormCheck"
                        as={FormikFormCheckBox}
                      />
                      <span className="cr">
                        <i className="cr-icon fas fa-check"></i>
                      </span>
                    </label>
                    <label className="h">
                          <FormattedMessage id="store_in_DB" />
                        </label>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-info-circle ms-2"
                          viewBox="0 0 16 16"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-html={formatMessage("store_in_db_info")}
                          data-tooltip-variant="dark"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <Tooltip id="my-tooltip" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                  </div>
                </div>
                <div className="col-md-3 col-12 mt-1 d-flex ">
                  <div className="checkbox">
                    <label>
                      <Field
                        data-testid="SendThresholdAlert"
                        className="form-control"
                        name="SendThresholdAlert"
                        role="switch"
                        type="checkbox"
                        id="inlineFormCheck"
                        as={FormikFormCheckBox}
                      />
                      <span className="cr">
                        <i className="cr-icon fas fa-check"></i>
                      </span>
                    </label>
                    <label className="h">
                      <FormattedMessage id="send_alert" />
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-info-circle ms-2"
                      viewBox="0 0 16 16"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html={formatMessage("send_alert_info")}
                      data-tooltip-variant="dark"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <Tooltip id="my-tooltip" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                  </div>
                
                </div>
                <div className="col-md-3 col-12 mt-1 d-flex ">
                  <div className="checkbox">
                    <label>
                      <Field
                        data-testid="BlockTenantOnThresholdLimitReached"
                        className="form-control"
                        name="BlockTenantOnThresholdLimitReached"
                        role="switch"
                        type="checkbox"
                        id="inlineFormCheck"
                        as={FormikFormCheckBox}
                      />
                      <span className="cr">
                        <i className="cr-icon fas fa-check"></i>
                      </span>
                    </label>
                    <label className="h">
                      <FormattedMessage id="block_tenant" />
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-info-circle ms-2"
                      viewBox="0 0 16 16"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html={formatMessage("block_tenant_info")}
                      data-tooltip-variant="dark"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <Tooltip id="my-tooltip" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                  </div>
                
                </div>
              </div>
              <div className="pb-3 mt-3 d-flex justify-content-center">
                <LoaderButton
                  type="submit"
                  id="SaveTenant"
                  isLoading={false}
                  text="next"
                />
                {
                  props.Id > 0 &&
                  <LoaderButton
                    type={isValid ? "submit" : "button"}
                    id="SaveTenant"
                    onClick={isValid ? ()=>{props1.next(values, true);} : undefined}
                    isLoading={props.isLoading}
                    text="update"
                    className="ms-3"
                  />
                }

              </div>
            </Form>
          )
        }
      </Formik>
    )
  }
  
  const StepTwo = (props2:any) =>{
  
    const handleSubmit = (values:any) =>{
      let tempValues = {
        ...values,
        BillingAddress: values.IsBillingAddressSame === true ? values.ShippingAddress : values.BillingAddress
      }
      props2.next({...tempValues}, true);
    }

    const FormObserver: React.FC = () => {
      const { values, setFieldValue } = useFormikContext<IAddTenant>();
  
      useEffect(() => {
        if (values.IsBillingAddressSame) {
          setFieldValue("ShippingAddress", values.BillingAddress);
        }
      }, [values.BillingAddress, values.IsBillingAddressSame]);
      
      useEffect(() => {
        if (values.IsInfoSame) {
          setFieldValue("ContactPersonName", values.TenantName);
          setFieldValue("ContactEmail", values.Email);
        }
      }, [values.TenantName, values.Email, values.IsInfoSame]);

      return null;
    };
    
    return(
      <Formik
        initialValues={props2.data}
        onSubmit ={handleSubmit}
        validationSchema={organizationDetailValidateSchema}
        validateOnBlur={false}
        enableReinitialize
        >
        {
          ({values, isValid})=>(
            <Form id="formBody">
              <FormObserver />
              <div className="pt-3">
                <div className="progress px-1 step-progress">
                  <div className={`progress-bar w-100`} role="progressbar"></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle"><i className="fa fa-check"></i></div>
                  <div className="step-circle active">2</div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="organization_name"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-info-circle ms-2"
                      viewBox="0 0 16 16"
                      data-tooltip-id="my-tooltip-org-name"
                      data-tooltip-html={formatMessage("name_validation")}
                      data-tooltip-variant="dark"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <Tooltip id="my-tooltip-org-name" place="bottom" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                    <Field
                      data-testid="OrganizationName"
                      className="form-control"
                      name="OrganizationName"
                      placeholder="organization_name"
                      type="text"
                      validcharacter={["@", "#", "$", "-", "_", "."]}
                      as={FormikFormInput}
                    />
                  </div>
                </div>
    
                <div className="col-md-4 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="organization_id"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-info-circle ms-2"
                      viewBox="0 0 16 16"
                      data-tooltip-id="my-tooltip-org-id"
                      data-tooltip-html={formatMessage("org_id_valid_chars")}
                      data-tooltip-variant="dark"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <Tooltip id="my-tooltip-org-id" place="bottom" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                    <Field
                      data-testid="OrganizationId"
                      className="form-control"
                      name="OrganizationId"
                      disabled={props.Id == -1 ? false : true}
                      placeholder="organization_id"
                      invalidcharacter={[" "]}
                      as={FormikFormInput}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      // required={true}
                      label="vat_no"
                    />
                    <Field
                      data-testid="VatNo"
                      className="form-control"
                      name="VatNo"
                      placeholder="vat_no"
                      invalidcharacter={[" "]}
                      as={FormikFormInput}
                    />
                  </div>
                </div>
                <div className="col-12 my-1">
                  <div className="checkbox">
                    <label>
                      <Field
                        data-testid="IsInfoSame"
                        className="form-control"
                        name="IsInfoSame"
                        role="switch"
                        type="checkbox"
                        id="IsInfoSame"
                        as={FormikFormCheckBox}
                      />
                      <span className="cr">
                        <i className="cr-icon fas fa-check"></i>
                      </span>
                    </label>
                    <label htmlFor="IsInfoSame" className="h">
                      <FormattedMessage id="is_info_same" />
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="contact_person_name"
                    />
    
                    <Field
                      data-testid="ContactPersonName"
                      className="form-control"
                      name="ContactPersonName"
                      placeholder="contact_person_name"
                      type="text"
                      disabled={values.IsInfoSame}
                      as={FormikFormInput}
                    />
                  </div>
                </div>
    
                <div className="col-md-4 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="contact_email"
                    />
                    <Field
                      id="ContactEmail"
                      className="form-control"
                      name="ContactEmail"
                      placeholder="contact_email"
                      type="text"
                      disabled={values.IsInfoSame}
                      invalidcharacter={[" "]}
                      validcharacter={[
                        "-",
                        "_",
                        "[",
                        "]",
                        "(",
                        ")",
                        ":",
                        "#",
                        "@",
                        ".",
                      ]}
                      as={FormikFormInput}
                    />
                  </div>
                </div>
    
                <div className="col-md-4 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={false}
                      label="contact_phone_number"
                    />
                    <Field
                      id="ContactPhoneNumber"
                      className="form-control"
                      name="ContactPhoneNumber"
                      placeholder="contact_phone_number"
                      type="number"
                      as={FormikFormInput}
                    />
                  </div>
                </div>
    
                <div className="col-12">
                <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="billing_address"
                      />
                      <Field
                        rows={2}
                        id="BillingAddress"
                        className="form-control"
                        name="BillingAddress"
                        placeholder="billing_address"
                        type="text"
                        as={FormikFormTextArea}
                      />
                    </div>
                </div>
                <div className="col-12 my-1">
                  <div className="checkbox">
                    <label>
                      <Field
                        data-testid="IsBillingAddressSame"
                        className="form-control"
                        name="IsBillingAddressSame"
                        role="switch"
                        type="checkbox"
                        id="IsBillingAddressSame"
                        as={FormikFormCheckBox}
                      />
                      <span className="cr">
                        <i className="cr-icon fas fa-check"></i>
                      </span>
                    </label>
                    <label htmlFor="IsBillingAddressSame" className="h">
                      <FormattedMessage id="is_billing_address_same" />
                    </label>
                  </div>
                </div>
                  <div className={`col-12 ${values.IsBillingAddressSame} billing_wrap`}>
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="shipping_address"
                      />
                      <Field
                        rows={2}
                        id="ShippingAddress"
                        className="form-control"
                        name="ShippingAddress"
                        disabled={values.IsBillingAddressSame}
                        placeholder="shipping_address"
                        type="text"
                        as={FormikFormTextArea}
                      />
                    </div>
                  </div>
              </div>
              <div className="pb-3 d-flex justify-content-center">
                  <Button
                    data-testid="back"
                    variant="secondary"
                    onClick={()=>{
                      if(props.Id > 0)
                        {
                          if(isValid)
                            props2.prev(values)
                        }
                      else{
                        props2.prev(values)
                      }
                    }}
                    disabled={props.isLoading}
                    className="me-3"
                  >
                    <FormattedMessage id="back" />
                  </Button>

                  <LoaderButton
                    type="submit"
                    id="SaveTenant"
                    isLoading={props.isLoading}
                    text={props.Id=== -1 ? "submit" : "update"}
                  />
                  
              </div>
            </Form>
          )
        }
      </Formik>
    )
  }
  
  const steps = [
    <StepOne next={handleNextStep} data={data} Id={props.Id} isLoading={isLoading}/>, 
    <StepTwo next={handleNextStep} prev={handlePrevStep} data={data} Id={props.Id} isLoading={isLoading}/>,
  ]

  return(
    <div>
      {isLoading && <FormLoader loading={isLoading} />}
      {steps[currentStep]}
    </div>
  )
}

export default observer(AddUpdateTenantForm1)
