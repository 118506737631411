import "./bulk-device-register.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import BulkDeviceRegisterForm from "../forms/bulk-device-register-form";
import { useStore } from "../../../contexts/StoreProvider";
import IAddDeviceIccidBulk, { IBulkDeviceRegisterFormInitialState } from "../../../models/Form/IAddDeviceRegisterInBulk";
import { useHistory } from "react-router-dom";
import { formatMessage } from "../../../translations/format-message";

const BulkDeviceRegister = (props: any) => {
  const { deviceRegisterStore, orderStore, tenantStore } = useStore();
  const { addDeviceRegisterInBulk,addBulkDeviceRegisterState } = deviceRegisterStore;
  const { GetAllTenantsService, allTenantOptions, resetAllTenantList } = tenantStore;

  const { allDeliveredOrderState, getAllDeliveredOrderOptions, resetAllDeliveredOrderState} = orderStore;
  const history = useHistory();
  
  const onSubmitForm = (values: IAddDeviceIccidBulk) => {
    addDeviceRegisterInBulk(values);
  };
  const tenantId = props.location.state ? props.location.state.tenantId : -1;
  console.log(tenantId)

  const initialValue: IBulkDeviceRegisterFormInitialState = {
    Name:"",
    OrderNo:"please_select",
    StartICCID:"",
    EndICCID:"",
    TenantId: tenantId,
    AddDeviceIccids: [{
      ICCID:0,
      Name:""
    }],
  };

  /**
 * The useEffect Hook lets you perform side effects in function component.
 * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
 * while a dependency of the useEffect has changed.
 */
  useEffect(() => {
    // if(tenantId === -1)
    GetAllTenantsService();
    return(()=>{
      resetAllTenantList();
    })
  }, []);

  /* Returning JSX */
  return (
    <React.Fragment>
      <div className="icon-nav navbar-fixed-top device-nav-bar deviceTop">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="filter-left-area ps-5">
              <ul className="d-flex align-items-center mt-1">
                <li title={formatMessage("back")}>
                <button role="" className="btn btn-secondary"  onClick={() => {
                  resetAllDeliveredOrderState();
                  history.push(RoutesConstants.DeviceRegister)}}>
                    <i className="fa fa-arrow-left"></i>
                </button>
                </li>
                <li className="ms-2">
                  <p className="mb-0 h5">
                    <FormattedMessage id="bulk_register_device" />
                  </p>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>
      <BulkDeviceRegisterForm
        initialValues={initialValue}
        tenantId={tenantId}
        tenantOptions={allTenantOptions}
        deliveredOrderList={getAllDeliveredOrderOptions}
        isAdding={addBulkDeviceRegisterState.inProgress || allDeliveredOrderState.inProgress}
        submitHandler={onSubmitForm}
      />
    </React.Fragment>
  );
};

export default observer(BulkDeviceRegister);
