/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate protocols in the overall application.
   Developer: Aashish Singh, Created Date: 07-April-2023 
</summary>
*/
enum protocolEnum {
    HTTP=0,
    MQTT=1,
    HTTPS=2,
    MQTTS=3,
    AWSIoT=4,
    AzureIoT=5,
    AWSS3Bucket=6,
    AWSKinesis=7,
}

export default protocolEnum;