/*    
<summary>
    This typescript file contains special datatype that enables for a variale to be set of predefined constant.
    This constant is used in displaying error messages according to the error code recieved from the backend API.
    Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
*/
export const Error = { 
    InvalidUsernameOrPassword: "invalid_username_or_password",
}
export const errorMessage = {
    ErrorOccured: 'message_some_error_occured',
    Unauthorized: "unauthorized",
    Forbidden: "forbidden",
    UnauthorizedAccess: "unauthorized_access",
    UserAlreadyLogin: "UserAlreadyLogin",
    AccessedByOtherUser:"AccessedByOtherUser",
    SessionExpired:"SessionExpired",
    YourAccountInactive:"YourAccountInactive",
    InvalidEmailOrPassword:"InvalidEmailOrPassword",
    UserEmailNotFound:"UserEmailNotFound",
    YourOrgaizationAccountInactive:"YourOrgaizationAccountInactive",
    YourAccountInactiveByOrganization:"YourAccountInactiveByOrganization"

}
