/*    
<summary>
   This file is used to provide initial values or state to the Order Module observables.
    Developer:Aashish Singh, Created Date:28-May-2024
</summary>
*/

import { IOrderListVM } from "../../models/response/IOrderResponse";

export const initialState: IOrderListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Orders: []
}