/*    
<summary>
   This class component is all about Managing Aws Region data functionality.
   Developer:Aashish Singh, Created Date:06-August-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { IObservableInitialState, IOption, ISubNameOptions } from '../../models/ICommon';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import { IRegionVM } from '../../models/response/IRegionResponse';

export class RegionStore implements ICommonState {
    inProgress = false;
    error = '';
    
    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    
    allRegions: IRegionVM[] = [];
    regionState:IObservableInitialState ={...this.initialStateValue}

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            allRegions: observable,
            regionState: observable,
            GetAllAWSRegionService: action,
            resetRegionState: action,
            reset: action,
            resetStore: action,
            allRegionOptions: computed,
        });
    }
    
    /*
    This function is used to Get All  Currencies data from API.  
    */
    GetAllAWSRegionService = () => {
        this.regionState.inProgress = true;
        const url = URLConstants.AWSRegionList;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IRegionVM[]>>) => {
                if (response.data.Error) {
                    this.regionState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allRegions = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.regionState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.regionState.inProgress = false; }));
    }

    /*
    This function is used to map allRegions to allRegionOptions List suitable for Grid Component.  
    */
    get allRegionOptions(): IOption[] {
        const options: IOption[] = [{id:-1, value:"please_select"}];
        if (this.allRegions && this.allRegions.length > 0)
            this.allRegions.map((regionDetails) => {
                options.push({
                    id: regionDetails.Id,
                    value: regionDetails.Region,
                    name: regionDetails.RegionName +"\t" +regionDetails.Region
                })
            })

        return options;
    }

    /*
    This function is used to reset regionState all observables to their initial values.  
    */
    resetRegionState = () => {
        this.regionState = {...this.initialStateValue};
        this.allRegions = [];
    }
    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.allRegions = [];
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
    }
}

export default new RegionStore();
