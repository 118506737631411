/*    
<summary>
   This file is used to provide initial values or state to the order Data observables.
   Developer:Ashish Singh, Created Date: 29-May-2024
</summary>
*/

import moment from "moment";
import { IOrderVM } from "../../models/response/IOrderResponse";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../constants/common-constants";

export const initialState:IOrderVM = {
    Id: 0,
    NoOfDevicesOrder: "",
    OrderDescriptionType: {
      Id: -1,
      Type: 0,
      TypeString: "",
      Description: ""
    },
    OrderNo:"",
    OrderStatus:0,
    PaymentStatus:0,
    PaymentOption:0,
    Tenant: {
      Id: -1,
      UserId: 0,
      TenantName: "",
      Email: "",
      OrganizationId: "",
      VatNo: "",
      OrganizationName: "",
      ContactPersonName: "",
      ContactEmail: "",
      ContactPhoneNumber: "",
      ShippingAddress: "",
      BillingAddress: "",
      MaxUserAllow: 0,
      MaxDeviceAllow: 0,
      IsActive: true,
      ApiKey: "",
      ApiKeySecretValue: "",
      IsTrialTenant: true,
      IsInfoSame: true,
      IsDataPakcetsStoreInDB: true,
      SendThresholdAlert: true,
      BlockTenantOnThresholdLimitReached: true,
    },
    Plan: {
      Id: -1,
      PlanName: "",
      MaxNoOfDevicesSupport: 0,
      MonthlyDataOperations: 0,
      YearlyPlanExpiryPeriod: 0,
      MonthlyInventoryPeriod: 0,
      OrderDescriptionType: {
        Id: -1,
        Type: 0,
        TypeString: "",
        Description: ""
      },
      Currency: {
        Id: 0,
        CountryName: "",
        CurrencyName: "",
        Symbol: "",
        Abbrevation: ""
      },
      PlanPrice: 0,
      ExcessDataSurcharge: 0,
      Tax: 0,
    },
    ShippingAddress: "",
    BillingAddress: "",
    IsBillingAddressSame: true,
    Remarks: "",
    TotalOrderAmount: 0,
    PendingAmount: 0,
    CSVFile: "",
    CreatedDate: "",
  }