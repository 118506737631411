/*    
<summary>
   This file is used to provide initial values or state to the Fota Module observables.
   Developer: Aashish Singh, Created Date: 28-August-2024
</summary>
*/

import { IFotaTenantReleaseListVM, IMzlReleaseListVM } from "../../models/response/IFotaResponse";

export const initialFotaMzlState: IMzlReleaseListVM= {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    FotaReleases: []
}

export const initialFotaTenantState: IFotaTenantReleaseListVM= {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    FotaReleases: []
}