/*    
<summary>
   This class component is all about pricing menu operations.
   Developer: Mohammad Saquib Khan, Created Date: 25-Jan-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { ICommonState } from "../../models/state/ICommonState";
import * as baseService from '../service/base-service';
import URLConstants from "../../constants/url.constants";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import toast from "react-hot-toast";
import { formatMessage } from "../../translations/format-message";
import { IOption } from "../../models/ICommon";
import config from "../../helpers/config-helper";
import {dailyPacketCountGraphDefaultLabel, getDaysInMonth, getMonthName } from "../../constants/common-constants";
import { IPricingMenuState } from "../../models/state/IPricingMenuState";
import { IGeneralStatisticsVm } from "../../models/response/IStaticsResponse";
import { initialState } from '../initial-state/statistics-state';

const appConfig = config();

export class PricingMenuStore implements IPricingMenuState, ICommonState {
    inProgress = false;
    error = '';

    
    initialStateValue = {
        success: false,
        error: '',
        inProgress: false
    }
    pricingMenuStats: IGeneralStatisticsVm | undefined = undefined;
    dailyMessageCountIccid: any = undefined;
    allIccidMessageCount: any = undefined;
    pricingMenuStatsState = {...this.initialStateValue}
    dailyMessageCountICCIDState = {...this.initialStateValue}
    allICCIDMessageCountState = {...this.initialStateValue}

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            pricingMenuStats:observable,
            pricingMenuStatsState:observable,
            dailyMessageCountIccid: observable,
            dailyMessageCountICCIDState:observable,
            allIccidMessageCount:observable,
            allICCIDMessageCountState:observable,
            GetPricingMenuStatsService:action,
            GetDailyMessageCountByICCID:action,
            GetAllIccidsMessageCount:action,
            resetAllIccidPacketCountData:action,
            reset: action,
            resetStore: action,
            resetPacketCountData:action,
            resetPricingMenuStatsState:action,
            getStatisticsDetail: computed,
            allMonthOptions: computed,
            allYearOptions: computed,
            dailyPacketCountByICCIDGraphData:computed,
            dailyPacketCountAllIccidGraphData:computed
        });
    }

    /**
    * This function is used get the pricing menu.
    * @param tenantId : Tenant
    * @param month : Month
    * @param year : Year
    * @returns
    */
    GetPricingMenuStatsService = (tenantId:number,month:number, year:number) => {
        this.pricingMenuStatsState.inProgress = true;
        let url: string = URLConstants.Statistics+"/Tenant/"+tenantId+'?year='+year+'&month='+month;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGeneralStatisticsVm>>) => {
                if (response.data.Error) {
                    this.pricingMenuStatsState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.pricingMenuStats = response.data.Data;
                    this.pricingMenuStatsState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.pricingMenuStatsState.inProgress = false; }));
    }

    get getStatisticsDetail(){
        if(this.pricingMenuStats){
            return {...this.pricingMenuStats};
        }
        return initialState;
    }

    resetPricingMenuStatsState = () =>{
        this.pricingMenuStats = undefined;
        this.pricingMenuStatsState = {...this.initialStateValue};
    }

    /**
    * This function is used get the Message Count using ICCID.
    * @param iccid : ICCID
    * @returns
    */
    GetDailyMessageCountByICCID = (tenantId:number,iccid:string,year:number,month:number) => {
        this.dailyMessageCountICCIDState.inProgress = true;
        let url: string = URLConstants.Statistics+'/Tenant/'+tenantId+'/Device/'+iccid+'/Daily/Packets/Count?year='+year+'&month='+month;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.dailyMessageCountICCIDState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.dailyMessageCountIccid = 
                    {
                        Year: year,
                        Month: month,
                        Data: response.data.Data
                    }
                    this.dailyMessageCountICCIDState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.dailyMessageCountICCIDState.inProgress = false; }));
    }

    get dailyPacketCountByICCIDGraphData(): any {
        let days:any[] = [];
        let labels:any[] = [];
        let month = this.dailyMessageCountIccid?.Month;
        let year = this.dailyMessageCountIccid?.Year;
        let dayCount = getDaysInMonth(month,year);
        if (this.dailyMessageCountIccid?.Data){
            for(let i=1; i<=dayCount; i++){
                const dayKey = "Day" + i;
                days.push(this.dailyMessageCountIccid?.Data[dayKey]);
                labels.push(dayKey);
            }
            return { Data:days , Labels:labels };
        }
        return { Data:days , Labels:dailyPacketCountGraphDefaultLabel };
    }


    /**
    * This function is used get the Message Count for all Iccids.
    * @param tenantId : Tenant Id
    * @param year : Year
    * @param month : Month
    * @returns
    */
    GetAllIccidsMessageCount = (tenantId:number,year:number,month:number) => {
        this.allICCIDMessageCountState.inProgress = true;
        return baseService.getRequest(URLConstants.Statistics+'/Tenant/'+tenantId+'/All/Devices/Daily/Packets/Sum/Count?year='+year+'&month='+month)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.allICCIDMessageCountState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allIccidMessageCount = 
                    {
                        Year: year,
                        Month: month,
                        Data: response.data.Data
                    }
                    this.allICCIDMessageCountState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.allICCIDMessageCountState.inProgress = false; }));
    }
    
    get dailyPacketCountAllIccidGraphData(): any {
        let days:any[] = [];
        let labels:any[] = [];
        let month = this.allIccidMessageCount?.Month;
        let year = this.allIccidMessageCount?.Year;
        let dayCount = getDaysInMonth(month,year);
        if (this.allIccidMessageCount?.Data){
            for(let i=1; i<=dayCount; i++){
                const dayKey = "Day" + i;
                days.push(this.allIccidMessageCount?.Data[dayKey]);
                labels.push(dayKey);
            }
            return { Data:days , Labels:labels };
        }
        return { Data:days , Labels:dailyPacketCountGraphDefaultLabel };
    }

    /**
    * This function is used to map allMonthOption suitable for FormikFormSelect Component.
    * @returns Month Options List containing values & id
    */
        get allMonthOptions(): IOption[] {
            const options: IOption[] = [{
                id: -1,
                value: "please_select",
            }];
            for(let i=1;i<=12;i++){
                options.push({
                    id: i,
                    value: getMonthName(i),
                })
            }
            return options;
        }

        /**
    * This function is used to map allYearOptions suitable for FormikFormSelect Component.
    * @returns Year Options List containing values & id
    */
        get allYearOptions(): IOption[] {
            const year = new Date().getFullYear();
            const options: IOption[] = [{
                id: -1,
                value: "please_select",
            }];
            for(let i=2023;i<=year;i++){
                options.push({
                    id: i,
                    value: (i).toString(),
                })
            }
            return options;
        }

 
    /**
     * This function is used to reset observables to their initial values.
     * @returns
     */
    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.pricingMenuStatsState = { ...this.initialStateValue }
    }

    resetPacketCountData = () => {
        this.dailyMessageCountIccid = undefined;
    }

    resetAllIccidPacketCountData = () => {
        this.allIccidMessageCount = undefined;
    }
}


export default new PricingMenuStore();