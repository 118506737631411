import React from "react";
import "./add-edit-order-form.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import { observer } from "mobx-react";
import { IAddUpdateOrderVM } from "../../../models/response/IOrderResponse";
import monoz_logo from "../../../assets/Images/monoZ_Light-Grey_icon.png";
import { DATE_FORMAT, emptyValue, formatNumberWithCommas, roundWithPrecision } from "../../../constants/common-constants";
import moment from "moment";

interface IProps {
  values: IAddUpdateOrderVM;
  noOfRecords: number;
  id:number;
}

const OrderInvoice = (props: IProps) => {
  const { orderStore, pricingPlanStore } = useStore();
  const { allOrders } = orderStore;
  const { allPricingPlans } = pricingPlanStore;
  const {values, noOfRecords} = props;
  const tempOrderDescription = allOrders.filter((order) => order.Id == values.OrderDescriptionType.Id)
  const tempPricingPlan = allPricingPlans.filter((plan) => plan.Id == values.Plan.Id);
  
  let unitPrice=0, addedDevicePlanPrice = 0, tax=0, totalAmount=0, subTotalAmount=0;
  
  if(tempPricingPlan.length === 1){
    unitPrice = tempPricingPlan[0].PlanPrice;
    addedDevicePlanPrice = values.OrderDescriptionType.Id == 3 ? noOfRecords*unitPrice : (values.NoOfDevicesOrder != "" ? unitPrice*parseInt(values.NoOfDevicesOrder) : 0);
    subTotalAmount = totalAmount + addedDevicePlanPrice;
    tax = (subTotalAmount/100)* tempPricingPlan[0].Tax;
    totalAmount = subTotalAmount + tax;
  }

  return (
    <>
      
          <div className="row">
            <div className="col-5">
              <img
                src={monoz_logo}
                alt="logo"
                className="invoice-logo img-fluid"
              ></img>
            </div>
            <div className="col-7 text-end">
              <h2 className="my-0 fw-bold text-end"><FormattedMessage id="order_summary"></FormattedMessage></h2>
              <p className="invoice_details m-0">
                <span className="value_holder"> 
                  {props.id > 0 ? (moment(values.CreatedDate).format(DATE_FORMAT)) : 
                  moment(new Date()).format(DATE_FORMAT)}
                </span>
              </p>
            </div>
          </div>
          <div className="row mt-3 justify-content-between">
           {
            values.OrderDescriptionType.Id != 3 &&
            <div className="col-6 text-end" >   
              <p className="fw-bold small">
                <FormattedMessage id='shipping_address' />                  
              </p>
              {
              (values.OrderDescriptionType.Id > 0 && values.ShippingAddress.trim().length > 0) ?
              <p className="invoice_details">
              {
                values.ShippingAddress
              }
              </p>  :
              <p className="invoice_details"><span className="blank_holder"></span></p>
              }
            </div>
           }
            
            <div className={`${values.OrderDescriptionType.Id == 3 ? "col-12" : "col-6"} text-end`} > 
            <p className="fw-bold small">
                <FormattedMessage id='billing_address' />                  
              </p>
              {
                (values.OrderDescriptionType.Id > 0 && values.BillingAddress.trim().length > 0) ?
                <p className="invoice_details">
                {
                  values.BillingAddress
                }
                </p>  :
                <p className="invoice_details"><span className="blank_holder"></span></p>
              }
            </div>
            
          </div>
          <table className="table invoice-table mt-4">
          { tempPricingPlan.length > 0 ?
            <thead>
              <tr>
                <th><FormattedMessage id="item" /></th>
                <th><FormattedMessage id="qty" /></th>
                <th><FormattedMessage id="unit_price_label" /></th>
                <th className="text-end"><FormattedMessage id="amount" />&nbsp;
                {
                  tempPricingPlan[0]?.Currency?.Symbol &&
                  <>
                    ({tempPricingPlan[0]?.Currency?.Symbol})
                  </>
                }
                </th>
              </tr>
            </thead> :
            <thead>
              <tr>
                <th><FormattedMessage id="item" /></th>
                <th><FormattedMessage id="qty" /></th>
                <th><FormattedMessage id="price" /></th>
                <th className="text-end"><FormattedMessage id="amount" /></th>
              </tr>
            </thead>
            }
                <tbody>
                  <tr>
                    <td>
                    {
                        tempOrderDescription.length > 0 ?
                        <h3 className="m-0">{tempOrderDescription[0].TypeString}</h3> :
                        <span className="blank_holder_lg"></span>
                      }
                      {
                        tempPricingPlan.length > 0 &&
                        <>
                        <FormattedMessage id="monoz_link"/> - 
                        <small className="text-muted"> {tempPricingPlan[0].PlanName}</small>
                        <br/>
                       </>
                      }
                      {
                        tempOrderDescription.length > 0 &&
                        <>
                          <FormattedMessage id="others"/> - 
                          <small className="text-muted ">{ tempOrderDescription[0].Description ? tempOrderDescription[0].Description : emptyValue}</small>
                        </>
                      }
                    </td>
                    {
                      tempPricingPlan.length > 0 ?
                      <>
                        <td ><span className="value_holder">
                          {(values.NoOfDevicesOrder != "0" && values.NoOfDevicesOrder != "") || noOfRecords > 0 ? 
                          (values.OrderDescriptionType.Id == 3 ? noOfRecords : Number(values.NoOfDevicesOrder)) : 0}
                          </span></td>
                        <td ><span className="value_holder">{tempPricingPlan[0].PlanPrice}</span></td>
                        <td className="text-end"><span className="value_holder amount">
                          { (values.NoOfDevicesOrder != "0" && values.NoOfDevicesOrder != "") || noOfRecords > 0 ? 
                          (values.OrderDescriptionType.Id == 3 ? formatNumberWithCommas(noOfRecords*tempPricingPlan[0].PlanPrice) : 
                          formatNumberWithCommas(parseInt(values.NoOfDevicesOrder)*tempPricingPlan[0].PlanPrice)) : 0}</span></td>
                      </> :
                      <>
                        <td><span className="blank_holder"></span></td>
                        <td><span className="blank_holder"></span></td>
                        <td className="text-end"><span className="blank_holder"></span></td>
                      </>
                    }
                  </tr>
                  
                </tbody>
              </table>
              {
               tempPricingPlan.length > 0 ?
                <div className="row">
                  <div className="col-4">

                  </div>
                  <div className="col-8">
                    <div className="bg-light px-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="text-muted"><FormattedMessage id="subtotal" /> </span>                    
                        <span className="value_holder text-end">{formatNumberWithCommas(subTotalAmount)}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="text-muted"><FormattedMessage id="tax" /> ({tempPricingPlan[0].Tax}%) </span>                    
                        <span className="value_holder text-end">{formatNumberWithCommas(tax)}</span>
                      </div>
                    </div>
                    <div className="px-2 mb-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="text-uppercase"><FormattedMessage id="total_amount" /> </span>                    
                        <span className="value_holder text-end">{formatNumberWithCommas(totalAmount)}</span>
                      </div>
                    </div>
                  </div>
                </div> : 
                <div className="row">
                <div className="col-4">
    
                </div>
                <div className="col-8">
                  <div className="bg-light px-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-muted"><FormattedMessage id="subtotal" /> </span>                    
                      <span className="blank_holder"></span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-muted"><FormattedMessage id="tax_heading" /> </span>                    
                      <span className="blank_holder"></span>
                    </div>
                  </div>
                  <div className="px-2 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-uppercase"><FormattedMessage id="total_amount" /> </span>                    
                      <span className="blank_holder"></span>
                    </div>
                  </div>
                </div>
              </div>

              }
          <div className="small invoice-footer">
            <ul className="styled-list">
            <li><FormattedMessage id="thank_message_1" /><br/></li>
            <li><FormattedMessage id="thank_message" /></li>
            </ul>
          </div>
        </>
  )
};

export default observer(OrderInvoice);