/*    
<summary>
  This functional component "Order Filter" renders the order details.
  Developer:Aashish Singh, Created Date: 27-August-2024
</summary>
<returns>Returns JSX</returns>
*/

import React from "react";
import { observer } from "mobx-react";
import { Formik, Field, Form } from "formik";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import Label from "../../../shared-components/Label/Label";
import { IOrderFilterDetails } from "../../../models/response/IOrderResponse";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { orderSearchByOptions, orderStatusOptions, paymentStatusOptions } from "../../../constants/common-constants";
import { orderFilterDetailsValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";

interface IProps {
  initialValues: IOrderFilterDetails;
  submitHandler: (values: IOrderFilterDetails) => void;
  isFilterActive: boolean;
  modalClosed: () => void;
}

const OrderFilterForm = (props: IProps) => {


  return (
    <React.Fragment>
      {
        <>
          <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validateOnBlur={false}
            validationSchema={orderFilterDetailsValidationSchema}
            onSubmit={async (values: IOrderFilterDetails) => {
              let tempValues: IOrderFilterDetails = {
                SearchBy: values.SearchBy,
                OrderNo: values.SearchBy == 1 ? values.OrderNo : "",
                OrderStatus: values.SearchBy == 2 ? (typeof values.OrderStatus === "string" ?  parseInt(values.OrderStatus) : values.OrderStatus) : -1,
                PaymentStatus: values.SearchBy == 3 ? (typeof values.PaymentStatus === "string" ?  parseInt(values.PaymentStatus) : values.PaymentStatus) : -1,
              };
              props.submitHandler({ ...tempValues });
            }}
          >
            {({ values }) => {
              return (
                <Form className="user-form p-3">
                      <div className="form-group row">
                        <Label
                          className="label-style col-sm-5 col-form-label"
                          required={true}
                          label="search_by"
                        />
                        <div className="col-sm-7">
                          <Field
                            name="SearchBy"
                            as={FormikFormSelect}
                            options={orderSearchByOptions}
                          />
                        </div>
                        {/* <div className="col-3 btn-radioCheckbox">
                          <Field
                            type="radio"
                            value="0"
                            name="SearchBy"
                            id="None"
                            className="nav-link active"
                            // as={FormikFormCheckBox}
                          />
                          <label htmlFor="None">
                            <FormattedMessage id="none" />
                          </label>
                        </div>
                        <div className="col-3 btn-radioCheckbox">
                          <Field
                            type="radio"
                            value="1"
                            name="SearchBy"
                            id="OrderNo"
                            className="nav-link active"
                            // as={FormikFormCheckBox}
                          />
                          <label htmlFor="OrderNo">
                            <FormattedMessage id="order_no" />
                          </label>
                        </div>

                        <div className="col-3 btn-radioCheckbox">
                          <Field
                            type="radio"
                            value="2"
                            name="SearchBy"
                            id="OrderStatus"
                            className="nav-link active"
                          />
                          <label htmlFor="OrderStatus">
                            <FormattedMessage id="order_status" />
                          </label>
                        </div>
                        <div className="col-3 btn-radioCheckbox">
                          <Field
                            type="radio"
                            value="3"
                            name="SearchBy"
                            id="PaymentStatus"
                            className="nav-link active"
                          />
                          <label htmlFor="PaymentStatus">
                            <FormattedMessage id="payment_status" />
                          </label>
                        </div> */}
                      </div>

                      <div className="">
                          {
                            values.SearchBy == 1 &&
                            <div className="form-group row">
                              <Label
                                className="col-sm-5"
                                required={true}
                                label="order_no"
                              />
                              <div className="col-sm-7">
                                <Field
                                  data-testid="OrderNo"
                                  id="custom"
                                  className="form-control"
                                  name="OrderNo"
                                  placeholder="order_no"
                                  type="text"
                                  validcharacter={["@", "#", "$", "-", "_", "."]}
                                  as={FormikFormInput}
                                />
                              </div>
                            </div>
                          }
                          {
                            values.SearchBy == 2 &&
                            <div className="form-group row">
                              <Label
                                className="col-sm-5"
                                required={true}
                                label="order_status"
                              />
                              <div className="col-sm-7">
                                <Field
                                  data-testid="OrderStatus"
                                  id="custom"
                                  className="form-control"
                                  name="OrderStatus"
                                  as={FormikFormSelect}
                                  options={[{ id: -1, value: "please_select" },...orderStatusOptions]}
                                />
                              </div>
                            </div>
                          }
                          {
                            values.SearchBy == 3 &&
                            <div className="form-group row">
                              <Label
                                className="col-sm-5"
                                required={true}
                                label="payment_status"
                              />
                              <div className="col-sm-7">
                                <Field
                                  data-testid="PaymentStatus"
                                  id="custom"
                                  className="form-control"
                                  name="PaymentStatus"
                                  options={paymentStatusOptions}
                                  as={FormikFormSelect}
                                />
                              </div>
                            </div>
                          }
                      </div>

                  <div className="d-flex justify-content-end pb-2">
                    <button
                      data-testid="apply"
                      className="btn btn-secondary"
                      type="button"
                      onClick={props.modalClosed}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FormattedMessage id="button_cancel" />
                    </button>

                    <LoaderButton type="submit" className="ms-2" text="apply" />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      }
    </React.Fragment>
  );
};

export default observer(OrderFilterForm);
