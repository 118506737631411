/*    
<summary>
  This functional component "LogoutPopup" provides modal popup that shows logout confirmation message (i.e. Are you sure 
  you want logout?).
  Developer:Aashish Singh, Created Date:08Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./LogoutPopup.css";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import RoutesConstants from "../../../../shared-components/Routes/routes.constants";
import { useStore } from "../../../../contexts/StoreProvider";
import { formatMessage } from "../../../../translations/format-message";
import toast, { Toaster } from "react-hot-toast";
import { observer } from "mobx-react";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";

interface IProps {
  modalClosed: () => void;
}

const LogoutPopup = (props: IProps) => {
  const history = useHistory();
  const { authStore, configurationStore, deviceStore, groupStore, oneNceStore, preferencesStore, tenantStore, userStore,
    currencyStore, auditLogStore, orderStore, pricingPlanStore, deviceRegisterStore, paymentHistoryStore} = useStore();
  const { logout } = authStore;

  const resetStores = () =>{
    configurationStore.resetStore();
    deviceStore.resetStore();
    groupStore.resetStore();
    oneNceStore.resetStore();
    tenantStore.resetStore();
    userStore.resetStore();
    preferencesStore.resetStore();
    currencyStore.resetStore();
    auditLogStore.resetStore();
    orderStore.resetStore();
    pricingPlanStore.resetStore();
    paymentHistoryStore.resetStore();
    deviceRegisterStore.resetStore();
  }
  const {setUpdateTheme} = preferencesStore;
  const { logoutError, logoutInprogress, logoutSuccess, resetLogout } =
    authStore;

  useEffect(() => {
    if (logoutError) {
      toast.error(formatMessage(logoutError));
      resetLogout();
    }
  }, [logoutError]);

  useEffect(() => {
    if (logoutSuccess) {
      toast.success(formatMessage("logout_successfully"));
      resetLogout();
      props.modalClosed();
      history.push(RoutesConstants.Login);
      resetStores();
    }
  }, [logoutSuccess]);

  const logoutCaller = () => {
    authStore.logout();
    setUpdateTheme("false");
  };

  return (
    <Modal
      show={true}
      centered
      onHide={props.modalClosed}
      dialogClassName="no-data-found-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="application_title" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bootbox-body">
          <FormattedMessage id="logout_message" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="Cancel"
          variant="secondary"
          onClick={props.modalClosed}
          className="btnOkSize"
        >
          <FormattedMessage id="button_cancel" />
        </Button>
        <LoaderButton
            type="button"
            isLoading={logoutInprogress}
            text="logout"
            data-testid="LogoutOk"
            onClick={logoutCaller}
            className="btnOkSize"
          />
        {/* <Button
          variant="primary"
          data-testid="LogoutOk"
          onClick={logoutCaller}
          className="btnOkSize"
        >
          <FormattedMessage id="logout" />
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default observer(LogoutPopup);
