/*    
<summary>
   This component is Used for Activation user Account from email link.
   Developer: Aashish Singh, Created Date:20-Sept-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "react-sliding-side-panel/lib/index.css";
import successIcon from "../../assets/Images/svg/activatesucess.svg";
import mailsentIcon from "../../assets/Images/svg/user-check.svg";
import noMessageIcon from "../../assets/Images/svg/interface-design.svg";
import { formatMessage } from "../../translations/format-message";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "../../contexts/StoreProvider";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import { resendMailTimer } from "../../constants/common-constants";

const UserAccountActivation = (props: any) => {
  const { userStore } = useStore();
  const {
    ActivateUserService,
    activateAccountState,
    resetAccountActivationState,
  } = userStore;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const EmailId = params.get("EmailId");
  const VerificationCode = params.get("VerificationCode");
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const [timer, setTimer] = useState(resendMailTimer);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // resetAccountActivationState();
    if (EmailId && VerificationCode) {
      ActivateUserService(VerificationCode, EmailId);
    }
    else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-img1 text-start background">
      <div className="d-flex justify-content-center align-items-center vh-100">
        {(activateAccountState.inProgress) &&
          <FormLoader loading={(activateAccountState.inProgress)} />}

        <div className="text-center activate_message_wrap">
          {
            activateAccountState.inProgress &&
            <>
              <img src={successIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4">
                <FormattedMessage id="activating_account_message" />
              </p>
            </>
          }
          {
            (activateAccountState.success) &&
            <>
              <img src={mailsentIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4 display-resendMailTimer ">
                <FormattedMessage id="account_created_successfully" />
              </p>
            </>
          }
          {
            error &&
            <>
              <img src={noMessageIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4 text-danger">
                <FormattedMessage id="invalind_link" />
              </p>
            </>
          }
          {
            activateAccountState.error &&
            <>
              <img src={noMessageIcon} alt="" className="no_message" />
              <p className="noData text-muted h3 mb-0">
                <FormattedMessage id="activating_account" />
              </p>
              <p className="noData h5 my-4 text-danger">
                {formatMessage(activateAccountState.error)}
              </p>
            </>
          }
          <div className="d-flex align-items-center justify-content-center">
            <button
              className={`btn btn-primary p-2`}
              onClick={() => {
                resetAccountActivationState();
                localStorage.clear();
                setTimeout(() => {
                  history.push(RoutesConstants.Login);
                });
              }}
            >
              <FormattedMessage id={"back_to_login"} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(UserAccountActivation);
