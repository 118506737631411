/*    
<summary>
   This functional component provides routing functionality. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary> 
<returns>Returns JSX</returns>
*/
import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Login from "../../modules/auth/Login";
import RoutesConstants from "./routes.constants";
import LoginLayoutRoute from "./LoginLayoutRoute";
import LayoutRoute from "./LayoutRoute";
import { withRouter } from "react-router-dom";
import Device from "../../modules/device/Device";
import AddBulk from "../../modules/device/add-bulk/AddBulk";
import Dashboard from "../../modules/dashboard/Dashboard";
import Configuration from "../../modules/configurations/configuration";
import Group from "../../modules/groups/group";
import tenants from "../../modules/tenants/tenants";
import User from "../../modules/user/user";
import AuditLog from "../../modules/audit-logs/audit-log";
import { observer } from "mobx-react";
import Documentation from "../../modules/documentation/documentation";
import SingleView from "../../modules/documentation/components/singleView/singleView";
import forgetPassword from "../../modules/forget-password/components/forget-password";
import PricingPlan from "../../modules/pricing-plan/pricing-plan";
import PricingMenu from "../../modules/pricing-menu/pricing-menu";
import DeviceRegister from "../../modules/device-iccid/device-register";
import OrganizationAccountActivation from "../../modules/account-activation/organization-account-activation";
import UserAccountActivation from "../../modules/account-activation/user-account-activation";
import ResendMail from "../../modules/create-account/components/resend-email";
import BulkDeviceRegister from "../../modules/device-iccid/components/bulk-device-register";
import Order from "../../modules/order/order";
import Fota from "../../modules/fota/fota";
import PaymentHistory from "../../modules/payment-history/payment-history";
import CreateAccount from "../../modules/create-account/create-account";
import systemModuleEnum from "../../constants/system-module-enum";
import { findRouteAccess, redirectToDefaultRoute } from "../../helpers/route-helper";
import { getUserTypeFromLocalStorage } from "../../helpers/local-stotrage-helper";
import userTypeEnum from "../../constants/user-type-enum";

const Routes = (props:any) => {
    return (
        <React.Fragment>
            <Switch>
                <LoginLayoutRoute path={RoutesConstants.Login} component={Login} />
                <LoginLayoutRoute path={RoutesConstants.ForgetPassword} component={forgetPassword} />
                <LoginLayoutRoute path={RoutesConstants.CreateAccount} component={CreateAccount} />
                <Route path={RoutesConstants.OrganizationAccountActivation} component={OrganizationAccountActivation} />
                <Route path={RoutesConstants.UserAccountActivation} component={UserAccountActivation} />
                <Route path={RoutesConstants.ResendMail} component={ResendMail} />
                {findRouteAccess(systemModuleEnum.Tenant) && <LayoutRoute path={RoutesConstants.Tenants} component={tenants} /> }
                <LayoutRoute path={RoutesConstants.Documentation} component={Documentation} />
                <LayoutRoute path={RoutesConstants.SingleView} component={SingleView} />
                {(findRouteAccess(systemModuleEnum.Statistics)) && <LayoutRoute path={RoutesConstants.PricingMenu} component={PricingMenu}/> }
                {findRouteAccess(systemModuleEnum.Plan) && <LayoutRoute path={RoutesConstants.PricingPlan} component={PricingPlan}/> }
                {(findRouteAccess(systemModuleEnum.Order) && getUserTypeFromLocalStorage() == userTypeEnum.SystemUser) && <LayoutRoute path={RoutesConstants.Order} component={Order}/> }
                {(findRouteAccess(systemModuleEnum.Order) && getUserTypeFromLocalStorage() == userTypeEnum.SystemUser) && <LayoutRoute path={RoutesConstants.PaymentHistory} component={PaymentHistory}/> }
                {findRouteAccess(systemModuleEnum.Dashboard) && <LayoutRoute path={RoutesConstants.Dashboard} component={Dashboard} /> }
                {findRouteAccess(systemModuleEnum.Configuration) && <LayoutRoute path={RoutesConstants.Configurations} component={Configuration}/> }
                {findRouteAccess(systemModuleEnum.Device) && <LayoutRoute path={RoutesConstants.Device} component={Device} /> }
                {findRouteAccess(systemModuleEnum.Device) && <LayoutRoute path={RoutesConstants.AddBulk} component={AddBulk} /> }
                {findRouteAccess(systemModuleEnum.DeviceRegisteration) && <LayoutRoute path={RoutesConstants.DeviceRegister} component={DeviceRegister} /> }
                {(findRouteAccess(systemModuleEnum.DeviceRegisteration) && getUserTypeFromLocalStorage() === userTypeEnum.SystemUser) && <LayoutRoute path={RoutesConstants.BulkDeviceRegister} component={BulkDeviceRegister} /> }
                {findRouteAccess(systemModuleEnum.Group) && <LayoutRoute path={RoutesConstants.Group} component={Group} /> }
                {/* {
                findRouteAccess(systemModuleEnum.Fota) && 
                <LayoutRoute path={RoutesConstants.Fota} component={Fota} />} */}
                {findRouteAccess(systemModuleEnum.User) && <LayoutRoute path={RoutesConstants.User} component={User} /> }
                {
                findRouteAccess(systemModuleEnum.AuditLog) && 
                <LayoutRoute path={RoutesConstants.AuditLog} component={AuditLog} />}
                <Redirect to={redirectToDefaultRoute()} />
            </Switch>
        </React.Fragment>
  );
};

export default withRouter(observer(Routes));
