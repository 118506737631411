/*    
<summary>
  This functional component "PaymentPopupHOC" provides props to the child component.
  1. State "showPaymentPopup" that manages the show/hide of pop up
  2. Function "paymentPopupToggleHandler" to toggle the show/hide popup state "showPaymentPopup"
  Developer:Aashish Singh, Created Date:14-June-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, {useState } from 'react';

const PaymentPopupHOC = (Component:any) => 
function Comp(props:any){
    const [showPaymentPopup,setShowPaymentPopup] = useState<boolean>(false);

    const paymentToggleHandler = () => {
        setShowPaymentPopup(!showPaymentPopup);
    }
	
	return <Component showPaymentPopup={showPaymentPopup} paymentPopupToggleHandler={paymentToggleHandler} {...props}/>;
};

export default PaymentPopupHOC;   