/*    
<summary>
   This class component is all about Tenant Management System.
   Developer: Mohammad Saquib Khan, Created Date: 17-August-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import { ICommonState } from '../../models/state/ICommonState';
import { ITenantState } from '../../models/state/ITenantState';
import IAddTenant, { IUpdateTenant } from '../../models/Form/IAddUpdateTenant';
import toast from 'react-hot-toast';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import URLConstants from '../../constants/url.constants';
import { ITenantListVM, ITenantVM, ITenantCSV, ITenantList, ISendMail } from '../../models/response/ITenantResponse';
import { initialState as addTenantInitialState } from "../initial-state/add-tenant-state";
import { initialState as allTenantInitialState } from "../initial-state/get-all-tenant-state";
import base64 from 'base-64';
import { IOption } from '../../models/ICommon';
import moment from 'moment';
import config from '../../helpers/config-helper';
import { ISelfServiceVerificationVM } from '../../models/response/ISelfServiceVerificationVM';
import { getLanguageFromLocalStorage } from '../../helpers/local-stotrage-helper';
import { DATE_TIME_FORMAT_FILENAME } from '../../constants/common-constants';
import systemModuleEnum from '../../constants/system-module-enum';

const appConfig = config();
const dateFormat =  appConfig.REACT_APP_DATE_FORMAT;
const fileNameDateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_FILENAME;

export class TenantStore implements ITenantState, ICommonState {
    inProgress = false;
    error = '';

    initialStateValue = {
        success: false,
        error: '',
        inProgress: false
    }

    tenantDataList: ITenantListVM = allTenantInitialState;
    allTenants: ITenantVM[] = [];

    tenant: any = undefined;
    addUpdateTenantState = { ...this.initialStateValue };
    addTrialTenantState = { ...this.initialStateValue };
    deleteTenantState = { ...this.initialStateValue };
    tenantState = { ...this.initialStateValue };
    changePasswordState ={...this.initialStateValue };

    activeInactiveState ={...this.initialStateValue }

    dashboardTenantDetail: any = undefined;

    selectedTenantId = -1;
    selectedOrderModuleTenantId = -1;

    isValidEmail:ISelfServiceVerificationVM  = {IsEmailConfimationOTPSent:false, IsEmailVerified:false};
    isValidEmailState = {...this.initialStateValue}

    isOTPVerifiedState = {...this.initialStateValue}
    isOTPVerified:boolean | undefined = false;
    verifiedEmail:string = "";

    activateOrganizationAccountState = { ...this.initialStateValue };
    resendActivationLinkState = { ...this.initialStateValue };

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,

            tenantDataList: observable,
            allTenants: observable,
            tenant: observable,
            dashboardTenantDetail: observable,
            addUpdateTenantState: observable,
            deleteTenantState: observable,
            tenantState: observable,
            changePasswordState: observable,
            activeInactiveState: observable,
            addTrialTenantState:observable,
            selectedTenantId: observable,
            selectedOrderModuleTenantId: observable,

            isValidEmail: observable,
            isValidEmailState:observable,
            isOTPVerifiedState:observable,
            isOTPVerified: observable,
            verifiedEmail: observable,

            activateOrganizationAccountState: observable,
            resendActivationLinkState: observable,
            
            GetTenantListService: action,
            GetAllTenantsService: action,
            GetTenantService: action,
            GetDashboardTenantByIdService: action,
            AddTenantService: action,
            AddTrialTenant: action,
            UpdateTenantService: action,
            DeleteTenantService: action,
            UpdatePasswordService: action,
            UpdateTenantStatusService: action,
            exportAllTenantsService:action,
            SelfServiceTenantEmailVerificationService:action,
            SelfServiceTenantOTPVerificationService:action,
            ActivateOrganizationService:action,
            ResendAccountActivateLinkService: action,
            setSelectedTenantId:action,
            setSelectedOrderModuleTenantId:action,
            
            reset: action,
            resetStore: action,
            resetAddUpdateTenant: action,
            resetGetTenantDetail: action,
            resetActiveInactiveState: action,
            resetChangePassword:action,
            resetIsValidEmailState: action,
            resetIsOTPVerifiedState:action,
            resetAddTrialTenantState:action,

            resetAccountActivationLink: action,
            resetAccountActivationState: action,

            allTenantlist: computed,
            allTenantOptions: computed,
            allTenantOptionsWithAll: computed,
            dashboardTenantInfo:computed,
            tenantDetails:computed,
        });
    }

   /**
    * This function is used to get tenants list with pagination by calling API.
    * @param pageNumber : Page Number
    * @param pageSize : Page Size
    * @returns 
    */
    GetTenantListService = (pageNumber: number, pageSize: number) => {
        this.inProgress = true;
        const url = URLConstants.Tenant + "/" + "List/" + "?PageNo=" + pageNumber + "&PageSize=" + pageSize;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantListVM>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.tenantDataList = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
    * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
    * @returns Initial Tenant Details
    */
    get allTenantlist(): ITenantList[] {
        if (this.tenantDataList && this.tenantDataList?.Tenants.length > 0)
            return this.tenantDataList?.Tenants.map((tenant) => {
                let language = getLanguageFromLocalStorage();
                return {
                    Id: tenant.Id,
                    UserId: tenant.UserId,
                    TenantName: tenant.TenantName,
                    Email: tenant.Email,
                    OrganizationId: tenant.OrganizationId,
                    VatNo: tenant.VatNo,
                    OrganizationName: tenant.OrganizationName,
                    ContactPhoneNumber: tenant.ContactPhoneNumber,
                    ContactEmail: tenant.ContactEmail,
                    ContactPersonName: tenant.ContactPersonName,
                    ShippingAddress: tenant.ShippingAddress,
                    BillingAddress: tenant.BillingAddress,
                    MaxUserAllow: tenant.MaxUserAllow,
                    MaxDeviceAllow: tenant.MaxDeviceAllow,
                    IsActive: tenant.IsActive,
                    IsInfoSame: (tenant.TenantName === tenant.ContactPersonName && tenant.Email === tenant.ContactEmail ) ? true : false,
                    IsDataPakcetsStoreInDB: tenant.IsDataPakcetsStoreInDB,
                    SendThresholdAlert: tenant.SendThresholdAlert,
                    BlockTenantOnThresholdLimitReached: tenant.BlockTenantOnThresholdLimitReached,
                    ApiKey: tenant?.ApiKey,
                    ApiKeySecretValue: tenant?.ApiKeySecretValue,
                    IsTrialTenant: tenant.IsTrialTenant,
                    CreatedBy: tenant.CreatedBy,
                    UpdatedBy: tenant.UpdatedBy,
                    Language: language,
                    isDelete: true,
                    isEdit: true,
                    isData: true,
                }
            })
        return [];
    }

   /**
    * This function is used to get All Tenants without pagination by calling an API.
    * @returns 
    */
    GetAllTenantsService = (moduleName?: systemModuleEnum) => {
        this.inProgress = true;
        const url = URLConstants.Tenant + "/" + "All";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantVM[]>>) => {
                if (response.data.Error) {              
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allTenants = response.data.Data;
                    console.log(systemModuleEnum.Order)
                    if(moduleName != systemModuleEnum.Order){
                        if(this.selectedTenantId === -1 )
                            this.selectedTenantId = this.allTenants.length > 0 ? this.allTenants[0].Id : -1;
                        else{
                            let selectedTenantIdPresent = this.allTenants.length > 0 ? this.allTenants.filter((tenant:ITenantVM)=> tenant.Id == this.selectedTenantId) : [];
                            if(selectedTenantIdPresent.length == 0)
                                this.selectedTenantId = this.allTenants.length > 0 ? this.allTenants[0].Id : -1;
                        }
                    }
                    else{
                        if(this.selectedOrderModuleTenantId !== -1 ){
                            let selectedOrderModuleTenantIdPresent = this.allTenants.length > 0 ? this.allTenants.filter((tenant:ITenantVM)=> tenant.Id == this.selectedOrderModuleTenantId) : [];
                            if(selectedOrderModuleTenantIdPresent.length == 0)
                                this.selectedOrderModuleTenantId = this.allTenants.length > 0 ? this.allTenants[0].Id : -1;
                        }
                    }
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    resetAllTenantList = () =>{
        this.allTenants = [];
    }

    /**
    * This function is used to map allTenants to allTenantOptions suitable for FormikFormSelect Component.
    * @returns Tenant Options List containing values & id
    */
    get allTenantOptions(): IOption[] {
        const options: IOption[] = [{
            id: -1,
            value: "please_select",
        }];
        if (this.allTenants && this.allTenants.length > 0)
            this.allTenants.map((tenant) => {
                options.push({
                    id: tenant.Id,
                    value: tenant.TenantName,
                })
            })

        return options;
    }
    /**
    * This function is used to map allTenants to allTenantOptions suitable for FormikFormSelect Component.
    * @returns Tenant Options List containing values & id
    */
    get allTenantOptionsWithAll(): IOption[] {
        const options: IOption[] = [{
            id: -1,
            value: "all",
        }];
        if (this.allTenants && this.allTenants.length > 0)
            this.allTenants.map((tenant) => {
                options.push({
                    id: tenant.Id,
                    value: tenant.TenantName,
                })
            })

        return options;
    }

    /**
     * This function is used to Get Tenant Details by calling an API.
     * @param tenantId : Tenant Identifier
     * @param userId : User Identifier
     * @returns
     */
    GetTenantService = (tenantId: number, userId: number) => {
        this.tenantState.inProgress = true;
        const url = URLConstants.Tenant + "?TenantId=" + tenantId + "&UserId=" + userId;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantVM>>) => {
                if (response.data.Error) {
                    this.tenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.tenant = response.data.Data;
                    this.tenantState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.tenantState.inProgress = false }));
    }

    /**
    * This function provides initail values to the Add Update Device Form.
    * @returns Initial Device Details
    */
    get tenantDetails(): IAddTenant {
        if (this.tenant)
            return {
                TenantName: this.tenant.TenantName,
                Email: this.tenant.Email,
                Password: this.tenant.Password,
                OrganizationName: this.tenant.OrganizationName,
                OrganizationId: this.tenant.OrganizationId,
                VatNo: this.tenant.VatNo,
                ContactPhoneNumber: this.tenant.ContactPhoneNumber,
                ContactEmail: this.tenant.ContactEmail,
                ContactPersonName: this.tenant.ContactPersonName,
                ShippingAddress: this.tenant.ShippingAddress,
                BillingAddress: this.tenant.BillingAddress,
                IsBillingAddressSame: this.tenant?.ShippingAddress?.trim() == this.tenant?.BillingAddress?.trim() ? true : false,
                MaxUserAllow: this.tenant.MaxUserAllow,
                IsInfoSame: (this.tenant?.TenantName === this.tenant?.ContactPersonName && this.tenant?.Email === this.tenant?.ContactEmail ) ? true : false,
                MaxDeviceAllow: this.tenant.MaxDeviceAllow,
                IsActive: this.tenant.IsActive,
                IsDataPakcetsStoreInDB: this.tenant.IsDataPakcetsStoreInDB,
                SendThresholdAlert: this.tenant.SendThresholdAlert,
                BlockTenantOnThresholdLimitReached: this.tenant.BlockTenantOnThresholdLimitReached,
                UserId: this.tenant.UserId,
                ApiKey: this.tenant.ApiKey,
                ApiKeySecretValue: this.tenant.ApiKeySecretValue,
            };
        return addTenantInitialState;
    }

    /**
    * This function is used to reset getDeviceDetail observables to their initial values.
    * @returns
    */
    resetGetTenantDetail = () => {
        this.tenant = undefined;
        this.tenantState = { ...this.initialStateValue }
    }

    /**
     * This function is used to Get tenant Details by Id from API.
     * @param tenantId : Tenant Identifier
     * @param userId : User Identifier
     * @returns
     */
    GetDashboardTenantByIdService = (tenantId: number, userId: number) => {
        this.tenantState.inProgress = true;
        const url = URLConstants.Tenant + "?TenantId=" + tenantId + "&UserId=" + userId;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantVM>>) => {
                if (response.data.Error) {
                    this.tenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    let data = response.data.Data;
                    this.dashboardTenantDetail = data
                    localStorage.setItem('mzl_IsTrialTenant', (data.IsTrialTenant!).toString());
                    this.tenantState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.tenantState.inProgress = false }));
    }

    /**
  * This function provides values to the dashboard.
  * @returns Initial Tenant Details
  */
    get dashboardTenantInfo(): IAddTenant {
        if (this.dashboardTenantDetail)
            return {
                TenantName: this.dashboardTenantDetail.TenantName,
                Email: this.dashboardTenantDetail.Email,
                Password: this.dashboardTenantDetail.Password,
                OrganizationName: this.dashboardTenantDetail.OrganizationName,
                OrganizationId: this.dashboardTenantDetail.OrganizationId,
                VatNo: this.dashboardTenantDetail.VatNo,
                ContactPhoneNumber: this.dashboardTenantDetail.ContactPhoneNumber,
                ContactEmail: this.dashboardTenantDetail.ContactEmail,
                ContactPersonName: this.dashboardTenantDetail.ContactPersonName,
                ShippingAddress: this.dashboardTenantDetail.ShippingAddress,
                BillingAddress: this.dashboardTenantDetail.BillingAddress,
                MaxUserAllow: this.dashboardTenantDetail.MaxUserAllow,
                MaxDeviceAllow: this.dashboardTenantDetail.MaxDeviceAllow,
                IsActive: this.dashboardTenantDetail.IsActive,
                IsBillingAddressSame: this.dashboardTenantDetail.IsBillingAddressSame ? this.dashboardTenantDetail.IsBillingAddressSame : false,
                IsInfoSame: (this.dashboardTenantDetail.TenantName === this.dashboardTenantDetail.ContactPersonName && this.dashboardTenantDetail.Email === this.dashboardTenantDetail.ContactEmail ) ? true : false,
                IsDataPakcetsStoreInDB: this.dashboardTenantDetail.IsDataPakcetsStoreInDB,
                SendThresholdAlert: this.dashboardTenantDetail.SendThresholdAlert,
                BlockTenantOnThresholdLimitReached: this.dashboardTenantDetail.BlockTenantOnThresholdLimitReached,
                UserId: this.dashboardTenantDetail.UserId,
                ApiKey: this.dashboardTenantDetail.ApiKey,
                ApiKeySecretValue: this.dashboardTenantDetail.ApiKeySecretValue,
                IsTrialTenant:this.dashboardTenantDetail.IsTrialTenant,
                CreatedDate: this.dashboardTenantDetail.CreatedDate
            };
        return addTenantInitialState;
    }


    /**
    * This function is used to Add New Tenant by calling an API & sending the required tenant details. 
    * @param tenant : Tenant Details
    * @returns
    */
    AddTenantService = (tenant: IAddTenant) => {
        var obj = {
            TenantName: tenant.TenantName,
            Password: base64.encode(tenant.Password),
            Email: tenant.Email,
            OrganizationId: tenant.OrganizationId?.toLowerCase(),
            VatNo: tenant.VatNo,
            OrganizationName: tenant.OrganizationName,
            ContactPersonName: tenant.ContactPersonName,
            ContactEmail: tenant.ContactEmail,
            ContactPhoneNumber: tenant.ContactPhoneNumber,
            ShippingAddress: tenant.ShippingAddress,
            BillingAddress: tenant.BillingAddress,
            MaxUserAllow: tenant.MaxUserAllow,
            MaxDeviceAllow: tenant.MaxDeviceAllow,
            IsActive: tenant.IsActive,
            SendThresholdAlert: tenant.SendThresholdAlert,
            BlockTenantOnThresholdLimitReached: tenant.BlockTenantOnThresholdLimitReached,
            IsDataPakcetsStoreInDB: tenant.IsDataPakcetsStoreInDB,
        }
        this.addUpdateTenantState.inProgress = true;
        return baseService.postRequest(URLConstants.Tenant, obj)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateTenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdateTenantState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addUpdateTenantState.inProgress = false; }));
    }

    /**
     * This function is used to reset addUpdateDevice observables to their initial values.
     * @returns
     */
    resetAddUpdateTenant = () => {
        this.addUpdateTenantState = { ...this.initialStateValue }
    }

    /**
    * This function is used to Add New Trial Tenant by calling an API & sending trail tenant details. 
    * @param tenant : Trial Tenant Details
    * @returns
    */
    AddTrialTenant = (tenant: IAddTenant) => {
        var obj = {
            TenantName: tenant.TenantName,
            Password: base64.encode(tenant.Password),
            Email: tenant.Email,
            OrganizationId: tenant.OrganizationId?.toLowerCase(),
            OrganizationName: tenant.OrganizationName,
            VatNo: tenant.VatNo,
            ContactPersonName: tenant.ContactPersonName,
            ContactEmail: tenant.ContactEmail,
            ContactPhoneNumber: tenant.ContactPhoneNumber,
            ShippingAddress: tenant.ShippingAddress,
            BillingAddress: tenant.BillingAddress,
            MaxUserAllow: tenant.MaxUserAllow,
            MaxDeviceAllow: tenant.MaxDeviceAllow,
            SendThresholdAlert: tenant.SendThresholdAlert,
            IsActive: tenant.IsActive,
            IsDataPakcetsStoreInDB: tenant.IsDataPakcetsStoreInDB,
        }
        this.addTrialTenantState.inProgress = true;
        return baseService.postRequest(URLConstants.TrialTenant, obj)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addTrialTenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addTrialTenantState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addTrialTenantState.inProgress = false; }));
    }

    /**
     * This function is used to reset addTrialTenantState observables to their initial values.
     * @returns
     */
    resetAddTrialTenantState = () => {
        this.addTrialTenantState = {... this.initialStateValue}
    }

    /**
    * This function is used to update existing tenant by calling an API & sending updated tenant details. 
    * @param id : Tenant identifier
    * @param tenant : Tenant Details
    * @returns
    */
    UpdateTenantService = (id: number, tenant: any) => {
        let data = {
            Id: id,
            UserId: tenant.UserId,
            TenantName: tenant.TenantName,
            Email: tenant.Email,
            OrganizationId: tenant.OrganizationId,
            VatNo: tenant.VatNo,
            OrganizationName: tenant.OrganizationName,
            ContactPersonName: tenant.ContactPersonName,
            ContactEmail: tenant.ContactEmail,
            ContactPhoneNumber: tenant.ContactPhoneNumber,
            ShippingAddress: tenant.ShippingAddress,
            BillingAddress: tenant.BillingAddress,
            MaxUserAllow: tenant.MaxUserAllow,
            MaxDeviceAllow: tenant.MaxDeviceAllow,
            SendThresholdAlert: tenant.SendThresholdAlert,
            BlockTenantOnThresholdLimitReached: tenant.BlockTenantOnThresholdLimitReached,
            IsActive: tenant.IsActive,
            IsDataPakcetsStoreInDB: tenant.IsDataPakcetsStoreInDB,
        }
        this.addUpdateTenantState.inProgress = true;
        return baseService.putRequest(URLConstants.Tenant, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateTenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdateTenantState.success = true;
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
                } else { toast.error(formatMessage(err)); }
            })
            .finally(action(() => { this.addUpdateTenantState.inProgress = false; }));
    }

    /**
    * This function is used to delete existing tenant by calling an API. 
    * @param id : Tenant identifier
    * @returns
    */
    DeleteTenantService = (id: number) => {
        this.deleteTenantState.inProgress = true;
        const url = URLConstants.Tenant + "/" + id;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteTenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteTenantState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteTenantState.inProgress = false; }));
    }

    /**
     * This function is used to Change Tenant Password by SuperAdmin calling an API.
     * @param data : New Password Details
     * @returns 
     */
    UpdatePasswordService = (data: any) => {
        this.changePasswordState.inProgress = true;
        let url: string = URLConstants.UpdateTenantPassword;
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.changePasswordState.error = response.data.Message;
                    // toast.error(formatMessage(response.data.Message));
                }
                else this.changePasswordState.success = true;
            })
            .catch((err: any) => {
                this.changePasswordState.error = err;
            }).finally(action(() => { this.changePasswordState.inProgress = false; }));
    }

    /*
    This function is used to reset all changePassword observables to their initial values.  
    */
    resetChangePassword = () => {
        this.changePasswordState = {...this.initialStateValue};
    }

   /**
    * This function is used to Updating Existing tenant state (i.e. active/inactive) Details by calling an API.
    * @param id : The Tenant Identifier
    * @param state : State (true/false for active/inactive) 
    * @returns 
    */
    UpdateTenantStatusService = (id: number, state: boolean) => {
        this.activeInactiveState.inProgress = true;
        let requestData: any = {
            Id: id,
            IsActive: state
        };
        let url = URLConstants.UpdateTenantStatus;
        return baseService.putRequest(url, requestData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.activeInactiveState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.activeInactiveState.success = true;
            })
            .catch((err: string) => {
                this.activeInactiveState.error = err;
            })
            .finally(action(() => { this.activeInactiveState.inProgress = false; }));

    }

     /*
    This function is used to reset all activeInactiveState observables to their initial values.  
    */
    resetActiveInactiveState = () => {
        this.activeInactiveState = {...this.initialStateValue}
    }

   /**
    * This function is used to get All Tenants list without pagination from API.
    * @returns 
    */
    exportAllTenantsService = () => {
        this.inProgress = true;
        const url = URLConstants.Tenant + "/" + "All";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantVM[]>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.downloadTenantCSV(response.data.Data);
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
     * This function is used to convert tenant list data to the corresponding CSV format
     * @param data : Tenant List data
     * @returns csvData suitable for creating blob object
     */
    convertToCSV = (data: any[]): string => {
        const header = Object.keys(data[0]).join(",");
        const rows = data.map((obj) => Object.values(obj).join(","));
        return `${header}\n${rows.join("\n")}`;
    };
    
    /**
     * This function downloads the CSV by dynamically making an <a> tag. 
     * @param data : Tenant List data
     */
    downloadTenantCSV = (data: any[]) => {
        const currentDate = moment(new Date()).format(DATE_TIME_FORMAT_FILENAME);
        const csvData = this.convertToCSV(this.exportTenantCSV(data));
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = currentDate + " Tenants.csv";
        document.body.appendChild(a);
        a.click();
        toast.success(formatMessage("download_started_successfully"));
        document.body.removeChild(a);
    };

    /**
     * This function is used to convert the tenantDetailsList to the required data suitable for convertToCSV function.
     * @param tenantDataList : Tenant List
     * @returns CSV data suitable for convertToCSV function
     */
    exportTenantCSV(tenantDataList:any): ITenantCSV[] {
        if (tenantDataList && tenantDataList?.length > 0)
            return tenantDataList?.map((tenant:any) => {
                return {
                    TenantName: tenant.TenantName,
                    Email: tenant.Email,
                    OrganizationId: tenant.OrganizationId,
                    OrganizationName: tenant.OrganizationName,
                    ContactPhoneNumber: tenant.ContactPhoneNumber,
                    ContactEmail: tenant.ContactEmail,
                    ContactPersonName: tenant.ContactPersonName,
                    MaxUserAllow: tenant.MaxUserAllow,
                    MaxDeviceAllow: tenant.MaxDeviceAllow,
                    VatNo: tenant.VatNo,
                    IsActive: tenant.IsActive,
                    IsTrialTenant:tenant.IsTrialTenant,
                    CreatedDate: moment(tenant.CreatedDate).format(dateFormat),
                }
            })
        return [];
    }

    /**
    * This function is used to check if the email already Exists or not by calling an API.
    * @param email : Login ID
    * @returns 
    */
    SelfServiceTenantEmailVerificationService = (email: string) => {
        this.isValidEmailState.inProgress = true;
        let data = { Email:email }
        let url = URLConstants.EmailExists;
        return baseService.postRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<ISelfServiceVerificationVM>>) => {
                if (response.data.Error) {
                    toast.error(formatMessage(response.data.Message));
                    this.isValidEmailState.error = response.data.Message;
                }
                else {
                    this.isValidEmail = response.data.Data;
                    toast.success(formatMessage(response.data.Message));
                    this.verifiedEmail = email;
                    this.isValidEmailState.success = true;
                }
            })
            .catch((err: string) => {
                // this.isValidEmailState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.isValidEmailState.inProgress = false; }));
    }

    /*
    This function is used to reset isValidEmailState observables to their initial values.  
    */
    resetIsValidEmailState = () => {
        this.isValidEmailState = {...this.initialStateValue};
    }

   /**
    * This function is used to verify the received OTP to create account by calling an API & sending email & otp.
    * @param email : User Email / Login ID
    * @param otp : OTP received on Email
    * @returns 
    */
    SelfServiceTenantOTPVerificationService = (email: string, otp:number) => {
        this.isOTPVerifiedState.inProgress = true;
        let data = { Email:email, OTP: otp };
        let url = URLConstants.VerifyEmailOTP;
        return baseService.postRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.isOTPVerifiedState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.isOTPVerified = true;
                    toast.success(formatMessage(response.data.Message));
                    this.isOTPVerifiedState.success = true;
                }
            })
            .catch((err: string) => {
                // this.isOTPVerifiedState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.isOTPVerifiedState.inProgress = false; }));
    }
    
    /*
    This function is used to reset isOTPVerifiedState observable to their initial values.  
    */
    resetIsOTPVerifiedState = () => {
        this.isOTPVerifiedState = {...this.initialStateValue};
        this.isValidEmail = {IsEmailConfimationOTPSent:false, IsEmailVerified:false};
    }

    /**
   * This function is used to Resend User Activation Link by calling an API.
   * @param data : User Data
   * @returns
   */
  ResendAccountActivateLinkService = (data: ISendMail) => {
    this.resendActivationLinkState.inProgress = true;
    let url = URLConstants.TrialTenant + "/Verification/ResendMail";
    return baseService
      .postRequest(url, {Email:data.Email})
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.resendActivationLinkState.error = response.data.Message;
        // toast.error(formatMessage(response.data.Message));
        } else this.resendActivationLinkState.success = true;
      })
      .catch((err: string) => {
        // toast.error(formatMessage(err));
        this.resendActivationLinkState.error = err;
      })
      .finally(
        action(() => {
          this.resendActivationLinkState.inProgress = false;
        })
      );
    };

    /**
    This function is used to reset all user observables to their initial values.  
    */
    resetAccountActivationLink = () => {
        this.resendActivationLinkState = {...this.initialStateValue};
    }

    /**
   * This function is used to Activate User Account by calling an API.
   * @param id : The User Identifier
   * @param data
   * @returns
   */
  ActivateOrganizationService = (
    VerificationCode: string,
    EmailId: string,
  ) => {
    this.activateOrganizationAccountState.inProgress = true;
    let url = URLConstants.TrialTenant + "/Verification?EmailId=" + EmailId?.trim() + "&VerificationCode=" + VerificationCode?.trim();
    return baseService
      .postRequest(url, {})
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.activateOrganizationAccountState.error = response.data.Message;
        } else {
          this.activateOrganizationAccountState.success = true;
        }
      })
      .catch((err: string) => {
        // toast.error(formatMessage(err));
        this.activateOrganizationAccountState.error = err;
      })
      .finally(
        action(() => {
          this.activateOrganizationAccountState.inProgress = false;
        })
      );
  };

    resetAccountActivationState = () => {
    this.activateOrganizationAccountState = { ...this.initialStateValue };
    };
    /**
     * This function is used to reset observables to their initial values.
     * @returns
     */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.deleteTenantState = {
            ...this.initialStateValue
        };
        this.changePasswordState = {...this.initialStateValue}
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.tenantDataList = allTenantInitialState;
        this.allTenants = [];

        this.tenant = undefined;
        this.addUpdateTenantState = { ...this.initialStateValue };
        this.addTrialTenantState = {...this.initialStateValue}
        this.deleteTenantState = { ...this.initialStateValue };
        this.tenantState = { ...this.initialStateValue };
        this.changePasswordState ={...this.initialStateValue };

        this.activeInactiveState ={...this.initialStateValue };
        this.dashboardTenantDetail = undefined;

        this.selectedTenantId = -1;

        this.isValidEmail  = {IsEmailConfimationOTPSent:false, IsEmailVerified:false};
        this.isValidEmailState = {...this.initialStateValue}

        this.isOTPVerifiedState = {...this.initialStateValue}
        this.isOTPVerified = false;
        this.verifiedEmail = "";
    }

    /*
    This function is used to set the selected tenant Id to the order observable.  
    */
    setSelectedTenantId = (tenantId: number) => {
        this.selectedTenantId = tenantId;
    }
    
    setSelectedOrderModuleTenantId = (tenantId: number) => {
        this.selectedOrderModuleTenantId = tenantId;
    }
}

export default new TenantStore();
