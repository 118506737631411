/*    
<summary>
   This class component is all about Managing pricingPlan data functionality.
   Developer:Aashish Singh, Created Date:17-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { initialState } from '../initial-state/add-edit-pricing-plan-state';
import { initialState as GetAllPricingPlanState } from '../initial-state/get-all-pricing-plan-state';
import { action, computed, makeObservable, observable } from 'mobx';
import { AuthStore } from './auth-store';
import { IPricingPlanState } from '../../models/state/IPricingPlanState';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { IPricingPlanListVM, IPricingPlanVM, IPricingPlanList } from '../../models/response/IPricingPlanResponse';
import { IAddUpdatePricingPlan } from '../../models/Form/IAddUpdatePricingPlan';
import { ICountryCurrencyDetail, IObservableInitialState, IOption } from '../../models/ICommon';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import { defaultCountryCurrency, emptyValue, emptyValueHyphen } from '../../constants/common-constants';
import { getLanguageFromLocalStorage } from '../../helpers/local-stotrage-helper';

const authStore = new AuthStore();

export class PricingPlanStore implements IPricingPlanState, ICommonState {
    inProgress = false;
    error = '';
    pricingPlanList: IPricingPlanListVM = GetAllPricingPlanState

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    allPricingPlans: IPricingPlanVM[] = [];

    addUpdatePricingPlanState ={...this.initialStateValue }
    deletePricingPlanState ={...this.initialStateValue }

    pricingPlan: IPricingPlanVM | undefined = undefined;
    pricingPlanState ={...this.initialStateValue }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            pricingPlanList: observable,
            pricingPlan: observable,
            allPricingPlans: observable,
            addUpdatePricingPlanState: observable,
            deletePricingPlanState: observable,
            pricingPlanState: observable,
            GetPricingPlanListService: action,
            GetAllPlansByOrderDesTypeService: action,
            AddPricingPlanService: action,
            UpdatePricingPlanService: action,
            DeletePricingPlanService: action,
            GetPricingPlanByIdService: action,
            reset: action,
            resetStore: action,
            resetPricingPlanDetail: action,
            resetAddUpdatePricingPlanState: action,
            allPricingPlanList: computed,
            pricingPlanDetail: computed,
            pricingPlanCountryCurrencyDetail: computed,
            allPricingPlansOptions: computed,
        });
    }

    /**
     * This function is used to Get All PricingPlans List data with pagination information by calling an API.
     * @param currentPage : Current Page Number
     * @param pagerSize : Page Size
     * @returns 
     */
    GetPricingPlanListService = (currentPage: number, pagerSize: number) => {
        this.inProgress = true;
        const url = URLConstants.PricingPlan + "/List?PageNo=" + currentPage + "&PageSize=" + pagerSize;
        // this.pricingPlanList = demoPricingPlanData;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IPricingPlanListVM>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.pricingPlanList = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

   /**
    * This function is used to map pricingPlanList to allPricingPlan List suitable for Grid Component.
    * @returns allPricingPlansList
    */
    get allPricingPlanList(): IPricingPlanList[] {
        if (this.pricingPlanList?.Plans && this.pricingPlanList.Plans?.length > 0)
            return this.pricingPlanList.Plans.map((pricingPlan:IPricingPlanVM) => {
                let isDelete = true;
                let isEdit = true;
                let language = getLanguageFromLocalStorage();
                return {
                    Id: pricingPlan.Id,
                    PlanName: pricingPlan.PlanName.trim(),
                    MaxNoOfDevicesSupport: (pricingPlan.OrderDescriptionType && pricingPlan.OrderDescriptionType?.Id == 3) ? emptyValueHyphen : Number(pricingPlan.MaxNoOfDevicesSupport),
                    MonthlyDataOperations: (pricingPlan.OrderDescriptionType && pricingPlan.OrderDescriptionType?.Id == 3) ? emptyValueHyphen :  Number(pricingPlan.MonthlyDataOperations),
                    YearlyPlanExpiryPeriod: (pricingPlan.OrderDescriptionType && pricingPlan.OrderDescriptionType?.Id == 3) ? emptyValueHyphen :  Number(pricingPlan.YearlyPlanExpiryPeriod),
                    MonthlyInventoryPeriod: (pricingPlan.OrderDescriptionType && pricingPlan.OrderDescriptionType?.Id == 3) ? emptyValueHyphen : Number(pricingPlan.MonthlyInventoryPeriod),
                    OrderDescriptionType: pricingPlan.OrderDescriptionType,
                    OrderDescriptionTypeString: pricingPlan.OrderDescriptionType ? pricingPlan.OrderDescriptionType?.TypeString : emptyValue,
                    Currency: pricingPlan.Currency,
                    PlanPrice: Number(pricingPlan.PlanPrice).toFixed(2),
                    ExcessDataSurcharge: (pricingPlan.OrderDescriptionType && pricingPlan.OrderDescriptionType?.Id == 3) ? emptyValueHyphen :  Number(pricingPlan.ExcessDataSurcharge).toFixed(2),
                    Tax: Number(pricingPlan.Tax).toFixed(2),
                    isDelete: isDelete,
                    Language: language,
                    isEdit: isEdit,
                    CreatedBy: pricingPlan.CreatedBy,
                    UpdatedBy: pricingPlan.UpdatedBy,
                }
            })
        return [];
    }

    /**
    * This function is used to get All Tenants without pagination by calling an API.
    * @returns 
    */
    GetAllPlansByOrderDesTypeService = (OrderDescriptionTypeId: number) => {
        this.inProgress = true;
        const url = URLConstants.PricingPlan + "/By/OrderDescriptionType/" + OrderDescriptionTypeId;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IPricingPlanVM[]>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allPricingPlans = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
    * This function is used to map allPricingPlans to allPricingPlansOptions suitable for FormikFormSelect Component.
    * @returns Tenant Options List containing values & id
    */
    get allPricingPlansOptions(): IOption[] {
        const options: IOption[] = [{
            id: -1,
            value: "please_select",
        }];
        if (this.allPricingPlans && this.allPricingPlans.length > 0)
            this.allPricingPlans.map((plan) => {
                options.push({
                    id: plan.Id,
                    value: plan.PlanName,
                })
            })
        return options;
    }

   /**
    * This function is used to Get PricingPlan Details by Id by calling an API.
    * @param id : The PricingPlan Identifier
    * @returns 
    */
    GetPricingPlanByIdService = (id: number) => {
        this.pricingPlanState.inProgress = true;
        let url = `${URLConstants.PricingPlan}/${id}`;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IPricingPlanVM>>) => {
                if (response.data.Error) {
                    // this.pricingPlanState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    let data = response.data.Data;
                    this.pricingPlan = data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.pricingPlanState.error = err;
            }).finally(action(() => { this.pricingPlanState.inProgress = false; }));
    }
    
   /**
    * This function provides initail values to the Add PricingPlan Form. 
    * @returns PricingPlan Details
    */
   get pricingPlanDetail() {
        if (this.pricingPlan) {
            return {
                PlanName: this.pricingPlan.PlanName.trim(),
                MaxNoOfDevicesSupport: Number(this.pricingPlan.MaxNoOfDevicesSupport),
                MonthlyDataOperations: Number(this.pricingPlan.MonthlyDataOperations),
                YearlyPlanExpiryPeriod: this.pricingPlan.YearlyPlanExpiryPeriod > 0 ? Number(this.pricingPlan.YearlyPlanExpiryPeriod) : -1,
                MonthlyInventoryPeriod: Number(this.pricingPlan.MonthlyInventoryPeriod),
                CountryCurrencyId: Number(this.pricingPlan.Currency.Id),
                OrderDescriptionType: this.pricingPlan.OrderDescriptionType ? this.pricingPlan.OrderDescriptionType : {...initialState.OrderDescriptionType},
                PlanPrice: Number(this.pricingPlan.PlanPrice),
                ExcessDataSurcharge: Number(this.pricingPlan.ExcessDataSurcharge),
                Tax: Number(this.pricingPlan.Tax),
            }
        }
        return initialState;
    }
   
    /**
    * This function provides initail values to the Add PricingPlan Form. 
    * @returns PricingPlan Details
    */
   get pricingPlanCountryCurrencyDetail(): ICountryCurrencyDetail {
    let currency = defaultCountryCurrency;
        if (this.pricingPlan && this.pricingPlan.Currency) {
            currency =  {
                id: this.pricingPlan.Currency.Id,
                symbol: this.pricingPlan.Currency.Symbol,
                placeholder: this.pricingPlan.Currency.CurrencyName + " (" + this.pricingPlan.Currency.Symbol +")"
            }
            
        }
        return currency;
    }
    
    /*
    This function is used to reset all pricingPlan observables to their initial values.  
    */
    resetPricingPlanDetail = () => {
        this.pricingPlan = undefined;
        this.pricingPlanState = {...this.initialStateValue}
    }

   /**
    * This function is used to Add New PricingPlan by calling an API.
    * @param data : PricingPlan Data
    * @returns 
    */
    AddPricingPlanService = (data: any) => {
        this.addUpdatePricingPlanState.inProgress = true;
        let url = URLConstants.PricingPlan;
        let pricingPlanData : any = {
            OrderDescriptionTypeId: parseInt(data.OrderDescriptionType.Id),
            PlanName: data.PlanName.trim(),
            MonthlyDataOperations: Number(data.MonthlyDataOperations),
            MaxNoOfDevicesSupport: Number(data.MaxNoOfDevicesSupport),
            YearlyPlanExpiryPeriod: Number(data.YearlyPlanExpiryPeriod),
            MonthlyInventoryPeriod: Number(data.MonthlyInventoryPeriod),
            CountryCurrencyId: Number(data.CountryCurrencyId),
            PlanPrice: Number(data.PlanPrice),
            ExcessDataSurcharge: Number(data.ExcessDataSurcharge),
            Tax: Number(data.Tax),
        }
        return baseService.postRequest(url, pricingPlanData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    // this.addUpdatePricingPlanState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdatePricingPlanState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.addUpdatePricingPlanState.error = err;
            })
            .finally(action(() => { this.addUpdatePricingPlanState.inProgress = false; }));
    }

    /**
     * This function is used to Updating Existing PricingPlan Details by calling an API.
     * @param id : The PricingPlan Identifier
     * @param data 
     * @returns 
     */
    UpdatePricingPlanService = (id: number, data: any) => {
        this.addUpdatePricingPlanState.inProgress = true;
        let url = URLConstants.PricingPlan;
        let pricingPlanData: any = {
                Id: id,
                OrderDescriptionTypeId: parseInt(data.OrderDescriptionType.Id),
                PlanName: data.PlanName.trim(),
                MaxNoOfDevicesSupport: Number(data.MaxNoOfDevicesSupport),
                MonthlyDataOperations: Number(data.MonthlyDataOperations),
                YearlyPlanExpiryPeriod: Number(data.YearlyPlanExpiryPeriod),
                MonthlyInventoryPeriod: Number(data.MonthlyInventoryPeriod),
                CountryCurrencyId: Number(data.CountryCurrencyId),
                PlanPrice: Number(data.PlanPrice),
                ExcessDataSurcharge: Number(data.ExcessDataSurcharge),
                Tax: Number(data.Tax),
            };
        return baseService.putRequest(url, pricingPlanData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    // this.addUpdatePricingPlanState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdatePricingPlanState.success = true;
            })
            .catch((err: string) => {
                // this.addUpdatePricingPlanState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addUpdatePricingPlanState.inProgress = false; }));

    }
    
    /*
    This function is used to reset all AddPricingPlan observables to their initial values.  
    */
    resetAddUpdatePricingPlanState = () => {
        this.addUpdatePricingPlanState.inProgress = false;
        this.addUpdatePricingPlanState.success = false;
        this.addUpdatePricingPlanState.error = '';
    }

   /**
    * This function is used to Delete PricingPlan by calling an API.
    * @param id : The PricingPlan Identifier
    * @returns 
    */
    DeletePricingPlanService = (id: number) => {
        this.deletePricingPlanState.inProgress = true;
        let url = `${URLConstants.PricingPlan}/${id}`;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deletePricingPlanState.error = response.data.Message;
                    // toast.error(formatMessage(response.data.Message));
                }
                else this.deletePricingPlanState.success = true;
            })
            .catch((err: string) => {
                this.deletePricingPlanState.error = err;
            }).finally(action(() => { this.deletePricingPlanState.inProgress = false; }));
    }

    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.addUpdatePricingPlanState.success = false;
        this.addUpdatePricingPlanState.error = '';
        this.deletePricingPlanState = {...this.initialStateValue}
        this.addUpdatePricingPlanState.inProgress = false;
        this.pricingPlanState = {...this.initialStateValue}
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.pricingPlanList = GetAllPricingPlanState

        this.addUpdatePricingPlanState ={...this.initialStateValue }
        this.deletePricingPlanState ={...this.initialStateValue }

        this.pricingPlan = undefined;
        this.pricingPlanState ={...this.initialStateValue }

        this.allPricingPlans = [];

    }

}

export default new PricingPlanStore();
