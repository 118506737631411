import { Formik, Form, Field } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/Label/Label";
import { auditLogFormValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { IAuditLogFilterDetails } from "../../../models/response/IAuditLogsResponse";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikDateFieldMUI";
import moment from "moment";
import config from "../../../helpers/config-helper";

interface IProps {
  initialValues: IAuditLogFilterDetails;
  submitHandler: (values: IAuditLogFilterDetails) => void;
  isFilterActive: boolean;
  modalClosed: () => void;
}

const appConfig = config();
const dateFormat =  appConfig.REACT_APP_DATE_FORMAT;

export const AuditLogFilterForm = (props: IProps) => {
  const { initialValues } = props;

  return (
    <React.Fragment>
      <Modal
        className="correction-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="filter" />
          </Modal.Title>
          <button
            onClick={props.modalClosed}
            title={formatMessage("close")}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validateOnBlur={false}
            validationSchema={auditLogFormValidationSchema}
            onSubmit={async (values: any) => {
              let StartDate =
                new Date(values.StartDate?.$d).toString() !== "Invalid Date"
                  ? moment(values.StartDate.$d).format(dateFormat)
                  : values.StartDate;
              let EndDate =
                new Date(values.EndDate.$d).toString() !== "Invalid Date"
                  ? moment(values.EndDate.$d).format(dateFormat)
                  : values.EndDate;
              let formData: IAuditLogFilterDetails = {
                StartDate,
                EndDate,
              };
              if (formData.StartDate == "" && formData.EndDate == "")
                toast.error(formatMessage("all_fields_cannot_be_empty"));
              else props.submitHandler({ ...formData });
            }}
            render={({ values }) => {
              return (
                <Form className="user-form p-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label
                          className="label-style"
                          required={true}
                          label="start_date"
                        />
                        <Field name="StartDate" 
                          as={FormikDateFieldMUI} 
                          min={moment().subtract(3, 'months').format('YYYY-MM-DD')}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <Label
                          className="label-style"
                          required={true}
                          label="end_date"
                        />
                        <Field name="EndDate" 
                          as={FormikDateFieldMUI} 
                          min={moment().subtract(3, 'months').format('YYYY-MM-DD')}
                        />
                      </div>
                    </div>
                  </div>
                  <li className="col d-flex justify-content-end pb-2">
                    <button
                      data-testid="apply"
                      className="btn btn-secondary me-1"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => {
                        props.modalClosed();
                      }}
                    >
                      <FormattedMessage id="button_cancel" />
                    </button>
                    <LoaderButton
                      type="submit"
                      className="ms-2"
                      text="apply"
                      // disabled={(values.TenantId == -1 && values.Month == 0 && values.Year == 0) ? true : false}
                    />
                  </li>
                </Form>
              );
            }}
          ></Formik>
        </div>
      </Modal>
    </React.Fragment>
  );
};
