/*    
<summary>
   This file is used to provide initial values or state to the Order Module observables.
    Developer:Aashish Singh, Created Date:14-June-2024
</summary>
*/

import { IPaymentHistoryListVM } from "../../models/response/IPaymentHistoryResponse";

export const initialState: IPaymentHistoryListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    PaymentHistory: []
}