/*    
<summary>
   This is the Users Component which shows the Users Data.
   Developer: Aashish Singh, Created Date:29-August-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React from "react";
import "./documentation.css";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import documentation from "../../assets/Images/svg/documentation.svg"
import startsvg from "../../assets/Images/svg/001-shuttle.svg"
import configsvg from "../../assets/Images/svg/002-setting-lines.svg"
import groupsvg from "../../assets/Images/svg/004-cells.svg"
import devicesvg from "../../assets/Images/svg/003-server.svg"

const Documentation = (props: any) => {

  const configHandler =(div_id:string) =>{
    props.history.push({
      pathname: RoutesConstants.SingleView,
      state: {
        ...props.location.state,
        div_id,
      },
    });
  }

  return (
    <React.Fragment>
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row g-0  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 ps-5 mb-0">
                        <FormattedMessage id="documentation_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="knowledge-container">
        <div className="row section-header mt-3 mb-4">
          <div className="col-lg-2 col-sm-4">
            <img src={documentation} alt="/" className="img-fluid no_message" />
          </div>
          <div className="col-lg-10 col-sm-8">
            <h3>Documentation</h3>
            <p>
              Here you'll find all the information you need regarding our system
              and you can also ask for support
            </p>
          </div>
        </div>
        <div className="row justify-content-center mb-30-none">
          <div className="col-lg-6 col-md-10">
            <div className="knowledge-item">
              <div className="knowledge-thumb">
                <img src={startsvg} alt="" />
              </div>
              <div
                className="knowledge-content"
                onClick={() => props.history.push(RoutesConstants.SingleView)}
              >
                <h4 className="title">
                  <a>Get started</a>
                </h4>
                <p>
                  Set up your account, configure support channels, customize
                  your portal.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-10">
            <div className="knowledge-item">
              <div className="knowledge-thumb">
                <img src={configsvg} alt="" />
              </div>
              <div className="knowledge-content" onClick={() => configHandler("doc_configure") }>
                <h4 className="title">
                  <a 
                  >Configurations</a>
                  
                </h4>
                <p>
                  {" "}
                  Set up essential parameters and establish connections to
                  enable device functionality.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-10">
            <div className="knowledge-item">
              <div className="knowledge-thumb">
                <img src={groupsvg} alt="" />
              </div>
              <div className="knowledge-content" onClick={() => configHandler("doc_group") }>
                <h4 className="title">
                  <a>Group</a>
                </h4>
                <p>
                  Unified configuration deployment for streamlined management
                  and synchronized behavior.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-10">
            <div className="knowledge-item">
              <div className="knowledge-thumb">
                <img src={devicesvg} alt="" />
              </div>
              <div className="knowledge-content" onClick={() => configHandler("doc_device") }>
                <h4 className="title">
                  <a>Device Management</a>
                </h4>
                <p>
                  ICCID linking, activation, and protocol selection for
                  efficient connectivity and control.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(Documentation);
