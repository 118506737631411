import roleTypeEnum from "../constants/role-type-enum";
import userTypeEnum from "../constants/user-type-enum";

export const getRoleTypeFromLocalStorage = () => {
    let roleType = localStorage.getItem('mzl_role_type');
    let role = roleType ? roleType : roleTypeEnum.TenantAdmin.toString();
    return parseInt(role);
}

export const getUserTypeFromLocalStorage = () => {
    let userType = localStorage.getItem('mzl_user_type');
    let type = userType ? userType : userTypeEnum.TenantUser.toString();
    return parseInt(type);
}

export const getTenantIdFromLocalStorage = () => {
    let tenantId = localStorage.getItem('mzl_tenantId');
    let id = tenantId ? tenantId : "-1";
    return parseInt(id);
}

export const getLoggedInTenantIdFromLocalStorage = () => {
    let tenantId = localStorage.getItem('mzl_login_tenantId');
    let id = tenantId ? tenantId : "-1";
    return parseInt(id);
}

export const getSelectedResendFromLocalStorage = () => {
    let email = localStorage.getItem('mzl_selected_resend_email');
    let teampEmail = email ? email : "";
    return teampEmail;
}

export const getEmailFromLocalStorage = () => {
    let email = localStorage.getItem('mzl_email');
    let teampEmail = email ? email : "";
    return teampEmail;
}

export const getTokenFromLocalStorage = () => {
    let token = localStorage.getItem('mzl_token');
    return token ? token : "";
}

export const getRoleNameFromLocalStorage = () => {
    let roleName = localStorage.getItem('mzl_role_name');
    return roleName ? roleName : "";
}

export const getPermissionsFromLocalStorage = () => {
    let permissions = localStorage.getItem('mzl_permissions');
    return permissions ? permissions : [];
}

export const getLanguageFromLocalStorage = () => {
    let language = localStorage.getItem('mzl_language');
    return language ? language : 'en';
}

export const getThemeFromLocalStorage = () => {
    let theme = localStorage.getItem('mzl_darkTheme');
    return theme ? theme == "true" : false;
}