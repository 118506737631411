/*    
<summary>
  This functional component "AddUpdateDevice" defines the props like initial values & functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date: 29 March 2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import AddUpdateDeviceForm from "./forms/add-edit-device-form";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import IAddDevices from "../../../models/Form/IAddUpdateDevice";
import { useStore } from "../../../contexts/StoreProvider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { cloneDeep } from "lodash";
import _ from "lodash";
import { IOption } from "../../../models/ICommon";
import { getTenantIdFromLocalStorage, getUserTypeFromLocalStorage } from "../../../helpers/local-stotrage-helper";
import userTypeEnum from "../../../constants/user-type-enum";
import protocolEnum from "../../../constants/protocol-enum";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateDevice = (props: IProps) => {
  const { deviceStore, groupStore, tenantStore } = useStore();
  const {
    addDevice,
    updateDevice,
    addUpdateDevicestate,
    resetAddUpdateDevice,
    getDeviceById,
    devieDetail,
    resetGetDeviceDetail,
    deviceDetailState,
  } = deviceStore;
  const {allAvailableGroupsAwsIotCore , allGroups,GetAllGroupService } = groupStore;
  const {selectedTenantId } = tenantStore;
  const [availableGroups, setAvailableGroups] = useState<any>(
    []
  );

  /**
   * This function saves the time correction details by provides new time  to "saveDeviceTimeCorrection" store funtion that
   * send the request to Api.
   */
  let t: any;

  const onSubmitForm = (values: IAddDevices) => {
    let tempValues = cloneDeep(values);
    tempValues.Name = values.Name.trim();
    tempValues.ClientCertificate = btoa(values.ClientCertificate);
    tempValues.ClientKey = btoa(values.ClientKey);
    // tempValues.ClientCertificateString = btoa(values.ClientCertificate);
    // tempValues.ClientKeyString = btoa(values.ClientKey);
    delete tempValues.ClientKeyData;
    delete tempValues.ClientCertificateData;
    if (props.id < 0) {
      addDevice(tempValues); //commment this code when uou use above code for adding multiple devices
    } else{
      updateDevice(props.id, tempValues);
    }
    // else {
    //   let defaultValue = _.omit(devieDetail, ["FirstPacketTimestamp"])
    //   if(JSON.stringify(defaultValue) != JSON.stringify(data)){
    //     updateDevice(props.id, tempValues);
    //   }
    //   else{
    //     onModalClosed();
    //   }
    // }
  };
  /**
   * This function closes the pop up modal and reset add update device, get device detail observables (success, inprogress and error).
   */
  const onModalClosed = () => {
    setAvailableGroups([]);
    resetAddUpdateDevice();
    resetGetDeviceDetail();
    props.modalClosed();
  };

  useEffect(() => {
    if (props.id > 0) {
      const configOptions: IOption[] = [
        {
          id: -1,
          value: "please_select",
        },
      ];
      if (allGroups && allGroups?.length > 0)
        allGroups?.map((groups: any) => {
          configOptions.push({
            id: groups.Id,
            value:groups.Name + (groups.IsDefault ? " (" + formatMessage("default") + ")": ""),
            disabled: devieDetail?.Protocol == protocolEnum.AWSIoT ?
              (devieDetail?.Protocol == groups?.ConfigurationProtocol?.DestinationProtocol ? false : true) :
              (protocolEnum.AWSIoT == groups?.ConfigurationProtocol?.DestinationProtocol ? true : false),
            protocol: groups?.ConfigurationProtocol?.DestinationProtocol
          });
        });
      setAvailableGroups(configOptions);
    }
  }, [
    allGroups.length > 0 && devieDetail.GroupId > -1,
  ]);

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0){ 
      let tenantId:number = getUserTypeFromLocalStorage() === userTypeEnum.SystemUser ? selectedTenantId : getTenantIdFromLocalStorage();
      getDeviceById(props.id, tenantId);
    }
    if(getUserTypeFromLocalStorage() === userTypeEnum.SystemUser)
      GetAllGroupService(selectedTenantId);
    else
      GetAllGroupService(getTenantIdFromLocalStorage());
  }, []);
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateDevicestate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateDevicestate.success) {
      if (props.id < 0) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateDevicestate.success]);

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id < 0 ? (
              <FormattedMessage id="add_device" />
            ) : (
              <FormattedMessage id="update_device" />
            )}
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            title={formatMessage("close")}
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <AddUpdateDeviceForm
            Id={props.id}
            initialValues={_.omit(devieDetail, ["FirstPacketTimestamp"])}
            protocol={devieDetail.Protocol ? devieDetail.Protocol : -1}
            groupList={props.id < 0 ? allAvailableGroupsAwsIotCore : availableGroups}
            submitHandler={onSubmitForm}
            isLoading={
              props.id < 0
                ? addUpdateDevicestate.inProgress
                : deviceDetailState.inProgress
            }
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddUpdateDevice);
