/*    
<summary>
  This functional component "AddUpdateGroupForm" provides form to add new user or update existing user.
  Developer:Mohammad Saquib Khan, Created Date: 29-March-2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import "./add-edit-group-form.css";
import { observer } from "mobx-react-lite";
import { Button } from "react-bootstrap";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import FormikFormTextArea from "../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { groupValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import IAddGroup from "../../../../models/Form/IAddGroup";
import { useStore } from "../../../../contexts/StoreProvider";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import Label from "../../../../shared-components/Label/Label";
import { formatMessage } from "../../../../translations/format-message";
import { Tooltip } from "react-tooltip";
import FormikFormCheckBox from "../../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import { IOption } from "../../../../models/ICommon";
import protocolEnum from "../../../../constants/protocol-enum";
import { getThemeFromLocalStorage } from "../../../../helpers/local-stotrage-helper";

interface IProps {
  initialValues: IAddGroup;
  id:number;
  allConfigurations:any;
  submitHandler: (data: any) => void;
  isLoading: boolean;
  modalClosed: () => void;
}

const AddEditGroupForm = (props: IProps) => {
  const { configurationStore } = useStore();
  const { allAvailableConfigurationsOptions, allconfigurations } =
    configurationStore;


  /**
   * Area Block for Get functions/variables from store class
   * UpdatedBy:Mohammad Saquib Khan , Date:24-April-2023
   */

  const { initialValues, submitHandler, modalClosed, isLoading } = props;

  /**End */

  return (
    <>
      <div className="mx-auto">
        {isLoading && <FormLoader loading={isLoading} />}
        <Formik
          initialValues={initialValues}
          validationSchema={groupValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            values.ConfigurationProtocolId = Number(
              values.ConfigurationProtocolId
            );
            submitHandler(values);
          }}
          >
          {({ values  }) => {
            return (
              <Form id="formBody">
                <div className="p-3 position-relative">
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style text-dark"
                          required={true}
                          label="group_name"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-info-circle ms-2"
                          viewBox="0 0 16 16"
                          data-tooltip-id="my-tooltip-group"
                          data-tooltip-html={formatMessage("name_validation")}
                          data-tooltip-variant="dark"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <Tooltip id="my-tooltip-group" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                        <Field
                          data-testid="GroupName"
                          className="form-control"
                          name="Name"
                          validcharacter={['@','#','$','-','_','.']}
                          placeholder="group_name"
                          type="text"
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style text-dark"
                          required={true}
                          label="available_configs"
                        />
                        <Field
                          id="Configurations"
                          className="form-control"
                          name="ConfigurationProtocolId"
                          disabled={props.id > 0 ? ((initialValues.ConfigurationProtocol) && initialValues.ConfigurationProtocol?.DestinationProtocol == protocolEnum.AWSIoT ? true : false) : false}
                          options={props.id > 0 ? props.allConfigurations : allAvailableConfigurationsOptions}
                          as={FormikFormSelect}
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-1">
                      <div className="form-group formSet">
                        <label className="label-style text-dark">
                          <FormattedMessage id="desc" />
                        </label>
                        <Field
                          id="GroupDescription"
                          name="Description"
                          placeholder="enter_text"
                          type="text"
                          className="form-control"
                          rows={5}
                          style={{ resize: "none" }}
                          as={FormikFormTextArea}
                        />
                      </div>
                    </div>
                    <div className="checkbox col-6">
                      <label className="d-flex align-items-center">
                        <Field
                          data-testid="IsDefault"
                          className="form-control"
                          name="IsDefault"
                          role="switch"
                          disabled={initialValues.IsDefault}
                          type="checkbox"
                          id="inlineFormCheck"
                          as={FormikFormCheckBox}
                        />
                        <span className="cr mt-0">
                          <i className="cr-icon fas fa-check"></i>
                        </span>
                        <span>
                          <FormattedMessage id="is_default" />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-info-circle ms-2"
                            viewBox="0 0 16 16"
                            data-tooltip-id="defalut-registered-device-group"
                            data-tooltip-html={formatMessage("default_registered_devices_group_infotip")}
                            data-tooltip-variant="dark"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                          <Tooltip id="defalut-registered-device-group" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                        </span>
                      </label>
                    </div>
                    <div className="checkbox col-6">
                      <label className="d-flex align-items-center">
                        <Field
                          data-testid="IsLocked"
                          className="form-control"
                          name="IsLocked"
                          role="switch"
                          type="checkbox"
                          id="inlineFormCheck"
                          as={FormikFormCheckBox}
                        />
                        <span className="cr mt-0">
                          <i className="cr-icon fas fa-check"></i>
                        </span>
                        <span>
                          <FormattedMessage id="imei_lock" />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-info-circle ms-2"
                            viewBox="0 0 16 16"
                            data-tooltip-id="imeilock-tooltip-group"
                            data-tooltip-html={formatMessage("group_imei_lock_infotip")}
                            data-tooltip-variant="dark"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                          <Tooltip id="imeilock-tooltip-group" place="right" className={getThemeFromLocalStorage() ? "dark-theme-wrap" : ""}/>
                        </span>
                        
                      </label>
                    </div>
                  </div>
                </div>

                <div className="modal-footer pt-0 border-0">
                  <LoaderButton
                    isLoading={props.isLoading}
                    type="submit"
                    // type={props.id < 0 ? "submit" : (dirty ? "submit" :"button")}
                    // onClick={props.id < 0 ? undefined : (dirty ? undefined : ()=>{
                    //   // toast.success(formatMessage("no_change_detected"));
                    //   modalClosed();
                    // })}
                    id={"SaveGroup"}
                    text={props.id > 0 ? "update" :"button_save"}
                  />
                  <Button
                    data-testid={"Cancel"}
                    variant="secondary"
                    type="button"
                    onClick={modalClosed}
                    disabled={false}
                    className=""
                  >
                    <FormattedMessage id="close" />
                  </Button>
                </div>
              </Form>
            );
          }}
          </Formik>
      </div>
    </>
  );
};

export default observer(AddEditGroupForm);
