import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { formatMessage } from "../../../translations/format-message";
import DeviceRegisterFilterForm from "../forms/device-register-filter-form";
import { IDeviceRegisterFilterDetails } from "../../../models/response/IRegisterDeviceResponse";

interface IProps {
  initialValues: IDeviceRegisterFilterDetails;
  submitHandler: (values: IDeviceRegisterFilterDetails) => void;
  modalClosed: () => void;
  isFilterActive: boolean;
}

const DeviceRegisterFilter = (props: IProps) => {

  const onModalClosed = () => {
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <Modal
        className="fancy-modal"
        show={true}
        centered={false}
        size="sm"
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="filter" />
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
            title={formatMessage("close")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <DeviceRegisterFilterForm
            initialValues={props.initialValues}
            isFilterActive={props.isFilterActive}
            submitHandler={props.submitHandler}
            modalClosed={props.modalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(DeviceRegisterFilter);
