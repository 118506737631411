/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   Developer: Aashish Singh, Created Date: 22-Nov-2023 
</summary>
*/
enum systemModuleEnum{
    Tenant = 0,
    Dashboard = 1,
    Configuration = 2,
    Group = 3,
    Device = 4,
    User = 5,
    AuditLog = 6,
    SIM = 7,
    Plan = 8,
    Order = 9,
    Role = 10,
    DeviceRegisteration=11,
    Statistics = 12,
    Fota = 13
}

export default systemModuleEnum;