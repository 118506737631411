/*    
<summary>
  This functional component shows the order details.
  Developer:Aashish Singh, Created Date: 10-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import "./order-detail.css";
import { IOrderList } from "../../../models/response/IOrderResponse";
import { useStore } from "../../../contexts/StoreProvider";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import OrderStatusEnum from "../../../constants/order-status-enum";
import moment from "moment";
import config from "../../../helpers/config-helper";
import PaymentStatusEnum from "../../../constants/payment-status-enum";
import userTypeEnum from "../../../constants/user-type-enum";
import { getUserTypeFromLocalStorage, getTenantIdFromLocalStorage } from "../../../helpers/local-stotrage-helper";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  selectedData: IOrderList;
}

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

const OrderDetails = (props: IProps) => {
  const { orderStore, tenantStore } = useStore();
  const { GetOrderByIdService, orderDetail, order, orderState } = orderStore;
  const { selectedTenantId } = tenantStore;

  /**
   * This function calls the "GetOrderByIdService" store funtion that get group data from API
   */
  const callGetOrderById = (tenantId: number) => {
    GetOrderByIdService(props?.selectedData.Id, tenantId);
  };

  useEffect(() => {
    if (props?.selectedData.Id > 0) {
        let tenantId:number = getUserTypeFromLocalStorage() === userTypeEnum.SystemUser ? selectedTenantId : getTenantIdFromLocalStorage();
        callGetOrderById(tenantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedData.Id]);

  return (
    <React.Fragment>
        {orderState.inProgress && <FormLoader loading={orderState.inProgress} />}
        {
            orderDetail &&
            <>
                <div className="order_panel_title">
                    <div className="row">
                        <div className="col-6">
                            <p className="small text-muted m-0">
                                <FormattedMessage id={"order_description_type_string"} />
                            </p>
                            <p>
                            {(orderDetail?.OrderDescriptionType && orderDetail.OrderDescriptionType?.TypeString) &&
                                    orderDetail.OrderDescriptionType.TypeString
                                }
                            </p>
                            <p className="small text-muted mb-0 mt-3">
                                <FormattedMessage id={"order_date"} />
                            </p>
                            <p>
                            {moment(orderDetail.CreatedDate).format(dateTimeFormat)
                                }
                            </p>
                        </div>
                        <div className="col-6 text-end">
                            <p className="small text-muted m-0">
                                <FormattedMessage id={"tenant_name"} />
                            </p>
                            <p>
                                {orderDetail?.Tenant?.TenantName}
                            </p>
                        
                            <p className="small text-muted mb-0 mt-3">
                                <FormattedMessage id={"order_status"} />
                            </p>
                            <p>
                            {
                                formatMessage(OrderStatusEnum[orderDetail.OrderStatus])
                            }
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3 order_panel_detail">
                    <div className="row py-2">
                        <div className="col-6">
                            <label className="text-muted bold">
                            <FormattedMessage id="shipping_address" />:{" "}
                            </label>
                        
                            <p className="mw-100">
                                {orderDetail.ShippingAddress}
                            </p>
                            
                        </div>
                     
                        <div className="col-6 text-end">
                            <label className="text-muted bold">
                            <FormattedMessage id="billing_address" />:{" "}
                            </label>
                        
                            <p className="mw-100">
                                {orderDetail.BillingAddress}
                            </p>
                        </div>
                        <div className="col-12">
                            <label className="text-muted bold mt-3">
                            <FormattedMessage id="remarks" />:{" "}
                            </label>
                         
                            <p className="mw-100">
                                {orderDetail.Remarks.length > 0 ? orderDetail.Remarks : "-"}
                            </p>
                        </div>
                    </div>
                    <table className="table table-bordered order_panel_table mt-4 mb-3">
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id={"plan_name"} />
                                </th>
                                <th>
                                    <FormattedMessage id={"life_time"} />
                                </th>
                                <th>
                                    <FormattedMessage id={"no_of_devices_order"} />
                                </th>
                                <th>
                                    <FormattedMessage id={"price_per_qty"} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{orderDetail?.Plan?.PlanName}</td>
                                <td> {(orderDetail.Plan && orderDetail.Plan.YearlyPlanExpiryPeriod &&
                                orderDetail.Plan.YearlyPlanExpiryPeriod) > 0 ? <> {orderDetail.Plan.YearlyPlanExpiryPeriod} <FormattedMessage id={ orderDetail.Plan.YearlyPlanExpiryPeriod == 1 ? "year" : "years"} /> </> : orderDetail.Plan.YearlyPlanExpiryPeriod}
                                </td>
                                <td>{orderDetail.NoOfDevicesOrder}</td>
                                <td>{orderDetail?.Plan?.PlanPrice}</td>
                            </tr>
                        </tbody>
                    </table>

                    
                    <div className="row">
                        <div className="col-4">
                            <label className="text-muted">
                            <FormattedMessage id="payment_status" />:{" "}
                            </label>
                            <p className="badge bg-danger">
                                {
                                    PaymentStatusEnum[orderDetail.PaymentStatus]
                                }
                            </p>
                        </div>
                        <div className="col-8">
                            <div className="pe-4 ps-2 py-1 d-flex align-items-center justify-content-between bg-light">
                                <p className="m-0">
                                    <FormattedMessage id={"total_amount"} />
                                </p>
                                <h3 className=" text-end">
                                    {orderDetail.TotalOrderAmount}
                                </h3>
                            </div>
                            <div className="pe-4 ps-2 py-1 d-flex align-items-center justify-content-between bg-light">
                                <p className="text-danger m-0">
                                    <FormattedMessage id={"pending"} />
                                </p>
                                <h3 className="text-danger text-end">
                                    {orderDetail.PendingAmount}
                                </h3>
                            </div>

                        </div>

                    </div>
                    
                </div>
            </>
        }
    </React.Fragment>
  );
};

export default observer(OrderDetails);
