/*    
<summary>
  This functional component "AddUpdateDeviceRegister" defines the props like initial values & functions for the child form component.
  Developer:Aashish Singh, Created Date: 11-June-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import AddUpdateDeviceRegisterForm from "../forms/add-edit-registered-device-form";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import IAddDeviceRegister from "../../../models/Form/IAddUpdateDeviceRegister";
import { useStore } from "../../../contexts/StoreProvider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { cloneDeep } from "lodash";
import _ from "lodash";

interface IProps {
  id: number;
  tenantId: number;
  modalClosed: () => void;
}

const AddUpdateDeviceRegister = (props: IProps) => {
  const { deviceRegisterStore, orderStore, tenantStore } = useStore();
  const {
    addDeviceRegister,
    UpdateDeviceRegisterService,
    addUpdateDeviceRegisterState,
    resetAddUpdateDeviceRegister,
    getDevieIccidDetail,
    getRegisteredDeviceByIdService,
    resetGetRegisterDeviceDetail
  } = deviceRegisterStore;
  const { GetAllTenantsService, allTenantOptions, reset } = tenantStore;
  const [selctedTenantId, setSelctedTenantId] = useState<number>(props.tenantId);
  const { allDeliveredOrderState, getAllDeliveredOrderOptions, resetAllDeliveredOrderState} = orderStore;
  
  /**
   * This function saves the time correction details by provides new time  to "saveDeviceRegisterTimeCorrection" store funtion that
   * send the request to Api.
   */
  let t: any;

  const onSubmitForm = (values: IAddDeviceRegister) => {
    let tempValues = cloneDeep(values);
    if (props.id < 0)
      addDeviceRegister(tempValues, values.TenantId); 
    else
      UpdateDeviceRegisterService(props.id, tempValues, values.TenantId);
  };
  /**
   * This function closes the pop up modal and reset add update device, get device detail observables (success, inprogress and error).
   */
  const onModalClosed = () => {
    resetAllDeliveredOrderState();
    resetAddUpdateDeviceRegister();
    resetGetRegisterDeviceDetail();
    setSelctedTenantId(-1);
    props.modalClosed();
  };

/**
 * The useEffect Hook lets you perform side effects in function component.
 * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
 * while a dependency of the useEffect has changed.
 */
  useEffect(() => {
    if (props.id > 0) getRegisteredDeviceByIdService(props.id);
    if(props.tenantId === -1)
      GetAllTenantsService();
  }, []);
  
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateDeviceRegisterstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateDeviceRegisterState.success) {
      if (props.id > 0) 
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateDeviceRegisterState.success]);

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id < 0 ? (
              <FormattedMessage id="add_device_iccid" />
            ) : (
              <FormattedMessage id="update_device_iccid" />
            )}
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
            title={formatMessage("close")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <AddUpdateDeviceRegisterForm
            Id={props.id}
            initialValues={{...getDevieIccidDetail, TenantId: props.id > 0 ? getDevieIccidDetail.TenantId : props.tenantId}}
            selectedTenantId={props.id > 0 ? getDevieIccidDetail.TenantId : selctedTenantId}
            setSelectedTenantId={setSelctedTenantId}
            tenantOptions={allTenantOptions}
            submitHandler={onSubmitForm}
            deliveredOrderList={getAllDeliveredOrderOptions}
            isLoading={ addUpdateDeviceRegisterState.inProgress || allDeliveredOrderState.inProgress}
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddUpdateDeviceRegister);
